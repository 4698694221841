import React from 'react';
import PropTypes from 'prop-types';
import { useLocalization } from '@progress/kendo-react-intl';
import { useAuthentication } from 'global/hooks';
import { tzDictionary } from 'global/utils';
import styles from './DateSquare.module.scss';

interface DateSquareProps {
  date?: number;
  novelty?: boolean;
  priority?: boolean;
}

const getDay = (date: Date, culture: string, timeZone: string) =>
  date.toLocaleString(culture, {
    day: 'numeric',
    timeZone,
  });

const getMonth = (date: Date, culture: string, timeZone: string) => {
  const month = date.toLocaleString(culture, {
    month: 'short',
    timeZone,
  });
  return typeof month === 'string' ? month.toUpperCase() : 'N/A';
};

const getYear = (date: Date, culture: string, timeZone: string) =>
  date.toLocaleString(culture, {
    year: 'numeric',
    timeZone,
  });

const DateSquare: React.FC<DateSquareProps> = ({ date, novelty, priority }) => {
  const { language } = useLocalization();
  const { user } = useAuthentication();
  const transformedDate = new Date(date);

  return (
    <div
      className={`${styles.square} ${priority ? styles.priority : ''} ${
        novelty ? styles.novelty : ''
      } `}
    >
      <time className={styles.day}>
        {getDay(transformedDate, language, tzDictionary[user?.timeZone])}
      </time>
      <div className={styles.monthYear}>
        <time>{getMonth(transformedDate, language, tzDictionary[user?.timeZone])}</time>
        <time>{getYear(transformedDate, language, tzDictionary[user?.timeZone])}</time>
      </div>
      {!novelty && priority && <div className={styles.exclamation}>!</div>}
    </div>
  );
};

DateSquare.defaultProps = {
  date: null,
  novelty: false,
  priority: false,
};

DateSquare.propTypes = {
  date: PropTypes.number,
  novelty: PropTypes.bool,
  priority: PropTypes.bool,
};

export default DateSquare;
