import { Question } from 'containers/Survey/constants/Survey.interface';
import { Validator } from 'global/types/System.interface';
import { defaultValidator, multipleChoiceValidator, toggleValidator } from 'global/validators';

const getSurveyValidator = (field: Question): Validator => {
  switch (field.QuestionTypeId) {
    case 1: // Ranking
    case 2: // Open
      return defaultValidator;
    case 5: // True or False
      return toggleValidator;
    case 3: // Multiple Choice
      return multipleChoiceValidator;
    default:
      return null;
  }
};

export default getSurveyValidator;
