import { useMutation, UseMutationResult } from 'react-query';
import axios from 'axios';

const postLogout = async () => {
  const data = await axios.post<boolean>('/authentication/logout');

  if (data.status !== 200) {
    throw Error('Error logging out');
  }

  return data?.data;
};

export default function useLogout(): UseMutationResult<boolean, Error, void, unknown> {
  return useMutation(postLogout);
}
