export default {
  'es': {
    'LOGIN.BLOCKED': 'El usuario se encuentra bloqueado',
    'LOGIN.CUSTOMER_PORTAL': 'Portal del Cliente',
    'LOGIN.ENTER_USERNAME': 'Ingrese el nombre del usuario',
    'LOGIN.FORGOT_YOUR_PASSWORD': '¿Olvidó su contraseña?',
    'LOGIN.INACTIVE': 'El usuario se encuentra inactivo',
    'LOGIN.LOGIN_ERROR': 'Credenciales incorrectos',
    'LOGIN.NO_PROJECTS': 'El usuario no tiene proyectos asociados',
    'LOGIN.PASSWORD': 'Contraseña',
    'LOGIN.PASSWORD_REMINDER': 'Recordar contraseña',
    'LOGIN.REMINDER_ERROR':
      'No se pudo enviar el correo. Por favor revisar las configuraciones del servidor',
    'LOGIN.REMINDER_SUCCESS': 'Recordatorio enviado satisfactoriamente',
    'LOGIN.SEND': 'Enviar',
    'LOGIN.SELECT_AUTHENTICATION_TYPE': 'Seleccione un tipo de autenticación',
    'LOGIN.SIGN_IN': 'Ingresar',
    'LOGIN.START_SESSION': 'Inicie sesión con:',
    'LOGIN.START_SESSION_OR': 'O inicie sesión con:',
    'LOGIN.USER': 'Usuario',
  },
  'en': {
    'LOGIN.BLOCKED': 'The user is blocked',
    'LOGIN.CUSTOMER_PORTAL': 'Customer Portal',
    'LOGIN.ENTER_USERNAME': 'Enter the username',
    'LOGIN.FORGOT_YOUR_PASSWORD': 'Forgot your password?',
    'LOGIN.INACTIVE': 'The user is inactive',
    'LOGIN.LOGIN_ERROR': 'Incorrect credentials',
    'LOGIN.NO_PROJECTS': 'The user is not associated to any projects',
    'LOGIN.PASSWORD': 'Password',
    'LOGIN.PASSWORD_REMINDER': 'Password reminder',
    'LOGIN.REMINDER_ERROR': 'Unable to find email. Please check server configurations.',
    'LOGIN.REMINDER_SUCCESS': 'Reminder sent successfully',
    'LOGIN.SEND': 'Send',
    'LOGIN.SELECT_AUTHENTICATION_TYPE': 'Select an authentication type',
    'LOGIN.SIGN_IN': 'Sign in',
    'LOGIN.START_SESSION': 'Login with:',
    'LOGIN.START_SESSION_OR': 'Or login with',
    'LOGIN.USER': 'User',
  },
  'pt': {
    'LOGIN.BLOCKED': 'O usuário está bloqueado',
    'LOGIN.CUSTOMER_PORTAL': 'Portal do cliente',
    'LOGIN.ENTER_USERNAME': 'Digite o nome de usuário',
    'LOGIN.FORGOT_YOUR_PASSWORD': 'Esqueceu sua senha?',
    'LOGIN.INACTIVE': 'O usuário está inativo',
    'LOGIN.LOGIN_ERROR': 'Usuário ou senha inválida',
    'LOGIN.NO_PROJECTS': 'O usuário não tem projeto associado',
    'LOGIN.PASSWORD': 'Senha',
    'LOGIN.PASSWORD_REMINDER': 'Dica de senha',
    'LOGIN.REMINDER_ERROR':
      'O e-mail não foi enviado. Verifique as configurações do servidor de e-mail',
    'LOGIN.REMINDER_SUCCESS': 'Dica enviada com sucesso!',
    'LOGIN.SEND': 'Enviar',
    'LOGIN.SELECT_AUTHENTICATION_TYPE': 'Selecionar um tipo de autenticação',
    'LOGIN.SIGN_IN': 'Entrar',
    'LOGIN.START_SESSION': 'Faça login com:',
    'LOGIN.START_SESSION_OR': 'Ou faça login com:',
    'LOGIN.USER': 'Usuário',
  },
};
