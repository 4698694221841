import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import axios from 'axios';
import { ListArticle } from 'global/types/System.interface';
import { ArticleType, ArticleViews, KbArticle } from 'containers/KB/constants/Kb.interface';

const postFavorite = async (article: ListArticle | KbArticle) => {
  let success: boolean;
  if (article.isFavorite === true) {
    const { data } = await axios.delete<boolean>(`/article/${article.id}/favorite`);
    success = data;
  } else {
    const { data } = await axios.post<boolean>(`/article/${article.id}/favorite`);
    success = data;
  }
  return success;
};

export default function useFavorite(
  projectId: number,
  view: ArticleViews,
  articleType?: ArticleType,
  categoryId?: number,
  searchText?: string,
  relatedParentId?: number
): UseMutationResult<boolean, Error, ListArticle | KbArticle, unknown> {
  const queryCache = useQueryClient();
  return useMutation(postFavorite, {
    onMutate: async (article) => {
      await queryCache.cancelQueries([
        'kbarticles',
        projectId,
        view,
        articleType,
        categoryId,
        searchText,
      ]);
      await queryCache.cancelQueries(['relatedarticles', relatedParentId]);
      const oldArticles: ListArticle[] = queryCache.getQueryData([
        'kbarticles',
        projectId,
        view,
        articleType,
        categoryId,
        searchText,
      ]);
      const oldRelatedArticles: KbArticle[] = queryCache.getQueryData([
        'relatedarticles',
        relatedParentId,
      ]);

      const updatedArticles = oldArticles?.map((oldArticle) => {
        if (oldArticle.id === article.id) {
          return {
            ...oldArticle,
            isFavorite: !oldArticle.isFavorite,
          };
        }
        return { ...oldArticle };
      });
      queryCache.setQueryData(
        ['kbarticles', projectId, view, articleType, categoryId, searchText],
        updatedArticles
      );

      if (relatedParentId && oldRelatedArticles) {
        const updatedRelatedArticles = oldRelatedArticles.map((oldArticle) => {
          if (oldArticle.id === article.id) {
            return {
              ...oldArticle,
              isFavorite: !oldArticle.isFavorite,
            };
          }
          return { ...oldArticle };
        });
        queryCache.setQueryData(['relatedarticles', relatedParentId], updatedRelatedArticles);
      }

      return () => {
        queryCache.setQueryData(
          ['kbarticles', projectId, view, articleType, categoryId, searchText],
          oldArticles
        );
        if (relatedParentId) {
          queryCache.setQueryData(['relatedarticles', relatedParentId], oldRelatedArticles);
        }
      };
    },
    onError: (error, updateArticle, rollback: unknown) => {
      if (typeof rollback === 'function') {
        rollback();
      }
    },
    onSettled: () => {
      queryCache.invalidateQueries([
        'kbarticles',
        projectId,
        view,
        articleType,
        categoryId,
        searchText,
      ]);
      if (relatedParentId) {
        queryCache.invalidateQueries(['relatedarticles', relatedParentId]);
      }
    },
  });
}
