import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, FormikProps, FieldProps } from 'formik';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { Label } from '@progress/kendo-react-labels';
import { useAuthentication, useTranslate } from 'global/hooks';
import { EmailValues } from 'containers/KB/constants/Kb.interface';
import { InputWrapper } from 'common/FormFields';
import { defaultValidator, emailValidator } from 'global/validators';
import styles from './EmailForm.module.scss';

interface EmailFormProps {
  handleEmail: (v: EmailValues) => void;
  defaultSubject?: string;
}

const EmailForm: React.FC<EmailFormProps> = ({ handleEmail, defaultSubject }) => {
  let props: FormikProps<EmailValues>;
  const translate = useTranslate();
  const { user } = useAuthentication();
  const handleSubmit = (values: EmailValues) => {
    handleEmail(values);
  };

  return (
    <div>
      <Formik
        initialValues={{
          emailFrom: user?.email || user?.userName,
          emailTo: '',
          subject: defaultSubject,
          includeAttachments: false,
        }}
        onSubmit={handleSubmit}
      >
        <Form className={styles.content}>
          <Field name="emailFrom" validate={defaultValidator}>
            {({ field, meta }: FieldProps) => (
              <InputWrapper
                disabled
                id="emailFrom"
                label={`${translate('COMPONENT.FROM')}:`}
                type="text"
                meta={meta}
                {...field}
              />
            )}
          </Field>
          <Field name="emailTo" validate={emailValidator}>
            {({ field, meta }: FieldProps) => (
              <InputWrapper
                id="emailTo"
                label={`${translate('COMPONENT.TO')}:`}
                type="email"
                meta={meta}
                {...field}
              />
            )}
          </Field>
          <Field name="subject" validate={defaultValidator}>
            {({ field, meta }: FieldProps) => (
              <InputWrapper
                id="subject"
                label={`${translate('COMPONENT.SUBJECT')}:`}
                max={255}
                meta={meta}
                {...field}
              />
            )}
          </Field>
          <Field name="includeAttachments">
            {({ field }: FieldProps) => (
              <div className={styles.content_check}>
                <Checkbox
                  className={`${styles.checkbox}`}
                  id="includeAttachments"
                  name="includeAttachments"
                  {...field}
                />
                <Label editorId="includeAttachments">
                  {translate('COMPONENT.ATTACH_FILE_EMAIL')}
                </Label>
              </div>
            )}
          </Field>
          <div className={styles.send}>
            <Button disabled={props?.isSubmitting} themeColor="primary" type="submit">
              {translate('COMPONENT.SEND')}
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

EmailForm.defaultProps = {
  defaultSubject: '',
};

EmailForm.propTypes = {
  handleEmail: PropTypes.func.isRequired,
  defaultSubject: PropTypes.string,
};

export default EmailForm;
