import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'global/hooks';
import styles from './TimeLabel.module.scss';

interface TimeLabelProps {
  label: string;
  time: number;
}

const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;

const TimeLabel: React.FC<TimeLabelProps> = ({ label, time }) => {
  const translate = useTranslate();

  const formatTime = (base: number): string => {
    const timeAsString = base.toString();
    return timeAsString.length === 1 ? `0${timeAsString}` : timeAsString;
  };

  return (
    <div className={styles.time}>
      <h3>{label}</h3>
      <div className={styles.counter}>
        <div className={styles.clock}>
          <span className="k-i-clock k-icon" />
        </div>
        <div className={styles.timeSplit}>
          <span className={styles.value}>
            {formatTime(Math.floor(time / HOURS_IN_DAY / MINUTES_IN_HOUR)) || '00'}
          </span>
          <span className={styles.type}>{translate('FSC.DAY')}</span>
        </div>
        <span className={styles.colon}>:</span>
        <div className={styles.timeSplit}>
          <span className={styles.value}>
            {formatTime(Math.floor((time / MINUTES_IN_HOUR) % HOURS_IN_DAY)) || '00'}
          </span>
          <span className={styles.type}>{translate('FSC.HOUR')}</span>
        </div>
        <span className={styles.colon}>:</span>
        <div className={styles.timeSplit}>
          <span className={styles.value}>
            {formatTime(Math.floor(time % MINUTES_IN_HOUR)) || '00'}
          </span>
          <span className={styles.type}>MIN</span>
        </div>
      </div>
    </div>
  );
};

TimeLabel.propTypes = {
  label: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
};

export default TimeLabel;
