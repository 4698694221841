export default {
  'es': {
    'HISTORY.ACTIVITY': 'Actividad',
    'HISTORY.ALL_CHANGES': 'Todos...',
    'HISTORY.ATTACHMENT': 'Agregó un archivo adjunto',
    'HISTORY.ATTACHMENT_REMOVED': 'Removió un archivo adjunto',
    'HISTORY.ARTICLE_ADD': 'Agregó un artículo',
    'HISTORY.ARTICLE_REMOVED': 'Removió un artículo',
    'HISTORY.CI_ADD': 'Agregó un CI',
    'HISTORY.CI_REMOVED': 'Removió un CI',
    'HISTORY.EVENT_ADD': 'Agregó un evento',
    'HISTORY.EVENT_REMOVED': 'Removió un Evento',
    'HISTORY.FILE_NOT_EXISTS': 'El archivo no existe',
    'HISTORY.MODIFIED_FIELD': 'Campo modificado',
    'HISTORY.NEW_VALUE': 'Nuevo valor',
    'HISTORY.NOTE': 'Nota',
    'HISTORY.NOTE_PUBLIC': 'Escribió una nota pública',
    'HISTORY.NOTE_PRIVATE': 'Escribió una nota privada',
    'HISTORY.OLD_VALUE': 'Valor anterior',
    'HISTORY.ONLY_NOTES': 'Ver notas',
    'HISTORY.ITEM': 'Creó un item',
    'HISTORY.ITEM_MODIFIED': 'Modificó un item',
    'HISTORY.ITEM_CONVERTED': 'Convirtió un item',
    'HISTORY.ITEM_ADD': 'Agregó un item',
    'HISTORY.ITEM_REMOVED': 'Removió un item',
    'HISTORY.ITEM_CLOSED': 'Cerró el item',
    'HISTORY.ITEM_OPENED': 'Reabrió un item',
    'HISTORY.RELATED': 'Relacionado',
    'HISTORY.RELATED_REMOVED': 'Remover relacionado',
    'HISTORY.RULE': 'Regla',
    'HISTORY.sendMail': 'Se envió un mensaje desde la cuenta ',
    'HISTORY.SEND_REPORT': 'Se envío un reporte',
    'HISTORY.SEND_REPORT_TITLE': 'Reporte',
    'HISTORY.SUBJECT': 'con el asunto: ',
    'HISTORY.TO': 'a ',
  },
  'en': {
    'HISTORY.ACTIVITY': 'Activity',
    'HISTORY.ALL_CHANGES': 'All changes...',
    'HISTORY.ATTACHMENT': 'Added an attachment',
    'HISTORY.ATTACHMENT_REMOVED': 'Removed an attachment',
    'HISTORY.ARTICLE_ADD': 'Added an article',
    'HISTORY.ARTICLE_REMOVED': 'Removed an article',
    'HISTORY.CI_ADD': 'Added a CI',
    'HISTORY.CI_REMOVED': 'Removed an IC',
    'HISTORY.EVENT_ADD': 'Added an event',
    'HISTORY.EVENT_REMOVED': 'Removed an Event',
    'HISTORY.FILE_NOT_EXISTS': 'The file does not exists',
    'HISTORY.MODIFIED_FIELD': 'Modified field',
    'HISTORY.NEW_VALUE': 'New value',
    'HISTORY.NOTE': 'Note',
    'HISTORY.NOTE_PUBLIC': 'Wrote a public note',
    'HISTORY.NOTE_PRIVATE': 'Wrote a private note',
    'HISTORY.OLD_VALUE': 'Old value',
    'HISTORY.ONLY_NOTES': 'Only notes',
    'HISTORY.ITEM': 'Created an item',
    'HISTORY.ITEM_MODIFIED': 'Modified an item',
    'HISTORY.ITEM_CONVERTED': 'Converted an item',
    'HISTORY.ITEM_ADD': 'Added an item',
    'HISTORY.ITEM_REMOVED': 'Removed an item',
    'HISTORY.ITEM_CLOSED': 'Closed the item',
    'HISTORY.ITEM_OPENED': 'Reopened an item',
    'HISTORY.RELATED': 'Related',
    'HISTORY.RELATED_REMOVED': 'Remove related',
    'HISTORY.RULE': 'Rule',
    'HISTORY.sendMail': 'A message was sent from the account ',
    'HISTORY.SEND_REPORT': 'A report was sent',
    'HISTORY.SEND_REPORT_TITLE': 'Report',
    'HISTORY.SUBJECT': 'with the subject:',
    'HISTORY.TO': 'to ',
  },
  'pt': {
    'HISTORY.ACTIVITY': 'Atividade',
    'HISTORY.ALL_CHANGES': 'Todos...',
    'HISTORY.ATTACHMENT': 'Arquivo anexado',
    'HISTORY.ATTACHMENT_REMOVED': 'Eliminado com sucesso',
    'HISTORY.ARTICLE_ADD': 'Artigo adicionado',
    'HISTORY.ARTICLE_REMOVED': 'Artigo eliminado',
    'HISTORY.CI_ADD': 'CI Adicionado',
    'HISTORY.CI_REMOVED': 'CI Eliminado',
    'HISTORY.EVENT_ADD': 'Evento adicionado',
    'HISTORY.EVENT_REMOVED': 'Evento eliminado',
    'HISTORY.FILE_NOT_EXISTS': 'O arquivo não existe',
    'HISTORY.MODIFIED_FIELD': 'Campo modificado',
    'HISTORY.NEW_VALUE': 'Novo valor',
    'HISTORY.NOTE': 'Anotações',
    'HISTORY.NOTE_PUBLIC': 'Você criou uma anotação pública',
    'HISTORY.NOTE_PRIVATE': 'Você criou uma anotação Interna',
    'HISTORY.OLD_VALUE': 'Valor anterior',
    'HISTORY.ONLY_NOTES': 'Ver Anotações',
    'HISTORY.ITEM': 'Você criou um item',
    'HISTORY.ITEM_MODIFIED': 'Você modificou um item',
    'HISTORY.ITEM_CONVERTED': 'Você converteu um item',
    'HISTORY.ITEM_ADD': 'Você adicionou um item',
    'HISTORY.ITEM_REMOVED': 'Você eliminou um item',
    'HISTORY.ITEM_CLOSED': 'Você fechou o item',
    'HISTORY.ITEM_OPENED': 'Você reabriu um item',
    'HISTORY.RELATED': 'Relacionados',
    'HISTORY.RELATED_REMOVED': 'Remover relacionado',
    'HISTORY.RULE': 'Regra',
    'HISTORY.sendMail': 'Uma mensagem foi enviada da conta ',
    'HISTORY.SEND_REPORT': 'Um relatório foi enviado',
    'HISTORY.SEND_REPORT_TITLE': 'Relatório',
    'HISTORY.SUBJECT': 'com o assunto:',
    'HISTORY.TO': 'para ',
  },
};
