export default {
  'es': {
    'CHATBOT.ADD_CASE_HISTORY': 'Añadiremos la conversación al historial del caso',
    'CHATBOT.ADD_NOTE_ERROR': 'Error añadiendo la nota',
    'CHATBOT.ADD_NOTE_SUCCESS': 'Nota agregada exitosamente',
    'CHATBOT.CHAT_SPECIALIST': 'Chatear con un especialista',
    'CHATBOT.CLEAR': 'Limpiar',
    'CHATBOT.CLICK_HERE': 'Haga clic aqui',
    'CHATBOT.CLOSED_CASE': 'El caso se encuentra cerrado, no es posible continuar con la gestion',
    'CHATBOT.ENTER_CASE_NUMBER': '# Ingrese el número de caso',
    'CHATBOT.ENTER_CASE_NUMBER_HINT': 'Si tienes el número de caso, ingréselo para continuar',
    'CHATBOT.EXPORT': 'Exportar',
    'CHATBOT.FOUND': 'Mira lo que encontré:',
    'CHATBOT.HEADER_ARTICLES': 'Estos artículos te pueden ayudar',
    'CHATBOT.HEADER_CASES': 'Estos son tus casos',
    'CHATBOT.HEADER_CATEGORIES': '¿Aún necesitas ayuda? Reporta un caso:',
    'CHATBOT.HEADER_CATEGORIES_TWO': 'Estas são as categorias associadas, crie um caso',
    'CHATBOT.HEADER_SERVICES': 'Seleccione un servicio o un grupo de servicios',
    'CHATBOT.INFO_FIRST': 'Por favor llenar lo siguiente',
    'CHATBOT.HELLO': 'Hola',
    'CHATBOT.HELP': '¿Cómo te podemos ayudar?',
    'CHATBOT.HISTORY': 'Histórico',
    'CHATBOT.NO_ARTICLE': 'No pude encontrar el artículo',
    'CHATBOT.NO_CASE': 'No pude encontrar el caso',
    'CHATBOT.NO_CATEGORIES': 'No pude encontrar categorias relacionado al servicio',
    'CHATBOT.NO_PROJECT': 'El proyecto no tiene la plantilla de creación de casos configurada',
    'CHATBOT.NO_SERVICES': 'No pude encontrar servicios relaconado al grupo',
    'CHATBOT.NO_RESULT': 'No encuentro nada relacionado con la busqueda',
    'CHATBOT.SEARCH': 'Buscar...',
    'CHATBOT.SEARCH_WITHOUT_COINCIDENCES': 'No existe un caso que coincida con su búsqueda',
    'CHATBOT.SELECT_PROJECT': 'Seleccionar un proyecto...',
    'CHATBOT.SELECT_PROJECT_HINT':
      'O si quieres iniciar una nueva conversación con uno de nuestros colaboradores',
    'CHATBOT.SERVICES': 'Servicios',
    'CHATBOT.START_CHAT': 'Para iniciar una conversación',
    'CHATBOT.UNDEFINED': 'Sin definir',
    'CHATBOT.VIEW_DETAILS': 'Ver detalles',
    'CHATBOT.WELCOME': 'Bienvenidos a nuestro chat de asistencia personalizada.',
  },
  'en': {
    'CHATBOT.ADD_CASE_HISTORY': 'We will add the case to the case history',
    'CHATBOT.ADD_NOTE_ERROR': 'Error adding the note',
    'CHATBOT.ADD_NOTE_SUCCESS': 'Note added successfully',
    'CHATBOT.CHAT_SPECIALIST': 'Chat with a specialist',
    'CHATBOT.CLEAR': 'Clear',
    'CHATBOT.CLICK_HERE': 'Click here',
    'CHATBOT.CLOSED_CASE': 'The case found is closed, it will not be forwarded to the specialist',
    'CHATBOT.ENTER_CASE_NUMBER': '# Enter the case number',
    'CHATBOT.ENTER_CASE_NUMBER_HINT': 'If you have the case number, please enter it to continue',
    'CHATBOT.EXPORT': 'Export',
    'CHATBOT.FOUND': 'These are the options that I found:',
    'CHATBOT.HEADER_ARTICLES': 'Maybe these articles can help you out',
    'CHATBOT.HEADER_CASES': 'These are your cases',
    'CHATBOT.HEADER_CATEGORIES': 'Need help? Feel free to report a case',
    'CHATBOT.HEADER_CATEGORIES_TWO': 'These are the associated categories, create a case',
    'CHATBOT.HEADER_SERVICES': 'We recommend these services or group of services',
    'CHATBOT.HELLO': 'Hello',
    'CHATBOT.HELP': 'How may we help you?',
    'CHATBOT.HISTORY': 'History',
    'CHATBOT.INFO_FIRST': "Let's get some information first",
    'CHATBOT.NO_ARTICLE': 'I was unable to find the article',
    'CHATBOT.NO_CASE': 'I was unable to find the case',
    'CHATBOT.NO_CATEGORIES': 'I was unable to find related categories',
    'CHATBOT.NO_PROJECT': 'The project does not have the case creation template configured',
    'CHATBOT.NO_SERVICES': 'I was unable to find related services',
    'CHATBOT.NO_RESULT': 'I was unable to find anything related to your search',
    'CHATBOT.SEARCH': 'Search...',
    'CHATBOT.SEARCH_WITHOUT_COINCIDENCES': 'There is no case that matches your search',
    'CHATBOT.SELECT_PROJECT': 'Select a project...',
    'CHATBOT.SELECT_PROJECT_HINT':
      'Or if you want to start a new conversation with one of our collaborators',
    'CHATBOT.SERVICES': 'Services',
    'CHATBOT.START_CHAT': 'To chat with a specialist',

    'CHATBOT.UNDEFINED': 'Undefined',
    'CHATBOT.VIEW_DETAILS': 'View details',
    'CHATBOT.WELCOME': 'Welcome to our personalized help chat.',
  },
  'pt': {
    'CHATBOT.ADD_CASE_HISTORY': 'Vamos adicionar o chat ao hitórico do chamado',
    'CHATBOT.ADD_NOTE_ERROR': 'Erro ao adicionar anotação',
    'CHATBOT.ADD_NOTE_SUCCESS': 'Anotação adicionada com sucesso!',
    'CHATBOT.CHAT_SPECIALIST': 'Chat com analista',
    'CHATBOT.CLEAR': 'Apagar',
    'CHATBOT.CLICK_HERE': 'Clique aqui',
    'CHATBOT.CLOSED_CASE': 'O chamado está fechado, não é possível edita-lo',
    'CHATBOT.ENTER_CASE_NUMBER': '# Insira o número do chamado',
    'CHATBOT.ENTER_CASE_NUMBER_HINT': 'Se você tiver o número do chamado, digite-o para continuar',
    'CHATBOT.EXPORT': 'Exportar',
    'CHATBOT.FOUND': 'Estas são as opções que encontrei:',
    'CHATBOT.HEADER_ARTICLES': 'De repente esses artigos possam ajudá-lo',
    'CHATBOT.HEADER_CASES': 'Estes são os seus chamados',
    'CHATBOT.HEADER_CATEGORIES': 'Você ainda precisa de ajuda? Registrar um chamado',
    'CHATBOT.HEADER_CATEGORIES_TWO': 'Estas são as categorias associadas, Registrar um chamados',
    'CHATBOT.HEADER_SERVICES': 'Selecionar um serviço ou grupo de serviços',
    'CHATBOT.HELLO': 'Olá',
    'CHATBOT.HELP': 'Como podemos te ajudar?',
    'CHATBOT.HISTORY': 'Histórico',
    'CHATBOT.INFO_FIRST': 'Por favor, preencha as informações a seguir',
    'CHATBOT.NO_ARTICLE': 'Não consegui encontrar o artigo',
    'CHATBOT.NO_CASE': 'Não foi possível localizar o chamado',
    'CHATBOT.NO_CATEGORIES': 'Não consegui encontrar categorias relacionadas ao serviço',
    'CHATBOT.NO_PROJECT': 'O projeto não tem o modelo de criação de caso configurado',
    'CHATBOT.NO_SERVICES': 'Não consegui localizar serviços relacionados',
    'CHATBOT.NO_RESULT': 'Não consegui localizar nada na pesquisa',
    'CHATBOT.SEARCH': 'Pesquisar...',
    'CHATBOT.SEARCH_WITHOUT_COINCIDENCES': 'Se não houver correspondência a sua pesquisa',
    'CHATBOT.SELECT_PROJECT': 'Selecionar um projeto...',
    'CHATBOT.SELECT_PROJECT_HINT':
      'Ou se você deseja iniciar um novo chat com um dos nossos analistas',
    'CHATBOT.SERVICES': 'Serviços',
    'CHATBOT.START_CHAT': 'Para iniciar um Chat',
    'CHATBOT.UNDEFINED': 'Indefinido',
    'CHATBOT.VIEW_DETAILS': 'Ver detalhes',
    'CHATBOT.WELCOME': 'Bem-vindo ao nosso chat para atendimento personalizado.',
  },
};
