import { Button } from '@progress/kendo-react-buttons';
import { Editor as KEditor, EditorChangeEvent } from '@progress/kendo-react-editor';
import { FC, useState } from 'react';

interface NewToolsProps {
  icon: string;
  title: string;
}

interface ReadWriteEditorProps {
  className: string;
  defaultContent: string;
  onBlur: () => void;
  onChange: (e: EditorChangeEvent) => void;
  tools: unknown[];
  value: string;
}

export const ReadWriteEditor: FC<ReadWriteEditorProps> = ({
  className,
  defaultContent,
  onBlur,
  onChange,
  tools,
  value,
}) => {
  const [readWrit, setReadWrit] = useState(false);
  const readonly = true;
  let mode, icon;
  const insertTool = (settings: { nodeType: string; props: NewToolsProps }) => () => {
    return (
      <Button
        type="button"
        onClick={() => setReadWrit(!readWrit)}
        onMouseDown={(e) => e.preventDefault()}
        onPointerDown={(e) => e.preventDefault()}
        {...settings.props}
      />
    );
  };

  if (readWrit) {
    mode = 'write';
    icon = 'track-changes-enable';
  } else {
    mode = 'reading';
    icon = 'track-changes-reject';
  }
  const InsertHrTool = insertTool({
    nodeType: 'horizontal_rule',
    props: {
      icon: `${icon}`,
      title: `Change ${mode} mode `,
    },
  });
  tools = [[InsertHrTool], ...tools];
  return (
    <>
      {readWrit && (
        <KEditor
          onExecute={(e) => {
            if (readonly) {
              return !e.transaction.docChanged;
            }
          }}
          className={className}
          defaultContent={defaultContent}
          onBlur={onBlur}
          onChange={onChange}
          tools={[InsertHrTool]}
          value={value}
        />
      )}
      {!readWrit && (
        <KEditor
          className={className}
          defaultContent={defaultContent}
          onBlur={onBlur}
          onChange={onChange}
          tools={tools}
          value={value}
        />
      )}
    </>
  );
};
