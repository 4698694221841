import React from 'react';
import PropTypes from 'prop-types';
import styles from './BreadCrumbs.module.scss';

interface CrumbSeparatorProps {
  character: string;
}

const CrumbSeparator: React.FC<CrumbSeparatorProps> = ({ character }) => (
  <li className={styles.separator}>{character}</li>
);

interface BreadCrumbsProps {
  children: [number, string][];
  handleClick: (id: number, index: number) => void;
}

const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ children, handleClick }) => {
  const lastIndex = children.length - 1;

  let crumbs = children.map((child: [number, string], index: number) => (
    <li
      title={child[1]}
      key={`${child[1]}-${index + 1}`}
      className={styles.crumb}
      onClick={() => handleClick(child[0], index)}
      role="presentation"
    >
      {child[1]}
    </li>
  ));

  crumbs = crumbs.reduce((acc, child, index) => {
    const notLast = index < lastIndex;
    if (notLast) {
      acc.push(child, <CrumbSeparator character="/" key={`breadcrumb_sep-${index + 1}`} />);
    } else {
      acc.push(child);
    }
    return acc;
  }, []);

  return (
    <div className={styles.container} aria-label="breadcrumb">
      <ol className={styles.breadcrumbs}>{crumbs}</ol>
    </div>
  );
};

BreadCrumbs.propTypes = {
  children: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
};

CrumbSeparator.propTypes = {
  character: PropTypes.string.isRequired,
};

export default BreadCrumbs;
