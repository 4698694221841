import React from 'react';
import PropTypes from 'prop-types';
import { useField, FieldMetaProps } from 'formik';
import { RadioGroup, RadioGroupChangeEvent } from '@progress/kendo-react-inputs';
import { Label, Hint, Error } from '@progress/kendo-react-labels';
import { Choice } from 'global/types/AddFields.interface';
import { useTranslate } from 'global/hooks';
import { FormValidator } from 'containers/Wizard/elements';
import styles from './MultiChoice.module.scss';

interface MultiChoiceProps {
  id: string;
  label: string;
  meta: FieldMetaProps<Choice[]>;
  disabled?: boolean;
  required?: boolean;
  hint?: string;
}

const MultiChoice: React.FC<MultiChoiceProps> = ({ id, label, disabled, required, hint, meta }) => {
  const translate = useTranslate();
  const [, , helpers] = useField(id);
  const error = meta?.touched && typeof meta?.error === 'string';

  const onChange = (e: RadioGroupChangeEvent) => {
    helpers.setValue(e.value);
  };

  return (
    <div className={styles.multipleChoice}>
      <Label editorId={id} editorValid={!error} editorDisabled={disabled}>
        {`${label}${required ? '(*)' : ''}`}
      </Label>
      {!!hint && <Hint className={styles.hint}>{hint}</Hint>}
      <RadioGroup
        valid={!error}
        disabled={disabled}
        className={styles.radioGroup}
        data={meta.initialValue}
        onChange={onChange}
      />
      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

MultiChoice.defaultProps = {
  disabled: false,
  required: false,
  hint: null,
};

MultiChoice.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  hint: PropTypes.string,
};

export default MultiChoice;
