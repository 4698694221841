import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import {
  AddField,
  AddFieldFormikValues,
  AddFieldsItemParams,
  Section,
} from 'global/types/AddFields.interface';
import { getModifiedField } from 'global/utils';
import { useAuthentication } from 'global/hooks';
import { AddFields, Risk } from 'components';
import { NoData, Skeleton } from 'common';
import { State } from 'global/types/Case.interface';
import { InterfaceDictionary } from 'containers/Wizard/constants/Wizard.interface';
import styles from './AddFieldsForm.module.scss';

interface AddFieldsFormProps {
  addFields: AddField[];
  children?: React.ReactNode;
  disable?: boolean;
  handleSaveFields?: (a: AddField[], s?: number, r?: number, v?: AddFieldFormikValues) => void;
  handleSave?: () => void;
  itemData?: AddFieldsItemParams;
  loading?: boolean;
  sections: Section[];
  status?: State;
  reason?: State;
  withSave?: boolean;
  disabledRisk?: boolean;
  itemId?: number;
  label?: string;
  modelId?: number;
  required?: boolean;
  stateId?: number;
  interfaceData?: InterfaceDictionary;
  riskCase?: [number, string];
}

const anyFieldVisible = (addFields: AddField[]) => addFields.some((field) => field.visible);

const AddFieldsForm: React.FC<AddFieldsFormProps> = ({
  addFields,
  sections,
  status,
  reason,
  withSave,
  itemData,
  handleSaveFields,
  handleSave,
  children,
  loading,
  disable,
  disabledRisk,
  itemId,
  label,
  modelId,
  required,
  stateId,
  interfaceData,
  riskCase,
}) => {
  const { user } = useAuthentication();
  const handleSubmit = (values: AddFieldFormikValues) => {
    const modifiedFields = addFields.map((field) => {
      if (field.fieldId in values) {
        return getModifiedField(field, values[field.fieldId], user?.timeZone);
      }
      return {
        ...field,
      };
    });
    handleSaveFields(modifiedFields, status?.id, reason?.id, values);
    handleSave();
  };

  const getFieldValue = (field: AddField) => {
    switch (field.fieldType) {
      case 1: // Short Text
      case 4: // Long Text
      case 10: // File
      case 16: // Link
        return field.stringValue;
      case 2: // DateTime
        return field.valueDateField ? new Date(field.valueDateField) : null;
      case 13: // Date
      case 14: // Time
        return field.valueDateField;
      case 3: // LookUp
      case 11: // Dropdown
        return field.valueIntField && field.stringValue
          ? { id: field.valueIntField, name: field.stringValue }
          : null;
      case 5: // Checkbox
        return field.boolValue;
      case 7: // Category List
      case 12: // Category Tree
        return { stringValue: field.stringValue, valueIntField: field.valueIntField };
      case 6: // Number
      case 9: // Decimal
      case 15: // Money
        return field.floatValue;
      case 18: // MultiSelect
        return field.stringValue ? JSON.parse(field.stringValue) : [];
      default:
        return '';
    }
  };

  const memoizedInitialValues = useMemo(
    () =>
      addFields?.reduce((acc, field) => {
        if (field.visible) {
          return { ...acc, [field.fieldId]: getFieldValue(field) };
        }
        return acc;
      }, {}),
    [addFields]
  );

  if (loading) {
    return (
      <div style={{ height: '100%', minHeight: 50, display: 'flex' }}>
        <Skeleton height="100%" width="100%" />
      </div>
    );
  }

  return (
    <div className={`${styles.addFields} ${withSave && styles.saveRow}`}>
      {memoizedInitialValues && addFields?.length > 0 && anyFieldVisible(addFields) ? (
        <Formik
          initialValues={{
            ...memoizedInitialValues,
            customer: { id: itemData.customerId, value: '' },
          }}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              {interfaceData?.riskName?.visible === true ? (
                <div className={styles.risk}>
                  <Risk
                    disabled={disabledRisk}
                    itemId={itemId}
                    id="risk"
                    label={label}
                    required={required}
                    modelId={modelId}
                    stateId={stateId}
                    riskCase={riskCase}
                  />
                </div>
              ) : null}
              <AddFields
                addFields={addFields}
                sections={sections}
                itemData={itemData}
                disable={disable}
              />
              {children}
            </Form>
          )}
        </Formik>
      ) : (
        <NoData />
      )}
    </div>
  );
};

AddFieldsForm.defaultProps = {
  addFields: undefined,
  sections: undefined,
  itemData: null,
  children: null,
  withSave: false,
  handleSaveFields: null,
  disable: false,
};

AddFieldsForm.propTypes = {
  addFields: PropTypes.array,
  sections: PropTypes.array,
  itemData: PropTypes.any,
  children: PropTypes.element,
  withSave: PropTypes.bool,
  handleSaveFields: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  disable: PropTypes.bool,
};

export default AddFieldsForm;
