import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import axios, { AxiosError, AxiosHeaders } from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import ErrorPage from 'routers/ErrorPage/ErrorPage';
import { useFileMax, useLogout, useSAMLLogout, useSettings, useUser } from 'global/services';
import { useResolve, useSessionStorage, useSnackbar, useUrlQuery } from 'global/hooks';
import { useLocalStorage } from 'react-use';
import { getSystemMsgs } from 'global/utils';
import { Authentication, Project } from 'global/types/Resolve.interface';
import { LoginResponse, LoginParams } from 'containers/Login/constants/Login.interface';
import { BackDrop } from 'common';

export const AuthContext = React.createContext<Authentication>({
  activeChat: null,
  activeServices: null,
  fileMax: null,
  isDomain: null,
  licenses: [],
  login: (response: LoginResponse[]) => response,
  logout: null,
  projects: [],
  settings: null,
  user: null,
});

interface AuthProviderProps {
  children: React.ReactNode;
}

const activeChat = (projects: Project[]): boolean =>
  projects?.some((project) => project.isActiveChat === true);

const activeServices = (projects: Project[]): boolean =>
  projects?.some((project) => project.hasActiveServices === true);

const checkIgnoredRequests = (url: string): boolean =>
  url?.includes('authentication/logout') ||
  url?.includes('setting/servers') ||
  url?.includes('setting/value') ||
  url?.includes('Settings.asmx');

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname: path } = useLocation();
  const { AuthenticationMode, HideAssistant } = useResolve();
  const { addAlert } = useSnackbar();
  const cache = useQueryClient();
  const [isDomain, setIsDomain] = useLocalStorage('usdk_auth_server', null);
  const [saml, setSAML] = useLocalStorage('usdk_saml_provider', '');
  const [project] = useLocalStorage('usdk_project', '');
  const [redirect, setRedirect] = useSessionStorage('usdk_redirect', '');
  const [loggedUser, setLoggedUser] = useLocalStorage('usdk_logged_user', '');
  const params = useUrlQuery();
  const idToken = params.get('id_token');
  const isLogged =
    path.includes('home') ||
    path.includes('survey') ||
    path.includes('teams') ||
    path.includes('voting');
  const { data: user, isLoading } = useUser(isLogged);
  const { data: settings } = useSettings(user?.projects?.content, user?.licenses?.content);
  const { data: fileMax } = useFileMax(isLogged && !!user);
  const { mutateAsync: logoutFnc, isLoading: logoutLoading, isError } = useLogout();
  const { mutateAsync: samlLogout, isLoading: samlLoading } = useSAMLLogout();

  const login = useCallback(
    async (sessionData: LoginResponse[], variables: LoginParams) => {
      setIsDomain(variables.authentication.Field);
      setSAML(null);
      if (redirect) {
        navigate(redirect);
        setRedirect(null);
      } else {
        navigate('/home');
      }
    },
    [navigate, redirect, setIsDomain, setRedirect, setSAML]
  );

  const logout = useCallback(async () => {
    cache.clear();
    if (AuthenticationMode === 'Windows') navigate(`/windowslogout`);
    else navigate(`/${project ? `project=${project}` : ''}`);
    setIsDomain(null);
    await logoutFnc();
    setLoggedUser(null);
    if (saml) {
      await samlLogout(saml, {
        onSuccess: (authLink) => {
          window.open(authLink, '_self');
        },
        onSettled: () => {
          setSAML('');
        },
      });
    }
    if (window.chatInstance?.disconnect) {
      window.chatInstance.disconnect();
    }
  }, [
    AuthenticationMode,
    cache,
    navigate,
    logoutFnc,
    project,
    saml,
    samlLogout,
    setIsDomain,
    setLoggedUser,
    setSAML,
  ]);

  const closeSession = useCallback(() => {
    if (path !== '/') setRedirect(path);
    logout();
    addAlert({ msg: getSystemMsgs('SESSION_EXPIRATION'), severity: 'error' });
  }, [addAlert, path, logout, setRedirect]);

  useLayoutEffect(() => {
    if ((path.includes('teams') || path.includes('survey') || path.includes('voting')) && idToken) {
      axios.interceptors.request.use((request) => {
        (request.headers as AxiosHeaders).set('X-Authorization', `Bearer ${idToken}`);
        return request;
      });
    }
  }, [idToken, path]);

  useLayoutEffect(() => {
    const authInterceptor = axios.interceptors.response.use(undefined, async (err: AxiosError) => {
      switch (err?.response?.status) {
        case 400:
        case 403:
        case 500:
          return err.response;
        case 404:
        case 401:
        default:
          if (!checkIgnoredRequests(err?.response?.config.url)) closeSession();
          return err.response;
      }
    });
    return () => axios.interceptors.response.eject(authInterceptor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.id && user?.id?.toString() !== loggedUser) {
      setLoggedUser(user?.id?.toString());
    }
  }, [loggedUser, setLoggedUser, user]);

  const auth = useMemo(
    () => ({
      activeChat: activeChat(user?.projects?.content) && !HideAssistant,
      activeServices: activeServices(user?.projects?.content),
      fileMax,
      isDomain: isDomain === '1',
      licenses: user?.licenses?.content,
      login,
      logout,
      projects: user?.projects?.content,
      settings,
      user,
    }),
    [fileMax, HideAssistant, isDomain, login, logout, settings, user]
  );

  if (isLoading || logoutLoading || samlLoading) {
    return <BackDrop show />;
  }
  if (isError) {
    return <ErrorPage />;
  }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
