import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftSVG, ArrowRightSVG } from 'common/icons';
import styles from './Carousel.module.scss';

interface CarouselProps {
  elements: React.ReactNode[];
}

const Carousel: React.FC<CarouselProps> = ({ elements }) => {
  const [x, setX] = useState(0);

  const goLeft = () => {
    if (x === 0) {
      setX(-100 * (elements.length - 1));
    } else {
      setX((prevState) => prevState + 100);
    }
  };

  const goRight = () => {
    if (x === -100 * (elements.length - 1)) {
      setX(0);
    } else {
      setX((prevState) => prevState - 100);
    }
  };

  return (
    <div className={styles.carousel}>
      <div className={styles.arrow} style={{ left: '10px' }} onClick={goLeft} role="presentation">
        <div className={styles.button}>
          <ArrowLeftSVG />
        </div>
      </div>
      <div className={styles.slides}>
        {elements.map((element, index) => (
          <div
            key={`${index + 1}`}
            className={styles.slide}
            style={{ transform: `translateX(${x}%)` }}
          >
            {element}
          </div>
        ))}
      </div>
      <div className={styles.arrow} style={{ right: '10px' }} onClick={goRight} role="presentation">
        <div className={`${styles.button} ${styles.rightButton}`}>
          <ArrowRightSVG />
        </div>
      </div>
    </div>
  );
};

Carousel.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default Carousel;
