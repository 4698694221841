import { useContext } from 'react';
import { SnackBarContext } from 'context/withSnackbars';
import { AlertType } from 'global/types/System.interface';

interface SnackbarContext {
  addAlert: (a: AlertType) => void;
}

const useSnackBar = (): SnackbarContext => useContext(SnackBarContext);

export default useSnackBar;
