import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'global/hooks';
import { Button } from '@progress/kendo-react-buttons';
import styles from './FileModalTeamsVote.module.scss';

interface AddNoteProps {
  close: () => void;
  download: () => void;
}

const FileModalTeamsVote: React.FC<AddNoteProps> = ({ close, download }) => {
  const translate = useTranslate();

  return (
    <div className={styles.fileModal}>
      <p>{translate('COMPONENT.DOWNLOAD_FILE_CONFIRMATION')}</p>
      <div className={styles.buttons}>
        <Button onClick={download} themeColor="primary" type="button">
          {translate('COMPONENT.YES')}
        </Button>
        <Button onClick={close} type="button">
          {translate('COMPONENT.NO')}
        </Button>
      </div>
    </div>
  );
};

FileModalTeamsVote.propTypes = {
  close: PropTypes.func.isRequired,
  download: PropTypes.func.isRequired,
};

export default FileModalTeamsVote;
