import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Slide } from '@progress/kendo-react-animation';
import { FileType } from 'global/types/Case.interface';
import { useOutsidePopup, useToggle, useTranslate, useWindowSize } from 'global/hooks';
import { FileList } from 'components';
import { CloseSVG } from 'common/icons';
import styles from './FileManager.module.scss';

interface FileManagerProps {
  files?: FileType[];
  canModify: boolean;
  handleAddFile?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteFile?: (f: FileType) => void;
  isTempFile: boolean;
}

const FileManager: React.FC<FileManagerProps> = ({
  files,
  canModify,
  handleAddFile,
  handleDeleteFile,
  isTempFile,
}) => {
  const translate = useTranslate();
  const [open, toggle, close] = useToggle(false);
  const filesRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  useOutsidePopup(popupRef, close, filesRef);
  const [height, width] = useWindowSize();

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggle();
  };

  const handleInsideClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  useEffect(() => {
    close();
  }, [height, width, close]);

  return (
    <div className={styles.container} ref={containerRef}>
      <Slide
        className={styles.animateContainer}
        unmountOnExit
        mountOnEnter
        transitionEnterDuration={800}
        transitionExitDuration={800}
        direction="up"
        style={{
          width: containerRef?.current?.offsetWidth,
          position: 'absolute',
          bottom: '30px',
          left: 0,
        }}
      >
        {open && (
          <div
            className={styles.fileList}
            onClick={handleInsideClick}
            role="presentation"
            ref={popupRef}
          >
            <div className={styles.header}>
              <h4>{translate('COMPONENT.FILE_LIST')}</h4>
              <div className={styles.close} onClick={close} role="presentation">
                <CloseSVG />
              </div>
            </div>
            <FileList
              files={files}
              handleDeleteFile={handleDeleteFile}
              disable={!canModify}
              isTempFile={isTempFile}
            />
          </div>
        )}
      </Slide>
      <div className={styles.fileManager} onClick={handleClick} role="presentation" ref={filesRef}>
        <span className={styles.count}>{`${files?.length || 0} ${translate(
          'COMPONENT.FILES'
        )}`}</span>
        {canModify && (
          <div className={styles.attach}>
            <span className={styles.attachText}>{translate('COMPONENT.ATTACH_FILE')}</span>
            <span className={`${styles.attachArrow} k-icon k-i-arrow-60-right`} />
            <input multiple type="file" onClick={handleInsideClick} onChange={handleAddFile} />
          </div>
        )}
      </div>
    </div>
  );
};

FileManager.defaultProps = {
  files: [],
  handleAddFile: null,
  handleDeleteFile: null,
};

FileManager.propTypes = {
  files: PropTypes.array,
  canModify: PropTypes.bool.isRequired,
  handleAddFile: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  isTempFile: PropTypes.bool.isRequired,
};

export default FileManager;
