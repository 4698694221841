import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Label, Error } from '@progress/kendo-react-labels';
import { useLocalization } from '@progress/kendo-react-intl';
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { useTranslate } from 'global/hooks';
import { defaultValidator } from 'global/validators';
import { FormValidator } from 'containers/Wizard/elements';
import styles from './DateWrapper.module.scss';

const DEFAULT_DATE_VALUE = -18000000;

interface DateWrapperProps {
  disabled?: boolean;
  id: string;
  label: string;
  required?: boolean;
}

const DateWrapper: React.FC<DateWrapperProps> = ({ id, label, disabled, required }) => {
  const translate = useTranslate();
  const { language } = useLocalization();
  const [, meta, helpers] = useField({
    name: id,
    validate: required ? defaultValidator : null,
  });

  const error = typeof meta?.error === 'string';

  const handleBlur = () => {
    if (meta.value && required) {
      helpers.setError(undefined);
    }
  };

  const handleUTC = (value: number) => {
    if (value.valueOf() !== DEFAULT_DATE_VALUE) {
      const offset = new Date(value).getTimezoneOffset();
      const date = offset * 60 * 1000;
      return new Date(value + date);
    }
  };

  const handleChange = (e: DatePickerChangeEvent) => {
    const offset = new Date(e.value).getTimezoneOffset();
    const date = offset * 60 * 1000;
    helpers.setValue(new Date((e.value as unknown as number) - date).getTime());
  };

  return (
    <div className={`${styles.dateWrap} ${disabled ? styles.disabled : ''}`}>
      <div title={label} className={styles.textLabel}>
        <Label editorId={id} editorValid={!error} editorDisabled={disabled}>
          {`${label}${required ? '(*)' : ''}`}
        </Label>
      </div>
      <DatePicker
        className={`${styles.date} ${!meta.value ? styles.placeholder : ''} ${
          !!error ? styles.error : ''
        }`}
        disabled={disabled}
        format={{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          pattern: language === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy',
        }}
        formatPlaceholder="short"
        id={id}
        onBlur={handleBlur}
        onChange={handleChange}
        value={meta.value ? handleUTC(meta.value) : null}
      />
      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

DateWrapper.defaultProps = {
  disabled: false,
  required: false,
};

DateWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default DateWrapper;
