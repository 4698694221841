import React from 'react';
import { useTranslate } from 'global/hooks';
import { Skeleton } from 'common';
import { KbSVG } from 'common/icons';
import styles from './ArticleSkeleton.module.scss';

const ArticleSkeleton: React.FC = () => {
  const translate = useTranslate();
  return (
    <div className={styles.article}>
      <div className={styles.header}>
        <KbSVG />
        <h3>{translate('KB.ARTICLE')}</h3>
      </div>
      <div className={styles.content}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    </div>
  );
};

export default ArticleSkeleton;
