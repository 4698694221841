import axios from 'axios';
import { UseQueryResult, useQuery } from 'react-query';
import { LANGUAGECODE, Response } from 'global/types/System.interface';
import { InterfaceDictionaryVote, InterfaceVote } from 'global/types/Item.interfaceVote';

const getInterfaceVote = async (
  modelId: number,
  itemType: number,
  statusId: number,
  mode: boolean,
  language: string
) => {
  const data = await axios.get<Response<InterfaceVote>>(
    `model/${modelId}/interfacefield?itemType=${itemType}&consoleType=Specialist&statusId=${statusId}&isView=${mode}&language=${LANGUAGECODE[language]}`
  );

  if (data.status !== 200) {
    throw Error('Error obtaining interface configuration');
  }
  const dictionary: InterfaceDictionaryVote = {};
  data.data.content.forEach((field) => {
    dictionary[field.member] = field;
  });

  return dictionary;
};

export default function useInterfaceVote(
  modelId: number,
  itemType: number,
  statusId: number,
  mode: boolean,
  language: string
): UseQueryResult<InterfaceDictionaryVote, Error> {
  return useQuery(
    ['interface', modelId, itemType, statusId, mode, language],
    () => getInterfaceVote(modelId, itemType, statusId, mode, language),
    { enabled: !!(modelId && itemType && statusId) }
  );
}
