import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FieldMetaProps } from 'formik';
import { Label, Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { useTranslate } from 'global/hooks';
import { FormValidator } from 'containers/Wizard/elements';
import styles from './PasswordWrapper.module.scss';

interface PasswordWrapperProps {
  disabled?: boolean;
  id: string;
  label: string;
  meta: FieldMetaProps<unknown>;
  required?: boolean;
  value: unknown;
}

const PasswordWrapper: React.FC<PasswordWrapperProps> = ({
  disabled,
  id,
  label,
  meta,
  required,
  value,
  ...others
}) => {
  const translate = useTranslate();
  const error = meta?.touched && typeof meta?.error === 'string';
  const [preview, setPreview] = useState(false);

  const handlePreview = () => {
    setPreview((prevState) => !prevState);
  };

  return (
    <div className={`${styles.inputWrap} ${disabled ? styles.disabled : ''}`} data-testid={id}>
      <Label editorId={id} editorValid={!error} editorDisabled={disabled}>
        {`${label}${required ? '(*)' : ''}`}
      </Label>
      <Input
        className={styles.input}
        disabled={disabled}
        id={id}
        type={preview ? 'text' : 'password'}
        valid={!error}
        value={(value as string) ?? ''}
        {...others}
      />
      {value ? (
        <span
          className={`k-icon k-i-preview ${styles.preview}`}
          onClick={handlePreview}
          role="presentation"
        />
      ) : null}
      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

PasswordWrapper.defaultProps = {
  disabled: false,
  required: false,
  value: null,
};

PasswordWrapper.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.any.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
};

export default PasswordWrapper;
