import React from 'react';
import PropTypes from 'prop-types';
import { Slide } from '@progress/kendo-react-animation';
import { useTranslate } from 'global/hooks';
import { HTMLHandler } from 'common';
import { CloseSVG } from 'common/icons';
import styles from './Helper.module.scss';

interface HelperProps {
  body?: string;
  handleClick: () => void;
  show: boolean;
}

const Helper: React.FC<HelperProps> = ({ body, handleClick, show }) => {
  const translate = useTranslate();

  return (
    <Slide
      direction="left"
      unmountOnExit
      mountOnEnter
      transitionEnterDuration={800}
      transitionExitDuration={800}
      className={styles.animateContainer}
    >
      {show && (
        <div className={styles.helper}>
          <div className={styles.title}>
            <h4>{translate('WIZARD.HELP')}</h4>
            <div className={styles.icon} onClick={handleClick} role="presentation">
              <CloseSVG />
            </div>
          </div>
          <HTMLHandler html={body} />
        </div>
      )}
    </Slide>
  );
};

Helper.defaultProps = {
  body: '',
};

Helper.propTypes = {
  body: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default Helper;
