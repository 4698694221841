import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { ArticleType } from 'containers/KB/constants/Kb.interface';

const getArticleTypes = async () => {
  const data = await axios.get<ArticleType[]>('/article/types');
  if (data.status !== 200) {
    throw Error('Error obtaining article types');
  }
  return data?.data;
};

export default function useArticleTypes(): UseQueryResult<ArticleType[], Error> {
  return useQuery('articletypes', () => getArticleTypes());
}
