import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@progress/kendo-react-buttons';
import { useResolve, useTranslate } from 'global/hooks';
import { Survey } from 'containers/Sidebars/constants/Sidebars.interface';
import styles from './SurveyList.module.scss';

interface SurveyListProps {
  surveys?: Survey[];
}

const SurveyList: React.FC<SurveyListProps> = ({ surveys }) => {
  const translate = useTranslate();
  const { CustomerURL } = useResolve();
  const handleOpenSurvey = (token: string) => {
    window.open(`${CustomerURL}/#/survey/${token}`, '_blank');
  };
  return (
    <div className={styles.list}>
      {surveys.map((survey, index) => (
        <div className={styles.survey} key={`${survey.name}-${index + 1}`}>
          <div className={styles.left}>
            <span className="k-i-paste-plain-text k-icon" />
            <span className={styles.name}>{survey.name}</span>
          </div>
          <div className={styles.right}>
            <span>{`${translate('COMPONENT.CASE')} ${survey.idByProject}`}</span>
            <div className={styles.button}>
              <Button
                onClick={() => handleOpenSurvey(survey.token)}
                themeColor="primary"
                type="button"
              >
                {translate('COMPONENT.RESPOND')}
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

SurveyList.defaultProps = {
  surveys: [],
};

SurveyList.propTypes = {
  surveys: PropTypes.array,
};

export default SurveyList;
