export default {
  'es': {
    'HEADER.ABOUT': 'Acerca de',
    'HEADER.ABOUT_TEXT': 'El uso de este producto se rige por las condiciones de usuario final.',
    'HEADER.ABOUT_VERSION': 'Versión del producto',
    'HEADER.APPLICATIONS': 'Aplicaciones',
    'HEADER.ADDITIONAL_DATA': 'Datos adicionales',
    'HEADER.CHANGE': 'Cambiar',
    'HEADER.CHANGE_PASSWORD': 'Cambiar contraseña',
    'HEADER.CLOSE_SESSION': 'Cerrar sessión',
    'HEADER.CONFIRM_PASSWORD': 'Confirmar contraseña:',
    'HEADER.CONFIRM_PASSWORD_ERROR': 'Las contraseñas no coinciden',
    'HEADER.CREATE_CASE': 'Registrar caso',
    'HEADER.NEW_PASSWORD': 'Nueva contraseña:',
    'HEADER.PASSWORD': 'Contraseña:',
    'HEADER.PASSWORD_ERROR': 'Ocurrió un error al cambiar la contraseña',
    'HEADER.PASSWORD_SUCCESS': 'Contraseña actualizada satisfactoriamente',
    'HEADER.PROFILE': 'Perfil',
    'HEADER.SAME_PASSWORD_ERROR': 'No puede ser la misma contraseña',
    'HEADER.SAVE': 'Guardar',
    'HEADER.SPECIALISTS': 'Especialistas',
    'HEADER.USERS': 'Usuarios',
  },
  'en': {
    'HEADER.ABOUT': 'About',
    'HEADER.ABOUT_TEXT': 'Use of this product is governed by end user conditions.',
    'HEADER.ABOUT_VERSION': 'Product version',
    'HEADER.APPLICATIONS': 'Applications',
    'HEADER.ADDITIONAL_DATA': 'Additional Data',
    'HEADER.CHANGE': 'Change',
    'HEADER.CHANGE_PASSWORD': 'Change password',
    'HEADER.CLOSE_SESSION': 'Close session',
    'HEADER.CONFIRM_PASSWORD': 'Confirm password:',
    'HEADER.CONFIRM_PASSWORD_ERROR': 'Passwords do not match',
    'HEADER.CREATE_CASE': 'Register case',
    'HEADER.NEW_PASSWORD': 'New password:',
    'HEADER.PASSWORD': 'Password:',
    'HEADER.PASSWORD_ERROR': 'An error occurred while changing the password',
    'HEADER.PASSWORD_SUCCESS': 'Password changed successfully',
    'HEADER.PROFILE': 'Profile',
    'HEADER.SAME_PASSWORD_ERROR': 'Cannot be the same password',
    'HEADER.SAVE': 'Save',
    'HEADER.SPECIALISTS': 'Specialists',
    'HEADER.USERS': 'Customers',
  },
  'pt': {
    'HEADER.ABOUT': 'Sobre...',
    'HEADER.ABOUT_TEXT': 'O uso deste produto é regido pelas condições do usuário final.',
    'HEADER.ABOUT_VERSION': 'Versão do produto',
    'HEADER.APPLICATIONS': 'Aplicativos',
    'HEADER.ADDITIONAL_DATA': 'Dados adicionais',
    'HEADER.CHANGE': 'Salvar',
    'HEADER.CHANGE_PASSWORD': 'Trocar senha',
    'HEADER.CLOSE_SESSION': 'Encerrar Sessão',
    'HEADER.CONFIRM_PASSWORD': 'Confirme a nova senha:',
    'HEADER.CONFIRM_PASSWORD_ERROR': 'As senhas nãos correspondem',
    'HEADER.CREATE_CASE': 'Registrar chamado',
    'HEADER.NEW_PASSWORD': 'Nova senha:',
    'HEADER.PASSWORD': 'Senha Antiga:',
    'HEADER.PASSWORD_ERROR': 'Ocorreu um erro ao alterar a senha',
    'HEADER.PASSWORD_SUCCESS': 'Senha atualizada com sucesso!',
    'HEADER.PROFILE': 'Perfil',
    'HEADER.SAME_PASSWORD_ERROR': 'A senha não pode ser a mesma',
    'HEADER.SAVE': 'Salvar',
    'HEADER.SPECIALISTS': 'Analistas',
    'HEADER.USERS': 'Usuários',
  },
};
