import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { LANGUAGECODE, Language, Response } from 'global/types/System.interface';
import { Interface, InterfaceDictionary } from 'containers/Wizard/constants/Wizard.interface';

const getInterface = async (
  itemTypeId: number,
  stateId: number,
  modelId: number,
  projectId: number,
  localization: Language
) => {
  const data = await axios.get<Response<Interface>>(
    `item/interfacefield/list?itemType=${itemTypeId}&state=${stateId}&isView=false&model=${modelId}&project=${projectId}&language=${
      LANGUAGECODE[localization] || '0'
    }`
  );

  if (data.status !== 200) {
    throw Error('Error obtaining interface settings');
  }

  const dictionary: InterfaceDictionary = {};
  data.data.content.forEach((field) => {
    dictionary[field.member] = field;
  });

  return dictionary;
};

export default function useInterface(
  itemTypeId: number,
  stateId: number,
  modelId: number,
  projectId: number,
  localization: string
): UseQueryResult<InterfaceDictionary, Error> {
  return useQuery(
    ['interface', itemTypeId, stateId, modelId, projectId, localization],
    () => getInterface(itemTypeId, stateId, modelId, projectId, localization as Language),
    { enabled: false }
  );
}
