import React from 'react';
import PropTypes from 'prop-types';
import { useField, FieldMetaProps } from 'formik';
import { Rating, RatingChangeEvent } from '@progress/kendo-react-inputs';
import { Label, Error, Hint } from '@progress/kendo-react-labels';
import { useTranslate } from 'global/hooks';
import { FormValidator } from 'containers/Wizard/elements';
import styles from './RatingWrapper.module.scss';

interface RatingWrapperProps {
  id: string;
  label: string;
  disabled?: boolean;
  meta: FieldMetaProps<unknown>;
  type?: string;
  required?: boolean;
  hint?: string;
  max: number;
}

const RatingWrapper: React.FC<RatingWrapperProps> = ({
  id,
  label,
  meta,
  disabled,
  required,
  hint,
  max,
}) => {
  const translate = useTranslate();
  const [, , helpers] = useField(id);
  const error = meta?.touched && typeof meta?.error === 'string';

  const onChange = (e: RatingChangeEvent) => {
    helpers.setValue(e.value);
  };
  return (
    <div className={styles.rating}>
      <Label editorId={id} editorValid={!error} editorDisabled={disabled}>
        {`${label}${required ? '(*)' : ''}`}
      </Label>
      {!!hint && <Hint className={styles.hint}>{hint}</Hint>}
      <div className={styles.container}>
        <Rating
          valid={!error}
          disabled={disabled}
          className={styles.input}
          max={max}
          onChange={onChange}
        />
        <div className={styles.total}>{meta.value as string}</div>
      </div>

      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

RatingWrapper.defaultProps = {
  disabled: false,
  required: false,
  hint: null,
};

RatingWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  hint: PropTypes.string,
  max: PropTypes.number.isRequired,
};

export default RatingWrapper;
