import { parseLanguageCode } from 'global/helpers';
import SystemMsgs from 'assets/locale/System.locale';

const BROWSER_LANGUAGE = parseLanguageCode(navigator.language);

const getMsg = (key: string): string => {
  switch (BROWSER_LANGUAGE) {
    case 'en':
      return (SystemMsgs.en as { [key: string]: string })[`SYSTEM.${key}`];
    case 'pt':
      return (SystemMsgs.pt as { [key: string]: string })[`SYSTEM.${key}`];
    default:
      return (SystemMsgs.es as { [key: string]: string })[`SYSTEM.${key}`];
  }
};

export default getMsg;
