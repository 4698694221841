export default {
  'es': {
    'FSC.APPLY': 'Aplicar',
    'FSC.CHANGE': 'Cambio',
    'FSC.CHANGE_ID': 'Número de cambio',
    'FSC.CHANGE_STAGES': 'Etapas del cambio',
    'FSC.CHANGES_CALENDAR': 'Calendario de cambios',
    'FSC.CLEAR': 'Limpiar',
    'FSC.CONSTRUCTION': 'Construcción',
    'FSC.DATE': 'Fecha',
    'FSC.DAY': 'Día',
    'FSC.DESCRIPTION': 'Descripción',
    'FSC.EXPORT_CALENDAR': 'EXPORTAR CALENDARIO',
    'FSC.EXPORT_WARNING': 'No hay cambios para exportar',
    'FSC.FILTER': 'Filtrar',
    'FSC.FROM': 'Desde:',
    'FSC.HOUR': 'Hora',
    'FSC.INSTALATION': 'Instalación',
    'FSC.MONTH': 'Mes',
    'FSC.NO_CHANGES': 'No hay cambios para este rango de fechas',
    'FSC.PROJECT': 'Proyecto',
    'FSC.PSO_FULL': 'Interrupción Proyectada del Servicio',
    'FSC.STAGES': 'Etapas',
    'FSC.TEST': 'Pruebas',
    'FSC.TO': 'Hasta:',
  },
  'en': {
    'FSC.APPLY': 'Apply',
    'FSC.CHANGE': 'Change',
    'FSC.CHANGE_ID': 'Change number',
    'FSC.CHANGE_STAGES': 'Change stages',
    'FSC.CHANGES_CALENDAR': 'Changes Calendar',
    'FSC.CLEAR': 'Clear',
    'FSC.CONSTRUCTION': 'Construction',
    'FSC.DATE': 'Date',
    'FSC.DAY': 'Day',
    'FSC.DESCRIPTION': 'Description',
    'FSC.EXPORT_CALENDAR': 'EXPORT CALENDAR',
    'FSC.EXPORT_WARNING': 'No changes to export',
    'FSC.FILTER': 'Filter',
    'FSC.FROM': 'From:',
    'FSC.HOUR': 'Hour',
    'FSC.INSTALATION': 'Instalation',
    'FSC.MONTH': 'Month',
    'FSC.NO_CHANGES': 'There are no changes for this date range',
    'FSC.PROJECT': 'Project',
    'FSC.PSO_FULL': 'Projected Service Outage',
    'FSC.STAGES': 'Stages',
    'FSC.TEST': 'Test',
    'FSC.TO': 'To:',
  },
  'pt': {
    'FSC.APPLY': 'Aplicar',
    'FSC.CHANGE': 'Mudança',
    'FSC.CHANGE_ID': 'Número da mudança',
    'FSC.CHANGE_STAGES': 'Etapas da mudança',
    'FSC.CHANGES_CALENDAR': 'Calendário de Mudanças',
    'FSC.CLEAR': 'Apagar',
    'FSC.CONSTRUCTION': 'Construção',
    'FSC.DATE': 'Data',
    'FSC.DAY': 'Dia',
    'FSC.DESCRIPTION': 'Descrição',
    'FSC.EXPORT_CALENDAR': 'EXPORTAR CALENDARIO',
    'FSC.EXPORT_WARNING': 'Sem alterações para exportar',
    'FSC.FILTER': 'Filtro',
    'FSC.FROM': 'Desde:',
    'FSC.HOUR': 'Tempo',
    'FSC.INSTALATION': 'Instalação',
    'FSC.MONTH': 'Mês',
    'FSC.NO_CHANGES': 'Não existem alterações para este intervalo de datas',
    'FSC.PROJECT': 'Projeto',
    'FSC.PSO_FULL': 'Indisponibilidade de Serviço Planejado',
    'FSC.STAGES': 'Etapas',
    'FSC.TEST': 'Testes',
    'FSC.TO': 'Até:',
  },
};
