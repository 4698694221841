import React from 'react';
import PropTypes from 'prop-types';
import { saveAs } from '@progress/kendo-file-saver';
import { useLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { FileType } from 'global/types/Case.interface';
import { useAuthentication, useModal, useResolve, useSnackbar, useTranslate } from 'global/hooks';
import { useCreateToken, useDownloadFile } from 'global/services';
import { tzDictionary } from 'global/utils';
import { ClipSVG, TrashSVG } from 'common/icons';
import { FileModalTeams } from 'components';
import styles from './FileList.module.scss';

interface FileListProps {
  files: FileType[];
  handleDeleteFile?: (f: FileType) => void;
  disable?: boolean;
  isTempFile: boolean;
}

const FileList: React.FC<FileListProps> = ({ files, handleDeleteFile, disable, isTempFile }) => {
  const translate = useTranslate();
  const { language } = useLocalization();
  const { user } = useAuthentication();
  const { addAlert } = useSnackbar();
  const { mutate } = useDownloadFile();
  const { data, isLoading, isFetching } = useCreateToken();
  const { openModal, closeModal } = useModal();
  const { CustomerURL } = useResolve();
  const teams = window.location.href.includes('teams');
  const caselist = window.location.href.includes('caselist');

  const downloadFile = (fileId: number, fileName: string) => {
    if (teams && window.matchMedia('(max-width: 1024px)').matches) {
      window.open(`${CustomerURL}/service/api/v9/file/${fileId}?id_token=${data}`, '_blank');
    } else {
      mutate(fileId, {
        onSuccess: (blob) => {
          saveAs(blob, fileName);
          addAlert({ msg: translate('COMPONENT.DOWNLOAD_FILE_SUCCESS'), severity: 'success' });
          if (teams) closeModal();
        },
        onError: () => {
          addAlert({ msg: translate('SYSTEM.GENERAL'), severity: 'error' });
        },
      });
    }
  };

  const onClick = (fileId: number, fileName: string) => {
    if (teams) {
      openModal(
        <FileModalTeams close={closeModal} download={() => downloadFile(fileId, fileName)} />,
        translate('COMPONENT.FILE_MODAL'),
        {
          width: 800,
          height: 190,
        }
      );
    } else {
      downloadFile(fileId, fileName);
    }
  };

  return (
    <div className={styles.container}>
      {files.length > 0 ? (
        files.map((file, index) => {
          const transformedDate = new Date(file.created);
          return (
            <div className={styles.file} key={`${file.id}-${index + 1}`}>
              <div className={styles.date}>
                <time className={styles.full}>
                  {transformedDate.toLocaleDateString(language, {
                    timeZone: tzDictionary[user?.timeZone],
                  })}
                </time>
                <time className={styles.hour}>
                  {transformedDate.toLocaleTimeString(language, {
                    timeZone: tzDictionary[user?.timeZone],
                  })}
                </time>
              </div>
              <div className={styles.text}>
                <Button
                  className={styles.name}
                  disabled={isTempFile || isLoading || isFetching}
                  onClick={() => onClick(file.id, file.name)}
                  title={file.name}
                  type="button"
                >
                  {file.name}
                </Button>
                {file.description ? <p className={styles.description}>{file.description}</p> : null}
              </div>
              <div className={styles.right}>
                {!isTempFile && (
                  <span className={styles.size}>{`[${Math.floor(file.size / 1024)} Kb]`}</span>
                )}
                {/* TODO: Se oculta */}
                {!disable && !caselist && <TrashSVG onClick={() => handleDeleteFile(file)} />}
              </div>
            </div>
          );
        })
      ) : (
        <div className={styles.noAttachments}>
          <ClipSVG />
          <span>{translate('COMPONENT.NO_FILES')}</span>
        </div>
      )}
    </div>
  );
};

FileList.defaultProps = {
  handleDeleteFile: null,
  disable: false,
};

FileList.propTypes = {
  files: PropTypes.array.isRequired,
  handleDeleteFile: PropTypes.func,
  disable: PropTypes.bool,
  isTempFile: PropTypes.bool.isRequired,
};

export default FileList;
