import axios from 'axios';
import { UseMutationResult, useMutation } from 'react-query';
import { RiskPostParams } from 'containers/Case/constants/Case.interface';

const postRisk = async (risk: RiskPostParams) => {
  const data = await axios.post<{ result: boolean }>(`item/${risk.itemId}/risk`, risk);
  if (data.status !== 200) {
    throw Error('Error generate Risk');
  }
  return data?.data;
};

export default function useRiskPost(): UseMutationResult<
  { result: boolean },
  Error,
  RiskPostParams,
  Error
> {
  return useMutation(postRisk);
}
