import React, { useState, useEffect } from 'react';
import { useWindowSize, useDimensions, useMounted } from 'global/hooks';
import { Skeleton } from 'common';
import styles from './WizardSkeleton.module.scss';

const SERVICE_WIDTH = 200;
const SERVICE_HEIGHT = 140;
const SERVICE_MARGIN = 10;

const WizardSkeleton: React.FC = () => {
  const [containerRef, containerSize] = useDimensions(true);
  const [height, width] = useWindowSize();
  const [arraySize, setArraySize] = useState(0);
  const isMounted = useMounted();

  useEffect(() => {
    if ('height' in containerSize && 'width' in containerSize) {
      const elementsPerRow = Math.floor(containerSize.width / (SERVICE_WIDTH + SERVICE_MARGIN));
      const elementsPerColumn = Math.floor(
        containerSize.height / (SERVICE_HEIGHT + SERVICE_MARGIN)
      );
      const elementsPerPage = elementsPerRow * elementsPerColumn;
      if (isMounted.current) {
        setArraySize(elementsPerPage);
      }
    }
  }, [height, width, containerSize, isMounted]);

  return (
    <div className={styles.wizardSkeleton} ref={containerRef}>
      {Array(arraySize)
        .fill('skeleton')
        .map((el, index) => (
          <Skeleton key={`${el}-${index + 1}`} width={SERVICE_WIDTH} height={SERVICE_HEIGHT} />
        ))}
    </div>
  );
};

export default WizardSkeleton;
