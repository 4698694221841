import React from 'react';
import { useTranslate } from 'global/hooks';
import { EmptySectionSVG } from 'common/icons';
import styles from './NoData.module.scss';

const NoData: React.FC = () => {
  const translate = useTranslate();
  return (
    <div className={styles.noData}>
      <EmptySectionSVG />
      <span>{translate('COMPONENT.NO_INFO')}</span>
    </div>
  );
};

export default NoData;
