import { AddField, Section } from 'global/types/AddFields.interface';

export default (addFields: AddField[], sections: Section[]): AddField[] => {
  const sectionFields = sections.flatMap((section) => section.listFields);

  return addFields.reduce((fieldsWithSection, field) => {
    const sectionInfo = sectionFields.find((sField) => sField.fieldId === field.fieldId);
    if (sectionInfo) {
      fieldsWithSection.push({
        ...field,
        sectionId: sectionInfo.sectionId,
        order: sectionInfo.order,
      });
    } else {
      fieldsWithSection.push({
        ...field,
        sectionId: null,
      });
    }
    return fieldsWithSection;
  }, []);
};
