import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { FileType } from 'global/types/Case.interface';
import { formatJSONDate } from 'global/helpers';

const getItemFiles = async (itemId: number, itemType: number, uploadType: number) => {
  const data = await axios.get<FileType[]>(`/item/${itemId}/${itemType}/${uploadType}/files`);

  if (data.status !== 200) {
    throw Error('Error obtaining case files');
  }

  const withFormattedDate = data?.data?.map((element) => {
    let created;
    if (typeof element.created === 'string') {
      created = formatJSONDate(element.created);
    }
    return {
      ...element,
      created,
    };
  });
  return withFormattedDate;
};

export default function useItemFiles(
  itemId: number,
  itemType: number,
  uploadType: number
): UseQueryResult<FileType[], Error> {
  return useQuery(
    ['itemfiles', itemId, itemType, uploadType],
    () => getItemFiles(itemId, itemType, uploadType),
    { enabled: false }
  );
}
