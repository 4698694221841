import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TabStrip, TabStripTab, TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import { Tab } from 'global/types/System.interface';
import styles from './Tabs.module.scss';

interface TabsProps {
  tabs: Tab[];
  returnTabIndex?: (index: number) => void;
  showMenu?: boolean;
}

const Tabs: React.FC<TabsProps> = ({ tabs, returnTabIndex }) => {
  const [selected, setSelected] = useState(0);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    if (typeof returnTabIndex === 'function') {
      returnTabIndex(e.selected);
    }
    setSelected(e.selected);
  };
  return (
    <TabStrip selected={selected} onSelect={handleSelect} className={styles.tabstrip}>
      {tabs.map((tab: Tab, index: number) => (
        <TabStripTab
          disabled={tab.disabled}
          title={tab.title}
          key={`${tab.title}_${index + 1}`}
          contentClassName={styles.tabContent}
        >
          {tab.content}
        </TabStripTab>
      ))}
    </TabStrip>
  );
};

Tabs.defaultProps = {
  returnTabIndex: null,
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  returnTabIndex: PropTypes.func,
};

export default Tabs;
