import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { LookUp } from 'global/types/AddFields.interface';

const CATEGORY_TREE_TYPE = '12';
const CATEGORY_LIST_TYPE = '7';

const getLookUp = async (
  addFieldId: string,
  fieldType: string,
  catalogId?: string,
  userId?: number
) => {
  const data = await axios.get<LookUp[]>(
    `additionalsfields/${addFieldId}/type/${fieldType}/values?dataType=&parentId=&catalogId=${catalogId}&userId=${
      userId || ''
    }`
  );

  if (data.status !== 200) {
    throw Error('Error obtaining lookup values');
  }

  let transformedData;
  if (fieldType === CATEGORY_TREE_TYPE || fieldType === CATEGORY_LIST_TYPE) {
    transformedData = data.data.map((field) => ({
      ...field,
      expanded: false,
      items: [],
      selected: false,
    }));
  } else {
    transformedData = data?.data;
  }
  return transformedData;
};

export default function useLookUp(
  addFieldId: string,
  fieldType: string,
  enabled: boolean,
  catalogId?: string,
  userId?: number
): UseQueryResult<LookUp[], Error> {
  return useQuery(
    ['lookup', addFieldId, fieldType, catalogId, userId],
    () => getLookUp(addFieldId, fieldType, catalogId, userId),
    { enabled: !!(addFieldId && fieldType) && enabled }
  );
}
