import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useQueryClient } from 'react-query';
import {
  TreeView,
  TreeViewExpandChangeEvent,
  TreeViewItemClickEvent,
  processTreeViewItems,
} from '@progress/kendo-react-treeview';
import { Loader } from '@progress/kendo-react-indicators';
import { Button } from '@progress/kendo-react-buttons';
import { CatLookUp, SendLookUp, TreeValue } from 'global/types/AddFields.interface';
import { useTranslate } from 'global/hooks';
import { useCatLookUp, useLookUp } from 'global/services';
import styles from './Tree.module.scss';

interface TreeProps {
  catalogId?: string;
  defaultValue?: TreeValue;
  fieldType?: string;
  handleSave: (t: TreeValue) => void;
  id?: string;
}

const Tree: React.FC<TreeProps> = ({ catalogId, defaultValue, fieldType, handleSave, id }) => {
  const translate = useTranslate();
  const queryClient = useQueryClient();
  const [selected, setSelected] = useState<CatLookUp>(null);
  const [expanded, setExpanded] = useState<string[]>([]);
  const { data, isLoading, isFetching, isSuccess } = useLookUp(id, fieldType, true, catalogId);
  const { mutate, isLoading: catLoading } = useCatLookUp();
  const onItemClick = (e: TreeViewItemClickEvent) => {
    e.item.selected = true;
    if (selected) {
      selected.selected = false;
    }
    setSelected(e.item);
  };

  useEffect(() => {
    if (isSuccess && data?.length > 0 && defaultValue) {
      setSelected({
        ...data[0],
        name: defaultValue.stringValue,
        id: defaultValue.valueIntField,
        expanded: false,
        selected: true,
        items: [],
      });
    }
  }, [data, defaultValue, isSuccess, setSelected]);

  const setNewData = (items: CatLookUp[], itemId: number, newData: CatLookUp[]): CatLookUp[] => {
    return items.map((item) => {
      if (item.id === itemId) {
        item.items = newData;
      } else if (item.items?.length > 0) {
        item.items = setNewData(item.items, itemId, newData);
      }
      return item;
    });
  };

  const loadItems = (field: CatLookUp) => {
    const sendLookUp: SendLookUp = {
      fieldId: id,
      fieldType,
      catalogId,
      parentId: field.id,
    };
    mutate(sendLookUp, {
      onSuccess: (catData, variables) => {
        const newData = setNewData(data, field.id, catData);
        queryClient.setQueryData(['lookup', id, fieldType, catalogId], newData);
        setExpanded((prevState) => [...prevState, variables.parentId.toString()]);
      },
    });
  };

  const onExpandChange = (e: TreeViewExpandChangeEvent) => {
    if (e.item.items?.length === 0) {
      loadItems(e.item);
    } else if (e.item.expanded) {
      setExpanded(_.without(expanded, e.item.id.toString()));
    } else {
      setExpanded((prevState) => [...prevState, e.item.id.toString()]);
    }
  };

  const handleClick = () => {
    const newValue: TreeValue = {
      stringValue: selected.name,
      valueIntField: selected.id,
    };
    handleSave(newValue);
  };
  return (
    <div className={styles.modal}>
      {isLoading || isFetching ? (
        <Loader className={styles.loader} size="large" />
      ) : (
        <>
          <span
            className={`${styles.loading} k-icon k-i-loading ${catLoading ? styles.display : ''}`}
          />
          <TreeView
            className={styles.tree}
            data={processTreeViewItems(data, {
              select: { ids: [selected?.id], idField: 'id' },
              expand: { ids: expanded, idField: 'id' },
            })}
            expandIcons
            hasChildrenField="hasChild"
            onExpandChange={onExpandChange}
            onItemClick={onItemClick}
            textField="name"
          />
          <Button onClick={handleClick} themeColor="primary" type="button">
            {translate('COMPONENT.SAVE')}
          </Button>
        </>
      )}
    </div>
  );
};

Tree.defaultProps = {
  catalogId: '',
  defaultValue: null,
  fieldType: '',
  id: '',
};

Tree.propTypes = {
  catalogId: PropTypes.string,
  defaultValue: PropTypes.any,
  fieldType: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  id: PropTypes.string,
};

export default Tree;
