import React, { ReactNode } from 'react';
import { AddField, AddFieldsItemParams } from 'global/types/AddFields.interface';
import { Field, FieldProps, FieldMetaProps } from 'formik';
import {
  CategoryTree,
  CheckboxWrapper,
  DropdownWrapper,
  DateTimeWrapper,
  InputWrapper,
  TextAreaWrapper,
  NumericWrapper,
  DateWrapper,
  TimeWrapper,
  LinkWrapper,
  FileWrapper,
  MultiSelectWrapper,
} from 'common/FormFields';
import { defaultValidator, dropdownValidator } from 'global/validators';
import ButtonWrapper from 'common/FormFields/ButtonWrapper/ButtonWrapper';

const getFieldType = (
  addField: AddField,
  itemData?: AddFieldsItemParams,
  formDisable?: boolean
) => {
  switch (addField.fieldType) {
    case 1: // Short Text
      return (
        <Field
          name={addField?.fieldId.toString()}
          validate={addField.mandatoryUser ? defaultValidator : null}
        >
          {({ field, meta }: FieldProps) => (
            <InputWrapper
              disabled={!addField.editableUser || formDisable}
              id={addField.fieldId.toString()}
              label={addField.name}
              meta={meta}
              required={addField.mandatoryUser}
              value={field.value}
              {...field}
            />
          )}
        </Field>
      );
    case 2: // Date Time
      return (
        <DateTimeWrapper
          disabled={!addField.editableUser || formDisable}
          id={addField.fieldId.toString()}
          label={addField.name}
          required={addField.mandatoryUser}
        />
      );
    case 3: // Lookup
      return (
        <Field
          name={addField?.fieldId.toString()}
          validate={addField.mandatoryUser ? defaultValidator : null}
        >
          {({ field, meta }: FieldProps) => (
            <DropdownWrapper
              dataSourceId={addField.dataSourceId}
              disabled={!addField.editableUser || formDisable}
              fieldType={addField.fieldType.toString()}
              id={addField.fieldId.toString()}
              label={addField.name}
              meta={meta}
              required={addField.mandatoryUser}
              textField="name"
              valueField="id"
              {...field}
            />
          )}
        </Field>
      );
    case 4: // Long Text
      return (
        <TextAreaWrapper
          disabled={!addField.editableUser || formDisable}
          id={addField.fieldId.toString()}
          label={addField.name}
          required={addField.mandatoryUser}
        />
      );
    case 5: // Checkbox
      return (
        <CheckboxWrapper
          disabled={!addField.editableUser || formDisable}
          label={addField.name}
          id={addField.fieldId.toString()}
          required={addField.mandatoryUser}
        />
      );
    case 6: // Numeric Text
      return (
        <Field
          name={addField?.fieldId.toString()}
          validate={addField.mandatoryUser ? defaultValidator : null}
        >
          {({ field, meta }: FieldProps) => (
            <NumericWrapper
              disabled={!addField.editableUser || formDisable}
              format="n0"
              id={addField.fieldId.toString()}
              label={addField.name}
              meta={meta}
              required={addField.mandatoryUser}
              {...field}
            />
          )}
        </Field>
      );
    case 7: // Catalog List
      return (
        <CategoryTree
          disabled={!addField.editableUser || formDisable}
          fieldType={addField.fieldType.toString()}
          id={addField.fieldId.toString()}
          label={addField.name}
          required={addField.mandatoryUser}
        />
      );
    case 9: // Decimal
      return (
        <Field
          name={addField?.fieldId.toString()}
          validate={addField.mandatoryUser ? defaultValidator : null}
        >
          {({ field, meta }: FieldProps) => (
            <NumericWrapper
              disabled={!addField.editableUser || formDisable}
              format="n2"
              id={addField.fieldId.toString()}
              label={addField.name}
              meta={meta}
              required={addField.mandatoryUser}
              {...field}
            />
          )}
        </Field>
      );
    case 10: // File
      return (
        <FileWrapper
          disabled={!addField.editableUser || formDisable}
          label={addField.name}
          id={addField.fieldId.toString()}
          itemId={itemData?.id}
          itemType={itemData?.itemType}
          required={addField.mandatoryUser}
          valueIntField={addField.valueIntField}
        />
      );
    case 11: // Dropdown
      return (
        <Field
          name={addField?.fieldId.toString()}
          validate={addField.mandatoryUser ? dropdownValidator : null}
        >
          {({ field, meta }: FieldProps) => (
            <DropdownWrapper
              catalogId={addField.catalogId?.toString()}
              disabled={!addField.editableUser || formDisable}
              id={addField.fieldId?.toString()}
              fieldType={addField.fieldType?.toString()}
              label={addField.name}
              meta={meta}
              required={addField.mandatoryUser}
              textField="name"
              valueField="id"
              {...field}
            />
          )}
        </Field>
      );
    case 12: // Catalog Tree
      return (
        <CategoryTree
          catalogId={addField?.catalogId?.toString()}
          disabled={!addField?.editableUser || formDisable}
          fieldType={addField?.fieldType?.toString()}
          id={addField?.fieldId?.toString()}
          label={addField?.name}
          required={addField?.mandatoryUser}
        />
      );
    case 13: // Date
      return (
        <DateWrapper
          disabled={!addField.editableUser || formDisable}
          id={addField.fieldId.toString()}
          label={addField.name}
          required={addField.mandatoryUser}
        />
      );
    case 14: // Time
      return (
        <TimeWrapper
          disabled={!addField.editableUser || formDisable}
          id={addField.fieldId.toString()}
          label={addField.name}
          required={addField.mandatoryUser}
        />
      );
    case 15: // Currency
      return (
        <Field
          name={addField?.fieldId.toString()}
          validate={addField.mandatoryUser ? defaultValidator : null}
        >
          {({ field, meta }: FieldProps) => (
            <NumericWrapper
              disabled={!addField.editableUser || formDisable}
              format="c2"
              id={addField.fieldId.toString()}
              label={addField.name}
              meta={meta}
              required={addField.mandatoryUser}
              {...field}
            />
          )}
        </Field>
      );
    case 16: // Link
      return (
        <Field
          name={addField?.fieldId.toString()}
          validate={addField.mandatoryUser ? defaultValidator : null}
        >
          {({ field, meta }: FieldProps) => (
            <LinkWrapper
              disabled={!addField.editableUser || formDisable}
              id={addField.fieldId.toString()}
              label={addField.name}
              meta={meta as FieldMetaProps<string>}
              required={addField.mandatoryUser}
              value={field.value}
              {...field}
            />
          )}
        </Field>
      );
    case 18: // Multiselect
      return (
        <MultiSelectWrapper
          dataSourceId={addField.dataSourceId}
          disabled={!addField.editableUser || formDisable}
          id={addField.fieldId.toString()}
          label={addField.name}
          required={addField.mandatoryUser}
        />
      );
    case 19: // ButtonUrl
      return (
        <ButtonWrapper
          description={addField.description}
          disabled={!addField.url || addField.url === ''}
          id={addField.fieldId.toString()}
          label={addField.name}
          url={addField.url}
        />
      );
    default:
      return null;
  }
};

const getAddField = (
  addField: AddField,
  itemData?: AddFieldsItemParams,
  formDisable?: boolean
): ReactNode => {
  return getFieldType(addField, itemData, formDisable);
};

export default getAddField;
