import React, { useState, createContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CaseElements } from 'global/types/Case.interface';
import { Chat } from 'containers/ArandaChat/constants/ArandaChat.interface';
import { CaseType } from 'containers/CaseList/constants/CaseList.interface';

export const ChatContext = createContext<Chat>({
  chatActive: false,
  chatTemplate: null,
  consultedCase: null,
  disconnectError: false,
  launchArandaChat: null,
  loadChat: false,
  setCase: null,
  setChatASMSActive: null,
  setLaunchFunction: null,
  setStatusLoadChat: null,
  setTemplate: null,
});

interface ChatProviderProps {
  children: React.ReactNode;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const [launchArandaChat, setLaunchArandaChat] = useState<() => void>(null);
  const [loadChat, setLoadChat] = useState<boolean>(false);
  const [chatActive, setChatActive] = useState<boolean>(false);
  const [consultedCase, setConsultedCase] = useState<CaseType>(null);
  const [chatTemplate, setChatTemplate] = useState<CaseElements[]>(null);
  const [disconnectError, setDisconnectError] = useState(false);

  const setChatASMSActive = useCallback((state: boolean) => {
    setChatActive(state);
  }, []);

  const setStatusLoadChat = useCallback((load: boolean) => {
    setLoadChat(load);
  }, []);

  const setCase = useCallback(
    (c: CaseType) => {
      setConsultedCase(c);
    },
    [setConsultedCase]
  );

  const setTemplate = useCallback(
    (t: CaseElements[]) => {
      setChatTemplate(t);
    },
    [setChatTemplate]
  );

  const setLaunchFunction = useCallback(
    (newFunc: () => void) => {
      setLaunchArandaChat(newFunc);
    },
    [setLaunchArandaChat]
  );

  window.clearCaseToConsult = useCallback(() => {
    setChatTemplate(null);
    setConsultedCase(null);
  }, [setChatTemplate, setConsultedCase]);

  window.turnOffChat = useCallback(() => {
    setDisconnectError(true);
  }, [setDisconnectError]);

  const value: Chat = useMemo(
    () => ({
      chatActive,
      consultedCase,
      disconnectError,
      setCase,
      setChatASMSActive,
      chatTemplate,
      setTemplate,
      launchArandaChat,
      setStatusLoadChat,
      loadChat,
      setLaunchFunction,
    }),
    [
      chatActive,
      consultedCase,
      disconnectError,
      setCase,
      setChatASMSActive,
      chatTemplate,
      setTemplate,
      launchArandaChat,
      setStatusLoadChat,
      loadChat,
      setLaunchFunction,
    ]
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

ChatProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
