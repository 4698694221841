/* eslint-disable react/state-in-constructor */
/* eslint-disable react/prop-types */
import React, { Component, ErrorInfo } from 'react';

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<{ children: React.ReactNode }> {
  public state = { hasError: false };

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(error, errorInfo);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <div>Ups!! Algo está mal, póngase en contacto con el administrador del sistema.</div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
