import { useMutation, UseMutationResult } from 'react-query';
import axios from 'axios';
import { SendUserFields } from 'containers/Header/constants/Header.interface';
import { CaseElements } from 'global/types/Case.interface';

const ignoredFields = [
  'birthDate',
  'chargeName',
  'projects',
  'licenses',
  'cultureName',
  'departmentCode',
  'departmentCodeName',
  'floorId',
  'floorName',
  'buildingId',
  'buildingName',
  'jobId',
  'jobName',
  'timeZone',
];

const putSaveUserFields = async (sendUserFields: SendUserFields) => {
  const dataElements: CaseElements[] = [];
  Object.entries(sendUserFields.user).forEach((entry) => {
    if (!ignoredFields.includes(entry[0])) {
      dataElements.push({ field: entry[0], value: (entry[1] as string) ?? '' });
    }
  });
  const data = await axios.put<boolean>(`/user/${sendUserFields.user.id}`, {
    dataElements,
    additionals: sendUserFields.additionalFields,
  });

  if (data.status !== 200) {
    throw Error('Error saving user fields');
  }

  return data?.data;
};

export default function useSaveUserFields(): UseMutationResult<
  boolean,
  Error,
  SendUserFields,
  Error
> {
  return useMutation(putSaveUserFields);
}
