import { AddField, MultiSelectValue, TreeValue } from 'global/types/AddFields.interface';

export interface Response<T> {
  additionalData?: AddField[];
  content: T[];
  time?: string;
  totalItems?: number;
  totalpage?: number;
}

/* VotePage */
export type DetailSpecialGroups = {
  description: string;
  groupName: string;
  icon: string;
};

export type FileType = {
  date: string | number;
  description: string;
  id: number;
  isPublic: boolean;
  name: string;
  size: number;
  url: string;
  userId: number;
  userName: string;
};

export type ResponseError = {
  data: {
    exceptionMessage: string;
    message: string;
    operation: string;
    serviceName: string;
  };
};

export type AlertType = {
  isManual?: boolean;
  msg: string;
  severity: Severity;
};

export const LANGUAGECODE: { [key: string]: number } = {
  'es': 0,
  'en': 1,
  'pt': 2,
};

export type Language = 'en' | 'es' | 'pt';

export type Severity = 'error' | 'warning' | 'info' | 'success';

export type Tab = { title: string; disabled: boolean; content: React.ReactNode };

export type TabsVotes = {
  id: string;
  title: string;
  content: React.ReactNode;
  keepMounted: boolean;
  disabled: boolean;
  icon?: React.ReactNode;
  displayTab?: boolean;
};

export type ItemsTabsVote = {
  item: TabsVotes;
};

export type Size =
  | {
      width: number;
      height: number;
    }
  | 'max';

export type ListArticle = {
  autorId: number;
  classId: number;
  content: string;
  createdDate: number;
  description: string;
  groupId: number;
  hitTotal: number;
  id: number;
  identifier: string;
  interfaceId: number;
  isPublic: boolean;
  isFavorite: boolean;
  keyword: string;
  modifiedDate: number;
  modifierId: number;
  openedDate: number;
  projectId: number;
  public: number;
  ratingTotal: number;
  reasonId: number;
  reasonName: string;
  responsibleId: number;
  stateId: number;
  stateName: string;
  stringStateColor: string;
  title: string;
  typeId: number;
  typeName: string;
  url: string;
};

export type Dropdown = {
  name: string;
  id: number;
};

export type Validator =
  | ((value: unknown) => string)
  | ((value: Dropdown) => string)
  | ((value: TreeValue) => string)
  | ((value: boolean) => string)
  | ((value: number) => string)
  | ((value: MultiSelectValue[]) => string);
