import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';

const getCreatedToken = async () => {
  const data = await axios.get<string>(`/authentication/create/token`); /* INTEGRATION TOKEN */

  if (data.status !== 200) {
    throw Error('Error obtaining integration token');
  }
  return data?.data;
};

export default function useCreateToken(): UseQueryResult<string, Error> {
  return useQuery(['integrationtoken'], () => getCreatedToken(), {
    keepPreviousData: false,
  });
}
