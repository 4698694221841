import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { Response } from 'global/types/System.interface';
import { State } from 'global/types/Case.interface';

const getFinalStates = async (modelId: number, itemTypeId: number, stateId?: number | string) => {
  const data = await axios.get<Response<State>>(
    `model/${modelId}/${itemTypeId}/states?stateId=${stateId}`
  );

  if (data.status !== 200) {
    throw Error('Error obtaining final statuses');
  }

  const transformedData = data?.data?.content.filter((state) => state.isFinal);
  return transformedData ?? [];
};

export default function useFinalStates(
  modelId: number,
  itemTypeId: number,
  stateId?: number | string
): UseQueryResult<State[], Error> {
  return useQuery(
    ['finalstates', modelId, itemTypeId, stateId],
    () => getFinalStates(modelId, itemTypeId, stateId),
    {
      enabled: !!(modelId && itemTypeId),
    }
  );
}
