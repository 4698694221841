import React from 'react';
import PropTypes from 'prop-types';
import { Item } from 'containers/Wizard/constants/Wizard.interface';
import { ListItem } from 'containers/Wizard/elements';
import styles from './ListContainer.module.scss';

interface ListContainerProps {
  items: Item[];
  children: React.ReactNode;
  handleFolderClick: (folder: Item) => void;
  handleCardClick: (card: Item) => void;
}

const getFolderStatus = (item: Item) => {
  let folderCheck = false;
  if ('isFolder' in item) {
    folderCheck = item.isFolder;
  } else if ('folderCount' in item) {
    folderCheck = item.folderCount > 0;
  }
  return folderCheck;
};

const ListContainer: React.FC<ListContainerProps> = ({
  items,
  children,
  handleFolderClick,
  handleCardClick,
}) => (
  <div className={styles.listContainer}>
    {children}
    <div className={styles.list} id="wizard-item-container">
      {items.map((item: Item) => {
        const isFolder = getFolderStatus(item);
        return (
          <ListItem
            item={item}
            handleClick={isFolder ? (i) => handleFolderClick(i) : (i) => handleCardClick(i)}
            handleSecondaryClick={(i) => handleCardClick(i)}
            isFolder={isFolder}
            key={item.id}
          />
        );
      })}
    </div>
  </div>
);

ListContainer.propTypes = {
  items: PropTypes.array.isRequired,
  children: PropTypes.element.isRequired,
  handleFolderClick: PropTypes.func.isRequired,
  handleCardClick: PropTypes.func.isRequired,
};

export default ListContainer;
