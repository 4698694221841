import React from 'react';
import PropTypes from 'prop-types';
import { GridSVG, ListSVG } from 'common/icons';
import styles from './ViewSwitch.module.scss';

interface ViewSwitchProps {
  isGrid: boolean;
  handleClick: (change: boolean) => void;
}

const ViewSwitch: React.FC<ViewSwitchProps> = ({ isGrid, handleClick }) => (
  <div className={styles.viewSwitch}>
    <div className={`${isGrid ? styles.darken : ''}`}>
      <GridSVG onClick={() => handleClick(true)} />
    </div>
    <div className={`${!isGrid ? styles.darken : ''}`}>
      <ListSVG onClick={() => handleClick(false)} />
    </div>
  </div>
);

ViewSwitch.propTypes = {
  isGrid: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default ViewSwitch;
