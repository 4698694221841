import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'global/hooks';
import { Item, WizardImage, Project } from 'containers/Wizard/constants/Wizard.interface';
import { Counter, Image } from 'common';
import styles from './SelectedCard.module.scss';

interface SelectedCardProps {
  item: Item | Project;
  type: WizardImage;
  isFolder: boolean;
}

const SelectedCard: React.FC<SelectedCardProps> = React.memo(({ item, type, isFolder }) => {
  const translate = useTranslate();
  let count = 0;
  let text = translate('WIZARD.FOLDER');
  if ('folderCount' in item && item.folderCount > 0) {
    count = item.folderCount;
  }
  if ('serviceCount' in item && item.serviceCount > 0) {
    count = item.serviceCount;
    text = translate('WIZARD.SERVICE');
  } else if ('childrenCount' in item && item.childrenCount > 0) {
    count = item.childrenCount;
    text = translate('WIZARD.CATEGORY');
  }
  return (
    <div className={`${styles.selectedCard} ${isFolder ? styles.folder : ''}`}>
      {isFolder ? (
        <div className={styles.counter}>
          <Counter number={count} text={text} />
        </div>
      ) : (
        ''
      )}
      <div className={styles.image}>
        <Image id={item.imageId} type={type} large />
      </div>
      <div className={styles.info}>
        <h6 title={item.name}>{item.name}</h6>
        <p>{item.description}</p>
      </div>
    </div>
  );
});

SelectedCard.propTypes = {
  item: PropTypes.any.isRequired,
  type: PropTypes.oneOf<WizardImage>(['project', 'service', 'category', 'article']).isRequired,
  isFolder: PropTypes.bool.isRequired,
};

export default SelectedCard;
