import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { Response } from 'global/types/System.interface';
import { License, Project, Settings } from 'global/types/Resolve.interface';

const getSettings = async (projects: Project[], types: License[]) => {
  const projectIds = projects?.map((project) => project.id);
  const itemTypes = types?.map((license) => license.id);
  const data = await axios.post<Response<Settings>>(`/setting/list`, {
    applicationCode: '13',
    projects: projectIds,
    types: itemTypes,
  });

  if (data.status !== 200) {
    throw Error('Error obtaining project settings');
  }
  return data?.data?.content;
};

export default function useSettings(
  projects: Project[],
  types: License[]
): UseQueryResult<Settings[], Error> {
  return useQuery(['settings', projects, types], () => getSettings(projects, types), {
    enabled: !!(projects && types),
  });
}
