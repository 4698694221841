import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'global/hooks';
import { Item, Category } from 'containers/Wizard/constants/Wizard.interface';
import { LinkPopup } from 'containers/Wizard/elements';
import { Counter } from 'common';
import { ArrowDownSVG, ArrowUpSVG } from 'common/icons';
import styles from './ListItem.module.scss';

interface ListItemProps {
  item: Item;
  handleClick: (item: Item) => void;
  handleSecondaryClick: (folder: Item) => void;
  isFolder: boolean;
}

const ListItem: React.FC<ListItemProps> = ({
  item,
  handleClick,
  handleSecondaryClick,
  isFolder,
}) => {
  const translate = useTranslate();
  const [expanded, setExpanded] = useState(false);

  const handleExpand = (event: React.MouseEvent) => {
    event.stopPropagation();
    setExpanded((prevState) => !prevState);
  };

  let count = item.folderCount;
  let text = translate('WIZARD.FOLDER');
  if ('childrenCount' in item && item.childrenCount > 0) {
    count = item.childrenCount;
    text = translate('WIZARD.CATEGORY');
  }

  const handleCategoryFolder = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    handleSecondaryClick(item);
  };

  const getRightElements = (category: Item) =>
    'childrenCount' in category && category.childrenCount > 0 && category.isVisibleUser ? (
      <>
        <div className={styles.newCase}>
          <span className={styles.newCaseIcon} />
          <span
            className={styles.newCaseText}
            onClick={handleCategoryFolder}
            style={{ paddingTop: '3px' }}
          >
            {translate('WIZARD.NEW_CASE')}
          </span>
        </div>
        <LinkPopup category={category} />
      </>
    ) : (
      <LinkPopup category={category as Category} />
    );

  return (
    <div
      className={`${styles.listItem} ${isFolder ? styles.folder : ''}`}
      onClick={() => handleClick(item)}
      role="presentation"
    >
      <div className={styles.upper}>
        <h6 title={item.name}>{item.name}</h6>
        <div className={styles.right}>
          {isFolder && <Counter number={count} text={text} />}
          {getRightElements(item)}
          <div className={styles.arrow} onClick={handleExpand} role="presentation">
            {expanded ? <ArrowUpSVG /> : <ArrowDownSVG />}
          </div>
        </div>
      </div>
      <div className={`${styles.lower} ${expanded ? styles.show : ''}`}>
        <p>{item.description}</p>
      </div>
    </div>
  );
};

ListItem.propTypes = {
  item: PropTypes.any.isRequired,
  handleClick: PropTypes.func.isRequired,
  isFolder: PropTypes.bool.isRequired,
};

export default ListItem;
