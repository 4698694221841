import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocalization } from '@progress/kendo-react-intl';
import { useTranslate } from 'global/hooks';
import { useAddFields } from 'global/services';
import { CaseStep, Helper } from 'containers/Wizard/elements';
import wizardReducer from 'containers/Wizard/Wizard.reducer';
import { WizardState } from 'containers/Wizard/constants/Wizard.interface';
import { getSelectedType } from 'containers/Wizard/constants/Wizard.constants';
import { useStates, useItemTypes, useInterface } from 'containers/Wizard/services';
import { QuestionMarkSVG } from 'common/icons';
import styles from './CaseCreation.module.scss';

interface CaseCreationProps {
  initialState: WizardState;
  async?: boolean;
}

const init = (initialState: WizardState) => initialState;

const CaseCreation: React.FC<CaseCreationProps> = ({ initialState, async }) => {
  const localization = useLocalization();
  const translate = useTranslate();
  const [state, dispatch] = useReducer(wizardReducer, initialState, init);
  const [complete, setComplete] = useState<string>('');
  const {
    data: typesData,
    isLoading: typesLoad,
    refetch: typesFetch,
  } = useItemTypes(state.category?.id, state.service?.id);
  const {
    data: statesData,
    isLoading: stateLoad,
    isSuccess: statesSuccess,
  } = useStates(getSelectedType(state.itemType, typesData)?.modelId, state.itemType);

  useEffect(() => {
    if (typesData?.length > 0) {
      dispatch({ type: 'SET_ITEMTYPE', payload: { itemType: typesData[0] } });
    }
  }, [typesData]);

  const {
    data: interfaceData,
    isLoading: interLoad,
    refetch: interfaceFetch,
  } = useInterface(
    state.itemType,
    statesData?.length > 0 ? statesData[0]?.id : null,
    getSelectedType(state.itemType, typesData)?.modelId,
    state.project?.id,
    localization?.language
  );
  const {
    data: addsData,
    isLoading: addLoad,
    isFetching,
    refetch: addsFetch,
  } = useAddFields(
    state.category?.id,
    state.itemType,
    getSelectedType(state.itemType, typesData)?.modelId,
    statesData?.length > 0 ? statesData[0]?.id : null,
    state.service?.id
  );

  useEffect(() => {
    if (state.category?.id) {
      typesFetch();
    }
  }, [state.category, typesFetch]);

  useEffect(() => {
    if (statesData?.length > 0 && statesSuccess) {
      interfaceFetch();
      addsFetch();
    }
  }, [state.itemType, statesData, interfaceFetch, addsFetch, statesSuccess]);

  const handleHelperClick = () => {
    dispatch({ type: 'HELPER', payload: { showHelper: !state.showHelper } });
  };

  const onSaveAction = (idByProject?: string) => {
    setComplete(idByProject);
  };

  const loading = stateLoad || interLoad || addLoad || isFetching;
  const helperExists = !!getSelectedType(state.itemType, typesData)?.helpNoHTML;
  const showHelper = helperExists && state.showHelper;
  return !!complete ? (
    <div className={styles.complete}>
      <span className={`k-icon k-i-check-outline ${styles.icon}`} style={{ color: '#39b54a' }} />
      <span className={styles.message}>{`${
        complete !== 'Record' ? complete : translate('WIZARD.RECORD')
      } ${translate('WIZARD.CREATE_CASE_SUCCESS')}`}</span>
    </div>
  ) : (
    <div className={styles.caseCreation}>
      <CaseStep
        state={state}
        dispatch={dispatch}
        itemTypes={typesData}
        selectedType={getSelectedType(state.itemType, typesData)}
        stateId={statesData?.length > 0 ? statesData[0].id : null}
        interfaceConfig={interfaceData}
        addFields={addsData}
        onSaveAction={onSaveAction}
        loading={loading}
        catLoading={false}
        async={async}
        typeLoading={typesLoad}
      />
      {showHelper ? (
        <Helper
          body={getSelectedType(state.itemType, typesData)?.help ?? null}
          show={showHelper}
          handleClick={handleHelperClick}
        />
      ) : (
        <div
          className={styles.helperButton}
          onClick={handleHelperClick}
          role="presentation"
          style={{ display: helperExists ? 'flex' : 'none' }}
        >
          <QuestionMarkSVG />
        </div>
      )}
    </div>
  );
};

CaseCreation.defaultProps = {
  async: false,
};

CaseCreation.propTypes = {
  initialState: PropTypes.any.isRequired,
  async: PropTypes.bool,
};

export default CaseCreation;
