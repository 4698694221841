import React from 'react';
import PropTypes from 'prop-types';
import { saveAs } from '@progress/kendo-file-saver';
import { useLocalization } from '@progress/kendo-react-intl';
import { FileType } from 'global/types/System.interface';
import { useAuthentication, useModal, useResolve, useSnackbar, useTranslate } from 'global/hooks';
import { useCreateToken, useDownloadFile } from 'global/services';
import { ClipSVG, TrashSVG } from 'common/icons';
import FileModalTeamsVote from './FileModalTeamsVote/FileModalTeamsVote';
import { tzDictionary } from 'global/utils';
import styles from './FileListVote.module.scss';

interface FileListProps {
  closeFile?: () => void;
  disable?: boolean;
  files: Partial<FileType>[];
  handleDeleteFile?: (f: FileType | Partial<FileType>) => void;
  isTempFile: boolean;
}

const FileList: React.FC<FileListProps> = ({
  closeFile,
  disable,
  files,
  handleDeleteFile,
  isTempFile,
}) => {
  const { addAlert } = useSnackbar();
  const { language } = useLocalization();
  const translate = useTranslate();
  const { user } = useAuthentication();
  const { data } = useCreateToken();
  const { openModal, closeModal } = useModal();
  const { mutate: download } = useDownloadFile();
  const { CustomerURL } = useResolve();
  const teams = window.location.href.includes('teams');
  const teamsBoards = window.location.href.includes('teams/boards');

  const downloadFile = (fileId: number, fileName: string) => {
    if (teams && window.matchMedia('(max-width: 1024px)').matches) {
      window.open(`${CustomerURL}/service/api/v9/file/${fileId}?id_token=${data}`, '_blank');
    } else if (!isTempFile) {
      download(fileId, {
        onSuccess: (blob) => {
          saveAs(blob, fileName);
          addAlert({ msg: translate('COMPONENT.DOWNLOAD_FILE_SUCCESS'), severity: 'success' });
          if (teams) closeModal();
        },
        onError: () => {
          addAlert({ msg: translate('SYSTEM.GENERAL'), severity: 'error' });
        },
      });
    }
  };

  const onClick = (fileId: number, fileName: string) => {
    if (teams) {
      if (teamsBoards) closeFile();
      openModal(
        <FileModalTeamsVote close={closeModal} download={() => downloadFile(fileId, fileName)} />,
        translate('COMPONENT.FILE_MODAL'),
        {
          width: 800,
          height: 190,
        }
      );
    } else {
      downloadFile(fileId, fileName);
    }
  };

  return (
    <div className={styles.container}>
      {files?.length > 0 ? (
        files?.map((file, index) => {
          const transformedDate = new Date(file?.date);
          return (
            <div className={styles.file} key={`${file.id}-${index + 1}`}>
              {!!file.date && (
                <div className={styles.date}>
                  <time className={styles.full}>
                    {transformedDate.toLocaleDateString(language, {
                      timeZone: tzDictionary[user?.timeZone],
                    })}
                  </time>
                  <time className={styles.hour}>
                    {transformedDate.toLocaleTimeString(language, {
                      timeZone: tzDictionary[user?.timeZone],
                    })}
                  </time>
                </div>
              )}
              <div className={styles.text}>
                <span
                  className={styles.name}
                  onClick={() => onClick(file.id, file.name)}
                  role="presentation"
                  title={file.name}
                >
                  {file.name}
                </span>
                {file?.description ? (
                  <p className={styles.description} title={file.description}>
                    {file.description}
                  </p>
                ) : null}
              </div>
              <div className={styles.right}>
                {!isTempFile && (
                  <span className={styles.size}>{`[${Math.floor(file.size / 1024)} Kb]`}</span>
                )}
                <div
                  className={`${styles.status} ${file.isPublic ? styles.public : styles.private}`}
                >
                  <span>
                    {file.isPublic ? translate('COMPONENT.PUBLIC') : translate('COMPONENT.PRIVATE')}
                  </span>
                </div>
                {!!handleDeleteFile
                  ? !disable && <TrashSVG onClick={() => handleDeleteFile(file)} />
                  : null}
              </div>
            </div>
          );
        })
      ) : (
        <div className={styles.noAttachments}>
          <ClipSVG />
          <span>{translate('COMPONENT.NO_FILES')}</span>
        </div>
      )}
    </div>
  );
};

FileList.defaultProps = {
  closeFile: null,
  disable: false,
  handleDeleteFile: null,
};

FileList.propTypes = {
  closeFile: PropTypes.func,
  disable: PropTypes.bool,
  files: PropTypes.array.isRequired,
  handleDeleteFile: PropTypes.func,
  isTempFile: PropTypes.bool.isRequired,
};

export default FileList;
