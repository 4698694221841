import React from 'react';
import PropTypes from 'prop-types';
import { Answer, RiskLevel, Search } from 'containers/Case/constants/Case.interface';
import { useAuthentication, useSnackbar, useTranslate } from 'global/hooks';
import { useLocalization } from '@progress/kendo-react-intl';
import { RadioGroup } from '@progress/kendo-react-inputs';
import { EmptySectionSVG } from 'common/icons';
import { Button } from '@progress/kendo-react-buttons';
import { BackDrop } from 'common';
import { Loader } from '@progress/kendo-react-indicators';
import { Language } from 'global/types/System.interface';
import { useQuestions, useRiskLevel, useRiskPost } from 'global/services';
import styles from './Questions.module.scss';

type QuestionsProps = {
  close?: () => void;
  handleChange: (s: Search) => void;
  itemId?: number;
  isLoading: boolean;
  modelId?: number;
  riskId: number;
  stateId?: number;
};

const Questions: React.FC<QuestionsProps> = ({
  close,
  handleChange,
  itemId,
  isLoading,
  modelId,
  riskId,
  stateId,
}) => {
  const translate = useTranslate();
  const { language } = useLocalization();
  const { user } = useAuthentication();
  const { addAlert } = useSnackbar();
  const { data: questions, isLoading: loadingQuestions } = useQuestions(riskId);
  const { mutate, isLoading: riskLevelLoading } = useRiskLevel();
  const { mutateAsync: mutateRisk, isLoading: riskLoading } = useRiskPost();
  const dataOptions = [
    { label: translate('CASE.YES'), value: 2 },
    { label: translate('CASE.NO'), value: 1 },
  ];

  const handleRisk = async (listAnswer: Answer[]) => {
    await mutateRisk(
      {
        itemId,
        listAnswer,
        stateId,
      },
      {
        onSuccess: (result) => {
          if (result) {
            addAlert({
              msg: translate('WIZARD.EVALUATE_RISK'),
              severity: 'success',
            });
          }
        },
      }
    );
    close();
  };

  const handleRiskLevel = async () => {
    const options = questions.map((item) => {
      return {
        id: item.id,
        option: item.option,
      };
    });
    const params: RiskLevel = {
      modelId,
      localization: language as Language,
      params: {
        modelId,
        stateId,
        listAnswer: options.map((item) => {
          return {
            questionId: item.id,
            userId: user?.id,
            response: item.option,
          };
        }),
      },
    };
    await mutate(params, {
      onSuccess: (result, variables) => {
        handleChange(result);
        handleRisk(variables.params.listAnswer);
      },
    });
  };

  const handleOption = (option: number, id: number) => {
    const index = questions.findIndex((x) => x.id === id);
    questions[index].option = option - 1;
  };

  const handleSubmit = () => {
    if (questions.some((x) => x.option < 0 || x.option === null || x.option === undefined)) {
      addAlert({
        msg: translate('WIZARD.EMPTY_QUESTION'),
        severity: 'error',
      });
    } else {
      handleRiskLevel();
    }
  };
  return (
    <>
      <div className={styles.container}>
        {isLoading && <Loader className={styles.mainLoader} size="large" />}
        {questions?.map((item) => (
          <div key={item.id} className={styles.question}>
            <div className={styles.question__item}>
              <span>{item.question}</span>
            </div>
            <div className={styles.question__option}>
              <RadioGroup data={dataOptions} onChange={(e) => handleOption(e.value, item.id)} />
            </div>
          </div>
        ))}
        {!questions && (
          <div className={styles.noQuestions}>
            <EmptySectionSVG />
            <p>{translate('WIZARD.NO_QUESTIONS')}</p>
          </div>
        )}
        <div className={styles.question__buttons}>
          <Button disabled={!questions} onClick={handleSubmit} themeColor="primary" type="button">
            {translate('WIZARD.RISK')}
          </Button>
        </div>
      </div>
      {(riskLevelLoading || riskLoading || loadingQuestions) && <BackDrop show />}
    </>
  );
};

Questions.defaultProps = {
  itemId: null,
  isLoading: false,
  modelId: null,
  stateId: null,
};

Questions.propTypes = {
  close: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  itemId: PropTypes.number,
  isLoading: PropTypes.bool,
  modelId: PropTypes.number,
  riskId: PropTypes.number.isRequired,
  stateId: PropTypes.number,
};

export default Questions;
