import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { Error, Label } from '@progress/kendo-react-labels';
import { MultiSelect, MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';
import { useMultiSelect } from 'global/services';
import { useTranslate } from 'global/hooks';
import { AddFieldFormikValues, MultiSelectValue } from 'global/types/AddFields.interface';
import { multiselectValidator } from 'global/validators';
import { FormValidator } from 'containers/Wizard/elements';
import styles from './MultiSelectWrapper.module.scss';

interface MultiSelectWrapperProps {
  dataSourceId: number;
  disabled?: boolean;
  id: string;
  label: string;
  required?: boolean;
}

const MultiSelectWrapper: React.FC<MultiSelectWrapperProps> = ({
  dataSourceId,
  disabled,
  id,
  label,
  required,
}) => {
  const translate = useTranslate();
  const [, meta, helpers] = useField<MultiSelectValue[]>({
    name: id,
    validate: required ? multiselectValidator : null,
  });
  const { values } = useFormikContext<AddFieldFormikValues>();
  const { data, isLoading, isFetching, refetch } = useMultiSelect(
    id,
    typeof values.customer === 'object' && values.customer && 'id' in values?.customer
      ? values?.customer?.id
      : undefined
  );

  const handleChange = (e: MultiSelectChangeEvent) => {
    helpers.setValue(e.target.value);
    helpers.setTouched(true);
    if (e.target.value?.length > 0) {
      helpers.setError(undefined);
    }
  };

  const error = meta?.touched && typeof meta?.error === 'string' && !meta?.value?.length;
  const disabledSelection =
    disabled || ((typeof values.customer === 'string' || !values.customer) && !!dataSourceId);

  return (
    <div className={`${styles.multiWrap} ${disabled ? styles.disabled : ''}`}>
      <div title={label} className={styles.textLabel}>
        <Label editorId={id} editorValid={!error} editorDisabled={disabled}>
          {`${label}${required ? '(*)' : ''}`}
        </Label>
      </div>
      <div className={`${styles.container}`}>
        <MultiSelect
          autoClose={false}
          className={`${styles.multicombobox} ${error ? styles.error : ''}`}
          data={data}
          dataItemKey="id"
          disabled={disabledSelection}
          loading={isLoading || isFetching}
          onChange={handleChange}
          onOpen={() => refetch()}
          placeholder={translate('COMPONENT.SELECT')}
          textField="value"
          value={meta.value}
        />
        {disabledSelection || isLoading || isFetching ? null : (
          <div className={styles.counter}>
            <span>... ({meta?.value?.length || 0})</span>
            <span className="k-icon k-i-arrow-60-down" />
          </div>
        )}
      </div>
      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

MultiSelectWrapper.defaultProps = {
  dataSourceId: null,
  disabled: false,
  required: false,
};

MultiSelectWrapper.propTypes = {
  dataSourceId: PropTypes.number,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default MultiSelectWrapper;
