import { useMutation, UseMutationResult } from 'react-query';
import axios from 'axios';

const getDownloadFile = async (id: string | number) => {
  const data = await axios.get<string>(`/file/${id}`, {
    responseType: 'blob',
  });

  if (data.status !== 200) {
    throw Error('Error downloading file');
  }

  return window.URL.createObjectURL(data.data as unknown as Blob);
};

export default function useDownloadFile(): UseMutationResult<
  string,
  Error,
  string | number,
  unknown
> {
  return useMutation(getDownloadFile);
}
