import React from 'react';
import PropTypes from 'prop-types';
import { Item } from 'containers/Wizard/constants/Wizard.interface';
import { Card, FolderCard } from 'containers/Wizard/elements';
import styles from './CardContainer.module.scss';

interface CardContainerProps {
  items: Item[];
  children: React.ReactNode;
  handleFolderClick: (folder: Item) => void;
  handleCardClick: (card: Item) => void;
}

const getFolderStatus = (item: Item) => {
  let folderCheck = false;
  if ('isFolder' in item) {
    folderCheck = item.isFolder;
  } else if ('folderCount' in item) {
    folderCheck = item.folderCount > 0;
  }
  return folderCheck;
};

const CardContainer: React.FC<CardContainerProps> = ({
  items,
  children,
  handleFolderClick,
  handleCardClick,
}) => (
  <div className={styles.cardContainer}>
    {children}
    <div className={styles.itemsContainer} id="wizard-item-container">
      {items?.map((item: Item, index) =>
        getFolderStatus(item) ? (
          <FolderCard
            item={item}
            key={item.id}
            handleClick={(i) => handleFolderClick(i)}
            handleSecondaryClick={(i) => handleCardClick(i)}
          />
        ) : (
          <Card
            item={item}
            key={`${item.id}-${index + 1}`}
            handleClick={(i) => handleCardClick(i)}
          />
        )
      )}
    </div>
  </div>
);

CardContainer.propTypes = {
  items: PropTypes.array.isRequired,
  children: PropTypes.element.isRequired,
  handleFolderClick: PropTypes.func.isRequired,
  handleCardClick: PropTypes.func.isRequired,
};

export default CardContainer;
