import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import { useLocalization } from '@progress/kendo-react-intl';
import { useAuthentication, useTranslate } from 'global/hooks';
import { tzDictionary } from 'global/utils';
import { ExtendedCase } from 'containers/CaseList/constants/CaseList.interface';
import { InterfaceDictionary } from 'containers/Wizard/constants/Wizard.interface';
import { StatusLabel, TimeLabel } from 'common';
import PlaqueLabel from './PlaqueLabel/PlaqueLabel';
import styles from './Plaque.module.scss';

interface PlaqueProps {
  info: ExtendedCase;
  interfaceConfig?: InterfaceDictionary;
}

const Plaque: React.FC<PlaqueProps> = React.memo(({ info, interfaceConfig }) => {
  const translate = useTranslate();
  const { user } = useAuthentication();
  const { language } = useLocalization();
  const getProgressbarColor = () => {
    if (info?.progress >= 80 && info?.progress <= 99) {
      return styles.yellow;
    }
    if (info?.progress >= 100) {
      return styles.red;
    }
    return styles.green;
  };

  return (
    <div className={styles.plaque}>
      <div className={styles.column}>
        <h3>{info.idByProject}</h3>
        <StatusLabel isClosed={info.isClosed} />
        <div
          className={styles.state}
          style={{
            borderLeft: `6px solid rgb(${info.backgroundColorRgb.replace(/\./g, ',')})`,
          }}
        >
          <span title={info.stateName}>{info.stateName}</span>
        </div>
        <h4 className={styles.service} title={info.serviceName}>
          {info.serviceName}
        </h4>
        <PlaqueLabel
          hierarchy={info.categoryHierarchy}
          label={`${
            interfaceConfig?.categoryName?.label
              ? interfaceConfig?.categoryName?.label
              : translate('COMPONENT.CATEGORY')
          }:`}
          text={info.categoryName}
        />
        <PlaqueLabel
          label={`${
            interfaceConfig?.autorName?.label
              ? interfaceConfig?.autorName?.label
              : translate('COMPONENT.AUTHOR')
          }:`}
          text={info.authorName}
        />
        <PlaqueLabel
          label={`${
            interfaceConfig?.companyName?.label
              ? interfaceConfig?.companyName?.label
              : translate('COMPONENT.COMPANY')
          }:`}
          text={info.companyName}
        />
        <PlaqueLabel
          label={`${
            interfaceConfig?.reasonName?.label
              ? interfaceConfig?.reasonName?.label
              : translate('COMPONENT.REASON')
          }:`}
          text={info.reasonName}
        />
      </div>
      <div className={styles.column}>
        <PlaqueLabel
          label={`${
            interfaceConfig?.groupName?.label
              ? interfaceConfig?.groupName?.label
              : translate('COMPONENT.SPECIALIST_GROUP')
          }:`}
          text={info.groupName}
        />
        <PlaqueLabel
          label={`${
            interfaceConfig?.responsibleName?.label
              ? interfaceConfig?.responsibleName?.label
              : translate('COMPONENT.SPECIALIST')
          }:`}
          text={info.responsibleName}
        />
        <div className={styles.progress}>
          <div className={styles.progressLabel}>
            <span className={styles.progressText}>{`${translate('COMPONENT.PROGRESS')}:`}</span>
            <span className={styles.progressValue}>{info.progress}%</span>
          </div>
          <ProgressBar
            value={info?.progress ?? 0}
            labelVisible={false}
            className={`${styles.progressbar} ${getProgressbarColor()}`}
          />
        </div>
        <div className={styles.row}>
          <TimeLabel label="SLA:" time={info.estimatedTime} />
          <TimeLabel label={`${translate('COMPONENT.ELAPSED_TIME')}:`} time={info.currentTime} />
        </div>
        <div className={styles.estimatedDate}>
          <span className={styles.dateLabel}>{translate('COMPONENT.ESTIMATED_DATE')}:</span>
          <span className={styles.date}>
            {info.estimatedDate
              ? new Date(info.estimatedDate).toLocaleString(language, {
                  timeZone: tzDictionary[user.timeZone],
                })
              : translate('COMPONENT.NO_APPLY')}
          </span>
        </div>
      </div>
    </div>
  );
});

Plaque.defaultProps = {
  interfaceConfig: null,
};

Plaque.propTypes = {
  info: PropTypes.any.isRequired,
  interfaceConfig: PropTypes.any,
};

export default Plaque;
