import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { AddField } from 'global/types/AddFields.interface';

const getUserFields = async () => {
  const data = await axios.get<AddField[]>('/user/additionalfields');
  if (data.status !== 200) {
    throw Error('Error obtaining user fields');
  }
  return data?.data;
};

export default function useUserFields(userTab: boolean): UseQueryResult<AddField[], Error> {
  return useQuery(['userfields', userTab], () => getUserFields(), {
    enabled: userTab,
  });
}
