import React from 'react';
import PropTypes from 'prop-types';
import { useLocalization } from '@progress/kendo-react-intl';
import { SendRating } from 'containers/KB/constants/Kb.interface';
import { useAuthentication, useModal, useSnackbar, useTranslate } from 'global/hooks';
import { tzDictionary } from 'global/utils';
import { useArticle, useArticleFiles, useRating } from 'containers/KB/services';
import { FileManager, Rating } from 'components';
import { Keywords, NoData, HTMLHandler, ExternalPage } from 'common';
import { ExtendSVG, KbSVG } from 'common/icons';
import { ArticleSkeleton } from 'skeletons';
import styles from './Article.module.scss';

interface ArticleProps {
  articleId?: number;
  bare?: boolean;
  addHit?: boolean;
}

const Article: React.FC<ArticleProps> = ({ articleId, bare, addHit }) => {
  const translate = useTranslate();
  const { addAlert } = useSnackbar();
  const { user } = useAuthentication();
  const { language } = useLocalization();
  const { openModal } = useModal();
  const { data: articleData, isLoading: articleLoading } = useArticle(articleId, addHit);
  const { data: filesData } = useArticleFiles(articleId);
  const { mutateAsync: rateMutate } = useRating(articleId);

  if (articleLoading) {
    return <ArticleSkeleton />;
  }
  const handleExternal = () => {
    window.open(articleData?.url, '_blank');
  };

  const handleRating = async (sendRating: SendRating) => {
    await rateMutate(sendRating, {
      onSuccess: () => {
        addAlert({ msg: translate('KB.RATING_SUCCESS'), severity: 'success' });
      },
    });
  };

  const contentNode =
    articleData?.url === '' ? (
      <HTMLHandler html={articleData?.content} />
    ) : (
      <ExternalPage url={articleData?.url} />
    );
  return (
    <div className={`${styles.article} ${bare && styles.bare}`} data-testid="article">
      <div className={styles.header}>
        <KbSVG />
        <h3>{translate('KB.ARTICLE')}</h3>
      </div>
      {articleData ? (
        <div className={`${styles.container}  ${articleData.keyword ? styles.hasWords : ''}`}>
          <div className={styles.subheader}>
            <div className={styles.details}>
              <span>{`${translate('KB.RESPONSIBLE')}: ${articleData.responsibleName} `} </span>
              <time>{`${translate('KB.MODIFICATION_DATE')} ${new Date(
                articleData.lastModified
              ).toLocaleDateString(language, { timeZone: tzDictionary[user?.timeZone] })} `}</time>
            </div>
            <Rating
              article={articleData}
              rating={articleData.ratingTotal}
              handleRating={handleRating}
              disabled={articleData.isRatedByUser}
            />
          </div>
          <p className={styles.description}>{articleData.description}</p>
          <div className={`${styles.content} ${articleData.url !== '' && styles.externalContent}`}>
            {articleData.url === '' ? (
              contentNode
            ) : (
              <div className={styles.external}>
                <p className={styles.externalHint}>
                  {`${translate('KB.CONTENT_NOT_LOADED')} `}
                  <span className={styles.link} onClick={handleExternal} role="presentation">
                    {translate('KB.CLICK_HERE')}
                  </span>
                </p>
                {contentNode}
              </div>
            )}
            {!bare && (
              <div
                className={styles.expand}
                onClick={() =>
                  openModal(contentNode, translate('KB.ARTICLE'), { width: 800, height: 600 })
                }
                role="presentation"
                data-testid="articleexpand"
              >
                <ExtendSVG />
              </div>
            )}
          </div>
          <FileManager files={filesData} canModify={false} isTempFile={false} />
          {articleData.keyword && <Keywords words={articleData.keyword.split(' ')} />}
        </div>
      ) : (
        <NoData />
      )}
    </div>
  );
};

Article.defaultProps = {
  articleId: null,
  bare: false,
  addHit: false,
};

Article.propTypes = {
  articleId: PropTypes.number,
  bare: PropTypes.bool,
  addHit: PropTypes.bool,
};

export default Article;
