import React, { createContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ErrorPage from 'routers/ErrorPage/ErrorPage';
import { Global } from 'global/types/Resolve.interface';
import { useAppGlobals } from 'global/services';
import { DEV } from 'global/utils';
import { BackDrop } from 'common';

export const ResolveContext = createContext<Global>({
  AdministratorURL: null,
  AllowExtAuthenticationInLogin: null,
  AllowHiddenLoginforSaml: null,
  APIUrl: null,
  AuthenticationMode: null,
  CmdbUrl: null,
  CustomerURL: null,
  HideAssistant: null,
  HideForgotPasswordCustomer: null,
  OpenLinkNewTab: null,
  ServiceDetailURL: null,
  ShowLoginNews: null,
  SpecialistURL: null,
  UsersAPRURL: null,
});

interface ResolveProviderProps {
  children: React.ReactNode;
}

export const ResolveProvider: React.FC<ResolveProviderProps> = ({ children }) => {
  const { data, isLoading, isSuccess, isError } = useAppGlobals();

  useEffect(() => {
    if (data && isSuccess) {
      axios.defaults.baseURL = DEV
        ? `http://localhost:3000/${process.env.REACT_APP_BASE_URL}/service/api/v9/`
        : `${data.CustomerURL}service/api/v9/`;
    }
  }, [data, isSuccess]);

  const global = useMemo(
    () => ({
      AdministratorURL: data?.AdministratorURL,
      AllowExtAuthenticationInLogin: data?.AllowExtAuthenticationInLogin === 'True',
      AllowHiddenLoginforSaml: data?.AllowHiddenLoginforSaml === 'True',
      APIUrl: DEV ? `http://localhost/${process.env.REACT_APP_API_URL}` : data?.APIUrl,
      AuthenticationMode: data?.AuthenticationMode,
      CmdbUrl: data?.CmdbUrl,
      CustomerURL: DEV ? 'http://localhost:3000' : data?.CustomerURL,
      HideAssistant: data?.HideAssistant === 'True',
      HideForgotPasswordCustomer: data?.HideForgotPasswordCustomer,
      OpenLinkNewTab: data?.OpenLinkNewTab === 'True',
      ServiceDetailURL: null,
      ShowLoginNews: data?.ShowLoginNews === 'True',
      SpecialistURL: data?.SpecialistURL,
      UsersAPRURL: data?.UsersAPRURL,
    }),
    [data]
  );

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <ResolveContext.Provider value={global}>
      {data && isSuccess && !isLoading ? children : <BackDrop show />}
    </ResolveContext.Provider>
  );
};

ResolveProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
