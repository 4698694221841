import React from 'react';
import PropTypes from 'prop-types';
import weekData from 'cldr-core/supplemental/weekData.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import numbers from 'cldr-numbers-full/main/es/numbers.json';
import dateFields from 'cldr-dates-full/main/es/dateFields.json';
import currencies from 'cldr-numbers-full/main/es/currencies.json';
import caGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import timeZoneNames from 'cldr-dates-full/main/es/timeZoneNames.json';
import numbersPT from 'cldr-numbers-full/main/pt/numbers.json';
import dateFieldsPT from 'cldr-dates-full/main/pt/dateFields.json';
import currenciesPT from 'cldr-numbers-full/main/pt/currencies.json';
import caGregorianPT from 'cldr-dates-full/main/pt/ca-gregorian.json';
import timeZoneNamesPT from 'cldr-dates-full/main/pt/timeZoneNames.json';
import {
  LocalizationProvider,
  loadMessages,
  load,
  IntlProvider as Int,
} from '@progress/kendo-react-intl';
import { useAuthentication } from 'global/hooks';
import { parseLanguageCode } from 'global/helpers';
import { getLocales } from 'global/utils';

const getCulture = (culture: string) =>
  parseLanguageCode(culture) || parseLanguageCode(navigator.language) || 'es';

interface IntlProviderProps {
  children: React.ReactNode;
}

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames,
  numbersPT,
  currenciesPT,
  caGregorianPT,
  dateFieldsPT,
  timeZoneNamesPT
);

const IntlProvider: React.FC<IntlProviderProps> = ({ children }) => {
  const { user } = useAuthentication();

  loadMessages(getLocales.en, 'en');
  loadMessages(getLocales.es, 'es');
  loadMessages(getLocales.pt, 'pt');

  const culture = getCulture(user?.cultureName);
  return (
    <LocalizationProvider language={culture}>
      <Int locale={culture}>{children}</Int>
    </LocalizationProvider>
  );
};

IntlProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default IntlProvider;
