import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { KbArticle } from 'containers/KB/constants/Kb.interface';

const getArticle = async (articleId: number, addHit: boolean) => {
  const data = await axios.get<KbArticle>(`/article/${articleId}?addHit=${addHit}`);
  if (data.status !== 200) {
    throw Error('Error obtaining article');
  }
  return data?.data;
};

export default function useArticle(
  articleId: number,
  addHit: boolean
): UseQueryResult<KbArticle, Error> {
  return useQuery(['article', articleId, addHit], () => getArticle(articleId, addHit), {
    enabled: !!articleId,
  });
}
