import React from 'react';
import PropTypes from 'prop-types';
import { Section, AddField, AddFieldsItemParams } from 'global/types/AddFields.interface';
import { useTranslate } from 'global/hooks';
import { getAddField } from 'global/utils';
import styles from './AddFields.module.scss';

const isFullColumn = (type: number) => type === 4;

interface AddFieldsProps {
  addFields?: AddField[];
  disable?: boolean;
  itemData?: AddFieldsItemParams;
  sections?: Section[];
}

const checkForFields = (addFields: AddField[]) => {
  return addFields.some((add) => {
    return !add.sectionId && add.visible;
  });
};

const AddFields: React.FC<AddFieldsProps> = ({ addFields, sections, itemData, disable }) => {
  const translate = useTranslate();
  const teams = window.location.href.includes('teams');

  const getSectionFields = (sectionsWith: Section[], addFieldsWith: AddField[]) =>
    sectionsWith?.reduce((sectionsWithArr, section, index) => {
      if (section.display) {
        sectionsWithArr.push(
          <section className={styles.section} key={`${section.id}-${index + 1}`}>
            <h4>{section.name}</h4>
            <h5>{section.description}</h5>
            {addFieldsWith?.map((field, sFieldIndex) => {
              if (field.sectionId === section.id && field.visible) {
                return (
                  <div
                    className={`${styles.field} ${
                      isFullColumn(field.fieldType) && styles.fullColumn
                    }`}
                    key={`${field.fieldId || 0}_${field.fieldType || 0}-${sFieldIndex + 1}`}
                    style={{ order: field.order }}
                  >
                    {getAddField(field, itemData, disable)}
                  </div>
                );
              }
            })}
          </section>
        );
      }
      return sectionsWithArr;
    }, []);

  const getSectionlessFields = (sectionless: Section[], addFieldsLess: AddField[]) => (
    <section className={styles.section}>
      {sectionless?.length > 0 && (
        <>
          <h4>{translate('COMPONENT.GENERAL_FIELDS')}</h4>
          <h5>{translate('COMPONENT.UNGROUPED_FIELDS')}</h5>
        </>
      )}
      {addFieldsLess?.map((field, fieldIndex) => {
        if (!field.sectionId && field.visible) {
          return teams ? (
            field.fieldType !== 10 && (
              <div
                className={`${styles.field} ${isFullColumn(field.fieldType) && styles.fullColumn}`}
                key={`${field.fieldId}-${fieldIndex + 1}`}
              >
                {getAddField(field, itemData, disable)}
              </div>
            )
          ) : (
            <div
              className={`${styles.field} ${isFullColumn(field.fieldType) && styles.fullColumn}`}
              key={`${field.fieldId}-${fieldIndex + 1}`}
            >
              {getAddField(field, itemData, disable)}
            </div>
          );
        }
      })}
    </section>
  );

  return (
    <div className={styles.fields}>
      {getSectionFields(sections, addFields)}
      {addFields?.length > 0 &&
        checkForFields(addFields) &&
        getSectionlessFields(sections, addFields)}
    </div>
  );
};

AddFields.defaultProps = {
  addFields: [],
  sections: [],
  itemData: null,
  disable: false,
};

AddFields.propTypes = {
  addFields: PropTypes.array,
  sections: PropTypes.array,
  itemData: PropTypes.any,
  disable: PropTypes.bool,
};

export default AddFields;
