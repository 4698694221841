export default {
  'es': {
    'GRID.RESPONSIBLE_GROUP': 'Grupo responsable',
    'GRID.STATE': 'Estado',
  },
  'en': {
    'GRID.RESPONSIBLE_GROUP': 'Responsible group',
    'GRID.STATE': 'Status',
  },
  'pt': {
    'GRID.RESPONSIBLE_GROUP': 'Grupo responsável',
    'GRID.STATE': 'Status',
  },
};
