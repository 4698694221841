import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { Response } from 'global/types/System.interface';
import { HistoryType } from 'global/types/Item.interfaceVote';
import { NoteType } from 'containers/Case/constants/Case.interface';

const getHistory = async (itemId: number, modelId: number, type: HistoryType, statusId: number) => {
  const data = await axios.get<Response<NoteType>>(
    `${type}/${itemId}/history/list?isClosed=false&consoleType=1&modelId=${modelId}&statusId=${statusId}`,
    {}
  );

  if (data.status !== 200) {
    throw Error(`'Error obtaining ${type} history`);
  }

  if (data.data?.content?.length > 0) {
    data.data.content = data.data.content.map((note) => ({
      ...note,
      stringified: JSON.stringify(note),
    }));
  }

  return data?.data;
};

export default function useHistory(
  itemId: number,
  modelId: number,
  type: HistoryType,
  statusId: number
): UseQueryResult<NoteType[], Error> {
  return useQuery(
    ['itemhistory', itemId, modelId, type, statusId],
    () => getHistory(itemId, modelId, type, statusId),
    {
      enabled: !!itemId,
    }
  );
}
