import { useMutation, UseMutationResult } from 'react-query';
import axios from 'axios';
import { SendLookUp, LookUp, CatLookUp } from 'global/types/AddFields.interface';

const getCatLookUp = async (sendLookUp: SendLookUp) => {
  const data = await axios.get<LookUp[]>(
    `additionalsfields/${sendLookUp.fieldId}/type/${sendLookUp.fieldType}/values?dataType=child&parentId=${sendLookUp.parentId}&catalogId=${sendLookUp.catalogId}`
  );

  if (data.status !== 200) {
    throw Error('Error obtaining lookup categories');
  }

  const catLookUp: CatLookUp[] = data?.data?.map((field) => ({
    ...field,
    expanded: false,
    items: [],
    selected: false,
  }));
  return catLookUp;
};

export default function useCatLookup(): UseMutationResult<
  CatLookUp[],
  SendLookUp,
  SendLookUp,
  unknown
> {
  return useMutation(getCatLookUp);
}
