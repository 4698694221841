import React from 'react';
import { Skeleton } from 'common';
import styles from './HistorySkeleton.module.scss';

const HistorySkeleton: React.FC = () => (
  <div className={styles.skeletonContainer}>
    <div className={styles.item}>
      <Skeleton />
    </div>
    <div className={styles.item}>
      <Skeleton />
    </div>
    <div className={styles.item}>
      <Skeleton />
    </div>
  </div>
);

export default HistorySkeleton;
