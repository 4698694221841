import React from 'react';
import PropTypes from 'prop-types';
import { Section as SectionType, AddField, FileWrapInfo } from 'global/types/AddFields.interface';
import { useTranslate } from 'global/hooks';
import { getAddFieldVote } from 'global/utils';
import { Section } from 'common';
import styles from './AddFieldsVote.module.scss';

const isFullColumn = (type: number) => type === 4;

interface AddFieldsProps {
  addFields?: AddField[];
  disable?: boolean;
  itemData?: FileWrapInfo;
  sections?: SectionType[];
}

const checkForFields = (addFields: AddField[]) => {
  return addFields.some((add) => {
    return !add.sectionId && add.visible;
  });
};

const AddFieldsVote: React.FC<AddFieldsProps> = ({ addFields, disable, itemData, sections }) => {
  const translate = useTranslate();
  const getSectionFields = (sectionsWith: SectionType[], addFieldsWith: AddField[]) =>
    sectionsWith?.reduce((sectionsWithArr, section, index) => {
      if (section.display) {
        sectionsWithArr.push(
          <Section
            description={section.description}
            key={`${section.id || index + 1}-${index + 1}`}
            title={section.name}
          >
            <section className={styles.section}>
              {addFieldsWith?.map((field, sFieldIndex) => {
                if (field.sectionId === section.id && field.visible) {
                  return (
                    <div
                      className={`${styles.field} ${
                        isFullColumn(field.fieldType) && styles.fullColumn
                      }`}
                      key={`${field.fieldId}-${sFieldIndex + 1}`}
                      style={{ order: field.order }}
                    >
                      {getAddFieldVote(field, disable, itemData)}
                    </div>
                  );
                }
              })}
            </section>
          </Section>
        );
      }
      return sectionsWithArr;
    }, []);

  const ungroupedFields = (addFieldsLess: AddField[]) => {
    return (
      <section className={styles.section}>
        {addFieldsLess?.map((field, fieldIndex) => {
          if (!field.sectionId && field.visible) {
            return (
              <div
                className={`${styles.field} ${isFullColumn(field.fieldType) && styles.fullColumn}`}
                key={`${field.fieldId}-${fieldIndex + 1}`}
              >
                {getAddFieldVote(field, disable, itemData)}
              </div>
            );
          }
        })}
      </section>
    );
  };

  const getSectionlessFields = (sectionless: SectionType[], addFieldsLess: AddField[]) =>
    sections?.length > 0 ? (
      <Section
        description={translate('COMPONENT.UNGROUPED_FIELDS')}
        title={translate('COMPONENT.GENERAL_FIELDS')}
      >
        {ungroupedFields(addFieldsLess)}
      </Section>
    ) : (
      ungroupedFields(addFieldsLess)
    );

  return (
    <div className={styles.fields}>
      {getSectionFields(sections, addFields)}
      {addFields?.length > 0 &&
        checkForFields(addFields) &&
        getSectionlessFields(sections, addFields)}
    </div>
  );
};

AddFieldsVote.defaultProps = {
  addFields: [],
  disable: false,
  itemData: null,
  sections: [],
};

AddFieldsVote.propTypes = {
  addFields: PropTypes.array,
  disable: PropTypes.bool,
  itemData: PropTypes.any,
  sections: PropTypes.array,
};

export default AddFieldsVote;
