import { useMutation, UseMutationResult } from 'react-query';
import axios, { AxiosError } from 'axios';
import { ChangePasswordValues } from 'containers/Header/constants/Header.interface';

const putPassword = async (passwords: ChangePasswordValues) => {
  const change = [
    {
      field: 'oldpassword',
      value: passwords.oldpassword,
    },
    {
      field: 'newpassword',
      value: passwords.newpassword,
    },
  ];

  const { data } = await axios.put<boolean | AxiosError>('/user/password', change);
  if (typeof data === 'object') {
    throw Error(data.message);
  }
  return data;
};

export default function useChangePassword(): UseMutationResult<
  boolean,
  Error,
  ChangePasswordValues,
  Error
> {
  return useMutation(putPassword);
}
