import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useModal, useTranslate } from 'global/hooks';
import { HTMLHandler, Tabs } from 'common';
import { ExtendSVG } from 'common/icons';
import { InterfaceDictionary } from 'containers/Wizard/constants/Wizard.interface';
import styles from './TextView.module.scss';

interface TextViewProps {
  description?: string;
  interfaceConfig?: InterfaceDictionary;
  showExtend?: boolean;
  solution?: string;
}

const TextView: React.FC<TextViewProps> = ({
  description,
  interfaceConfig,
  showExtend,
  solution,
}) => {
  const translate = useTranslate();
  const { openModal } = useModal();
  const [currentTab, setCurrentTab] = useState(0);

  const getTab = (index: number) => {
    setCurrentTab(index);
  };

  const tabs = [
    {
      disabled: false,
      title: interfaceConfig?.description?.label
        ? interfaceConfig?.description?.label
        : translate('COMPONENT.DESCRIPTION'),
      content: <HTMLHandler html={description} />,
    },
    {
      disabled: false,
      title: interfaceConfig?.commentary?.label
        ? interfaceConfig?.commentary?.label
        : translate('COMPONENT.SOLUTION'),
      content: <HTMLHandler html={solution} />,
    },
  ];

  return (
    <div className={styles.textView}>
      <Tabs tabs={tabs} returnTabIndex={(index) => getTab(index)} />
      {showExtend && (
        <div
          className={styles.expand}
          onClick={() => openModal(tabs[currentTab].content, tabs[currentTab].title, 'max')}
          role="presentation"
          data-testid="textview-expand"
        >
          <ExtendSVG />
        </div>
      )}
    </div>
  );
};

TextView.defaultProps = {
  description: '',
  interfaceConfig: null,
  showExtend: true,
  solution: '',
};

TextView.propTypes = {
  description: PropTypes.string,
  interfaceConfig: PropTypes.any,
  showExtend: PropTypes.bool,
  solution: PropTypes.string,
};

export default TextView;
