import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { WizardImage } from 'containers/Wizard/constants/Wizard.interface';
import ProjectIMG from 'assets/img/Default_Project.png';
import { ArticleSVG, CategorySVG, ServiceSVG, ServiceFolderSVG, CaseSVG } from 'common/icons';
import styles from './Image.module.scss';

interface ImageProps {
  id?: number;
  type: WizardImage;
  large?: boolean;
}

const getDefaultImg = (type: string): React.ReactNode => {
  switch (type) {
    case 'project':
      return <img src={ProjectIMG} alt="Project" />;
    case 'service':
      return <ServiceSVG />;
    case 'folder':
      return <ServiceFolderSVG />;
    case 'category':
      return <CategorySVG />;
    case 'article':
      return <ArticleSVG />;
    case 'case':
      return <CaseSVG />;
    default:
      return '';
  }
};

const Image: React.FC<ImageProps> = React.memo(({ id, type, large }) => {
  const [fallback, setFallback] = useState(false);

  const handleError = () => {
    setFallback(true);
  };

  if (fallback) {
    return (
      <div
        className={`${large && styles.large} ${styles.container} ${
          type === 'folder' ? styles.folder : ''
        }`}
      >
        {getDefaultImg(type)}
      </div>
    );
  }
  return (
    <div className={`${large && styles.large} ${styles.container}`}>
      {id ? (
        <img onError={handleError} src={`${axios.defaults.baseURL}file/${id}`} alt={type} />
      ) : (
        getDefaultImg(type)
      )}
    </div>
  );
});

Image.defaultProps = {
  id: null,
  large: false,
};

Image.propTypes = {
  id: PropTypes.number,
  type: PropTypes.oneOf<WizardImage>([
    'project',
    'service',
    'category',
    'article',
    'folder',
    'case',
  ]).isRequired,
  large: PropTypes.bool,
};

export default Image;
