import React from 'react';
import PropTypes from 'prop-types';
import { Project as ProjectType } from 'containers/Wizard/constants/Wizard.interface';
import { Image } from 'common';
import styles from './Project.module.scss';

interface ProjectProps {
  handleProjectSelect: (project: ProjectType) => void;
  project: ProjectType;
}

const Project: React.FC<ProjectProps> = ({ project, handleProjectSelect }) => (
  <div className={styles.project} onClick={() => handleProjectSelect(project)} role="presentation">
    <div className={styles.image}>
      <Image id={project.imageId} type="project" />
    </div>
    <div className={styles.info}>
      <h3 title={project.name}>{project.name}</h3>
      <p title={project.description}>{project.description}</p>
    </div>
  </div>
);

Project.propTypes = {
  handleProjectSelect: PropTypes.func.isRequired,
  project: PropTypes.any.isRequired,
};

export default Project;
