import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import _ from 'lodash';
import { Service, ArticleCategory } from 'containers/Wizard/constants/Wizard.interface';

const getServices = async (projectId: number, validateArticleDisplay = false) => {
  const resOne = axios.get(`/project/${projectId}/folders/services?language=0`);
  const resTwo = axios.get(
    `/project/${projectId}/7/category/list${
      validateArticleDisplay ? '?validateArticleDisplay=true' : ''
    }`
  );
  const results = await axios.all([resOne, resTwo]);
  let services: Service[] = results[0].data;
  const articles: ArticleCategory[] = results[1].data;

  if (results[0].status !== 200 || results[1].status !== 200) {
    throw Error('Error obtaining services');
  }

  if (services?.length > 0) {
    const serviceFolderCount = _.countBy(services, 'parentId');

    services = services.map((service) => {
      service.folderCount = serviceFolderCount[service.id] || 0;
      service.isArticle = false;
      return service;
    });
  }

  if (articles?.length > 0) {
    const onlyServiceFolders = services.filter((service) => service.isFolder === true);

    const articleFoldersCount = _.countBy(articles, 'parentId');

    const transformedArticles = articles.reduce((filteredArticles, article) => {
      const duplicateService = onlyServiceFolders.find((sFolder) => sFolder.id === article.id);

      if (duplicateService && duplicateService.isFolder) {
        const originalService = services.find((service) => service.id === duplicateService.id);
        originalService.serviceCount += article.countItems;
      } else {
        const transformedArticle: Service = {
          amountCategories: null,
          impactName: null,
          isConfidential: null,
          name: article.name,
          parentId: article.parentId,
          description: article.description,
          hierarchy: null,
          id: article.id,
          imageId: article.imageId,
          isFolder: true,
          image: null,
          serviceCount: article.countItems,
          folderCount: articleFoldersCount[article.id] || 0,
          isArticle: true,
        };

        filteredArticles.push(transformedArticle);
      }

      return filteredArticles;
    }, []);

    services = [...services, ...transformedArticles];
  }
  return services;
};

export default function useServices(
  projectId: number,
  validateArticleDisplay = false
): UseQueryResult<Service[], Error> {
  return useQuery(['service', projectId], () => getServices(projectId, validateArticleDisplay), {
    enabled: false,
  });
}
