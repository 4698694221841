import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'index.css';
import App from 'App';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import UpdateSW from 'containers/UpdateSW/UpdateSW';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
    mutations: {
      retry: 0,
    },
  },
});

if (!window.location.hash) {
  window.location.hash = '#/';
}

const root = createRoot(document.getElementById('root'));

root.render(
  <>
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </HashRouter>
    <UpdateSW />
  </>
);

serviceWorkerRegistration.register({
  onInit: (registration) => window.setServiceWorker(registration),
  onUpdate: (registration) => window.setServiceWorker(registration),
});
