import React, { createContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, OptionalModal } from 'common';
import { Size } from 'global/types/System.interface';

interface ModalContextProps {
  openModal: (el: React.ReactNode, title: string, size?: Size) => void;
  optionalOpenModal: (el: React.ReactNode, title: string, size?: Size) => void;
  closeModal: () => void;
  optionalcloseModal: () => void;
  openParentModal: (el: React.ReactNode, title: string, size?: Size) => void;
  closeParentModal: () => void;
}

export const ModalContext = createContext<ModalContextProps>({
  openModal: () => {},
  optionalOpenModal: () => {},
  closeModal: () => {},
  optionalcloseModal: () => {},
  openParentModal: () => {},
  closeParentModal: () => {},
});

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState<React.ReactNode>(null);
  const [title, setTitle] = useState<string>(null);
  const [size, setSize] = useState<Size>(null);

  const [optionalShow, setOptionalShow] = useState(false);
  const [optionalcontentPrueba, setOptionalContent] = useState<React.ReactNode>(null);
  const [optionaltitlePrueba, setOptionalTitle] = useState<string>(null);
  const [optionalSizePrueba, setOptionalSize] = useState<Size>(null);

  const [parentShow, setParentShow] = useState(false);
  const [parentContent, setParentContent] = useState<React.ReactNode>(null);
  const [parentTitle, setParentTitle] = useState<string>(null);
  const [parentSize, setParentSize] = useState<Size>(null);

  const openModal = useCallback((element: React.ReactNode, elTitle: string, elSize: Size) => {
    setShow(true);
    setContent(element);
    setTitle(elTitle);
    setSize(elSize);
  }, []);

  const optionalOpenModal = useCallback(
    (element: React.ReactNode, elTitle: string, elSize: Size) => {
      setOptionalShow(true);
      setOptionalContent(element);
      setOptionalTitle(elTitle);
      setOptionalSize(elSize);
    },
    []
  );

  const closeModal = useCallback(() => {
    setShow(false);
    setContent(null);
    setTitle(null);
    setSize(null);
  }, []);

  const optionalcloseModal = useCallback(() => {
    setOptionalShow(false);
    setOptionalContent(null);
    setOptionalTitle(null);
    setOptionalSize(null);
  }, []);

  const openParentModal = useCallback((element: React.ReactNode, elTitle: string, elSize: Size) => {
    setParentShow(true);
    setParentContent(element);
    setParentTitle(elTitle);
    setParentSize(elSize);
  }, []);

  const closeParentModal = useCallback(() => {
    setParentShow(false);
    setParentContent(null);
    setParentTitle(null);
    setParentSize(null);
  }, []);

  const modal = {
    openModal,
    optionalOpenModal,
    closeModal,
    optionalcloseModal,
    openParentModal,
    closeParentModal,
  };

  return (
    <ModalContext.Provider value={modal}>
      {children}
      {show && (
        <Modal title={title} closeModal={closeModal} size={size}>
          {content}
        </Modal>
      )}
      {optionalShow && (
        <OptionalModal
          title={optionaltitlePrueba}
          closeModal={optionalcloseModal}
          size={optionalSizePrueba}
        >
          {optionalcontentPrueba}
        </OptionalModal>
      )}
      {parentShow ? (
        <Modal
          title={parentTitle}
          closeModal={closeParentModal}
          size={parentSize}
          childActive={show}
        >
          {parentContent}
        </Modal>
      ) : null}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
