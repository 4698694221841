import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Label, Error } from '@progress/kendo-react-labels';
import { TextArea, TextAreaChangeEvent } from '@progress/kendo-react-inputs';
import { useTranslate } from 'global/hooks';
import { defaultValidator } from 'global/validators';
import { FormValidator } from 'containers/Wizard/elements';
import styles from './TextAreaWrapper.module.scss';

interface TextAreaWrapperProps {
  disabled?: boolean;
  id: string;
  label: string;
  required?: boolean;
}

const TextAreaWrapper: React.FC<TextAreaWrapperProps> = ({ disabled, id, label, required }) => {
  const translate = useTranslate();
  const [, meta, helpers] = useField({ name: id, validate: required ? defaultValidator : null });
  const error = meta?.touched && typeof meta?.error === 'string';

  const handleBlur = () => {
    if (meta.value && required) {
      helpers.setError(undefined);
    }
  };

  const handleChange = (e: TextAreaChangeEvent) => {
    helpers.setTouched(true);
    helpers.setValue(e.value);
    if (e.value) {
      helpers.setError(undefined);
    }
  };

  return (
    <div className={`${styles.textAreaWrap} ${disabled ? styles.disabled : ''}`}>
      <div title={label} className={styles.textLabel}>
        <Label editorId={id} editorValid={!error} editorDisabled={disabled}>
          {`${label}${required ? '(*)' : ''}`}
        </Label>
      </div>
      <TextArea
        className={styles.textarea}
        defaultValue={(meta.value as string) ?? ''}
        disabled={disabled}
        id={id}
        name={id}
        onBlur={handleBlur}
        onChange={handleChange}
        rows={5}
        valid={!error}
      />
      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

TextAreaWrapper.defaultProps = {
  disabled: false,
  required: false,
};

TextAreaWrapper.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default TextAreaWrapper;
