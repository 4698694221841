import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { LookUp, MultiSelectValue } from 'global/types/AddFields.interface';

const getMultiSelect = async (addFieldId: string, userId: number) => {
  const data = await axios.get<LookUp[]>(
    `additionalsfields/${addFieldId}/type/18/values?dataType=&parentId=&catalogId=&userId=${userId}`
  );

  if (data.status !== 200) {
    throw Error('Error obtaining multiselect values');
  }
  const transformedLookups: MultiSelectValue[] = data?.data?.map((lookUp) => {
    return { id: lookUp.id, value: lookUp.name };
  });
  return transformedLookups;
};

export default function useMultiSelect(
  addFieldId: string,
  userId: number
): UseQueryResult<MultiSelectValue[], Error> {
  return useQuery(['multiselect', addFieldId, userId], () => getMultiSelect(addFieldId, userId), {
    enabled: false,
  });
}
