export default {
  'es': {
    'SIDEBARS.CLOSED_WITHOUT_SURVEY': 'Casos cerrados sin responder encuesta',
    'SIDEBARS.CONSULT_SERVICES': 'Consultar Servicios',
    'SIDEBARS.FSC': 'FSC',
    'SIDEBARS.HOME': 'Portal',
    'SIDEBARS.KB': 'Base de Conocimiento',
    'SIDEBARS.NEWS': 'Noticias',
    'SIDEBARS.NEWS_TWO': 'Novedades',
    'SIDEBARS.PENDING_SURVEYS': 'Encuestas Pendientes',
    'SIDEBARS.SURVEYS': 'Encuestas',
    'SIDEBARS.VIEW_CASES': 'Ver Casos',
    'SIDEBARS.VIEW_SURVEYS': 'Ver encuestas',
  },
  'en': {
    'SIDEBARS.CLOSED_WITHOUT_SURVEY': 'Closed cases without an answered survey',
    'SIDEBARS.CONSULT_SERVICES': 'Consult Services',
    'SIDEBARS.FSC': 'FSC',
    'SIDEBARS.HOME': 'Portal',
    'SIDEBARS.KB': 'Knowledgebase',
    'SIDEBARS.NEWS': 'News',
    'SIDEBARS.NEWS_TWO': 'Updates',
    'SIDEBARS.PENDING_SURVEYS': 'Pending Surveys',
    'SIDEBARS.SURVEYS': 'Surveys',
    'SIDEBARS.VIEW_CASES': 'View Cases',
    'SIDEBARS.VIEW_SURVEYS': 'View surveys',
  },
  'pt': {
    'SIDEBARS.CLOSED_WITHOUT_SURVEY': 'Chamados encerrados sem responder à pesquisa',
    'SIDEBARS.CONSULT_SERVICES': 'Consultar Serviços',
    'SIDEBARS.FSC': 'FSC',
    'SIDEBARS.HOME': 'Portal',
    'SIDEBARS.KB': 'Base de Conhecimento',
    'SIDEBARS.NEWS': 'Notícia',
    'SIDEBARS.NEWS_TWO': 'Notícia',
    'SIDEBARS.PENDING_SURVEYS': 'Pesquisas Pendentes',
    'SIDEBARS.SURVEYS': 'Pesquisas',
    'SIDEBARS.VIEW_CASES': 'Exibir Chamados',
    'SIDEBARS.VIEW_SURVEYS': 'Exibir Pesquisas',
  },
};
