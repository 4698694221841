export default {
  'es': {
    'WIZARD.ADVANCED_CONFIGURATION': 'Configuración Avanzada',
    'WIZARD.ATTACH_FILE_ERROR': 'No fue posible agregar el archivo',
    'WIZARD.ATTACH_FILE_SUCCESS': 'Archivo agregado satisfactoriamente',
    'WIZARD.BRAND': 'Marca',
    'WIZARD.CASE': 'Caso',
    'WIZARD.CASE_TYPE': 'Tipo de caso',
    'WIZARD.CATEGORY': 'Categoría',
    'WIZARD.CHANGE': 'Cambio',
    'WIZARD.CI': 'Elemento de Configuración',
    'WIZARD.CONFIGURE_CASE_OPTIONS': 'Defina las caracteristicas',
    'WIZARD.COPY': 'Copiar',
    'WIZARD.CREATE_CASE': 'Crear caso',
    'WIZARD.CREATE_CASE_ERROR': 'No fue posible crear el caso',
    'WIZARD.CREATE_CASE_SUCCESS': 'creado exitosamente',
    'WIZARD.CUSTOMER': 'Cliente',
    'WIZARD.DESCRIPTION': 'Descripción',
    'WIZARD.EMPTY_QUESTION': 'Es necesario responder todas las preguntas',
    'WIZARD.DIRECT_ACCESS_ERROR':
      'El usuario no esta asociado al proyecto, servicio o categoría o el link esta inválido',
    'WIZARD.FILE_SIZE_ERROR_SINGULAR': 'El archivo supera el limite de ',
    'WIZARD.FILE_SIZE_ERROR_PLURAL': 'Uno de los archivo supera el limite de ',
    'WIZARD.FOLDER': 'Carpeta',
    'WIZARD.HELP': 'Ayuda',
    'WIZARD.INCIDENT': 'Incidente',
    'WIZARD.INVALID_FILE_ERROR': 'El tipo de archivo no esta permitido',
    'WIZARD.LINK_COPIED': 'Vinculado copiado',
    'WIZARD.MINIMUM_THREE': 'Digite mínimo tres letras',
    'WIZARD.MISSING_FIELDS': 'Por favor ingrese los campos requeridos',
    'WIZARD.MODEL': 'Modelo',
    'WIZARD.NEW_CASE': 'Nuevo Caso',
    'WIZARD.NO_ACTIVE_SERVICE': 'No tiene servicios asociados en ningún proyecto',
    'WIZARD.NO_INFO': 'No hay información',
    'WIZARD.PROJECT': 'Proyecto',
    'WIZARD.RECORD': 'Registro',
    'WIZARD.RESPONSIBLE_CI': 'Responsable y uso del CI',
    'WIZARD.RESPONSIBLE_CI_HELP': 'Selecione el elemento afectado que quiere relacionar al caso',
    'WIZARD.RISK': 'Riesgo',
    'WIZARD.EVALUATE_RISK': 'Riesgo evaluado',
    'WIZARD.EVALUATE_RISK_TITLE': 'Evaluar riesgo',
    'WIZARD.RISK_TITLE': 'Evaluación del riesgo',
    'WIZARD.SEARCH': 'Buscar',
    'WIZARD.SELECT_CATEGORY': 'Seleccione una categoría...',
    'WIZARD.SELECT_PROJECT': 'Seleccione un proyecto...',
    'WIZARD.SELECT_SERVICE': 'Seleccione un servicio o grupo de servicios...',
    'WIZARD.SERVICE': 'Servicio',
    'WIZARD.SERVICE_REQUIREMENT': 'Requerimiento de servicio',
    'WIZARD.START': 'Inicio',
    'WIZARD.SUBJECT': 'Asunto',
    'WIZARD.TAG': 'Etiqueta de Inventario',
  },
  'en': {
    'WIZARD.ADVANCED_CONFIGURATION': 'Advanced Configuration',
    'WIZARD.ATTACH_FILE_ERROR': 'Unable to attach the file',
    'WIZARD.ATTACH_FILE_SUCCESS': 'File attached successfully',
    'WIZARD.BRAND': 'Brand',
    'WIZARD.CASE': 'Case',
    'WIZARD.CASE_TYPE': 'Case type',
    'WIZARD.CATEGORY': 'Category',
    'WIZARD.CHANGE': 'Change',
    'WIZARD.CI': 'Configuration Item',
    'WIZARD.CONFIGURE_CASE_OPTIONS': 'Configure case options',
    'WIZARD.COPY': 'Copy',
    'WIZARD.CREATE_CASE': 'Create case',
    'WIZARD.CREATE_CASE_ERROR': 'Unable to create the case',
    'WIZARD.CREATE_CASE_SUCCESS': 'created successfully',
    'WIZARD.CUSTOMER': 'Client',
    'WIZARD.DESCRIPTION': 'Description',
    'WIZARD.EMPTY_QUESTION': 'It is necessary to answer all the questions',
    'WIZARD.DIRECT_ACCESS_ERROR':
      'The user is not associated to the project, service or category or the link is invalid',
    'WIZARD.FILE_SIZE_ERROR_SINGULAR': 'File exceeds the limit of ',
    'WIZARD.FILE_SIZE_ERROR_PLURAL': 'One of the files exceeds the limit of ',
    'WIZARD.FOLDER': 'Folder',
    'WIZARD.HELP': 'Help',
    'WIZARD.INCIDENT': 'Incident',
    'WIZARD.INVALID_FILE_ERROR': 'This file type is not allowed',
    'WIZARD.LINK_COPIED': 'Link copied',
    'WIZARD.MINIMUM_THREE': 'Enter at least three letters',
    'WIZARD.MISSING_FIELDS': 'Please enter the required fields',
    'WIZARD.MODEL': 'Model',
    'WIZARD.NEW_CASE': 'New Case',
    'WIZARD.NO_ACTIVE_SERVICE': 'You have no associated services in any project',
    'WIZARD.NO_INFO': 'No information',
    'WIZARD.PROJECT': 'Project',
    'WIZARD.RECORD': 'Record',
    'WIZARD.RESPONSIBLE_CI': 'Responsible and use of the CI',
    'WIZARD.RESPONSIBLE_CI_HELP': 'Select the affected item that you want to associate to the case',
    'WIZARD.RISK': 'Risk',
    'WIZARD.EVALUATE_RISK': 'Risk assessed',
    'WIZARD.EVALUATE_RISK_TITLE': 'Evaluate risk',
    'WIZARD.RISK_TITLE': 'Risk evaluation',
    'WIZARD.SEARCH': 'Search',
    'WIZARD.SELECT_CATEGORY': 'Select a category...',
    'WIZARD.SELECT_PROJECT': 'Select a project...',
    'WIZARD.SELECT_SERVICE': 'Select a service or group of services...',
    'WIZARD.SERVICE': 'Service',
    'WIZARD.SERVICE_REQUIREMENT': 'Service requirement',
    'WIZARD.START': 'Start',
    'WIZARD.SUBJECT': 'Subject',
    'WIZARD.TAG': 'Inventory Tag',
  },
  'pt': {
    'WIZARD.ADVANCED_CONFIGURATION': 'Configuração avançada',
    'WIZARD.ATTACH_FILE_ERROR': 'Não foi possível adicionar o arquivo',
    'WIZARD.ATTACH_FILE_SUCCESS': 'Arquivo anexado com sucesso!',
    'WIZARD.BRAND': 'Companhia',
    'WIZARD.CASE': 'Chamado',
    'WIZARD.CASE_TYPE': 'Tipo de chamado',
    'WIZARD.CATEGORY': 'Categoria',
    'WIZARD.CHANGE': 'Mudança',
    'WIZARD.CI': 'Item de configuração',
    'WIZARD.CONFIGURE_CASE_OPTIONS': 'Defina as características',
    'WIZARD.COPY': 'Copiar',
    'WIZARD.CREATE_CASE': 'Criar chamado',
    'WIZARD.CREATE_CASE_ERROR': 'Não foi possível criar o chamado',
    'WIZARD.CREATE_CASE_SUCCESS': 'criado com sucesso',
    'WIZARD.CUSTOMER': 'Cliente',
    'WIZARD.DESCRIPTION': 'Descrição',
    'WIZARD.EMPTY_QUESTION': 'É necessário responder a todas as perguntas',
    'WIZARD.DIRECT_ACCESS_ERROR':
      'O usuário não está associado ao projeto, serviço,categoria ou o link é inválido',
    'WIZARD.FILE_SIZE_ERROR_SINGULAR': 'Arquivo excede o limite de ',
    'WIZARD.FILE_SIZE_ERROR_PLURAL': 'Um dos arquivos excede o limite de ',
    'WIZARD.FOLDER': 'Pasta',
    'WIZARD.HELP': 'Ajuda',
    'WIZARD.INCIDENT': 'Incidente',
    'WIZARD.INVALID_FILE_ERROR': 'O tipo de arquivo não é permitido',
    'WIZARD.LINK_COPIED': 'Cópia vinculada',
    'WIZARD.MINIMUM_THREE': 'Pelo menos três letras',
    'WIZARD.MISSING_FIELDS': 'Por favor, preencha as informações obrigatórias dos campos',
    'WIZARD.MODEL': 'Modelo',
    'WIZARD.NEW_CASE': 'Novo chamado',
    'WIZARD.NO_ACTIVE_SERVICE': 'Não tem serviços associados para nenhum projeto',
    'WIZARD.NO_INFO': 'Sem Informações',
    'WIZARD.PROJECT': 'Projeto',
    'WIZARD.RECORD': 'Registro',
    'WIZARD.RESPONSIBLE_CI': 'Responsável por e usuário do CI',
    'WIZARD.RESPONSIBLE_CI_HELP': 'Selecionar o CI afetado que vocÃª deseja relacionar ao chamado',
    'WIZARD.RISK': 'Risco',
    'WIZARD.EVALUATE_RISK': 'Risco avaliado',
    'WIZARD.EVALUATE_RISK_TITLE': 'Avaliar risco',
    'WIZARD.RISK_TITLE': 'Avaliação de risco',
    'WIZARD.SEARCH': 'Pesquisa',
    'WIZARD.SELECT_CATEGORY': 'Selecionar uma categoria ...',
    'WIZARD.SELECT_PROJECT': 'Selecionar um projeto ...',
    'WIZARD.SELECT_SERVICE': 'Selecionar um serviço ou grupo de serviços ....',
    'WIZARD.SERVICE': 'Serviço',
    'WIZARD.SERVICE_REQUIREMENT': 'Solicitação de serviço',
    'WIZARD.START': 'Iniciar',
    'WIZARD.SUBJECT': 'Assunto',
    'WIZARD.TAG': 'Tag de Inventário',
  },
};
