import React from 'react';
import PropTypes from 'prop-types';
import styles from './ExternalPage.module.scss';

interface ExternalPageProps {
  url?: string;
}

const ExternalPage: React.FC<ExternalPageProps> = ({ url }) => (
  <iframe src={url} title="external" className={styles.iframe} />
);

ExternalPage.defaultProps = {
  url: '',
};

ExternalPage.propTypes = {
  url: PropTypes.string,
};

export default ExternalPage;
