import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AdditionalMandatory from 'components/CaseDetail/AdditionalMandatory/AdditionalMandatory';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Label, Hint } from '@progress/kendo-react-labels';
import { Button } from '@progress/kendo-react-buttons';
import { State } from 'global/types/Case.interface';
import { useAddFields, useFinalStates, useReasons } from 'global/services';
import { useTranslate } from 'global/hooks';
import { AddField, AddFieldsItemParams, Section } from 'global/types/AddFields.interface';
import { ExtendedCase } from 'containers/CaseList/constants/CaseList.interface';
import styles from './CloseCase.module.scss';

interface CloseCaseProps {
  caseDetail?: ExtendedCase;
  sections?: Section[];
  itemData?: AddFieldsItemParams;
  disable?: boolean;
  disableAddFields?: boolean;
  handleSaveFields?: (a: AddField[], s?: number, r?: number) => void;
  handleChange: (s: number, r?: number) => void;
  handleClose: () => void;
  handleOpen: (c: React.ReactNode, t: string, s?: { height: number; width: number }) => void;
  model: number;
  type: number;
  state: number;
}

const CloseCase: React.FC<CloseCaseProps> = ({
  caseDetail,
  sections,
  itemData,
  disable,
  disableAddFields,
  handleSaveFields,
  handleChange,
  handleClose,
  handleOpen,
  model,
  type,
  state,
}) => {
  const translate = useTranslate();
  const [status, setStatus] = useState<State>(null);
  const [reason, setReason] = useState<State>(null);
  const { data, isLoading, isFetching } = useFinalStates(model, type, state);
  const { data: reasonData, isLoading: reasonLoading } = useReasons(state, status?.id);
  const { data: addsData } = useAddFields(
    caseDetail?.categoryId,
    caseDetail?.itemType,
    caseDetail?.modelId,
    status?.id,
    caseDetail?.serviceId,
    caseDetail?.id
  );

  const handleClick = () => {
    if (!addsData?.addFields?.some((f) => f.mandatoryUser === true)) {
      handleClose();
      handleChange(status?.id, reason?.id);
    } else {
      handleClose();
      handleOpen(
        <AdditionalMandatory
          id="close-additional-mandatory"
          addFields={addsData?.addFields}
          caseDetail={caseDetail}
          disable={disable}
          disableAddFields={disableAddFields}
          handleSaveFields={handleSaveFields}
          handleClose={handleClose}
          handleOpen={handleOpen}
          handleChange={handleChange}
          itemData={itemData}
          reason={reason}
          status={status}
          sections={sections}
          translate={translate}
        />,
        translate('COMPONENT.ADDITIONAL_FIELDS'),
        { height: 161, width: 365 }
      );
    }
  };

  return (
    <div className={styles.closeCase}>
      <Label editorId="close-state-list" className={styles.label}>
        {translate('COMPONENT.STATUS')}
      </Label>
      <DropDownList
        id="close-state-list"
        value={status}
        data={data}
        textField="name"
        style={{ width: '100%' }}
        onChange={(e) => setStatus(e.value)}
        loading={isLoading || isFetching}
      />
      <Label editorId="close-reason-list" className={styles.label}>
        {translate('COMPONENT.REASON')}
      </Label>
      <DropDownList
        id="close-reason-list"
        value={reason}
        data={reasonData}
        textField="name"
        style={{ width: '100%' }}
        onChange={(e) => setReason(e.value)}
        loading={reasonLoading}
        disabled={!status}
      />
      <Hint>{translate('COMPONENT.CHANGE_STATUS_HELP')}</Hint>
      <Button
        onClick={handleClick}
        disabled={!status || !reason}
        themeColor="primary"
        type="button"
      >
        {translate('COMPONENT.SAVE')}
      </Button>
    </div>
  );
};

CloseCase.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  model: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
  state: PropTypes.number.isRequired,
};

export default CloseCase;
