import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { Response, FileType } from 'global/types/System.interface';

const getFiles = async (
  itemId: number,
  itemTypeId: number,
  isVoting?: boolean,
  approvalType?: number
) => {
  const data = await axios.get<Response<FileType>>(
    `item/${itemId}/files?itemType=${itemTypeId}&uploadType=0&additionalFieldId=&validate=true${
      isVoting ? '&isVoting=true' : ''
    }&approvalType=${approvalType}`
  );

  if (data.status !== 200) {
    throw Error('Error obtaining file list');
  }
  return data?.data?.content;
};

export default function useFiles(
  itemId: number,
  itemTypeId: number,
  isVoting?: boolean,
  approvalType?: number
): UseQueryResult<FileType[], Error> {
  return useQuery(
    ['files', itemId, itemTypeId],
    () => getFiles(itemId, itemTypeId, isVoting, approvalType),
    {
      enabled: !!(itemId && itemTypeId),
    }
  );
}
