export default {
  'es': {
    'KB.ARTICLE': 'Artículo',
    'KB.BEST_RATED': 'Mejor Calificado',
    'KB.CATEGORIES': 'Categorías',
    'KB.CLICK_HERE': 'Haga click aqui',
    'KB.CLOSE_AND_RETURN': 'Cerrar y volver a listado',
    'KB.CONTENT_NOT_LOADED': 'Si no carga el contendio:',
    'KB.CREATION_DATE': 'Fecha de creación:',
    'KB.EMAIL_ERROR': 'No se pudo enviar el correo',
    'KB.EMAIL_SUCCESS': 'Correo enviado satisfactoriamente',
    'KB.KB': 'Base de Conocimiento',
    'KB.MODIFICATION_DATE': 'Fecha de modificación:',
    'KB.MOST_CONSULTED': 'Más Consultados',
    'KB.NEED_HELP': '¿Necesita ayuda?',
    'KB.NEW': 'Nuevos',
    'KB.NO_INFORMATION': 'No hay información',
    'KB.NO_RELATED_ARTICLE': 'No se encontro articulos relacionados',
    'KB.PROJECTS': 'Proyectos',
    'KB.RATING_SUCCESS': 'Artículo calificado satisfactoriamente',
    'KB.REASON': 'Razón:',
    'KB.RELATED_ARTICLES': 'Artículos relacionados',
    'KB.RESPONSIBLE': 'Responsable',
    'KB.SEARCH': 'Buscar',
    'KB.SELECT_TYPE': 'Seleccione un tipo...',
    'KB.TYPE': 'Tipo:',
  },
  'en': {
    'KB.ARTICLE': 'Article',
    'KB.BEST_RATED': 'Top Rated',
    'KB.CATEGORIES': 'Categories',
    'KB.CLICK_HERE': 'Click here',
    'KB.CLOSE_AND_RETURN': 'Close and return to list',
    'KB.CONTENT_NOT_LOADED': 'If the content does not load:',
    'KB.CREATION_DATE': 'Creation date:',
    'KB.EMAIL_ERROR': 'Unable to send email',
    'KB.EMAIL_SUCCESS': 'Email sent successfully',
    'KB.KB': 'Knowledgebase',
    'KB.MODIFICATION_DATE': 'Modification date:',
    'KB.MOST_CONSULTED': 'Most Consulted',
    'KB.NEED_HELP': 'Need help?',
    'KB.NEW': 'New',
    'KB.NO_INFORMATION': 'No information',
    'KB.NO_RELATED_ARTICLE': 'No related articles found',
    'KB.PROJECTS': 'Projects',
    'KB.REASON': 'Reason:',
    'KB.RATING_SUCCESS': 'Article rated successfully',
    'KB.RELATED_ARTICLES': 'Related articles',
    'KB.RESPONSIBLE': 'Responsible',
    'KB.SEARCH': 'Search',
    'KB.SELECT_TYPE': 'Select a type...',
    'KB.TYPE': 'Type:',
  },
  'pt': {
    'KB.ARTICLE': 'Artigo',
    'KB.BEST_RATED': 'Melhor Qualificado',
    'KB.CATEGORIES': 'Categorias',
    'KB.CLICK_HERE': 'Clique aqui',
    'KB.CLOSE_AND_RETURN': 'Fechar e voltar à lista',
    'KB.CONTENT_NOT_LOADED': 'Se você não carregar o conteúdo:',
    'KB.CREATION_DATE': 'Data de criação:',
    'KB.EMAIL_ERROR': 'O e-mail não pôde ser enviado',
    'KB.EMAIL_SUCCESS': 'E-mail enviado com sucesso!',
    'KB.KB': 'Base de Conhecimento',
    'KB.MODIFICATION_DATE': 'Data de modificação:',
    'KB.MOST_CONSULTED': 'Mais Vistos',
    'KB.NEED_HELP': 'Precisa de ajuda?',
    'KB.NEW': 'Novos',
    'KB.NO_INFORMATION': 'Não existem informações',
    'KB.NO_RELATED_ARTICLE': 'Nenhum artigo relacionado encontrado',
    'KB.PROJECTS': 'Projetos',
    'KB.RATING_SUCCESS': 'O artigo foi avaliado com sucesso!',
    'KB.REASON': 'Razão:',
    'KB.RELATED_ARTICLES': 'Artigos relacionados',
    'KB.RESPONSIBLE': 'Responsável',
    'KB.SEARCH': 'Pesquisar',
    'KB.SELECT_TYPE': 'Selecione um tipo ...',
    'KB.TYPE': 'Tipo:',
  },
};
