export default {
  'es': {
    'CASELIST.ALL': 'Todos',
    'CASELIST.APPLICANT': 'Solicitante',
    'CASELIST.ATTACH_FILE_ERROR': 'No fue posible agregar el archivo',
    'CASELIST.CASE_NUMBER': 'Número del caso',
    'CASELIST.CASE_TYPE': 'Tipo de Caso',
    'CASELIST.CASE': 'Caso',
    'CASELIST.CHANGE': 'Cambio',
    'CASELIST.CLOSED': 'Cerrado',
    'CASELIST.CONDITION': 'Condición',
    'CASELIST.CUSTOMER': 'Cliente',
    'CASELIST.DOWNLOAD_FILE': 'Archivo descargado exitosamente',
    'CASELIST.ENTER_EXPORT_NAME': 'Ingrese el nombre del archivo a exportar',
    'CASELIST.EXACT_SEARCH': 'Clic para mostrar resultados exactos',
    'CASELIST.EXPORT': 'Exportar',
    'CASELIST.FILE_SIZE_ERROR_PLURAL': 'Uno de los archivo supera el limite de ',
    'CASELIST.FILE_SIZE_ERROR_SINGULAR': 'El archivo supera el limite de ',
    'CASELIST.FILTER_BY': 'Filtrar por',
    'CASELIST.FILTER': 'Filtrar',
    'CASELIST.INCIDENT': 'Incidente',
    'CASELIST.INCLUDE_DESCRIPTION': 'Incluir la descripción en el archivo',
    'CASELIST.INVALID_FILE_ERROR': 'El tipo de archivo no esta permitido',
    'CASELIST.MY_CASES': 'Mis casos',
    'CASELIST.OPEN_DATE': 'Fecha Registro',
    'CASELIST.OPEN': 'Abierto',
    'CASELIST.ORDER_BY': 'Ordenar por',
    'CASELIST.PROJECT': 'Proyecto',
    'CASELIST.SEARCH': 'Buscar',
    'CASELIST.SEE_HOW': 'Ver como',
    'CASELIST.SERVICE_REQUIREMENT': 'Requerimiento de Servicio',
    'CASELIST.STATUS': 'Estado',
    'CASELIST.SUBJECT': 'Asunto',
    'CASELIST.YOU_FILTER': 'Usted Filtró por »',
  },
  'en': {
    'CASELIST.ALL': 'All',
    'CASELIST.APPLICANT': 'Applicant',
    'CASELIST.ATTACH_FILE_ERROR': 'Unable to attach the file',
    'CASELIST.CASE': 'Case',
    'CASELIST.CASE_NUMBER': 'Case Number',
    'CASELIST.CASE_TYPE': 'Case Type',
    'CASELIST.CHANGE': 'Change',
    'CASELIST.CLOSED': 'Closed',
    'CASELIST.CONDITION': 'Condition',
    'CASELIST.CUSTOMER': 'Customer',
    'CASELIST.DOWNLOAD_FILE': 'File downloaded successfully',
    'CASELIST.ENTER_EXPORT_NAME': 'Enter the filename',
    'CASELIST.EXACT_SEARCH': 'Click to show exact results',
    'CASELIST.EXPORT': 'Export',
    'CASELIST.FILE_SIZE_ERROR_SINGULAR': 'File exceeds the limit of ',
    'CASELIST.FILE_SIZE_ERROR_PLURAL': 'One of the files exceeds the limit of ',
    'CASELIST.FILTER': 'Filter',
    'CASELIST.FILTER_BY': 'Filter by',
    'CASELIST.INCIDENT': 'Incident',
    'CASELIST.INCLUDE_DESCRIPTION': 'Include description in the file',
    'CASELIST.INVALID_FILE_ERROR': 'This file type is not allowed',
    'CASELIST.MY_CASES': 'My cases',
    'CASELIST.OPEN': 'Open',
    'CASELIST.OPEN_DATE': 'Opened Date',
    'CASELIST.ORDER_BY': 'Order by',
    'CASELIST.PROJECT': 'Project',
    'CASELIST.SEARCH': 'Search',
    'CASELIST.SEE_HOW': 'See how',
    'CASELIST.SERVICE_REQUIREMENT': 'Service Requirement',
    'CASELIST.STATUS': 'Status',
    'CASELIST.SUBJECT': 'Subject',
    'CASELIST.YOU_FILTER': 'You filtered by »',
  },
  'pt': {
    'CASELIST.ALL': 'Todo',
    'CASELIST.APPLICANT': 'Solicitante',
    'CASELIST.ATTACH_FILE_ERROR': 'Não foi possível adicionar o arquivo',
    'CASELIST.CASE': 'Chamado',
    'CASELIST.CASE_NUMBER': 'Número do chamado',
    'CASELIST.CASE_TYPE': 'Tipo de Chamado',
    'CASELIST.CHANGE': 'Mudança',
    'CASELIST.CLOSED': 'Fechadas',
    'CASELIST.CONDITION': 'Condição',
    'CASELIST.CUSTOMER': 'Cliente',
    'CASELIST.DOWNLOAD_FILE': 'Arquivo baixado com sucesso',
    'CASELIST.ENTER_EXPORT_NAME': 'Digite o nome do arquivo a exportar',
    'CASELIST.EXACT_SEARCH': 'Clique para mostrar resultados exatos',
    'CASELIST.EXPORT': 'Exportar',
    'CASELIST.FILE_SIZE_ERROR_SINGULAR': 'Arquivo excede o limite de ',
    'CASELIST.FILE_SIZE_ERROR_PLURAL': 'Um dos arquivos excede o limite de ',
    'CASELIST.FILTER': 'Filtro',
    'CASELIST.FILTER_BY': 'Filtrar por',
    'CASELIST.INCIDENT': 'Incidente',
    'CASELIST.INCLUDE_DESCRIPTION': 'Incluir descrição no arquivo',
    'CASELIST.INVALID_FILE_ERROR': 'O tipo de arquivo não é permitido',
    'CASELIST.MY_CASES': 'Meus chamados',
    'CASELIST.OPEN': 'Abrir',
    'CASELIST.OPEN_DATE': 'Data de registro',
    'CASELIST.ORDER_BY': 'Organizar por',
    'CASELIST.PROJECT': 'Projeto',
    'CASELIST.SEARCH': 'Pesquisa',
    'CASELIST.SEE_HOW': 'Ver como',
    'CASELIST.SERVICE_REQUIREMENT': 'Requisição de serviço',
    'CASELIST.STATUS': 'Status',
    'CASELIST.SUBJECT': 'Asunto',
    'CASELIST.YOU_FILTER': 'Você filtrou por »',
  },
};
