import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'global/hooks';
import {
  Service,
  Item,
  WizardState,
  WizardActions,
} from 'containers/Wizard/constants/Wizard.interface';
import { CardContainer, Instruction, SelectedCard } from 'containers/Wizard/elements';
import { BreadCrumbs } from 'common';
import { WizardSkeleton } from 'skeletons';
import styles from './ServiceStep.module.scss';

interface ServiceStepProps {
  state: WizardState;
  dispatch: React.Dispatch<WizardActions>;
  services?: Service[];
  loading: boolean;
}

const ServiceStep: React.FC<ServiceStepProps> = ({ services, state, dispatch, loading }) => {
  const translate = useTranslate();
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [crumbs, setCrumbs] = useState<[number, string][]>([[null, translate('WIZARD.START')]]);

  const handleFolderClick = (service: Item) => {
    setCrumbs((prevState) => [...prevState, [service.id, service.name]]);
    setSelectedFolder(service);
    dispatch({ type: 'SERVICE_FOLDER', payload: { serviceFolder: service as Service } });
  };

  const handleServiceSelect = (service: Service) => {
    if (service.isArticle === true) {
      dispatch({ type: 'ARTICLE_DETAIL', payload: { service } });
    } else {
      dispatch({ type: '2', payload: { service } });
    }
  };

  const handleCrumbClick = (id: number, index: number) => {
    if (selectedFolder.id !== id) {
      const newSelected = id ? services.find((service) => service.id === id) : null;
      setSelectedFolder(newSelected);
      setCrumbs((prevState) => prevState.slice(0, index + 1));
    }
  };

  const filteredServices = (selection: number) =>
    services?.filter(
      (service) =>
        service.parentId === selection &&
        service.name.toUpperCase().includes(state.searchText?.toUpperCase())
    );

  return (
    <div className={`${styles.serviceStep} ${selectedFolder ? styles.folderView : ''}`}>
      <div className={styles.selectedProject}>
        <Instruction number="&#10003;" text={translate('WIZARD.PROJECT')} />
        <SelectedCard item={state.project} type="project" isFolder={false} />
      </div>
      {selectedFolder && (
        <>
          <BreadCrumbs handleClick={(id, index) => handleCrumbClick(id, index)}>
            {crumbs}
          </BreadCrumbs>
          <SelectedCard item={selectedFolder} type="service" isFolder />
        </>
      )}
      {!loading ? (
        <CardContainer
          items={filteredServices(selectedFolder?.id || null)}
          handleFolderClick={handleFolderClick}
          handleCardClick={(i) => handleServiceSelect(i as Service)}
        >
          <Instruction number="2" text={translate('WIZARD.SELECT_SERVICE')} />
        </CardContainer>
      ) : (
        <WizardSkeleton />
      )}
    </div>
  );
};

ServiceStep.defaultProps = {
  services: [],
};

ServiceStep.propTypes = {
  services: PropTypes.array,
  state: PropTypes.any.isRequired,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ServiceStep;
