import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { Response } from 'global/types/System.interface';
import {
  AddField,
  AddFieldParams,
  Section,
  AddFieldsResponse,
} from 'global/types/AddFields.interface';
import { getSectionalizedFields, setSectionDisplay } from 'global/utils';

const getAddFields = async (
  categoryId: number,
  itemType: number,
  modelId: number,
  stateId: number,
  serviceId: number,
  id?: number
) => {
  const params: AddFieldParams = {
    asdkWeb: true,
    categoryId,
    itemType,
    modelId,
    stateId,
    serviceId,
  };

  if (id) {
    params.id = id;
  }

  const fieldsResponse = axios.post('/item/additionalfields', params);
  const sectionsResponse = axios.get(`/additionalsfields/form/${itemType}/${modelId}`);
  const results = await axios.all([fieldsResponse, sectionsResponse]);

  if (results[0].status !== 200 || results[1].status !== 200) {
    throw Error('Error obtaining add fields');
  }

  let addFields: AddField[] = results[0].data;
  const sections: Response<Section> = results[1].data;

  if (sections?.content?.length > 0 && addFields) {
    addFields = getSectionalizedFields(addFields, sections.content);
    setSectionDisplay(addFields, sections.content);
  }

  return { addFields, sections: sections?.content };
};

export default function useAddFields(
  categoryId: number,
  itemType: number,
  modelId: number,
  stateId: number,
  serviceId: number,
  id?: number
): UseQueryResult<AddFieldsResponse, Error> {
  return useQuery(
    ['addfields', categoryId, itemType, modelId, stateId, serviceId, id],
    () => getAddFields(categoryId, itemType, modelId, stateId, serviceId, id),
    {
      enabled: !!(categoryId && itemType && modelId && serviceId && stateId),
    }
  );
}
