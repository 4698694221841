import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'common';
import styles from './ListSkeleton.module.scss';

interface ListSkeletonProps {
  count: number;
  height: number;
}

const ListSkeleton: React.FC<ListSkeletonProps> = ({ count, height }) => (
  <div className={styles.list}>
    <Skeleton count={count} height={height} />
  </div>
);

ListSkeleton.propTypes = {
  count: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default ListSkeleton;
