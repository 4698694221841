import axios from 'axios';
import { UseQueryResult, useQuery } from 'react-query';
import { LANGUAGECODE, Response } from 'global/types/System.interface';
import { Search } from 'containers/Case/constants/Case.interface';

const getRiskList = async (localization: string) => {
  const data = await axios.get<Response<Search>>(
    `risk/list?language=${LANGUAGECODE[localization] || 0}`
  );

  if (data.status !== 200) {
    throw Error('Error obtaining risk list');
  }

  return data?.data.content;
};

export default function useRiskList(localization: string): UseQueryResult<Search[], Error> {
  return useQuery(['risklist', localization], () => getRiskList(localization), {
    enabled: false,
  });
}
