import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useImage } from 'containers/Header/services';

interface PictureProps {
  defaultImage: JSX.Element;
  imageClass?: string;
  pictureId?: number;
}

const Picture: React.FC<PictureProps> = ({ defaultImage, imageClass, pictureId }) => {
  const { data, isError, isLoading } = useImage(pictureId?.toString());
  const [error, setError] = useState(false);

  return (
    <>
      {isError || isLoading || !pictureId || error ? (
        defaultImage
      ) : (
        <img alt="customimage" className={imageClass} onError={() => setError(true)} src={data} />
      )}
    </>
  );
};

Picture.defaultProps = {
  imageClass: '',
  pictureId: null,
};

Picture.propTypes = {
  defaultImage: PropTypes.any.isRequired,
  imageClass: PropTypes.string,
  pictureId: PropTypes.number,
};

export default Picture;
