import { UseMutationResult, useMutation } from 'react-query';
import axios from 'axios';
import { LANGUAGECODE } from 'global/types/System.interface';
import { RiskLevel, Search } from 'containers/Case/constants/Case.interface';

const postRiskLevel = async (risk: RiskLevel) => {
  const data = await axios.post<Search>(
    `model/${risk.modelId}/risk/level?language=${LANGUAGECODE[risk.localization] || '0'}`,
    risk.params
  );
  if (data.status !== 200) {
    throw Error('Error generate Risk Level');
  }
  return data?.data;
};

export default function useRiskLevel(): UseMutationResult<Search, Error, RiskLevel, Error> {
  return useMutation(postRiskLevel);
}
