import { useMutation, UseMutationResult } from 'react-query';
import axios from 'axios';
import { SaveCase, CaseResponse } from 'global/types/Case.interface';
import { ResponseError } from 'global/types/System.interface';

const putCase = async (caseParams: SaveCase) => {
  const data = await axios.put<CaseResponse>(`/item/${caseParams.caseId}/${caseParams.caseType}`, {
    additionals: caseParams.additionals,
    dataElements: caseParams.dataElements,
  });

  if (data.status === 400) {
    if ((data as unknown as ResponseError)?.data?.exceptionMessage === 'ItemBlocked') {
      throw Error('ITEMBLOCKED');
    }
    if ((data as unknown as ResponseError)?.data?.exceptionMessage === 'PendingTasks') {
      throw Error('PENDINGTASKS');
    }
    if (
      (data as unknown as ResponseError)?.data?.exceptionMessage === 'InvalidAdditionalFieldValue'
    ) {
      throw Error('SAVE_FIELDS_ERROR');
    }
  }

  if (data.status !== 200) {
    throw Error('Error saving case');
  }

  return data?.data;
};

export default function useSaveCase(): UseMutationResult<CaseResponse, Error, SaveCase, Error> {
  return useMutation(putCase);
}
