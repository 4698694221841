import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@progress/kendo-react-inputs';
import { useModal, useTranslate } from 'global/hooks';
import { NoteType } from 'containers/CaseList/constants/CaseList.interface';
import { AddNote, Note } from 'components';
import { NoData } from 'common';
import styles from './Notes.module.scss';

interface NoteListProps {
  noteList: NoteType[];
  handleNote: (n: string) => void;
  disable?: boolean;
}

const NoteList: React.FC<NoteListProps> = ({ noteList, handleNote, disable }) => {
  const translate = useTranslate();
  const [filterText, setFilterText] = useState('');
  const { openModal, closeModal } = useModal();

  const createNotes = () =>
    noteList?.reduce((newNotesList, note, index) => {
      if (note.descriptionNoHtml.toUpperCase().includes(filterText?.toUpperCase())) {
        newNotesList.push(<Note key={`${note.actionId}-${index + 1}`} note={note} index={index} />);
      }
      return newNotesList;
    }, []);

  const handleAddNote = (noteDesc: string) => {
    closeModal();
    handleNote(noteDesc);
  };

  return (
    <div className={styles.notes}>
      <div className={styles.actions}>
        {!disable && (
          <div
            className={styles.add}
            onClick={() =>
              openModal(
                <AddNote handleSave={(s: string) => handleAddNote(s)} />,
                translate('COMPONENT.ADD_NOTE'),
                { width: 800, height: 600 }
              )
            }
            role="presentation"
            data-testid="addnote-button"
          >
            <span className="k-i-plus-circle k-icon" />
            <span className={styles.addText}>{translate('COMPONENT.ADD_NOTE')}</span>
          </div>
        )}
        <Input
          placeholder={`${translate('COMPONENT.FILTER_BY_DESC')}...`}
          className={styles.filter}
          onChange={(e) => setFilterText(e.value)}
          type="search"
        />
      </div>
      <div className={styles.list}>{noteList?.length > 0 ? createNotes() : <NoData />}</div>
    </div>
  );
};

NoteList.defaultProps = {
  noteList: undefined,
  disable: false,
};

NoteList.propTypes = {
  noteList: PropTypes.array,
  handleNote: PropTypes.func.isRequired,
  disable: PropTypes.bool,
};

export default NoteList;
