import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { ItemTypes } from 'containers/Wizard/constants/Wizard.interface';

const getItemTypes = async (categoryId: number, serviceId: number) => {
  const urlCat = `category/${categoryId}/service/${serviceId}/enabled/itemtypes`;
  const data = await axios.get<ItemTypes[]>(urlCat);

  if (data.status !== 200) {
    throw Error('Error obtaining item types');
  }

  const allowedTypes = [1, 3, 4];
  const filteredData = data.data.filter((itemType) => allowedTypes.includes(itemType.id));
  return filteredData;
};

export default function useItemTypes(
  categoryId: number,
  serviceId: number
): UseQueryResult<ItemTypes[], Error> {
  return useQuery(['enableditemtypes', categoryId], () => getItemTypes(categoryId, serviceId), {
    enabled: false,
  });
}
