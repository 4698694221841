import { useMutation, UseMutationResult } from 'react-query';
import axios from 'axios';
import { SaveCase } from 'global/types/Case.interface';
import { CreateResponse } from 'containers/Wizard/constants/Wizard.interface';

const postCase = async (caseParams: SaveCase) => {
  const filterAddFields =
    process.env.REACT_APP_FILTER_ADDITIONALS === 'TRUE'
      ? caseParams.additionals.filter((x) => x.visible === true && x.editableUser === true)
      : caseParams.additionals;
  const data = await axios.post<CreateResponse[]>(
    `/item/${caseParams.async ? '?async=true' : ''}`,
    {
      additionals: filterAddFields,
      dataElements: caseParams.dataElements,
    }
  );

  if (data.status !== 200) {
    throw Error('Error creating case');
  }
  return data?.data;
};

export default function useCreateCase(): UseMutationResult<
  CreateResponse[],
  Error,
  SaveCase,
  Error
> {
  return useMutation(postCase);
}
