import React, { useState, useRef, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import { Rating as KRating, RatingChangeEvent } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { useOutsidePopup, useToggle, useTranslate } from 'global/hooks';
import { KbArticle, SendRating } from 'containers/KB/constants/Kb.interface';
import { ArrowDownSVG, CloseSVG } from 'common/icons';
import styles from './Rating.module.scss';

interface RatingProps {
  rating?: number;
  article: KbArticle;
  handleRating?: (s: SendRating) => void;
  disabled?: boolean;
}

const Rating: React.FC<RatingProps> = ({ rating, article, handleRating, disabled }) => {
  const translate = useTranslate();
  const [open, toggle, close] = useToggle(false);
  const [rate, setRate] = useState<number>(null);
  const [comment, setComment] = useState('');
  const arrowRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  useOutsidePopup(popupRef, close, arrowRef);

  const handleClick = () => {
    handleRating({ article, comment, rate });
    close();
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.stars}>
          <KRating value={rating} disabled className={styles.star} />
        </div>
        <div className={styles.total}>{rating.toFixed(1)}</div>
        {!disabled ? (
          <div className={styles.arrow} ref={arrowRef} onClick={toggle} role="presentation">
            <ArrowDownSVG />
          </div>
        ) : null}
      </div>
      <Popup
        show={open}
        anchor={arrowRef.current}
        anchorAlign={{ horizontal: 'right', vertical: 'bottom' }}
        popupAlign={{ horizontal: 'right', vertical: 'top' }}
      >
        <div className={styles.popup} ref={popupRef}>
          <div className={styles.header}>
            <h5>{translate('COMPONENT.RATE_ARTICLE')}</h5>
            <div onClick={close} role="presentation">
              <CloseSVG />
            </div>
          </div>
          <div className={styles.popUpContent}>
            <div className={styles.stars}>
              <KRating
                defaultValue={rating}
                className={styles.star}
                value={rate}
                onChange={(e: RatingChangeEvent) => setRate(e.value)}
              />
            </div>
            <textarea
              placeholder={`${translate('COMPONENT.COMMENTS')}...`}
              value={comment}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value)}
              className={styles.comment}
            />
            <div className={styles.buttons}>
              <div className={styles.button}>
                <Button onClick={close} type="button">
                  {translate('COMPONENT.CANCEL')}
                </Button>
              </div>
              <div className={styles.button}>
                <Button
                  disabled={!rate || !comment}
                  onClick={handleClick}
                  themeColor="primary"
                  type="button"
                >
                  {translate('COMPONENT.SAVE')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};

Rating.defaultProps = {
  rating: 0,
  article: null,
  handleRating: null,
  disabled: false,
};

Rating.propTypes = {
  rating: PropTypes.number,
  article: PropTypes.any,
  handleRating: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Rating;
