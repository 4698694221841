import { useMutation, UseMutationResult } from 'react-query';
import axios from 'axios';
import { SendExport } from 'containers/CaseList/constants/CaseList.interface';
import { LANGUAGECODE, Language } from 'global/types/System.interface';
import { columnsExport } from 'containers/CaseList/constants/CaseList.constants';

const postExportList = async (params: SendExport) => {
  const projectIds = params.projects?.map((project) => ({ project: project.id }));
  const types = params.filter.types?.map((lic) => ({ itemType: lic.id }));
  const data = await axios.post<BlobPart>(
    `/item/export/excel?language=${
      LANGUAGECODE[params.localization as Language] || '0'
    }&description=${params.description}`,
    {
      column: columnsExport,
      criteria: [],
      filename: params.filename,
      filterText: params.filter.filterText,
      language: LANGUAGECODE[params.localization as Language] || '0',
      level: 0,
      order: params.filter.order,
      orderField: params.filter.orderField,
      orderType: params.filter.order,
      pageIndex: params.filter.pageIndex,
      pageSize: params.amount,
      projects: projectIds,
      repository: params.filter.repository,
      searchApplicant: params.filter.filter.some((filter) => filter === 'APPLICANT'),
      searchCustomer: params.filter.filter.some((filter) => filter === 'CUSTOMER'),
      searchMethod: 1,
      types,
      viewId: -6,
    },
    {
      responseType: 'arraybuffer',
    }
  );
  if (data.status !== 200) {
    throw Error('Error exporting case list');
  }
  return data?.data;
};

export default function useExportList(): UseMutationResult<BlobPart, Error, SendExport, unknown> {
  return useMutation(postExportList);
}
