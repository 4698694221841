export default {
  'es': {
    'PORTAL.ACCESS': 'Accesos',
    'PORTAL.ARTICLE': 'Artículo',
    'PORTAL.ARTICLE_TEXT': 'Consulte artículos que le pueden ayudar a resolver su caso.',
    'PORTAL.ARTICLE_TEXT_TWO': 'para resolver tu caso',
    'PORTAL.ARTICLES': 'Artículos',
    'PORTAL.ATTACH_FILE_ERROR': 'No fue posible agregar el archivo',
    'PORTAL.CASES': 'Casos',
    'PORTAL.CONTENT_NOT_LOADED': 'Si no carga el contenido:',
    'PORTAL.HOW_MAY_WE_HELP': '¿Cómo te podemos ayudar?',
    'PORTAL.INVALID_FILE_ERROR': 'El tipo de archivo no esta permitido',
    'PORTAL.MY_CASES_TEXT': 'Haga un seguimiento de los casos registrados.',
    'PORTAL.MY_CASES': 'Mis casos',
    'PORTAL.NEWS': 'Noticias',
    'PORTAL.NEWS_TEXT': 'del sector que te pueden interesar',
    'PORTAL.OTHER': 'Otros',
    'PORTAL.OTHER_ACCESS': 'Otro acceso',
    'PORTAL.OTHER_TAB': 'Abrir en otra pestaña',
    'PORTAL.REQUIRED_FIELD': 'Este campo es obligatorio',
    'PORTAL.SERVICE_TEXT': 'Consulte servicios y registre su caso en breve daremos una respuesta.',
    'PORTAL.SERVICES': 'Servicios',
  },
  'en': {
    'PORTAL.ACCESS': 'Accesses',
    'PORTAL.ARTICLE': 'Article',
    'PORTAL.ARTICLE_TEXT': 'Consult articles that may help you solve your case.',
    'PORTAL.ARTICLE_TEXT_TWO': 'to solve your case',
    'PORTAL.ARTICLES': 'Articles',
    'PORTAL.ATTACH_FILE_ERROR': 'Unable to attach the file',
    'PORTAL.CASES': 'Cases',
    'PORTAL.CONTENT_NOT_LOADED': 'If the content does not load:',
    'PORTAL.HOW_MAY_WE_HELP': 'How may we help you?',
    'PORTAL.INVALID_FILE_ERROR': 'This file type is not allowed',
    'PORTAL.MY_CASES_TEXT': 'Follow up on registered cases.',
    'PORTAL.MY_CASES': 'My cases',
    'PORTAL.NEWS': 'News',
    'PORTAL.NEWS_TEXT': 'of the sector that may interest you',
    'PORTAL.OTHER': 'Other',
    'PORTAL.OTHER_ACCESS': 'Other access',
    'PORTAL.OTHER_TAB': 'Open in another tab',
    'PORTAL.REQUIRED_FIELD': 'This field is required',
    'PORTAL.SERVICE_TEXT':
      'Consult services and register your case, we will send an answer shortly.',
    'PORTAL.SERVICES': 'Services',
  },
  'pt': {
    'PORTAL.ACCESS': 'Acessos',
    'PORTAL.ARTICLE': 'Artigo',
    'PORTAL.ARTICLE_TEXT': 'Veja artigos que podem ajudá-lo a resolver seu chamado.',
    'PORTAL.ARTICLE_TEXT_TWO': 'para resolver o seu chamado',
    'PORTAL.ARTICLES': 'Artigos',
    'PORTAL.ATTACH_FILE_ERROR': 'Não foi possível adicionar o arquivo',
    'PORTAL.CASES': 'Chamados',
    'PORTAL.CONTENT_NOT_LOADED': 'Se você não fizer o upload do conteúdo:',
    'PORTAL.HOW_MAY_WE_HELP': 'Como podemos ajudá-lo?',
    'PORTAL.INVALID_FILE_ERROR': 'O tipo de arquivo não é permitido',
    'PORTAL.MY_CASES_TEXT': 'Acompanhar chamados registrados.',
    'PORTAL.MY_CASES': 'Meus chamados',
    'PORTAL.NEWS': 'Notícia',
    'PORTAL.NEWS_TEXT': 'da indústria que podem lhe interessar',
    'PORTAL.OTHER': 'Outros',
    'PORTAL.OTHER_ACCESS': 'Outro acesso',
    'PORTAL.OTHER_TAB': 'Abrir em outra aba',
    'PORTAL.REQUIRED_FIELD': 'Este campo é obrigatório',
    'PORTAL.SERVICE_TEXT':
      'Consulte os serviços e registre seu chamado, em breve entraremos em contato.',
    'PORTAL.SERVICES': 'Serviços',
  },
};
