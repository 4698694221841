import React from 'react';
import '@progress/kendo-date-math/tz/all';
import MainRouter from 'routers/MainRouter';
import IntlProvider from 'context/withIntl';
import { BrandingProvider } from 'context/withBranding';
import { AuthProvider } from 'context/withAuthentication';
import { ResolveProvider } from 'context/withResolve';
import { SnackbarProvider } from 'context/withSnackbars';
import { ModalProvider } from 'context/withModal';
import { ChatProvider } from 'context/withChat';
import 'App.scss';
import 'assets/styles/KendoOverride.scss';

const App: React.FC = () => (
  <main className="app">
    <ResolveProvider>
      <SnackbarProvider>
        <BrandingProvider>
          <AuthProvider>
            <IntlProvider>
              <ModalProvider>
                <ChatProvider>
                  <MainRouter />
                </ChatProvider>
              </ModalProvider>
            </IntlProvider>
          </AuthProvider>
        </BrandingProvider>
      </SnackbarProvider>
    </ResolveProvider>
  </main>
);

export default App;
