import React, { useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import { useLocalStorage } from 'react-use';
import { useResolve, useToggle, useTranslate } from 'global/hooks';
import { Category } from 'containers/Wizard/constants/Wizard.interface';
import { WizardContext } from 'containers/Wizard/elements/Steps/CategoryStep/CategoryStep';
import { CloseSVG, LinkSVG } from 'common/icons';
import styles from './LinkPopup.module.scss';

interface LinkPopupProps {
  category: Category;
}

const LinkPopup: React.FC<LinkPopupProps> = React.memo(({ category }) => {
  const translate = useTranslate();
  const { CustomerURL } = useResolve();
  const wiz = useContext(WizardContext);
  const [open, toggle, close] = useToggle(false);
  const linkRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [project] = useLocalStorage('usdk_project', '');

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggle();
  };

  const handleInsideClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const copyLinkText = () => {
    inputRef.current.select();
    document.execCommand('copy');
  };

  useEffect(() => {
    if (inputRef?.current && open) {
      inputRef.current.value = `${CustomerURL}/index.html#/home/wizard/${btoa(
        `${wiz.project.id}_${wiz.service.id}_${category.id}`
      )}`;
      copyLinkText();
    }
  }, [CustomerURL, wiz, category, open, project]);

  useEffect(() => {
    const parent = document.getElementById('wizard-item-container');
    if (open) {
      parent.addEventListener('scroll', close);
    }
    return function removeListener() {
      parent.removeEventListener('scroll', close);
    };
  }, [open, close]);

  return (
    <>
      <div className={styles.icon} ref={linkRef} onClick={handleClick} role="presentation">
        <LinkSVG />
      </div>
      <Popup
        show={open}
        anchor={linkRef.current}
        collision={{
          horizontal: 'fit',
          vertical: 'flip',
        }}
        anchorAlign={{ horizontal: 'left', vertical: 'bottom' }}
        popupAlign={{ horizontal: 'left', vertical: 'top' }}
      >
        <div className={styles.popup} onClick={handleInsideClick} role="presentation">
          <div className={styles.close} onClick={close} role="presentation">
            <CloseSVG />
          </div>

          <div className={styles.upper}>
            <em className={styles.checkmark} />
            <span>{translate('WIZARD.LINK_COPIED')}</span>
          </div>
          <div className={styles.lower}>
            <input ref={inputRef} readOnly />
            <button type="button" onClick={copyLinkText}>
              {translate('WIZARD.COPY')}
            </button>
          </div>
        </div>
      </Popup>
    </>
  );
});

LinkPopup.propTypes = {
  category: PropTypes.any.isRequired,
};

export default LinkPopup;
