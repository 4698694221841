import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FieldMetaProps, useFormikContext } from 'formik';
import { Label, Error } from '@progress/kendo-react-labels';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useLookUp } from 'global/services';
import { useTranslate } from 'global/hooks';
import { AddFieldFormikValues, LookUp } from 'global/types/AddFields.interface';
import { FormValidator } from 'containers/Wizard/elements';
import styles from './DropdownWrapper.module.scss';

interface DropdownWrapperProps {
  catalogId?: string;
  data?: unknown[];
  dataSourceId?: number;
  disabled?: boolean;
  fieldType?: string;
  id: string;
  label: string;
  meta: FieldMetaProps<unknown>;
  required?: boolean;
  textField: string;
  valueField: string;
}

const DropdownWrapper: React.FC<DropdownWrapperProps> = ({
  catalogId,
  data,
  dataSourceId,
  disabled,
  fieldType,
  id,
  label,
  meta,
  required,
  textField,
  valueField,
  ...others
}) => {
  const translate = useTranslate();
  const { values: formikValues, setFieldValue } = useFormikContext<AddFieldFormikValues>();
  const {
    data: values,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useLookUp(
    id,
    fieldType,
    false,
    catalogId,
    typeof formikValues.customer === 'object' &&
      formikValues.customer &&
      'id' in formikValues?.customer
      ? formikValues.customer?.id
      : undefined
  );
  const error = meta?.touched && typeof meta?.error === 'string';

  useEffect(() => {
    if ((meta?.value as LookUp)?.id === null && required) {
      setFieldValue(id, null);
    }
  });

  const setData = () => {
    if (data?.length > 0) {
      return data;
    }
    if (values?.length > 0) {
      return values;
    }
    return [];
  };

  const disabledSelection =
    disabled ||
    isError ||
    isLoading ||
    isFetching ||
    ((typeof formikValues.customer === 'string' || !formikValues.customer) && !!dataSourceId);

  return (
    <div className={`${styles.dropdownWrap} ${disabled ? styles.disabled : ''}`}>
      <div title={label} className={styles.textLabel}>
        <Label editorId={id} editorValid={!error} editorDisabled={disabled}>
          {`${label}${required ? '(*)' : ''}`}
        </Label>
      </div>
      <DropDownList
        className={`${styles.dropdown} ${!!error ? styles.error : ''}`}
        data={setData()}
        defaultItem={{ [textField]: translate('COMPONENT.SELECT'), [valueField]: null }}
        disabled={disabledSelection}
        id={id}
        loading={isLoading || isFetching}
        onOpen={!!(id && fieldType) ? () => refetch() : null}
        popupSettings={{ height: 250 }}
        textField={textField}
        title={(meta?.value as LookUp)?.name}
        {...others}
      />
      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

DropdownWrapper.defaultProps = {
  catalogId: '',
  data: [],
  dataSourceId: null,
  disabled: false,
  fieldType: '',
  required: false,
};

DropdownWrapper.propTypes = {
  catalogId: PropTypes.string,
  data: PropTypes.array,
  dataSourceId: PropTypes.number,
  disabled: PropTypes.bool,
  fieldType: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.any.isRequired,
  required: PropTypes.bool,
  textField: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
};

export default DropdownWrapper;
