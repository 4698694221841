import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import axios from 'axios';
import { ResultResponse, FileType, DeleteFile, CaseFile } from 'global/types/Case.interface';

const deleteFile = async (fileData: DeleteFile) => {
  let tempFile = false;
  let params;

  if ('tempFile' in fileData) {
    tempFile = fileData.tempFile;
    params = {
      itemId: fileData.caseData.id,
      itemType: fileData.caseData.itemTypeId,
      fileName: fileData.file.name,
    };
  } else {
    params = {
      itemId: fileData.caseData.id,
      itemType: fileData.caseData.itemTypeId,
    };
  }

  const data = await axios(`/file/${tempFile ? -1 : fileData.file.id}?uploadType=case`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    data: params,
  });

  if (data.status !== 200) {
    throw Error('Error deleting file');
  }

  return data.data;
};

export default function useDeleteFile(
  caseDetails: CaseFile
): UseMutationResult<ResultResponse, DeleteFile, DeleteFile, unknown> {
  const queryCache = useQueryClient();
  return useMutation(deleteFile, {
    onMutate: async (fileData) => {
      await queryCache.cancelQueries([
        'itemfiles',
        fileData.caseData.id,
        fileData.caseData.itemTypeId,
        0,
      ]);
      const oldFiles: FileType[] = queryCache.getQueryData([
        'itemfiles',
        fileData.caseData.id,
        fileData.caseData.itemTypeId,
        0,
      ]);
      const updatedFiles = oldFiles.filter((el) => el.name !== fileData.file.name);
      queryCache.setQueryData(
        ['itemfiles', fileData.caseData.id, fileData.caseData.itemTypeId, 0],
        updatedFiles
      );
      return () =>
        queryCache.setQueryData(
          ['itemfiles', fileData.caseData.id, fileData.caseData.itemTypeId, 0],
          oldFiles
        );
    },
    onError: (error, updateFile, rollback: unknown) => {
      if (typeof rollback === 'function') {
        rollback();
      }
    },
    onSettled: () => {
      queryCache.invalidateQueries(['itemfiles', caseDetails.id, caseDetails.itemTypeId, 0]);
    },
  });
}
