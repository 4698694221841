import React, { ReactNode } from 'react';
import { AddField, FileWrapInfo } from 'global/types/AddFields.interface';
import { Field, FieldProps, FieldMetaProps } from 'formik';
import {
  CategoryTree,
  CheckboxWrapper,
  DateWrapper,
  DateTimeWrapper,
  DropdownWrapper,
  FileWrapper,
  InputWrapper,
  LinkWrapper,
  MultiSelectWrapper,
  NumericWrapper,
  TextAreaWrapper,
  TimeWrapper,
} from 'common/FormFields';
import { defaultValidator, dropdownValidator } from 'global/validators';

const getFieldType = (addField: AddField, disable?: boolean, itemData?: FileWrapInfo) => {
  switch (addField.fieldType) {
    case 1: // Short Text
      return (
        <Field
          name={addField?.fieldId.toString()}
          validate={addField.mandatory ? defaultValidator : null}
        >
          {({ field, meta }: FieldProps) => (
            <InputWrapper
              disabled={!addField.enable || disable}
              id={addField.fieldId.toString()}
              label={addField.name}
              meta={meta as FieldMetaProps<string>}
              required={addField.mandatory}
              value={field.value}
              {...field}
            />
          )}
        </Field>
      );
    case 2: // Date Time
      return (
        <DateTimeWrapper
          disabled={!addField.enable || disable}
          id={addField.fieldId.toString()}
          label={addField.name}
          required={addField.mandatory}
        />
      );
    case 3: // Lookup
      return (
        <Field
          name={addField?.fieldId.toString()}
          validate={addField.mandatory ? dropdownValidator : null}
        >
          {({ field, meta }: FieldProps) => (
            <DropdownWrapper
              dataSourceId={addField.dataSourceId}
              disabled={!addField.enable || disable}
              fieldType={addField.fieldType.toString()}
              id={addField.fieldId.toString()}
              label={addField.name}
              meta={meta}
              required={addField.mandatory}
              textField="name"
              valueField="id"
              {...field}
            />
          )}
        </Field>
      );
    case 4: // Long Text
      return (
        <TextAreaWrapper
          disabled={!addField.enable || disable}
          id={addField.fieldId.toString()}
          label={addField.name}
          required={addField.mandatory}
        />
      );
    case 5: // Checkbox
      return (
        <CheckboxWrapper
          disabled={!addField.enable || disable}
          id={addField.fieldId.toString()}
          label={addField.name}
          required={addField.mandatory}
        />
      );
    case 6: // Numeric Text
      return (
        <Field
          name={addField?.fieldId.toString()}
          validate={addField.mandatory ? defaultValidator : null}
        >
          {({ field, meta }: FieldProps) => (
            <NumericWrapper
              disabled={!addField.enable || disable}
              format="n0"
              id={addField.fieldId.toString()}
              label={addField.name}
              meta={meta}
              required={addField.mandatory}
              {...field}
            />
          )}
        </Field>
      );
    case 7: // Catalog List
      return (
        <CategoryTree
          disabled={!addField.enable || disable}
          fieldType={addField.fieldType.toString()}
          id={addField.fieldId.toString()}
          label={addField.name}
          required={addField.mandatory}
        />
      );
    case 9: // Decimal
      return (
        <Field
          name={addField?.fieldId.toString()}
          validate={addField.mandatory ? defaultValidator : null}
        >
          {({ field, meta }: FieldProps) => (
            <NumericWrapper
              disabled={!addField.enable || disable}
              format="n2"
              id={addField.fieldId.toString()}
              label={addField.name}
              meta={meta}
              required={addField.mandatory}
              {...field}
            />
          )}
        </Field>
      );
    case 10: // File
      return (
        <FileWrapper
          disabled={!addField.enable || disable}
          id={addField.fieldId.toString()}
          valueIntField={addField.intValue}
          itemId={itemData?.id}
          itemType={itemData?.itemType}
          label={addField.name}
          required={addField.mandatory}
        />
      );
    case 11: // Dropdown
      return (
        <Field
          name={addField?.fieldId.toString()}
          validate={addField.mandatory ? dropdownValidator : null}
        >
          {({ field, meta }: FieldProps) => (
            <DropdownWrapper
              catalogId={addField.catalogId.toString()}
              disabled={!addField.enable || disable}
              fieldType={addField.fieldType.toString()}
              id={addField.fieldId.toString()}
              label={addField.name}
              meta={meta}
              required={addField.mandatory}
              textField="name"
              valueField="id"
              {...field}
            />
          )}
        </Field>
      );
    case 12: // Catalog Tree
      return (
        <CategoryTree
          catalogId={addField.catalogId.toString()}
          disabled={!addField.enable || disable}
          fieldType={addField.fieldType.toString()}
          id={addField.fieldId.toString()}
          label={addField.name}
          required={addField.mandatory}
        />
      );
    case 13: // Date
      return (
        <DateWrapper
          disabled={!addField.enable || disable}
          id={addField.fieldId.toString()}
          label={addField.name}
          required={addField.mandatory}
        />
      );
    case 14: // Time
      return (
        <TimeWrapper
          disabled={!addField.enable || disable}
          id={addField.fieldId.toString()}
          label={addField.name}
          required={addField.mandatory}
        />
      );
    case 15: // Currency
      return (
        <Field
          name={addField?.fieldId.toString()}
          validate={addField.mandatory ? defaultValidator : null}
        >
          {({ field, meta }: FieldProps) => (
            <NumericWrapper
              disabled={!addField.enable || disable}
              format="c2"
              id={addField.fieldId.toString()}
              label={addField.name}
              meta={meta}
              required={addField.mandatory}
              {...field}
            />
          )}
        </Field>
      );
    case 16: // Link
      return (
        <Field
          name={addField?.fieldId.toString()}
          validate={addField.mandatory ? defaultValidator : null}
        >
          {({ field, meta }: FieldProps) => (
            <LinkWrapper
              disabled={!addField.enable || disable}
              id={addField.fieldId.toString()}
              label={addField.name}
              meta={meta as FieldMetaProps<string>}
              required={addField.mandatory}
              value={field.value}
              {...field}
            />
          )}
        </Field>
      );
    case 18: // Multiselect
      return (
        <MultiSelectWrapper
          dataSourceId={addField.dataSourceId}
          disabled={!addField.enable || disable}
          id={addField.fieldId.toString()}
          label={addField.name}
          required={addField.mandatory}
        />
      );
    default:
      return null;
  }
};

const getAddField = (addField: AddField, disable?: boolean, itemData?: FileWrapInfo): ReactNode => {
  return getFieldType(addField, disable, itemData);
};

export default getAddField;
