export default {
  'es': {
    'VOTING.ADD': 'Agregar',
    'VOTING.ADD_VOTERS': 'Agregar votantes',
    'VOTING.APPROVED': 'Aprobar',
    'VOTING.APPROVED_DETAIL': 'Aprobaron',
    'VOTING.COMPLETED': 'Realizada',
    'VOTING.DATA_INFORMATION': 'Está pendiente de su aprobación para este caso',
    'VOTING.ERROR_ADD_VOTERS': 'No se han podido agregar los votantes',
    'VOTING.HISTORY': 'Histórico de votaciones',
    'VOTING.HOLDER_DESCRIPTION_TEAMS': 'Ingrese la razón de su voto',
    'VOTING.HOLDER_DESCRIPTION': 'Por favor explique la razón de su elección',
    'VOTING.INCOMPLETED': 'Pendiente',
    'VOTING.INFORMATION': 'Resultado de votaciones anteriores',
    'VOTING.JUSTIFICATION': 'Escribe una justificación',
    'VOTING.MESSAGE_DIALOG': 'Gracias por votar',
    'VOTING.NO_APPROVED': 'Rechazar',
    'VOTING.NO_DATA': 'No hay votaciones pendientes',
    'VOTING.NO_INFORMATION': 'Sin información',
    'VOTING.NO_VOTED': 'No voto',
    'VOTING.NUM_VOTING': 'Votaciones realizadas',
    'VOTING.NUM_VOTERS': 'Número de votantes',
    'VOTING.PENDING': 'Votaciones pendientes',
    'VOTING.REJECTED': 'Rechazaron',
    'VOTING.RESPONSABLE': 'Responsables del CI',
    'VOTING.SAVE': 'Guardar',
    'VOTING.RESULTS': 'Resumen de votación',
    'VOTING.SEARCH': 'Buscar',
    'VOTING.STATUS_APPROVAL': {
      'APPROVED': 'Aprobó',
      'MORE_INFORMATION': 'Más información',
      'NO_APPROVED': 'Rechazó',
      'NO_VOTED': 'No voto',
    },
    'VOTING.SUCCESS_ADD_VOTERS': 'Se han agregado los votantes exitosamente',
    'VOTING.THIRD_PARTIES': 'Aprobación por terceros',
    'VOTING.THIRD_PARTIES_DIS': 'Desaprobación por terceros',
    'VOTING.THIRD_PARTY': 'Aprobación por tercero',
    'VOTING.THIRD_PARTY_DIS': 'Desaprobación por tercero',
    'VOTING.VOTERS_LABEL': 'votantes',
    'VOTING.VOTING_NUMBER': 'Votación No.',
    'VOTING.FOOTER_SAVE': 'Guardar',
  },
  'en': {
    'VOTING.ADD': 'Add',
    'VOTING.ADD_VOTERS': 'Add voters',
    'VOTING.APPLY': 'Apply',
    'VOTING.APPROVED': 'Approve',
    'VOTING.APPROVED_DETAIL': 'Approved',
    'VOTING.COMPLETED': 'Done',
    'VOTING.DATA_INFORMATION': 'Your approval is pending for this case',
    'VOTING.ERROR_ADD_VOTERS': 'Unable to add voters',
    'VOTING.HISTORY': 'Voting history',
    'VOTING.HOLDER_DESCRIPTION_TEAMS': 'Enter the reason for your vote',
    'VOTING.HOLDER_DESCRIPTION': 'Please enter an approval reason',
    'VOTING.INCOMPLETED': 'Pending',
    'VOTING.INFORMATION': 'Result of previous votes',
    'VOTING.JUSTIFICATION': 'Enter a justification',
    'VOTING.MESSAGE_DIALOG': 'Thanks for voting',
    'VOTING.NO_APPROVED': 'Reject',
    'VOTING.NO_DATA': 'There are no pending votes',
    'VOTING.NO_INFORMATION': 'No information',
    'VOTING.NO_VOTED': 'Without votes',
    'VOTING.NUM_VOTING': 'Votes made',
    'VOTING.NUM_VOTERS': 'Turnout',
    'VOTING.PENDING': 'Pending votes',
    'VOTING.REJECTED': 'Rejected',
    'VOTING.RESPONSABLE': 'CI managers',
    'VOTING.SAVE': 'Save',
    'VOTING.RESULTS': 'Voting results',
    'VOTING.SEARCH': 'Search',
    'VOTING.STATUS_APPROVAL': {
      'APPROVED': 'Is approved',
      'MORE_INFORMATION': 'More information',
      'NO_APPROVED': 'Is rejected',
      'NO_VOTED': 'Without vote',
    },
    'VOTING.SUCCESS_ADD_VOTERS': 'Voters have been added successfully',
    'VOTING.THIRD_PARTIES': 'Approval by third parties',
    'VOTING.THIRD_PARTIES_DIS': 'Disapproval by third parties',
    'VOTING.THIRD_PARTY': 'Third party approval',
    'VOTING.THIRD_PARTY_DIS': 'Disapproval by third party',
    'VOTING.VOTERS_LABEL': 'voters',
    'VOTING.VOTING_NUMBER': 'Vote No.',
    'VOTING.FOOTER_SAVE': 'Save',
  },
  'pt': {
    'VOTING.ADD': 'Adicionar',
    'VOTING.ADD_VOTERS': 'Adicionar aprovadores',
    'VOTING.APPLY': 'Aplicar',
    'VOTING.APPROVED': 'Aprovar',
    'VOTING.APPROVED_DETAIL': 'Aprovado',
    'VOTING.COMPLETED': 'Realziado',
    'VOTING.DATA_INFORMATION': 'Você tem uma aprovação pendente para este chamado',
    'VOTING.ERROR_ADD_VOTERS': 'Não foi possível adicionar aprovadores',
    'VOTING.HISTORY': 'Histórico de votação',
    'VOTING.HOLDER_DESCRIPTION_TEAMS': 'Digite o motivo do seu voto',
    'VOTING.HOLDER_DESCRIPTION': 'Digite o motivo da aprovação',
    'VOTING.INCOMPLETED': 'Pendente',
    'VOTING.INFORMATION': 'Votação anterior',
    'VOTING.JUSTIFICATION': 'Escreva uma justificativa',
    'VOTING.MESSAGE_DIALOG': 'Obrigado por votar',
    'VOTING.NO_APPROVED': 'Rejeitar',
    'VOTING.NO_DATA': 'Sem votos pendentes',
    'VOTING.NO_INFORMATION': 'Sem informação',
    'VOTING.NO_VOTED': 'Não votou',
    'VOTING.NUM_VOTING': 'Votos realizados',
    'VOTING.NUM_VOTERS': 'Número de aprovadores',
    'VOTING.PENDING': 'Votos pendentes',
    'VOTING.REJECTED': 'Rejeitaram',
    'VOTING.RESPONSABLE': 'Responsável do CI',
    'VOTING.SAVE': 'Salvar',
    'VOTING.RESULTS': 'Resumo da votação',
    'VOTING.SEARCH': 'Pesquisar',
    'VOTING.STATUS_APPROVAL': {
      'APPROVED': 'Aprovou',
      'MORE_INFORMATION': 'Mais informações',
      'NO_APPROVED': 'Rejeitou',
      'NO_VOTED': 'Nenhum voto',
    },
    'VOTING.SUCCESS_ADD_VOTERS': 'Os aprovadores foram adicionados com sucesso',
    'VOTING.THIRD_PARTIES': 'Aprovação de terceiros',
    'VOTING.THIRD_PARTIES_DIS': 'Desaprovação de terceiros',
    'VOTING.THIRD_PARTY': 'Aprovação de terceiros',
    'VOTING.THIRD_PARTY_DIS': 'Reprovação por terceiros',
    'VOTING.VOTERS_LABEL': 'aprovadores',
    'VOTING.VOTING_NUMBER': 'Total aprovadores',
    'VOTING.FOOTER_SAVE': 'Salvar',
  },
};
