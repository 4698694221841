import React, { useState, useMemo, createContext } from 'react';
import PropTypes from 'prop-types';
import { useLocalStorage, useTranslate } from 'global/hooks';
import {
  Category,
  Item,
  WizardState,
  WizardActions,
  WizContext,
} from 'containers/Wizard/constants/Wizard.interface';
import {
  CardContainer,
  Instruction,
  ListContainer,
  SelectedCard,
  ViewSwitch,
} from 'containers/Wizard/elements';
import { BreadCrumbs, Skeleton } from 'common';
import { WizardSkeleton } from 'skeletons';
import styles from './CategoryStep.module.scss';

interface CategoryStepProps {
  state: WizardState;
  dispatch: React.Dispatch<WizardActions>;
  categories?: Category[];
  loading: boolean;
}

export const WizardContext = createContext<WizContext>({
  project: null,
  service: null,
});

export const CategoryStep: React.FC<CategoryStepProps> = ({
  state,
  dispatch,
  categories,
  loading,
}) => {
  const translate = useTranslate();
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState<[number, string][]>([
    [null, translate('WIZARD.START')],
  ]);
  const [gridStorage, setGridStorage] = useLocalStorage<string>('usdk_wizardGrid', '');
  const [isGrid, setIsGrid] = useState(!(gridStorage === 'false'));

  const handleFolderClick = (category: Item) => {
    setBreadcrumbs((prevState) => [...prevState, [category.id, category.name]]);
    setSelectedFolder(category);
  };

  const handleCrumbClick = (id: number, index: number) => {
    if (selectedFolder.id !== id) {
      const newSelected = id ? categories.find((category) => category.id === id) : null;
      setSelectedFolder(newSelected);
      setBreadcrumbs((prevState) => prevState.slice(0, index + 1));
    }
  };

  const handleViewChange = (viewIsGrid: boolean) => {
    setIsGrid(viewIsGrid);
    setGridStorage(viewIsGrid.toString());
  };

  const filteredCategories = (selection: number) =>
    categories?.filter(
      (category) =>
        category.parentId === selection &&
        category.name.toUpperCase().includes(state.searchText?.toUpperCase())
    );

  const handleCategorySelect = (category: Category) => {
    dispatch({ type: '3', payload: { category } });
  };

  const getGrid = () =>
    !loading ? (
      <CardContainer
        items={filteredCategories(selectedFolder?.id || null)}
        handleFolderClick={handleFolderClick}
        handleCardClick={(i) => handleCategorySelect(i as Category)}
      >
        <Instruction number="3" text={translate('WIZARD.SELECT_CATEGORY')} />
      </CardContainer>
    ) : (
      <WizardSkeleton />
    );

  const getList = () =>
    !loading ? (
      <ListContainer
        items={filteredCategories(selectedFolder?.id || null)}
        handleFolderClick={handleFolderClick}
        handleCardClick={(i) => handleCategorySelect(i as Category)}
      >
        <Instruction number="3" text={translate('WIZARD.SELECT_CATEGORY')} />
      </ListContainer>
    ) : (
      <div className={styles.listSkeleton}>
        <Skeleton count={8} height={45} />
      </div>
    );

  const wizardContextValues = useMemo(
    () => ({
      project: state.project,
      service: state.service,
    }),
    [state.project, state.service]
  );

  return (
    <WizardContext.Provider value={wizardContextValues}>
      <div className={`${styles.categoryStep} ${selectedFolder ? styles.folderView : ''}`}>
        <div className={styles.selectedProject}>
          <Instruction number="&#10003;" text={translate('WIZARD.PROJECT')} />
          <SelectedCard item={state.project} type="project" isFolder={false} />
        </div>
        <div className={styles.selectedService}>
          <Instruction number="&#10003;" text={translate('WIZARD.SERVICE')} />
          <SelectedCard item={state.service} type="service" isFolder={false} />
        </div>
        {selectedFolder && (
          <>
            <BreadCrumbs handleClick={(id, index) => handleCrumbClick(id, index)}>
              {breadcrumbs}
            </BreadCrumbs>
            <SelectedCard item={selectedFolder} type="category" isFolder />
          </>
        )}
        {isGrid ? getGrid() : getList()}
        <div className={styles.viewSwitchPlacer}>
          <ViewSwitch isGrid={isGrid} handleClick={(change) => handleViewChange(change)} />
        </div>
      </div>
    </WizardContext.Provider>
  );
};

CategoryStep.defaultProps = {
  categories: [],
};

CategoryStep.propTypes = {
  state: PropTypes.any.isRequired,
  dispatch: PropTypes.func.isRequired,
  categories: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};
