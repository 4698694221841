import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { Response } from 'global/types/System.interface';
import { UserSearch } from 'containers/Wizard/constants/Wizard.interface';

const getUserSearch = async (
  projectId: number,
  itemTypeId: number,
  serviceId: number,
  searchText: string
) => {
  const data = await axios.get<Response<UserSearch>>(
    `project/${projectId}/${itemTypeId}/clients/search?&serviceId=${serviceId}&criteria=${searchText}`
  );

  if (data.status !== 200) {
    throw Error('Error obtaining user list from search');
  }

  return data?.data?.content;
};

export default function useUserSearch(
  projectId: number,
  itemTypeId: number,
  serviceId: number,
  searchText: string
): UseQueryResult<UserSearch[], Error> {
  return useQuery(
    ['usersearch', projectId, itemTypeId, serviceId, searchText],
    () => getUserSearch(projectId, itemTypeId, serviceId, searchText),
    { enabled: searchText.length >= 3 }
  );
}
