export default {
  'es': {
    'CASE.ALERT': 'Alerta',
    'CASE.ALL': 'Todos',
    'CASE.APPLY_SCRIPT': '¿Desea aplicar el guión?',
    'CASE.APPROVAL_TYPE': 'El caso se encuentra pendiente por aprobación del cliente',
    'CASE.CASEID': 'Caso',
    'CASE.CLOSE_CASE': 'Cerrar caso padre',
    'CASE.CLOSED': 'Cerrado',
    'CASE.CONFIRM_CONVERT_ITEM.CLEAR_ADDITIONAL_FIELDS_1': 'Al convertir el caso a ',
    'CASE.CONFIRM_CONVERT_ITEM.CLEAR_ADDITIONAL_FIELDS_2':
      ' la información de los campos adicionales se limpiará, ¿está seguro?',
    'CASE.CONFIRM_CONVERT_ITEM.CONFIRM_SAVE': '¿Esta seguro que desea guardar el caso como ',
    'CASE.CONFIRM_CONVERT_ITEM.WANT_CHANGE': '¿Quiére cambiarlo?',
    'CASE.CONFIRM_SOLUTION': 'Por favor llene la siguiente información:',
    'CASE.CONVERT_CASE': 'Convertir caso',
    'CASE.DETAILS': 'Gestión de casos',
    'CASE.DETAILPARENTCASES': 'Detalle de casos padres',
    'CASE.ITEMBLOCKED': 'El caso está bloqueado',
    'CASE.NO': 'No',
    'CASE.MESSAGE': 'Mensaje',
    'CASE.MESSAGE_STATUS_CLOSE':
      'El caso padre tiene un estado complementario cerrado no configurado',
    'CASE.MESSAGE_MODIFICACION': 'El caso padre no esta en la ultima versión',
    'CASE.MESSAGE_TASKPENDINGCLOSE': 'El caso padre tiene tareas pendientes por cerrar',
    'CASE.MESSAGE_SUCESS': 'El caso padre se cerró correctamente',
    'CASE.MESSAGE_ADDITIONAL': 'El caso padre tiene campos adicionales obligatorios',
    'CASE.MESSAGE_ERROR': 'El caso padre tiene un error desconocido',
    'CASE.OPENED': 'Abierto',
    'CASE.OUTDATED':
      'Existe una versión superior a la actual, es necesario actualizarla, ¿quieres continuar?',
    'CASE.PENDING_TASKS': 'No se puede cambiar de estado, existen tareas pendientes',
    'CASE.PERMISSION': 'No tiene permisos para ejecutar esta acción',
    'CASE.PROJECT': 'Proyecto',
    'CASE.SAVE_ERROR': 'No fue posible salvar el caso',
    'CASE.SAVE_SUCCESS': 'Se actualizó el ítem exitosamente',
    'CASE.SCHEDULING': 'Fecha de agendamiento',
    'CASE.SCRIPT': 'Guión',
    'CASE.QUESTION_CLOSE_1': 'El caso actual tiene',
    'CASE.QUESTION_CLOSE_2': 'el siguiente caso padre',
    'CASE.QUESTION_CLOSE_3': 'los siguientes casos padre',
    'CASE.QUESTION_CLOSE_4': 'en estado abierto',
    'CASE.QUESTION_CLOSE_5': '¿Desea cerrarlo?',
    'CASE.QUESTION_CLOSE_6': '¿Desea cerrarlos?',
    'CASE.YES': 'Si',
    'CASE.NOTE_REQUIRED': 'La nota es requerida para este estado',
    'CASE.MESSAGE_EXIST_CHILD_COMPLEMENTARY':
      'No se puede cerrar el caso, tiene hijos abiertos con relación complementaria',
  },
  'en': {
    'CASE.ALL': 'All',
    'CASE.ALERT': 'Alert',
    'CASE.APPLY_SCRIPT': 'Do you want to apply the script?',
    'CASE.APPROVAL_TYPE': 'The case is pending for client approval',
    'CASE.CASEID': 'Case',
    'CASE.CLOSE_CASE': 'Close parent case',
    'CASE.CLOSED': 'Closed',
    'CASE.CONFIRM_CONVERT_ITEM.CLEAR_ADDITIONAL_FIELDS_1': 'When converting the case to ',
    'CASE.CONFIRM_CONVERT_ITEM.CLEAR_ADDITIONAL_FIELDS_2':
      ' the information in the additional fields will be cleaned, are you sure?',
    'CASE.CONFIRM_CONVERT_ITEM.CONFIRM_SAVE': 'Are you sure you want to save the case as ',
    'CASE.CONFIRM_CONVERT_ITEM.WANT_CHANGE': 'Want to change it?',
    'CASE.CONFIRM_SOLUTION': 'Please fill in the following information:',
    'CASE.CONVERT_CASE': 'Convert case',
    'CASE.DETAILS': 'Case management',
    'CASE.DETAILPARENTCASES': 'Detail of parent cases',
    'CASE.ITEMBLOCKED': 'The case is blocked',
    'CASE.NO': 'No',
    'CASE.MESSAGE': 'Message',
    'CASE.MESSAGE_STATUS_CLOSE': 'The parent case has an unconfigured closed complementary state',
    'CASE.MESSAGE_MODIFICACION': 'The parent case it is not in the latest version',
    'CASE.MESSAGE_TASKPENDINGCLOSE': 'The parent case has pending tasks to close',
    'CASE.MESSAGE_SUCESS': 'The parent case was closed successfully',
    'CASE.MESSAGE_ADDITIONAL': 'The parent case has additional required fields',
    'CASE.MESSAGE_ERROR': 'The parent case has an unknown error',
    'CASE.OPENED': 'Opened',
    'CASE.OUTDATED':
      'There is a higher version than the current one, it is necessary to update it, do you want to proceed?',
    'CASE.PENDING_TASKS': 'Cannot change status because there are pending tasks',
    'CASE.PERMISSION': 'You do not have permission to perform this action',
    'CASE.PROJECT': 'Project',
    'CASE.SAVE_ERROR': 'Unable to save the case',
    'CASE.SAVE_SUCCESS': 'The item was successfully updated',
    'CASE.SCHEDULING': 'Scheduling date',
    'CASE.SCRIPT': 'Script',
    'CASE.QUESTION_CLOSE_1': 'The current case has',
    'CASE.QUESTION_CLOSE_2': 'the following parent case',
    'CASE.QUESTION_CLOSE_3': 'the following parent cases',
    'CASE.QUESTION_CLOSE_4': 'in the open state.',
    'CASE.QUESTION_CLOSE_5': 'Do you want to close it?',
    'CASE.QUESTION_CLOSE_6': 'Do you want to close them?',
    'CASE.YES': 'Yes',
    'CASE.NOTE_REQUIRED': 'Note is required for the selected state',
    'CASE.MESSAGE_EXIST_CHILD_COMPLEMENTARY':
      'Cannot close the case, has open children with complementary relationship',
  },
  'pt': {
    'CASE.ALL': 'Todos',
    'CASE.ALERT': 'Alerta',
    'CASE.APPLY_SCRIPT': 'Você quer aplicar o script?',
    'CASE.APPROVAL_TYPE': 'O caso está pendente de aprovação do cliente',
    'CASE.CASEID': 'Caso',
    'CASE.CLOSE_CASE': 'Fechar caso pai',
    'CASE.CLOSED': 'Fechado',
    'CASE.CONFIRM_CONVERT_ITEM.CLEAR_ADDITIONAL_FIELDS_1': 'Convertendo o chamado em ',
    'CASE.CONFIRM_CONVERT_ITEM.CLEAR_ADDITIONAL_FIELDS_2':
      ' as informações nos campos adicionais serão apagadas, você tem certeza?',
    'CASE.CONFIRM_CONVERT_ITEM.CONFIRM_SAVE': 'Tem certeza de que deseja salvar o chamado como ',
    'CASE.CONFIRM_CONVERT_ITEM.WANT_CHANGE': 'Quer alterar isso?',
    'CASE.CONFIRM_SOLUTION': 'Por favor, preencha as seguintes informações:',
    'CASE.CONVERT_CASE': 'Converter chamado',
    'CASE.DETAILS': 'Gestão de chamado',
    'CASE.DETAILPARENTCASES': 'Detalhe de chamado pai',
    'CASE.NO': 'Não',
    'CASE.MESSAGE': 'Mensagem',
    'CASE.MESSAGE_STATUS_CLOSE':
      'O chamado pais tem um estado complementar fechado não configurado',
    'CASE.MESSAGE_MODIFICACION': 'O chamado pai não está na última versão',
    'CASE.MESSAGE_TASKPENDINGCLOSE': 'O chamado pai tem tarefas pendentes para fechar',
    'CASE.MESSAGE_SUCESS': 'O chamado pai foi fechado com sucesso',
    'CASE.MESSAGE_ADDITIONAL': 'O caso pai tem campos obrigatórios adicionais',
    'CASE.MESSAGE_ERROR': 'O chamado pai tem um erro desconhecido',
    'CASE.ITEMBLOCKED': 'O chamado está bloqueado',
    'CASE.OPENED': 'Aberto',
    'CASE.OUTDATED': 'Existe uma nova versão, é necessário atualizá-la, deseja continuar?',
    'CASE.PENDING_TASKS': 'Não é possível alterar o status, existem tarefas pendentes',
    'CASE.PERMISSION': 'Você não tem permissão para executar esta ação',
    'CASE.PROJECT': 'Projeto',
    'CASE.SAVE_ERROR': 'No fue possível salvar o chamado',
    'CASE.SAVE_SUCCESS': 'Chamado atualizado com sucesso',
    'CASE.SCHEDULING': 'Data de agendamento',
    'CASE.SCRIPT': 'Roteiro',
    'CASE.QUESTION_CLOSE_1': 'O chamado atual tem',
    'CASE.QUESTION_CLOSE_2': 'o seguinte chamado pai',
    'CASE.QUESTION_CLOSE_3': 'os seguintes chamado pai',
    'CASE.QUESTION_CLOSE_4': 'no status aberto.',
    'CASE.QUESTION_CLOSE_5': 'Você quer fechá-lo?',
    'CASE.QUESTION_CLOSE_6': 'Você quer fechá-los?',
    'CASE.YES': 'Sim',
    'CASE.NOTE_REQUIRED': 'A anotação é necessária para este status',
    'CASE.MESSAGE_EXIST_CHILD_COMPLEMENTARY':
      'Não pode fechar o chamado, tem chamados filhos abertos com relacionamento complementar',
  },
};
