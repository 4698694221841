import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { Project } from 'global/types/Resolve.interface';
import { Response } from 'global/types/System.interface';
import { FilterState, CaseType, Criteria } from 'containers/CaseList/constants/CaseList.interface';

const getCaseList = async (filter: FilterState, projects: Project[]) => {
  const projectIds = projects?.map((project) => ({ project: project.id }));
  const types = filter.types?.map((lic) => ({ itemType: lic.id }));
  let criteria: Criteria[] = [];

  if (filter.exactSearch && filter.filterText) {
    const activeSubject: Criteria = {
      fieldName: 'ActiveSubject',
      fieldValue: 'subject',
      logicOperator: 2,
      operatorName: 'eq',
      operatorValue: '==',
      type: 1,
      value: filter.filterText,
      valueName: filter.filterText,
    };
    const activeIdByProject: Criteria = {
      fieldName: 'ActiveIdByProject',
      fieldValue: 'idByProject',
      logicOperator: null,
      operatorName: 'eq',
      operatorValue: '==',
      type: 1,
      value: filter.filterText,
      valueName: filter.filterText,
    };
    criteria = [activeSubject, activeIdByProject];
  }

  const data = await axios.post<Response<CaseType>>('/item/list', {
    criteria,
    filterText: criteria?.length > 0 ? '' : filter.filterText,
    order: filter.order,
    orderField: filter.orderField,
    pageIndex: filter.pageIndex,
    pageSize: 50,
    projects: projectIds,
    repository: filter.repository,
    searchApplicant: filter.filter?.some((item) => item === 'APPLICANT'),
    searchCustomer: filter.filter?.some((item) => item === 'CUSTOMER'),
    types,
  });

  if (data.status !== 200) {
    throw Error('Error obtaining caselist');
  }
  return data?.data;
};

export default function useCaseList(
  filter: FilterState,
  projects: Project[]
): UseQueryResult<Response<CaseType>, Error> {
  return useQuery(['caselist', filter, projects], () => getCaseList(filter, projects), {
    enabled: !!(projects?.length > 0),
  });
}
