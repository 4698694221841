import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Label, Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { TreeValue } from 'global/types/AddFields.interface';
import { useModal, useTranslate } from 'global/hooks';
import { categoryValidator } from 'global/validators';
import { CloseSVG } from 'common/icons';
import { FormValidator } from 'containers/Wizard/elements';
import Tree from './Tree/Tree';
import styles from './CategoryTree.module.scss';

interface CategoryTreeProps {
  catalogId?: string;
  disabled?: boolean;
  fieldType?: string;
  id: string;
  label: string;
  required?: boolean;
}

const CategoryTree: React.FC<CategoryTreeProps> = ({
  catalogId,
  disabled,
  fieldType,
  id,
  label,
  required,
}) => {
  const translate = useTranslate();
  const [, meta, helpers] = useField<TreeValue>({
    name: id,
    validate: required ? categoryValidator : null,
  });
  const { optionalOpenModal, optionalcloseModal } = useModal();

  const handleClear = () => {
    helpers.setValue({ stringValue: null, valueIntField: null });
  };

  const handleSave = (newValue: TreeValue) => {
    helpers.setValue(newValue);
    helpers.setTouched(true);
    helpers.setError(undefined);
    optionalcloseModal();
  };

  const handleClick = () => {
    optionalOpenModal(
      <Tree
        catalogId={catalogId}
        defaultValue={meta.value}
        fieldType={fieldType}
        handleSave={handleSave}
        id={id}
      />,
      label
    );
  };

  const error = meta?.touched && typeof meta?.error === 'string' && !meta?.value?.valueIntField;
  return (
    <div className={`${styles.treeWrap} ${disabled ? styles.disabled : ''}`}>
      <div title={label} className={styles.textLabel}>
        <Label editorDisabled={disabled} editorId={id} editorValid={!error}>
          {`${label}${required ? '(*)' : ''}`}
        </Label>
      </div>
      <Input
        onClick={!disabled ? () => handleClick() : null}
        readOnly
        valid={!error}
        value={meta?.value?.stringValue ?? ''}
      />
      {!disabled && meta?.value?.stringValue && <CloseSVG onClick={handleClear} />}
      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

CategoryTree.defaultProps = {
  catalogId: '',
  disabled: false,
  fieldType: '',
  required: false,
};

CategoryTree.propTypes = {
  catalogId: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  fieldType: PropTypes.string,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default CategoryTree;
