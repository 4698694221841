import React, { useCallback, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FileType, SaveCase, SendFile, DeleteFile } from 'global/types/Case.interface';
import { AddFieldsResponse } from 'global/types/AddFields.interface';
import { useAttachFile, useDeleteFile, useItemFiles } from 'global/services';
import { useAuthentication, useSnackbar, useTranslate } from 'global/hooks';
import {
  Service,
  Category,
  ItemTypes,
  WizardState,
  WizardActions,
  InterfaceDictionary,
} from 'containers/Wizard/constants/Wizard.interface';
import { useCreateCase } from 'containers/Wizard/services';
import { CaseForm, Options } from 'containers/Wizard/elements';
import { FileManager } from 'components';
import { BackDrop } from 'common';
import { CaseFormSkeleton } from 'skeletons';
import styles from './CaseStep.module.scss';

interface CaseStepProps {
  addFields?: AddFieldsResponse;
  async?: boolean;
  categories?: Category[];
  catLoading: boolean;
  dispatch?: React.Dispatch<WizardActions>;
  interfaceConfig?: InterfaceDictionary;
  itemTypes?: ItemTypes[];
  loading: boolean;
  onSaveAction: (s?: string) => void;
  selectedType?: ItemTypes;
  services?: Service[];
  state: WizardState;
  stateId?: number;
  typeLoading: boolean;
}

const CaseStep: React.FC<CaseStepProps> = ({
  state,
  dispatch,
  services,
  categories,
  itemTypes,
  selectedType,
  stateId,
  interfaceConfig,
  addFields,
  onSaveAction,
  loading,
  catLoading,
  async,
  typeLoading,
}) => {
  const translate = useTranslate();
  const { fileMax } = useAuthentication();
  const { addAlert } = useSnackbar();
  const teams = window.location.href.includes('teams');
  const caseRef = useRef<HTMLDivElement>();
  const { data: filesData, refetch: filesFetch } = useItemFiles(-1, state.itemType, 0);
  const { mutateAsync: attachMutate, isLoading: fileLoading } = useAttachFile();
  const { mutateAsync: deleteMutate } = useDeleteFile({ id: -1, itemTypeId: state.itemType });
  const { mutate: createMutate, isLoading: createLoading } = useCreateCase();
  const isCreateCase = localStorage.getItem('asms_client_creation');

  const handleAddFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileSize = Array.from(e.target.files).some((file) => file.size > fileMax);
    if (!fileSize) {
      const sendFile: SendFile = {
        files: e.target.files,
        caseData: {
          itemTypeId: state.itemType,
          id: -1,
        },
      };
      await attachMutate(sendFile, {
        onSuccess: () => {
          addAlert({ msg: translate('WIZARD.ATTACH_FILE_SUCCESS'), severity: 'success' });
          filesFetch();
        },
        onError: (err) => {
          addAlert({ msg: translate(`WIZARD.${err.message}`), severity: 'error' });
        },
      });
    } else {
      addAlert({
        msg: `${
          e.target.files.length === 1
            ? `${translate('WIZARD.FILE_SIZE_ERROR_SINGULAR')} ${fileMax / 1000000}MB`
            : `${translate('WIZARD.FILE_SIZE_ERROR_PLURAL')} ${fileMax / 1000000}MB`
        }`,
        severity: 'error',
      });
    }
    e.target.value = null;
  };

  const handleDeleteFile = useCallback(
    async (file: FileType) => {
      const deleteFile: DeleteFile = {
        file,
        caseData: {
          itemTypeId: state.itemType,
          id: -1,
        },
        tempFile: true,
      };
      await deleteMutate(deleteFile);
    },
    [deleteMutate, state.itemType]
  );

  useLayoutEffect(() => {
    if (isCreateCase) {
      setTimeout(() => {
        if (filesData?.length > 0) {
          filesData.map((file) => {
            handleDeleteFile(file);
          });
          localStorage.removeItem('asms_client_creation');
        } else {
          localStorage.removeItem('asms_client_creation');
        }
      }, 1000);
    }
  }, [filesData, handleDeleteFile, isCreateCase]);

  const handleCreate = (caseParams: SaveCase) => {
    caseParams.dataElements.push({ field: 'StateId', value: stateId });
    caseParams.dataElements.push({ field: 'ModelId', value: selectedType?.modelId });
    caseParams.dataElements.push({
      field: 'RegistryTypeName',
      value: state.registryTypeName || '',
    });
    caseParams.async = async;
    createMutate(caseParams, {
      onSuccess: (data) => {
        const idByProject = data.find((value) => value.Field === 'IDBYPROJECT');
        addAlert({
          msg: `${idByProject.Value} ${translate('WIZARD.CREATE_CASE_SUCCESS')}`,
          severity: 'success',
        });
        onSaveAction(idByProject.Value);
      },
      onError: () => {
        addAlert({ msg: translate('WIZARD.CREATE_CASE_ERROR'), severity: 'error' });
      },
    });
  };

  return (
    <>
      <div className={styles.caseStep}>
        <div className={styles.form} ref={caseRef}>
          <div className={styles.caseOptions}>
            <Options
              state={state}
              dispatch={dispatch}
              services={services}
              categories={categories}
              itemTypes={itemTypes}
              forwardedRef={caseRef}
              catLoading={catLoading}
              typeLoading={typeLoading}
            />
          </div>
          {!loading && !catLoading && !typeLoading ? (
            <>
              <CaseForm
                state={state}
                stateId={stateId}
                interfaceConfig={interfaceConfig}
                defaultDescription={selectedType?.script}
                modelId={selectedType?.modelId}
                addFields={addFields}
                handleCreate={handleCreate}
              />
              {!teams && (
                <div className={styles.attachments}>
                  <FileManager
                    files={filesData}
                    canModify
                    handleAddFile={handleAddFile}
                    handleDeleteFile={handleDeleteFile}
                    isTempFile
                  />
                </div>
              )}
            </>
          ) : (
            <CaseFormSkeleton />
          )}
        </div>
      </div>
      {fileLoading || createLoading ? <BackDrop show /> : null}
    </>
  );
};

CaseStep.defaultProps = {
  dispatch: null,
  services: [],
  categories: [],
  itemTypes: [],
  selectedType: null,
  stateId: null,
  interfaceConfig: null,
  addFields: null,
  async: false,
};

CaseStep.propTypes = {
  state: PropTypes.any.isRequired,
  dispatch: PropTypes.func,
  services: PropTypes.array,
  categories: PropTypes.array,
  itemTypes: PropTypes.array,
  selectedType: PropTypes.any,
  stateId: PropTypes.number,
  addFields: PropTypes.any,
  interfaceConfig: PropTypes.any,
  onSaveAction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  catLoading: PropTypes.bool.isRequired,
  async: PropTypes.bool,
  typeLoading: PropTypes.bool.isRequired,
};

export default CaseStep;
