import React, { useState, ChangeEvent } from 'react';
import { createPortal } from 'react-dom';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';

interface HTMLHandlerProps {
  html?: string;
}

const HTMLHandler: React.FC<HTMLHandlerProps> = ({ html }) => {
  const [iframeBody, setIframeBody] = useState(null);

  const handleLoad = (e: ChangeEvent<HTMLIFrameElement>) => {
    if (!e.defaultPrevented) {
      setIframeBody(e.target.contentDocument.body);
    }
  };

  return (
    <iframe
      srcDoc="<!DOCTYPE html>"
      title="HTMLHandler"
      style={{ width: '100%', height: 'calc(100% - 6px)', border: 'none' }}
      onLoad={handleLoad}
      data-testid="htmlhandler"
    >
      {iframeBody &&
        createPortal(
          React.Children.only(
            <div>
              <base target="_blank" />
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }} />
            </div>
          ),
          iframeBody
        )}
    </iframe>
  );
};

HTMLHandler.defaultProps = {
  html: null,
};

HTMLHandler.propTypes = {
  html: PropTypes.string,
};

export default HTMLHandler;
