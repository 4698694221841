import React from 'react';
import PropTypes from 'prop-types';
import { Article } from 'components';
import styles from './ArticleStep.module.scss';

interface ArticleStepProps {
  articleId?: number;
}

const ArticleStep: React.FC<ArticleStepProps> = ({ articleId }) => (
  <div className={styles.articleStep} data-testid="wizard-articlestep">
    <Article articleId={articleId} />
  </div>
);

ArticleStep.defaultProps = {
  articleId: null,
};

ArticleStep.propTypes = {
  articleId: PropTypes.number,
};

export default ArticleStep;
