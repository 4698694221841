export default {
  'es': {
    'SYSTEM.404': 'Lo sentimos, está página no existe',
    'SYSTEM.CLOSE_SESSION': 'Cerrar Sesión',
    'SYSTEM.GENERAL': 'Ups!! Algo está mal, póngase en contacto con el administrador del sistema',
    'SYSTEM.HOME': 'Inicio',
    'SYSTEM.NEW_VERSION': 'Hay una nueva versión disponible',
    'SYSTEM.NO_LICENSE': 'No hay licencias disponibles',
    'SYSTEM.NO_PERMISSION': 'El usuario no cuenta con permisos suficientes',
    'SYSTEM.NO_PROJECTS': 'El usuario no tiene proyectos asociados',
    'SYSTEM.RETURN': 'Regresar',
    'SYSTEM.SESSION_EXPIRATION': 'La sesión ha terminado, por favor autenticar de nuevo',
    'SYSTEM.UPDATE': 'Actualizar',
    'SYSTEM.WINDOWS_1': 'Cierre el explorador para completar el cierre de sesión.',
    'SYSTEM.WINDOWS_2': 'Debe cerrar el explorador para completar el proceso de cierre sesión.',
  },
  'en': {
    'SYSTEM.404': 'Sorry, this page does not exist',
    'SYSTEM.CLOSE_SESSION': 'Close Session',
    'SYSTEM.GENERAL': 'Oops!! Something is wrong, contact the system administrator',
    'SYSTEM.HOME': 'Home',
    'SYSTEM.NEW_VERSION': 'There is a new version available',
    'SYSTEM.NO_LICENSE': 'No licenses available',
    'SYSTEM.NO_PERMISSION': 'The user does not have the required permissions',
    'SYSTEM.NO_PROJECTS': 'The user is not associated to any projects',
    'SYSTEM.RETURN': 'Return',
    'SYSTEM.SESSION_EXPIRATION': 'The session has expired, please authenticate again',
    'SYSTEM.UPDATE': 'Update',
    'SYSTEM.WINDOWS_1': 'Close your browser to complete the logout.',
    'SYSTEM.WINDOWS_2': 'You must close your browser to complete the logout process.',
  },
  'pt': {
    'SYSTEM.404': 'Desculpe, esta página não existe',
    'SYSTEM.CLOSE_SESSION': 'Encerrar sessão',
    'SYSTEM.GENERAL': 'Ups!! Algo está errado, entre em contato com o administrador do sistema',
    'SYSTEM.HOME': 'Inicio',
    'SYSTEM.NEW_VERSION': 'Existe uma nova versão disponível',
    'SYSTEM.NO_LICENSE': 'Nenhuma licença disponível',
    'SYSTEM.NO_PERMISSION': 'O usuário não tem permissões suficientes',
    'SYSTEM.NO_PROJECTS': 'O usuário não está associado projetos',
    'SYSTEM.RETURN': 'Voltar',
    'SYSTEM.SESSION_EXPIRATION': 'A sessão terminou, por favor autenticar novamente',
    'SYSTEM.UPDATE': 'Atualizar',
    'SYSTEM.WINDOWS_1': 'Feche seu navegador para concluir o logout.',
    'SYSTEM.WINDOWS_2': 'Você deve fechar o navegador para concluir o processo de logout.',
  },
};
