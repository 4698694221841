import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'global/hooks';
import styles from './PlaqueLabel.module.scss';

interface PlaqueLabelProps {
  hierarchy?: string;
  label: string;
  text?: string;
}

const PlaqueLabel: React.FC<PlaqueLabelProps> = React.memo(({ label, text, hierarchy }) => {
  const translate = useTranslate();
  return (
    <div className={styles.label}>
      <h4 title={label}>{label}</h4>
      <h5 title={hierarchy}>{text || translate('COMPONENT.UNDEFINED')}</h5>
    </div>
  );
});

PlaqueLabel.defaultProps = {
  hierarchy: '',
  text: 'N/A',
};

PlaqueLabel.propTypes = {
  hierarchy: PropTypes.string,
  label: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default PlaqueLabel;
