import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@progress/kendo-react-indicators';
import styles from './BackDrop.module.scss';

interface BackDropProps {
  show: boolean;
}

const BackDrop: React.FC<BackDropProps> = ({ show }) => (
  <div className={`${styles.backdrop} ${show ? styles.display : styles.hidden}`}>
    <Loader size="large" />
  </div>
);

BackDrop.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default BackDrop;
