import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'global/hooks';
import {
  Project as ProjectType,
  WizardActions,
} from 'containers/Wizard/constants/Wizard.interface';
import { Instruction, Project } from 'containers/Wizard/elements';
import styles from './ProjectStep.module.scss';

interface ProjectStepProps {
  projects?: ProjectType[];
  dispatch: React.Dispatch<WizardActions>;
}

const ProjectStep: React.FC<ProjectStepProps> = ({ projects, dispatch }) => {
  const translate = useTranslate();

  const handleProjectSelect = (project: ProjectType) => {
    dispatch({ type: '1', payload: { project } });
  };

  return (
    <div className={`${styles.projectStep} ${projects?.length <= 3 ? styles.threeOrLess : ''}`}>
      <Instruction number="1" text={translate('WIZARD.SELECT_PROJECT')} />
      <div className={styles.projectsContainer} data-testid="wizard-projectcard">
        {projects?.map((project, index) => (
          <Project
            project={project}
            handleProjectSelect={(p) => handleProjectSelect(p)}
            key={`${project.id}-${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

ProjectStep.defaultProps = {
  projects: [],
};

ProjectStep.propTypes = {
  projects: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
};

export default ProjectStep;
