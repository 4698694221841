import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@progress/kendo-react-indicators';
import { useModal, useResolve } from 'global/hooks';
import { useCreateToken } from 'global/services';
interface AdminPageProps {
  calendarId?: number;
  fieldId?: number;
  params?: { itemId: number; itemTypeId: number; relationTypeId: number };
  projectId?: number;
  type: 'Client' | 'Company' | 'Articles' | 'Applicant' | 'Calendar';
  url?: string;
}
const AdminPage: React.FC<AdminPageProps> = ({
  calendarId,
  fieldId,
  params,
  projectId,
  type,
  url,
}) => {
  const { AdministratorURL, OpenLinkNewTab } = useResolve();
  const [loading, setLoading] = useState(true);
  const { data, isLoading, isFetching } = useCreateToken();
  // Change manually to URL iframe tye Articles for Article
  const typeValue = type !== 'Articles' ? type : 'Article';
  const [viewIframe, setViewIframe] = useState(true);
  const [validate, setValidate] = useState(false);
  const { closeModal, closeParentModal } = useModal();

  const urlTag = useMemo(
    () =>
      `${AdministratorURL}/Main/Pages/${typeValue}.aspx?id_token=${data}${
        type !== 'Calendar'
          ? `&projectId=${projectId}&${
              !!fieldId ? `id=${fieldId}${type === 'Articles' ? '&mode=edit' : ''}` : 'mode=new'
            }${
              type === 'Articles' && !!params
                ? `&consoletype=1&relationid=${params?.relationTypeId}&itemtype=${params?.itemTypeId}&itemid=${params?.itemId}`
                : ''
            }`
          : `&calendarId=${calendarId}`
      }`,
    [AdministratorURL, calendarId, data, fieldId, params, projectId, type, typeValue]
  );
  useEffect(() => {
    if (OpenLinkNewTab && !isLoading && !isFetching && data?.length > 0) {
      closeModal();
      closeParentModal();
      if (url !== null) {
        window.open(url, '_blank');
      } else {
        window.open(urlTag, '_blank');
      }
    }
  }, [OpenLinkNewTab, closeModal, closeParentModal, data, isFetching, isLoading, url, urlTag]);

  if (OpenLinkNewTab) return null;

  const handleViewIframe = () => {
    setLoading(false);
    if (navigator.userAgent.includes('Firefox')) {
      if (!validate) {
        setViewIframe(false);
        setTimeout(() => {
          setViewIframe(true);
          setValidate(true);
        }, 100);
      }
    }
  };

  return (
    <>
      <div style={{ height: '100%', width: '100%', display: 'grid', placeItems: 'center' }}>
        {isLoading || isFetching || !viewIframe ? null : (
          <iframe
            onLoad={handleViewIframe}
            src={url !== null ? `${url}` : urlTag}
            style={{
              height: 'calc(100% - 5px)',
              width: '100%',
              border: 'none',
              display: loading ? 'none' : 'block',
            }}
            title="blogik"
          />
        )}
        {(isLoading || isFetching || !viewIframe || loading || data?.length < 0) && (
          <Loader size="large" />
        )}
      </div>
    </>
  );
};

AdminPage.defaultProps = {
  calendarId: null,
  fieldId: null,
  params: null,
  url: null,
  projectId: null,
};

AdminPage.propTypes = {
  calendarId: PropTypes.number,
  fieldId: PropTypes.number,
  params: PropTypes.any,
  projectId: PropTypes.number,
  type: PropTypes.any.isRequired,
  url: PropTypes.string,
};

export default AdminPage;
