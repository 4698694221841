import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useModal, useTranslate } from 'global/hooks';
import { InterfaceDictionaryVote } from 'global/types/Item.interfaceVote';
import { HTMLHandler, TabsVote } from 'common';
import { ExtendSVG } from 'common/icons';
import styles from './TextViewVote.module.scss';

interface TextViewProps {
  description?: string;
  interfaceData?: InterfaceDictionaryVote;
  showExtend?: boolean;
  showSolution?: boolean;
  solution?: string;
}

const TextView: React.FC<TextViewProps> = ({
  description,
  interfaceData,
  showExtend,
  showSolution,
  solution,
}) => {
  const translate = useTranslate();
  const { openModal } = useModal();
  const [currentTab, setCurrentTab] = useState('description');

  const getTab = (id: string) => {
    setCurrentTab(id);
  };

  const tabs = [
    {
      id: 'description',
      title: interfaceData?.description?.label
        ? interfaceData.description.label
        : translate('COMPONENT.DESCRIPTION'),
      content: <HTMLHandler html={description} />,
      keepMounted: false,
      disabled: false,
    },
    ...(showSolution
      ? [
          {
            id: 'solution',
            title: interfaceData?.commentary?.label
              ? interfaceData.commentary.label
              : translate('COMPONENT.SOLUTION'),
            content: <HTMLHandler html={solution} />,
            keepMounted: false,
            disabled: false,
          },
        ]
      : []),
  ];

  const handleExpand = () => {
    const tab = tabs.find((el) => {
      return el.id === currentTab;
    });
    if (tab) {
      openModal(tab.content, tab.title, { width: 800, height: 600 });
    }
  };

  return (
    <div className={styles.textView}>
      {showExtend && (
        <div
          className={styles.expand}
          data-testid="textview-expand"
          onClick={handleExpand}
          role="presentation"
        >
          <ExtendSVG />
        </div>
      )}
      <TabsVote selectCallback={getTab} tabs={tabs} showMenu={false} />
    </div>
  );
};

TextView.defaultProps = {
  description: '',
  interfaceData: null,
  showExtend: true,
  showSolution: true,
  solution: '',
};

TextView.propTypes = {
  description: PropTypes.string,
  interfaceData: PropTypes.any,
  showExtend: PropTypes.bool,
  showSolution: PropTypes.bool,
  solution: PropTypes.string,
};

export default TextView;
