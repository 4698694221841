import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import _ from 'lodash';
import { User } from 'global/types/Resolve.interface';

const getUser = async () => {
  const data = await axios.get<User>(`/user/0`);

  if (data.status !== 200) {
    throw Error('Error obtaining user details');
  }

  if (data?.data?.projects?.content?.length > 0) {
    data.data.projects.content = _.uniqBy(data.data.projects.content, (project) => project.id);
  }

  return data.data;
};

export default function useUser(isLogged: boolean): UseQueryResult<User, Error> {
  return useQuery(['user'], () => getUser(), { enabled: isLogged });
}
