import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { ListArticle, Response } from 'global/types/System.interface';
import { ArticleType } from 'containers/KB/constants/Kb.interface';

const getKbArticles = async (
  projectId: number,
  viewName: string,
  articleType: ArticleType,
  categoryId: number,
  searchText: string
) => {
  if (viewName && projectId) {
    const data = await axios.get<Response<ListArticle>>(`/article/${viewName}/Public/list`, {
      params: { project: projectId },
    });
    if (data.status !== 200) {
      throw Error('Error obtaining KB articles');
    }
    return data?.data?.content;
  }
  if (searchText || searchText === '' || categoryId) {
    const type = articleType ? [{ id: articleType.id }] : null;
    const data = await axios.post<Response<ListArticle>>('/article/list', {
      isPublic: true,
      pageIndex: 0,
      pageSize: 5,
      projects: [{ id: projectId }],
      search: searchText,
      models: type,
      ...(categoryId && { categories: [{ id: categoryId }] }),
    });

    if (data.status !== 200) {
      throw Error('Error searching for articles');
    }
    return data?.data?.content;
  }
  throw Error('No valid parameters');
};

export default function useKbArticles(
  projectId: number,
  viewName: string,
  articleType?: ArticleType,
  categoryId?: number,
  searchText?: string
): UseQueryResult<ListArticle[], Error> {
  return useQuery(
    ['kbarticles', projectId, viewName, articleType, categoryId, searchText],
    () => getKbArticles(projectId, viewName, articleType, categoryId, searchText),
    {
      enabled: !!(projectId && (viewName || searchText || categoryId)),
      keepPreviousData: true,
    }
  );
}
