import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '@progress/kendo-react-inputs';
import { useTranslate } from 'global/hooks';
import { WizardState, WizardActions } from 'containers/Wizard/constants/Wizard.interface';
import { Title } from 'common';
import { QuestionMarkSVG, ServiceSVG } from 'common/icons';
import styles from './WizardHeader.module.scss';

interface WizardHeaderProps {
  state: WizardState;
  dispatch: React.Dispatch<WizardActions>;
  showHelper: boolean;
}

const WizardHeader: React.FC<WizardHeaderProps> = ({ state, dispatch, showHelper }) => {
  const translate = useTranslate();

  const handleHelperClick = () => {
    dispatch({ type: 'HELPER', payload: { showHelper: !state.showHelper } });
  };
  return (
    <>
      <Title
        svg={<ServiceSVG className={styles.service} />}
        text={`${translate('WIZARD.SERVICE')}s`}
      />
      {(state.step === 1 || state.step === 2) && (
        <Input
          placeholder={`${translate('WIZARD.SEARCH')} ${
            state.step === 1 ? translate('WIZARD.SERVICE') : translate('WIZARD.CATEGORY')
          }s...`}
          className={styles.search}
          value={state.searchText}
          onChange={(e) => dispatch({ type: 'SEARCH', payload: { searchText: e.value } })}
          type="search"
        />
      )}
      {state.step === 3 && showHelper && (
        <div className={styles.helperButton} onClick={handleHelperClick} role="presentation">
          <QuestionMarkSVG />
        </div>
      )}
    </>
  );
};

WizardHeader.propTypes = {
  state: PropTypes.any.isRequired,
  dispatch: PropTypes.func.isRequired,
  showHelper: PropTypes.bool.isRequired,
};

export default WizardHeader;
