import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Error, Label } from '@progress/kendo-react-labels';
import { DateTimePicker, DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { useLocalization } from '@progress/kendo-react-intl';
import { useTranslate } from 'global/hooks';
import { defaultValidator } from 'global/validators';
import styles from './DateTimeWrapper.module.scss';

interface DateTimeWrapperProps {
  disabled?: boolean;
  id: string;
  label: string;
  required?: boolean;
}

const DateTimeWrapper: React.FC<DateTimeWrapperProps> = ({ disabled, id, label, required }) => {
  const translate = useTranslate();
  const [, meta, helpers] = useField({
    name: id,
    validate: required ? defaultValidator : undefined,
  });
  const { language } = useLocalization();
  const error = typeof meta?.error === 'string';
  const changedValue = () => {
    if (meta?.value) {
      return new Date(Number(meta.value));
    }
    return null;
  };

  const transformDate = () => {
    return changedValue();
  };

  const handleBlur = () => {
    if (meta.value && required) {
      helpers.setError(undefined);
    }
  };

  const handleChange = (e: DateTimePickerChangeEvent) => {
    helpers.setTouched(true);
    helpers.setValue(new Date(e.value).getTime());
  };

  return (
    <div className={`${styles.dateTimeWrap} ${disabled ? styles.disabled : ''}`}>
      <Label editorId={id} editorValid={!error} editorDisabled={disabled}>
        {`${label}${required ? '(*)' : ''}`}
      </Label>
      <DateTimePicker
        className={`${styles.dateTime} ${!meta.value ? styles.placeholder : ''} ${
          !!error ? styles.error : ''
        }`}
        disabled={disabled}
        format={{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          pattern: language === 'en' ? 'MM/dd/yyyy HH:mm:ss' : 'dd/MM/yyyy HH:mm:ss',
        }}
        formatPlaceholder="short"
        id={id}
        name={id}
        onBlur={handleBlur}
        onChange={handleChange}
        value={meta.initialValue !== meta.value ? changedValue() : transformDate()}
      />
      {error && <Error>{translate(meta?.error)}</Error>}
    </div>
  );
};

DateTimeWrapper.defaultProps = {
  disabled: false,
  required: false,
};

DateTimeWrapper.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default DateTimeWrapper;
