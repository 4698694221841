import { AddField, Section } from 'global/types/AddFields.interface';

const anyFieldVisible = (addFields: AddField[], sectionId: number) =>
  addFields.some((field) => field.visible && sectionId === field.sectionId);

export default (addFields: AddField[], sections: Section[]): void => {
  sections.forEach((section) => {
    section.display = anyFieldVisible(addFields, section.id);
  });
};
