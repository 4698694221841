import React from 'react';
import { useTranslate } from 'global/hooks';
import { Skeleton } from 'common';
import { SurveySVG } from 'common/icons';
import styles from './SurveySkeleton.module.scss';

const SurveySkeleton: React.FC = () => {
  const translate = useTranslate();
  return (
    <div className={styles.surveySkeleton}>
      <div className={styles.mainHeader}>
        <SurveySVG />
        <h1>
          {translate('SURVEY.HEADER_FIRST')}
          <br /> {translate('SURVEY.HEADER_LAST')}
        </h1>
      </div>
      <Skeleton height={25} />
      <Skeleton height={15} />
      <Skeleton height={140} width={240} />
      <div className={styles.field}>
        <Skeleton />
        <Skeleton />
      </div>
      <div className={styles.field}>
        <Skeleton />
        <Skeleton />
      </div>
      <div className={styles.field}>
        <Skeleton />
        <Skeleton />
      </div>
    </div>
  );
};

export default SurveySkeleton;
