import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { KbArticle } from 'containers/KB/constants/Kb.interface';

const getRelatedArticles = async (articleId: number) => {
  const data = await axios.get<KbArticle[]>(`/article/${articleId}/related/articles`);
  if (data.status !== 200) {
    throw Error('Error obtaining related articles');
  }
  return data?.data;
};

export default function useRelatedArticles(articleId: number): UseQueryResult<KbArticle[], Error> {
  return useQuery(['relatedarticles', articleId], () => getRelatedArticles(articleId), {
    enabled: false,
  });
}
