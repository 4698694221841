import React, { createContext, useEffect, useState, useCallback, useRef } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { AlertType } from 'global/types/System.interface';
import styles from 'containers/Survey/elements/Alert/Alert.module.scss';

interface SnackbarContext {
  addAlert: (a: AlertType) => void;
}

export const SnackBarContext = createContext<SnackbarContext>({
  addAlert: (alert: AlertType) => alert,
});

interface SnackbarProviderProps {
  children: React.ReactNode;
}

const AUTO_DISMISS = 3000;

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [alerts, setAlerts] = useState<AlertType[]>([]);
  const snackbarsRef = useRef(null);

  useEffect(() => {
    if (alerts.length) {
      alerts.map((alert) => {
        if (!alert.isManual) {
          const dismissAlert = setTimeout(() => {
            setAlerts((prev) => prev.slice(0, prev.length - 1));
          }, AUTO_DISMISS);
          return () => {
            clearTimeout(dismissAlert);
          };
        }
        const handleClickOutside = (e: MouseEvent) => {
          if (snackbarsRef.current && !snackbarsRef.current.contains(e.target)) {
            setAlerts((prev) => prev.slice(0, prev.length - 1));
          }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      });
    }
  }, [alerts]);

  const handleClose = () => {
    setAlerts((prev) => prev.slice(0, prev.length - 1));
  };

  const addAlert = useCallback(
    (alert: AlertType) => setAlerts((prevState) => [alert, ...prevState]),
    []
  );

  return (
    <SnackBarContext.Provider value={{ addAlert }}>
      {children}
      <div ref={snackbarsRef}>
        <NotificationGroup
          style={{
            position: 'fixed',
            bottom: '20px',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}
        >
          <Fade enter exit>
            {_.uniqBy(alerts, 'msg')?.map((alert, index) => (
              <Notification
                type={{ style: alert.severity, icon: false }}
                closable
                onClose={handleClose}
                style={{
                  display: 'flex',
                  flexFlow: 'row nowrap',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '40px',
                  width: '600px',
                  borderRadius: '3px',
                  fontSize: '14px',
                  textAlign: 'center',
                }}
                key={`${alert.msg}-${index + 1}`}
                className={styles.alertWithbar}
              >
                {alert.msg}
              </Notification>
            ))}
          </Fade>
        </NotificationGroup>
      </div>
    </SnackBarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
