import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'global/hooks';
import { Item, WizardImage } from 'containers/Wizard/constants/Wizard.interface';
import { LinkPopup } from 'containers/Wizard/elements';
import { Counter, Image } from 'common';
import styles from './FolderCard.module.scss';

interface FolderCardProps {
  item: Item;
  handleClick: (folder: Item) => void;
  handleSecondaryClick?: (folder: Item) => void;
}

const FolderCard: React.FC<FolderCardProps> = React.memo(
  ({ item, handleClick, handleSecondaryClick }) => {
    const translate = useTranslate();
    let count = item.folderCount;
    let text = translate('WIZARD.FOLDER');
    let type: WizardImage = 'service';
    if ('serviceCount' in item && item.serviceCount > 0) {
      count = item.serviceCount;
      text = translate('WIZARD.SERVICE');
    } else if ('childrenCount' in item && item.childrenCount > 0) {
      count = item.childrenCount;
      text = translate('WIZARD.CATEGORY');
      type = 'category';
    }

    const handleCategoryFolder = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      handleSecondaryClick(item);
    };
    return (
      <div onClick={() => handleClick(item)} className={styles.folder} role="presentation">
        <div className={styles.upper}>
          <div className={styles.image} title={item.description}>
            <Image id={item.imageId} type={type} />
          </div>
          <div className={styles.counter}>
            <Counter number={count} text={text} />
          </div>
        </div>
        <div className={styles.lower} data-testid="wizard-folderlower">
          <h6 title={item.name}>{item.name}</h6>
          {'childrenCount' in item && item.isVisibleUser && item.modelActive ? (
            <div className={styles.categoryParent}>
              <LinkPopup category={item} />
              <div className={styles.newCase}>
                <span className={styles.newCaseIcon} />
                <span
                  className={styles.newCaseText}
                  onClick={handleCategoryFolder}
                  role="presentation"
                >
                  {translate('WIZARD.NEW_CASE')}
                </span>
              </div>
            </div>
          ) : (
            <p title={item.description}>{item.description}</p>
          )}
        </div>
      </div>
    );
  }
);

FolderCard.defaultProps = {
  handleSecondaryClick: null,
};

FolderCard.propTypes = {
  item: PropTypes.any.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleSecondaryClick: PropTypes.func,
};

export default FolderCard;
