import { useMutation, UseMutationResult } from 'react-query';
import axios from 'axios';
import { ResultResponse, DeleteAddFieldFile } from 'global/types/Case.interface';

const deleteAddFile = async (fileData: DeleteAddFieldFile) => {
  const data = await axios(
    `file/temporary/itemId/${fileData.caseData.id}/concept/item?field=${fileData.addFieldId}`,
    {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
    }
  );

  if (data.status !== 200) {
    throw Error('Error deleting add field file');
  }

  return data.data;
};

export default function useDeleteAddFile(): UseMutationResult<
  ResultResponse,
  DeleteAddFieldFile,
  DeleteAddFieldFile,
  unknown
> {
  return useMutation(deleteAddFile);
}
