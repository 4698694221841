export default {
  'es': {
    'scheduler.today': 'Hoy',
    'scheduler.allDay': 'todo el día',
    'calendar.today': 'Hoy',
    'datetimepicker.date': 'Fecha',
    'datetimepicker.time': 'Hora',
    'datetimepicker.cancel': 'Cancelar',
    'datetimepicker.set': 'Guardar',
    'datetimepicker.toggleDateTimeSelector': 'Mostrar Selector de Tiempo',
    'dropdowns.clear': 'limpiar',
    'dropdowns.nodata': 'DATOS NO ENCONTRADOS.',
    'timepicker.cancel': 'Cancelar',
    'timepicker.now': 'Ahora',
    'timepicker.selectNow': 'Seleccionar Ahora',
    'timepicker.set': 'Guardar',
    'timepicker.toggleTimeSelector': 'Mostrar Selector de Tiempo',
  },
  'pt': {
    'scheduler.today': 'Hoje',
    'scheduler.allDay': 'O dia Todo',
    'calendar.today': 'Hoje',
    'dateinput.increment': 'Aumente o valor',
    'dateinput.decrement': 'Diminuir valor',
    'datepicker.toggleCalendar': 'Alterar calendário',
    'datetimepicker.date': 'Data',
    'datetimepicker.time': 'dia',
    'datetimepicker.cancel': 'Cancelar',
    'datetimepicker.set': 'Salvar',
    'datetimepicker.toggleDateTimeSelector': 'Exibir seletor de tempo',
    'dropdowns.clear': 'Apagar',
    'dropdowns.nodata': 'Dados não localizados.',
    'timepicker.cancel': 'Cancelar',
    'timepicker.now': 'Agora',
    'timepicker.selectNow': 'Selecionar Agora',
    'timepicker.set': 'Salvar',
    'timepicker.toggleTimeSelector': 'Exibir seletor de tempo',
    'multiviewcalendar.prevView': 'Anterior',
    'multiviewcalendar.nextView': 'Próxima',
  },
};
