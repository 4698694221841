import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useTranslate } from 'global/hooks';
import { AddField, AddFieldsItemParams, Section } from 'global/types/AddFields.interface';
import { AddFieldsForm } from 'components';
import { ExtendedCase } from 'containers/CaseList/constants/CaseList.interface';
import { State } from 'global/types/Case.interface';
import styles from './AdditionalMandatory.module.scss';

interface AdditionalMandatoryProps {
  id?: string;
  addFields?: AddField[];
  caseDetail?: ExtendedCase;
  sections?: Section[];
  itemData?: AddFieldsItemParams;
  status?: State;
  reason?: State;
  disable?: boolean;
  disableAddFields?: boolean;
  handleSaveFields?: (a: AddField[], s?: number, r?: number) => void;
  handleClose: () => void;
  handleOpen: (c: React.ReactNode, t: string, s?: { height: number; width: number }) => void;
  translate: ReturnType<typeof useTranslate>;
  handleChange: (s: number, r?: number) => void;
  loading?: boolean;
}

const AdditionalMandatory: React.FC<AdditionalMandatoryProps> = ({
  addFields,
  caseDetail,
  sections,
  itemData,
  status,
  reason,
  disable,
  handleSaveFields,
  handleClose,
  handleOpen,
  translate,
  loading,
}) => {
  const addFieldsMandatory = addFields.filter((f) => f.mandatoryUser === true);
  const sectionsMandatory = sections?.filter((s) => {
    const sectionId = addFieldsMandatory?.find((f) => f.sectionId === s.id);
    return sectionId;
  });

  const handleSave = () => {
    handleClose();
  };

  const handleAccept = () => {
    handleClose();
    handleOpen(
      <div>
        <AddFieldsForm
          withSave
          status={status}
          reason={reason}
          addFields={addFieldsMandatory}
          sections={sectionsMandatory}
          itemData={itemData}
          disable={disable}
          handleSaveFields={handleSaveFields}
          handleSave={handleSave}
          loading={loading}
        >
          {!caseDetail?.isClosed ? (
            <div className={styles.button}>
              <Button type="submit" themeColor="primary">
                {translate('COMPONENT.SAVE')}
              </Button>
            </div>
          ) : null}
        </AddFieldsForm>
      </div>,
      translate('COMPONENT.ADDITIONAL_FIELDS'),
      { height: 320, width: 420 }
    );
  };
  return (
    <div className={styles.additionalMandatory}>
      <span className={styles.text}>{translate('COMPONENT.ADDITIONAL_MANDATORY')}</span>
      <div className={styles.buttons}>
        <Button themeColor="light" type="button" onClick={() => handleClose()}>
          {translate('COMPONENT.CANCEL')}
        </Button>
        <Button themeColor="primary" type="button" onClick={() => handleAccept()}>
          {translate('COMPONENT.ACCEPT')}
        </Button>
      </div>
    </div>
  );
};

export default AdditionalMandatory;
