export default {
  'es': {
    'VOTINGPAGE.ACTIVE': 'Activo',
    'VOTINGPAGE.COMMENT': 'Envíe sus comentarios sobre su selección',
    'VOTINGPAGE.COMPLETED_VOTE': 'La votación ya se realizó',
    'VOTINGPAGE.SENDING': 'Enviando voto...',
    'VOTINGPAGE.PENDING': 'Pendiente de votación',
    'VOTINGPAGE.REQUEST_APPROVAL': 'Solicitud de aprobación',
    'VOTINGPAGE.VOTE_STATE': 'Estado de la votación',
  },
  'en': {
    'VOTINGPAGE.ACTIVE': 'Active',
    'VOTINGPAGE.COMMENT': 'Send comments about your selection',
    'VOTINGPAGE.COMPLETED_VOTE': 'The voting has already been completed',
    'VOTINGPAGE.PENDING': 'Pending vote',
    'VOTINGPAGE.REQUEST_APPROVAL': 'Request for approval',
    'VOTINGPAGE.SENDING': 'Sending vote...',
    'VOTINGPAGE.VOTE_STATE': 'Vote Status',
  },
  'pt': {
    'VOTINGPAGE.ACTIVE': 'Ativo',
    'VOTINGPAGE.COMMENT': 'Enviar justificativa',
    'VOTINGPAGE.COMPLETED_VOTE': 'Aprovação encerrada',
    'VOTINGPAGE.PENDING': 'Pendente de aprovação',
    'VOTINGPAGE.REQUEST_APPROVAL': 'Pedido de aprovação',
    'VOTINGPAGE.SENDING': 'Enviando aprovação...',
    'VOTINGPAGE.VOTE_STATE': 'Status da Aprovação',
  },
};
