import { useQuery, UseQueryResult } from 'react-query';
import {
  DefaultProjectSettings,
  ExternalProvider,
  ProjectSettingsResponse,
} from 'global/types/Resolve.interface';
import { DEV } from 'global/utils';

const getProjectSettings = async (adminUrl: string, projectId: string) => {
  const response = await fetch(
    DEV
      ? `${process.env.REACT_APP_BASE_URL}/WebServices/Settings.asmx/GetSettingsByProject`
      : 'WebServices/Settings.asmx/GetSettingsByProject',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ projectId, providerId: 'ASDK-Customer.ExternalProvider' }),
    }
  );

  const value: DefaultProjectSettings = {
    BannerImg: null,
    BgImg: null,
    ContrastColor: null,
    ExternalProviders: [],
    LDAP: null,
    LoginImg: null,
    LogoImg: null,
    PrimaryColor: null,
    Rgb: null,
  };

  if (response?.status !== 200) {
    return value;
  }
  const text = await response.text();
  const data: ProjectSettingsResponse = JSON.parse(text);

  const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  if (text && text.indexOf('DOCTYPE') > -1) {
    throw new Error('Error obtaining default project settings');
  }

  const getNewApiImages = (imageValue: string) => {
    const splitImageId = imageValue.split('id=');
    if (typeof splitImageId[1] !== 'undefined') {
      return `${DEV ? `${process.env.REACT_APP_BASE_URL}/` : ''}api/file/asms/${splitImageId[1]}`;
    }
    return `${adminUrl}${imageValue}`;
  };

  const externalProvidersImageApi = (externalProviders: ExternalProvider[]) => {
    return externalProviders.map((provider: ExternalProvider) => {
      const newProvider = {
        ...provider,
        Logo: getNewApiImages(provider.Logo),
      };
      return newProvider;
    });
  };

  if (typeof data !== 'string') {
    value.PrimaryColor = data?.Branding?.Color?.colorprimary;
    const rgb = hexToRgb(data?.Branding?.Color?.colorprimary);
    value.Rgb = rgb ? `rgba(${rgb?.r},${rgb?.g},${rgb?.b},1)` : null;
    value.BannerImg = data?.Branding?.Images?.bannerimg
      ? getNewApiImages(data?.Branding?.Images?.bannerimg)
      : null;
    value.LogoImg = data?.Branding?.Images?.logoimg
      ? getNewApiImages(data?.Branding?.Images?.logoimg)
      : null;
    value.LoginImg = data?.Branding?.Images?.loginimg
      ? getNewApiImages(data.Branding.Images.loginimg)
      : null;
    value.BgImg = !!data?.Branding?.Carousel
      ? Object.values(data.Branding.Carousel)?.map((img) => {
          return getNewApiImages(img);
        })
      : null;
    value.LDAP = data.Ldap;
    value.ExternalProviders = externalProvidersImageApi(data.externalProviders);
  }
  return value;
};

export default function useProjectSettings(
  adminUrl: string,
  projectId: string
): UseQueryResult<DefaultProjectSettings, Error> {
  return useQuery(
    ['defaultprojectsettings', adminUrl, projectId],
    () => getProjectSettings(adminUrl, projectId),
    { enabled: !!adminUrl }
  );
}
