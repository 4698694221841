import React, { cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { AutoComplete, ListItemProps } from '@progress/kendo-react-dropdowns';
import { Label, Error } from '@progress/kendo-react-labels';
import { User } from 'global/types/Resolve.interface';
import { useDebounce, useTranslate } from 'global/hooks';
import { useUserSearch } from 'containers/Wizard/services';
import { FormValidator } from 'containers/Wizard/elements';
import styles from './UserSearch.module.scss';

interface UserSearchProps {
  id: string;
  label: string;
  disabled: boolean;
  project?: number;
  service?: number;
  itemType?: number;
}

const UserSearch: React.FC<UserSearchProps> = ({
  id,
  label,
  disabled,
  itemType,
  project,
  service,
  ...others
}) => {
  const translate = useTranslate();
  const [, meta, helpers] = useField(id);
  const [text, setText] = useState(meta.initialValue?.name ?? '');
  const debouncedValue = useDebounce<string>(text, 500);
  const { data, isLoading, isFetching } = useUserSearch(project, itemType, service, debouncedValue);
  const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = <div className={styles.searchResult}>{itemProps.dataItem.name}</div>;
    return cloneElement(li, li.props, itemChildren);
  };

  const handleSearch = (value: string | User) => {
    if (typeof value === 'string') {
      setText(value);
      if (value === '') {
        helpers.setValue(null);
      }
    } else if (typeof value === 'object') {
      setText(value.name);
      helpers.setValue(value);
    }
  };
  const error = meta?.touched && typeof meta?.error === 'string';
  return (
    <div className={`${styles.searchWrap} ${disabled ? styles.disabled : ''}`}>
      <div className={styles.textLabel} title={label}>
        <Label editorId={id} editorValid={!error} editorDisabled={disabled}>
          {label}
        </Label>
      </div>
      <AutoComplete
        id={id}
        data={data}
        itemRender={itemRender}
        disabled={disabled}
        valid={!error}
        className={styles.autoComplete}
        loading={isLoading || isFetching}
        placeholder={translate('WIZARD.MINIMUM_THREE')}
        {...others}
        onChange={(e) => handleSearch(e.target.value)}
        value={text}
      />
      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

UserSearch.defaultProps = {
  project: null,
  service: null,
  itemType: null,
};

UserSearch.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  project: PropTypes.number,
  service: PropTypes.number,
  itemType: PropTypes.number,
};

export default UserSearch;
