import React from 'react';
import { Skeleton } from 'common';
import styles from './SignInSkeleton.module.scss';

const SignInSkeleton: React.FC = () => (
  <div className={styles.skeletonContainer}>
    <Skeleton height={50} />
    <Skeleton height={50} />
    <div className={styles.field}>
      <Skeleton height={15} />
      <Skeleton height={25} />
    </div>
    <div className={styles.field}>
      <Skeleton height={15} />
      <Skeleton height={25} />
    </div>
    <div className={styles.field}>
      <Skeleton height={15} />
      <Skeleton height={25} />
    </div>
    <div className={styles.field}>
      <Skeleton height={15} />
      <Skeleton height={25} />
    </div>
    <Skeleton height={25} />
  </div>
);

export default SignInSkeleton;
