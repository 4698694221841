import { useMutation, UseMutationResult } from 'react-query';
import axios from 'axios';
import { AttachFileResponse, SendFile } from 'global/types/Case.interface';
import { ResponseError } from 'global/types/System.interface';

const postFile = async (fileData: SendFile) => {
  const formData = new FormData();
  if (fileData.files instanceof FileList) {
    Array.from(fileData.files).forEach((file, index) => {
      formData.append(`Data${index}`, file);
    });
  }
  if (fileData.files instanceof File) {
    formData.append('Data0', fileData.files);
  }
  if ('caseData' in fileData) {
    formData.append('FileItemId', fileData.caseData.id.toString());
    formData.append('FileItemType', fileData.caseData.itemTypeId.toString());
    formData.append('UploadType', '0');
    formData.append('Concept', '0');
    formData.append('IsPublic', 'true');
  }
  if ('addData' in fileData) {
    formData.append('FileItemId', fileData.addData.FileItemId.toString());
    formData.append('FileItemType', fileData.addData.FileItemType.toString());
    formData.append('AdditionalFieldId', fileData.addData.AdditionalFieldId);
    formData.append('UploadType', '1');
    formData.append('Concept', 'item');
    formData.append('IsPublic', 'false');
  }

  const data = await axios.post<AttachFileResponse[]>(`/file/attach`, formData);
  if (
    data.status === 400 &&
    (data as unknown as ResponseError)?.data?.exceptionMessage === 'InvalidFileOperation'
  ) {
    throw Error('INVALID_FILE_ERROR');
  }
  if (data.status !== 200) {
    throw Error('ATTACH_FILE_ERROR');
  }

  return data?.data;
};

export default function useAttachFile(): UseMutationResult<
  AttachFileResponse[],
  Error,
  SendFile,
  Error
> {
  return useMutation(postFile);
}
