import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ErrorBoundary from 'global/error/ErrorBoundary';
import { BackDrop } from 'common';

const Page404 = lazy(() => import('routers/404/404'));
const AuthenticatedRouter = lazy(() => import('routers/AuthenticatedRouter/AuthenticatedRouter'));
const ErrorPage = lazy(() => import('routers/ErrorPage/ErrorPage'));
const TeamsRouter = lazy(() => import('routers/TeamsRouter/TeamsRouter'));
const SAMLRedirect = lazy(() => import('routers/SAMLRedirect/SAMLRedirect'));
const SAMLLogout = lazy(() => import('routers/SAMLRedirect/SAMLLogout'));
const WindowsLogout = lazy(() => import('routers/WindowsRouter/WindowsLogout'));
const Login = lazy(() => import('containers/Login/Login'));
const Survey = lazy(() => import('containers/Survey/Survey'));
const SurveyRerouter = lazy(
  () => import('containers/Survey/elements/SurveyRerouter/SurveyRerouter')
);
const VotingPage = lazy(() => import('containers/VotingPage/VotingPage'));

const MainRouter: React.FC = () => (
  <Suspense fallback={<BackDrop show />}>
    <Routes>
      <Route path="/">
        <Route index element={<Login />} />
        <Route path="project=:project" element={<Login />} />
        <Route path="Survey/:token(Viewer.aspx)?" element={<SurveyRerouter />} />
        <Route path="Survey/Viewer.aspx/:secondaryToken" element={<SurveyRerouter />} />
        <Route
          path="survey/:token"
          element={
            <ErrorBoundary>
              <Survey />
            </ErrorBoundary>
          }
        />
        <Route path="home/*" element={<AuthenticatedRouter />} />
        <Route path="error" element={<ErrorPage />} />
        <Route path="windows" element={<SAMLRedirect />} />
        <Route path="windowslogout" element={<WindowsLogout />} />
        <Route path="saml" element={<SAMLRedirect />} />
        <Route path="samllogout" element={<SAMLLogout />} />
        <Route path="teams/*" element={<TeamsRouter />} />
        <Route
          path="voting"
          element={
            <ErrorBoundary>
              <VotingPage />
            </ErrorBoundary>
          }
        />
        <Route path="404" element={<Page404 />} />
      </Route>
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  </Suspense>
);

export default MainRouter;
