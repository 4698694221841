import React from 'react';
import PropTypes from 'prop-types';
import { FieldMetaProps } from 'formik';
import { Label, Error, Hint } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { useTranslate } from 'global/hooks';
import { FormValidator } from 'containers/Wizard/elements';
import styles from './InputWrapper.module.scss';

interface InputWrapperProps {
  disabled?: boolean;
  hint?: string;
  id: string;
  label: string;
  max?: number;
  meta: FieldMetaProps<unknown>;
  placeholder?: string;
  required?: boolean;
  type?: string;
  value: unknown;
}

const InputWrapper: React.FC<InputWrapperProps> = ({
  disabled,
  id,
  hint,
  label,
  max,
  meta,
  placeholder,
  required,
  value,
  ...others
}) => {
  const translate = useTranslate();
  const error = meta?.touched && typeof meta?.error === 'string';

  return (
    <div className={`${styles.inputWrap} ${disabled ? styles.disabled : ''}`} data-testid={id}>
      <div title={label} className={styles.textLabel}>
        <Label editorId={id} editorValid={!error} editorDisabled={disabled}>
          {`${label}${required ? '(*)' : ''}`}
        </Label>
      </div>
      {!!hint && <Hint className={styles.hint}>{hint}</Hint>}
      <Input
        className={styles.input}
        disabled={disabled}
        id={id}
        maxLength={max || null}
        placeholder={placeholder}
        valid={!error}
        value={(value as string) ?? ''}
        {...others}
      />
      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

InputWrapper.defaultProps = {
  disabled: false,
  hint: null,
  max: null,
  placeholder: null,
  required: false,
  type: 'text',
  value: null,
};

InputWrapper.propTypes = {
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  max: PropTypes.number,
  meta: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default InputWrapper;
