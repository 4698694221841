import React, { ReactNode } from 'react';
import { Field, FieldProps, FieldInputProps, FieldMetaProps } from 'formik';
import { getSurveyValidator } from 'global/utils';
import { Choice } from 'global/types/AddFields.interface';
import { Question } from 'containers/Survey/constants/Survey.interface';
import { InputWrapper, RatingWrapper, ToggleWrapper, MultiChoiceWrapper } from 'common/FormFields';

const getFieldType = (
  surveyField: Question,
  fieldGenerics: FieldInputProps<unknown>,
  meta: FieldMetaProps<unknown>
) => {
  switch (surveyField.QuestionTypeId) {
    case 1: // Ranking
      return (
        <RatingWrapper
          label={surveyField.Title}
          id={surveyField.Id.toString()}
          meta={meta}
          required={surveyField.IsMandatory}
          hint={surveyField.HelpText}
          max={surveyField.MaxValue}
        />
      );
    case 2: //  Open
      return (
        <InputWrapper
          label={surveyField.Title}
          id={surveyField.Id.toString()}
          meta={meta}
          required={surveyField.IsMandatory}
          value={fieldGenerics.value}
          hint={surveyField.HelpText}
          {...fieldGenerics}
        />
      );
    case 3: // Multiple Choice
      return (
        <MultiChoiceWrapper
          label={surveyField.Title}
          meta={meta as FieldMetaProps<Choice[]>}
          id={surveyField.Id.toString()}
          required={surveyField.IsMandatory}
          hint={surveyField.HelpText}
        />
      );
    case 5: // True or False
      return (
        <ToggleWrapper
          label={surveyField.Title}
          id={surveyField.Id.toString()}
          meta={meta}
          firstChoice={surveyField.ResponseChoices[0].Text}
          secondChoice={surveyField.ResponseChoices[1].Text}
          required={surveyField.IsMandatory}
          hint={surveyField.HelpText}
        />
      );
    default:
      return null;
  }
};

const getSurveyField = (surveyField: Question): ReactNode => (
  <Field
    name={surveyField.Id.toString()}
    validate={surveyField.IsMandatory ? getSurveyValidator(surveyField) : null}
  >
    {({ field, meta }: FieldProps) => getFieldType(surveyField, field, meta)}
  </Field>
);

export default getSurveyField;
