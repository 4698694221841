import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { FileType } from 'global/types/Case.interface';
import { formatJSONDate } from 'global/helpers';

const getArticleFiles = async (articleId: number) => {
  const data = await axios.get<FileType[]>(`/article/${articleId}/file/list`);

  if (data.status !== 200) {
    throw Error('Error obtaining article files');
  }

  const withFormattedDate = data?.data?.map((element) => {
    let created;
    if (typeof element.created === 'string') {
      created = formatJSONDate(element.created);
    }
    return {
      ...element,
      created,
    };
  });
  return withFormattedDate;
};

export default function useArticleFiles(articleId: number): UseQueryResult<FileType[], Error> {
  return useQuery(['articlefiles', articleId], () => getArticleFiles(articleId), {
    enabled: !!articleId,
  });
}
