import { ZonedDate } from '@progress/kendo-date-math';
import { AddField, AddFieldValue } from 'global/types/AddFields.interface';
import { tzDictionary } from 'global/utils';

const DEFAULT_TIME_VALUE = -18000000;

export default (field: AddField, value: AddFieldValue, timeZone: string): AddField => {
  switch (field.fieldType) {
    case 1: // Short Text
    case 4: // Long Text
    case 16: // Link
      if (typeof value === 'string') {
        return { ...field, stringValue: value };
      }
      return { ...field };
    case 2: // DateTime
      if (value instanceof Date || typeof value === 'number') {
        if (field.valueDateField !== new Date(value).getTime()) {
          if (timeZone) {
            const formattedTime = ZonedDate.fromUTCDate(new Date(value), tzDictionary[timeZone])
              .toTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
              .cachedUTCDate.getTime();
            return { ...field, valueDateField: formattedTime };
          }
        }
        return {
          ...field,
          valueDateField: value instanceof Date ? value.getTime() : (value as number),
        };
      }
      if (value === null) {
        return { ...field, valueDateField: null };
      }
      return { ...field };
    case 13: // Date
    case 14: // Time
      if (typeof value === 'number' && value.valueOf() !== DEFAULT_TIME_VALUE) {
        return { ...field, valueDateField: value as number };
      }
      if (value === null || value.valueOf() === DEFAULT_TIME_VALUE) {
        return { ...field, valueDateField: null };
      }
      return { ...field };
    case 3: // LookUp
    case 11: // Dropdown
      if (typeof value === 'object' && value && 'name' in value && 'id' in value) {
        return {
          ...field,
          valueIntField: value.id ? value.id : null,
          stringValue: value.id ? value.name : null,
        };
      }
      return { ...field };
    case 5: // Checkbox
      if (typeof value === 'boolean') {
        return { ...field, boolValue: value };
      }
      return { ...field };
    case 7: // Category List
    case 12: // Category Tree
      if (
        typeof value === 'object' &&
        value &&
        'stringValue' in value &&
        'valueIntField' in value
      ) {
        return { ...field, stringValue: value.stringValue, valueIntField: value.valueIntField };
      }
      return { ...field };
    case 6: // Number
    case 9: // Decimal
    case 15: // Money
      if (typeof value === 'number' || value === null) {
        return { ...field, floatValue: value as number };
      }
      return { ...field };
    case 10: // File
      if (value === '') {
        return { ...field, stringValue: null, valueIntField: null };
      }
      if (typeof value === 'string') {
        return { ...field, stringValue: value };
      }
      return { ...field };
    case 18: // Multiselect
      if (value instanceof Array && value?.length > 0) {
        return { ...field, stringValue: JSON.stringify(value) };
      }
      return { ...field, stringValue: null };
    default:
      return { ...field };
  }
};
