import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';

const getImage = async (id: string) => {
  const data = await axios.get<string>(`/user/image/${id}`, {
    responseType: 'blob',
  });

  if (data.status !== 200) {
    throw Error('Error obtaining image');
  }

  return window.URL.createObjectURL(data.data as unknown as Blob);
};

export default function useImage(id: string): UseQueryResult<string, Error> {
  return useQuery(['image', id], () => getImage(id), {
    enabled: !!id,
  });
}
