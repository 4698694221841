import React from 'react';
import PropTypes from 'prop-types';
import { Window } from '@progress/kendo-react-dialogs';
import { Size } from 'global/types/System.interface';

interface ModalProps {
  title: string;
  children: React.ReactNode;
  closeModal: () => void;
  size?: Size;
  childActive?: boolean;
}

const empty = () => <></>;

const Modal: React.FC<ModalProps> = ({ title, children, closeModal, size, childActive }) => (
  <Window
    modal
    minimizeButton={empty}
    maximizeButton={empty}
    draggable={false}
    resizable={false}
    title={title}
    height={typeof size === 'object' ? size.height : null}
    width={typeof size === 'object' ? size.width : null}
    onClose={closeModal}
    style={{
      maxHeight: '100vh',
      maxWidth: '100vw',
      opacity: childActive ? '0' : '1',
      zIndex: childActive ? -1 : null,
      ...(size === 'max' && { height: 'calc(100% - 40px)' }),
      ...(size === 'max' && { width: 'calc(100% - 40px)' }),
      ...(size === 'max' && { top: '20px' }),
      ...(size === 'max' && { left: '20px' }),
    }}
  >
    {children}
  </Window>
);

const OptionalModal: React.FC<ModalProps> = ({
  title,
  children,
  closeModal,
  size,
  childActive,
}) => (
  <Window
    modal
    minimizeButton={empty}
    maximizeButton={empty}
    draggable={false}
    resizable={false}
    title={title}
    height={typeof size === 'object' ? size.height : null}
    width={typeof size === 'object' ? size.width : null}
    onClose={closeModal}
    style={{
      maxHeight: '100vh',
      maxWidth: '100vw',
      opacity: childActive ? '0' : '1',
      ...(size === 'max' && { height: 'calc(100% - 40px)' }),
      ...(size === 'max' && { width: 'calc(100% - 40px)' }),
      ...(size === 'max' && { top: '20px' }),
      ...(size === 'max' && { left: '20px' }),
    }}
  >
    {children}
  </Window>
);

Modal.defaultProps = {
  size: { height: 600, width: 600 },
  childActive: false,
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  closeModal: PropTypes.func.isRequired,
  size: PropTypes.any,
  childActive: PropTypes.bool,
};

OptionalModal.defaultProps = {
  size: { height: 600, width: 600 },
  childActive: false,
};

OptionalModal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  closeModal: PropTypes.func.isRequired,
  size: PropTypes.any,
  childActive: PropTypes.bool,
};

export { Modal, OptionalModal };
