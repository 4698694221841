import { Settings } from 'global/types/Resolve.interface';

const getProjectSetting = (
  settings: Settings[],
  key: string,
  type: number,
  project: number
): string => {
  try {
    const value = settings.find(
      (setting) => setting.key === key && setting.projectId === project && setting.Type === type
    );
    return value?.value ?? 'false';
  } catch (e) {
    return null;
  }
};

export default getProjectSetting;
