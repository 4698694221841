import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';

const getProjectCase = async (projectId: number) => {
  const data = await axios.post<number>(`/setting/value`, {
    applicationCode: 13,
    key: 'DefaultCaseType',
    valueType: 'INT',
    projectId,
  });
  if (data.status !== 200) {
    throw Error('Error obtaining max file size');
  }

  return data.data;
};

export default function useGetProjectCase(projectId: number): UseQueryResult<number, Error> {
  return useQuery(['projectcase', projectId], () => getProjectCase(projectId), {
    enabled: !!projectId,
  });
}
