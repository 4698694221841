import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, FieldProps } from 'formik';
import { Button } from '@progress/kendo-react-buttons';
import { useAuthentication, useTranslate } from 'global/hooks';
import { defaultValidator } from 'global/validators';
import { getProjectSetting } from 'global/helpers';
import { SaveCase } from 'global/types/Case.interface';
import { AddFieldValue, AddFieldsResponse } from 'global/types/AddFields.interface';
import { getModifiedField } from 'global/utils';
import {
  WizardState,
  CaseFormikValues,
  InterfaceDictionary,
} from 'containers/Wizard/constants/Wizard.interface';
import { FormValidator, CISearch, UserSearch } from 'containers/Wizard/elements';
import { Editor, AddFields, Risk } from 'components';
import { InputWrapper } from 'common/FormFields';
import styles from './CaseForm.module.scss';

interface CaseFormProps {
  state: WizardState;
  stateId?: number;
  interfaceConfig?: InterfaceDictionary;
  defaultDescription?: string;
  modelId?: number;
  addFields?: AddFieldsResponse;
  handleCreate?: (c: SaveCase) => void;
}

const CaseForm: React.FC<CaseFormProps> = ({
  state,
  stateId,
  interfaceConfig,
  defaultDescription,
  modelId,
  addFields,
  handleCreate,
}) => {
  const translate = useTranslate();
  const { user, settings } = useAuthentication();

  const initialSubject = useMemo(() => {
    if (
      getProjectSetting(settings, 'CategoryInSubject', state.itemType, state.project?.id) === 'True'
    ) {
      return `${state.service?.name} >> ${state.category?.name}`;
    }
  }, [state, settings]);
  const registryType = useMemo(() => {
    const settingValue = getProjectSetting(
      settings,
      'RegistryType',
      state.itemType,
      state.project?.id
    );
    return settingValue ?? '';
  }, [state, settings]);

  const handleSubmit = (values: CaseFormikValues) => {
    const modifiedFields = addFields?.addFields.map((field) => {
      if (field.fieldId in values) {
        return getModifiedField(field, values[field.fieldId] as AddFieldValue, user?.timeZone);
      }
      return {
        ...field,
      };
    });
    let customerId;
    let ciId;
    let riskId;
    if (typeof values.customer === 'object' && 'id' in values.customer) {
      customerId = values.customer.id;
    }
    if (typeof values.ci === 'object' && 'id' in values.ci) {
      ciId = values.ci.id;
    }
    if (typeof values.risk === 'object' && 'id' in values.risk) {
      riskId = values.risk.id;
    }
    const createParams = {
      additionals: modifiedFields,
      dataElements: [
        { field: 'ApplicantId', value: user.id },
        { field: 'ServiceId', value: state.service.id },
        { field: 'ItemTypeId', value: state.itemType },
        { field: 'CategoryId', value: state.category.id },
        { field: 'Description', value: values.description as string },
        { field: 'Subject', value: values.subject as string },
        { field: 'ProjectId', value: state.project.id },
        { field: 'CustomerId', value: customerId },
        { field: 'CiId', value: ciId ?? '' },
        {
          field: 'RegistryTypeId',
          value: (registryType as string) !== 'false' ? registryType : '1',
        },
        { field: 'RiskId', value: riskId ?? '' },
        { field: 'TempItemId', value: -1 },
      ],
    };
    handleCreate(createParams);
  };

  const memoizedInitialAdds = useMemo(
    () =>
      addFields?.addFields?.reduce((acc, field) => {
        if (field.visible) {
          return { ...acc, [field.fieldId]: null };
        }
        return acc;
      }, {}),
    [addFields]
  );

  return (
    <div className={styles.container} data-testid="case-form">
      {memoizedInitialAdds && addFields && (
        <Formik
          initialValues={{
            subject: initialSubject || '',
            customer: user,
            description: defaultDescription || initialSubject || '',
            ...memoizedInitialAdds,
          }}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form className={styles.form}>
              {interfaceConfig?.subject?.visible ? (
                <Field
                  name="subject"
                  validate={interfaceConfig?.subject?.mandatory ? defaultValidator : null}
                >
                  {({ field, meta }: FieldProps) => (
                    <InputWrapper
                      disabled={!interfaceConfig?.subject?.enable}
                      id="subject"
                      label={
                        interfaceConfig?.subject?.label
                          ? interfaceConfig?.subject?.label
                          : translate('WIZARD.SUBJECT')
                      }
                      meta={meta}
                      {...field}
                      max={256}
                    />
                  )}
                </Field>
              ) : null}
              {interfaceConfig?.customerName?.visible ? (
                <Field name="customer" validate={defaultValidator}>
                  {({ field }: FieldProps) => (
                    <UserSearch
                      disabled={!interfaceConfig?.customerName?.enable}
                      id="customer"
                      itemType={state.itemType}
                      label={
                        interfaceConfig?.customerName?.label
                          ? interfaceConfig?.customerName?.label
                          : translate('WIZARD.CUSTOMER')
                      }
                      project={state?.project?.id}
                      service={state?.service?.id}
                      {...field}
                    />
                  )}
                </Field>
              ) : null}
              {interfaceConfig?.ciName?.visible ? (
                <Field
                  name="ci"
                  validate={interfaceConfig?.ciName?.mandatory ? defaultValidator : null}
                >
                  {({ field, meta }: FieldProps) => (
                    <CISearch
                      disabled={!interfaceConfig?.ciName?.enable}
                      id="ci"
                      itemType={state.itemType}
                      label={
                        interfaceConfig?.ciName?.label
                          ? interfaceConfig?.ciName?.label
                          : translate('WIZARD.CI')
                      }
                      meta={meta}
                      project={state?.project?.id}
                      service={state?.service?.id}
                      {...field}
                    />
                  )}
                </Field>
              ) : null}
              {interfaceConfig?.description?.visible && (
                <Editor
                  disabled={!interfaceConfig?.description?.enable}
                  id="description"
                  label={
                    interfaceConfig?.description?.label
                      ? interfaceConfig?.description?.label
                      : translate('WIZARD.DESCRIPTION')
                  }
                  required={interfaceConfig?.description?.mandatory}
                />
              )}
              {interfaceConfig?.riskName?.visible === true &&
              (state?.itemType === 3 || state?.itemType === 13) ? (
                <Risk
                  disabled={!interfaceConfig?.riskName?.enable}
                  itemId={-1}
                  id="risk"
                  label={
                    interfaceConfig?.riskName?.label
                      ? interfaceConfig?.riskName?.label
                      : translate('WIZARD.RISK')
                  }
                  required={interfaceConfig?.riskName?.mandatory}
                  modelId={modelId}
                  stateId={stateId}
                />
              ) : null}
              <AddFields
                addFields={addFields?.addFields}
                sections={addFields?.sections}
                itemData={{ customerId: null, id: -1, itemType: state.itemType }}
              />
              <div className={styles.save}>
                <Button themeColor="primary" type="submit">
                  {translate('WIZARD.CREATE_CASE')}
                </Button>
              </div>
              <FormValidator />
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

CaseForm.defaultProps = {
  interfaceConfig: null,
  addFields: null,
  defaultDescription: '',
};

CaseForm.propTypes = {
  state: PropTypes.any.isRequired,
  interfaceConfig: PropTypes.any,
  defaultDescription: PropTypes.string,
  addFields: PropTypes.any,
  handleCreate: PropTypes.any.isRequired,
};

export default CaseForm;
