import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { Reason } from 'global/types/Case.interface';

const getReasons = async (originalStateId: number, stateId: number) => {
  const data = await axios.get<Reason[]>(
    `state/${stateId}/reasons?originalStateId=${originalStateId}`
  );

  if (data.status !== 200) {
    throw Error('Error obtaining reasons');
  }
  return data?.data;
};

export default function useReasons(
  originalStateId: number,
  stateId: number
): UseQueryResult<Reason[], Error> {
  return useQuery(
    ['reasons', originalStateId, stateId],
    () => getReasons(originalStateId, stateId),
    { enabled: !!(originalStateId && stateId) }
  );
}
