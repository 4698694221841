import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar as KAvatar } from '@progress/kendo-react-layout';
import styles from './Avatar.module.scss';

interface UserProps {
  pictureUrl?: string;
}

const Avatar: React.FC<UserProps> = ({ pictureUrl }) => {
  const [fallback, setFallback] = useState(false);

  const handleError = () => {
    setFallback(true);
  };

  return pictureUrl && !fallback ? (
    <KAvatar type="image" style={{ width: '100%', height: '100%' }} className={styles.picture}>
      <img src={pictureUrl} alt="User" onError={handleError} />
    </KAvatar>
  ) : (
    <KAvatar type="icon" style={{ width: '100%', height: '100%' }} className={styles.nopicture}>
      <span className="k-icon k-i-user" />
    </KAvatar>
  );
};

Avatar.defaultProps = {
  pictureUrl: '',
};

Avatar.propTypes = {
  pictureUrl: PropTypes.string,
};

export default Avatar;
