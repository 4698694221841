export default {
  'es': {
    'COMPONENT.ACCEPT': 'Aceptar',
    'COMPONENT.ADD_NOTE': 'Añadir nota',
    'COMPONENT.ADDITIONAL_FIELDS': 'Campos Adicionales',
    'COMPONENT.ADDITIONAL_FIELDS_MANDATORY': 'Campos adicionales obligatorios',
    'COMPONENT.ADDITIONAL_MANDATORY':
      'Para realizar el cierre del caso se deben completar los campos adicionales obligatorios. ¿Desea continuar?',
    'COMPONENT.ADDITIONAL_DATA': 'Datos Adicionales',
    'COMPONENT.ATTACH_FILE': 'Adjuntar archivo',
    'COMPONENT.ATTACH_FILE_ERROR': 'No fue posible agregar el archivo',
    'COMPONENT.ATTACH_FILE_SUCCESS': 'Archivo agregado exitosamente',
    'COMPONENT.ATTACH_FILE_EMAIL': 'Anexar archivos al correo',
    'COMPONENT.AUTHOR': 'Autor',
    'COMPONENT_BUTTON_DISABLED': 'El botón no tiene una url definida',
    'COMPONENT.CANCEL': 'Cancelar',
    'COMPONENT.CASE': 'Caso',
    'COMPONENT.CASE_DETAIL': 'Detalle del Caso',
    'COMPONENT.CATEGORY': 'Categoria',
    'COMPONENT.CHANGE_STATUS': 'Cambiar estado',
    'COMPONENT.CHANGE_STATUS_ERROR': 'No fue posible cambiar el estado del caso',
    'COMPONENT.CHANGE_STATUS_HELP': 'Al cerrar el caso, no se realizará más gestión sobre el mismo',
    'COMPONENT.CLICK_HERE': 'Haga click aqui',
    'COMPONENT.CLIENT': 'Cliente',
    'COMPONENT.CLOSE_CASE': 'Cerrar caso',
    'COMPONENT.COMMENTS': 'Comentarios',
    'COMPONENT.COMPANY': 'Compañía',
    'COMPONENT.COMPLETE_INFO': 'Completar la información para este caso',
    'COMPONENT.CONTENT_NOT_LOADED': 'If the content does not load:',
    'COMPONENT.DELETE_FILE_ERROR': 'No fue posible eliminar el archivo',
    'COMPONENT.DELETE_FILE_SUCCESS': 'Archivo eliminado exitosamente',
    'COMPONENT.DESCRIPTION': 'Descripción',
    'COMPONENT.DOWNLOAD_FILE_CONFIRMATION':
      'El archivo se va descargar en su equipo ¿Desea continuar?',
    'COMPONENT.DOWNLOAD_FILE_SUCCESS': 'Archivo descargado exitosamente',
    'COMPONENT.ELAPSED_TIME': 'Tiempo transcurrido',
    'COMPONENT.ESTIMATED_DATE': 'Fecha de solución estimada',
    'COMPONENT.FILE_LIST': 'Lista de archivos',
    'COMPONENT.FILE_MODAL': 'Descargar archivo',
    'COMPONENT.FILE_SIZE_ERROR': 'El archivo supera el limite de ',
    'COMPONENT.FILES': 'Adjuntos',
    'COMPONENT.FILTER_BY_DESC': 'Filtrar por descripción',
    'COMPONENT.FROM': 'De',
    'COMPONENT.GENERAL_FIELDS': 'Adicionales generales',
    'COMPONENT.HASVOTINGPROCESS': 'El caso tiene un proceso de votación activo',
    'COMPONENT.HISTORY': 'Historial',
    'COMPONENT.HIDE': 'Ocultar',
    'COMPONENT.ITEMBLOCKED': 'El caso esta bloqueado',
    'COMPONENT.INVALID_EMAIL': 'Por favor ingresar un correo valido',
    'COMPONENT.INVALID_FILE_ERROR': 'El tipo de archivo no esta permitido',
    'COMPONENT.INVALID_LINK': 'Por favor ingresar un link valido',
    'COMPONENT.MESSAGE_MODEL': 'El modelo de la categoría se encuentra inactivo',
    'COMPONENT.NUMBERCASE': 'Número del caso',
    'COMPONENT.NO_APPLY': 'No Aplica',
    'COMPONENT.NO_FILES': 'No hay adjuntos',
    'COMPONENT.NO_INFO': 'No hay información',
    'COMPONENT.NO': 'No',
    'COMPONENT.NOTE': 'Nota',
    'COMPONENT.NOTES': 'Notas',
    'COMPONENT.PENDING_SURVEY': 'Encuesta pendiente',
    'COMPONENT.PENDINGTASKS': 'El caso tiene tareas pendientes',
    'COMPONENT.PRIVATE': 'Privado',
    'COMPONENT.PROJECT': 'Proyecto',
    'COMPONENT.PUBLIC': 'Público',
    'COMPONENT.PROGRESS': 'Progreso',
    'COMPONENT.RATE_ARTICLE': 'Calificar artículo',
    'COMPONENT.REASON': 'Razón',
    'COMPONENT.REQUIRED': 'Este campo es obligatorio',
    'COMPONENT.RESPOND': 'Responder',
    'COMPONENT.RESPONSIBLE': 'Responsable',
    'COMPONENT.SAVE': 'Guardar',
    'COMPONENT.SAVE_FIELDS_ERROR': 'No fue posible guardar los campo adicionales',
    'COMPONENT.SAVE_FIELDS_SUCCESS': 'Se guardaron los cambios',
    'COMPONENT.SEE_MORE': 'Ver más',
    'COMPONENT.SELECT': 'Seleccione',
    'COMPONENT.SEND': 'Enviar',
    'COMPONENT.SEND_EMAIL': 'Enviar correo',
    'COMPONENT.SERVICE': 'Servicio',
    'COMPONENT.SPECIALIST': 'Especialista',
    'COMPONENT.SPECIALIST_GROUP': 'Grupo de Especialista',
    'COMPONENT.SOLUTION': 'Solución',
    'COMPONENT.STATUS': 'Estado',
    'COMPONENT.SUBJECT': 'Asunto',
    'COMPONENT.TO': 'Para',
    'COMPONENT.UNDEFINED': 'Sin definir',
    'COMPONENT.UNGROUPED_FIELDS': 'Campos Adicionales no agrupados',
    'COMPONENT.YES': 'Si',
  },
  'en': {
    'COMPONENT.ACCEPT': 'Accept',
    'COMPONENT.ADD_NOTE': 'Add note',
    'COMPONENT.ADDITIONAL_FIELDS': 'Additional fields',
    'COMPONENT.ADDITIONAL_FIELDS_MANDATORY': 'Additional required fields',
    'COMPONENT.ADDITIONAL_MANDATORY':
      'To close the case, you must fill in the additional required fields. Do you wish to continue?',
    'COMPONENT.ADDITIONAL_DATA': 'Additional Data',
    'COMPONENT.ATTACH_FILE': 'Attach file',
    'COMPONENT.ATTACH_FILE_ERROR': 'Unable to attach the file',
    'COMPONENT.ATTACH_FILE_SUCCESS': 'File attached successfully',
    'COMPONENT.ATTACH_FILE_EMAIL': 'Attach files to email',
    'COMPONENT.AUTHOR': 'Author',
    'COMPONENT_BUTTON_DISABLED': 'The button does not have a defined url',
    'COMPONENT.CANCEL': 'Cancel',
    'COMPONENT.CASE': 'Case',
    'COMPONENT.CASE_DETAIL': 'Case Detail',
    'COMPONENT.CATEGORY': 'Category',
    'COMPONENT.CHANGE_STATUS': 'Change status',
    'COMPONENT.CHANGE_STATUS_ERROR': 'Unable to change case status',
    'COMPONENT.CHANGE_STATUS_HELP':
      'After closing the case, no further action will be carried out on it',
    'COMPONENT.CLICK_HERE': 'Click here',
    'COMPONENT.CLIENT': 'Client',
    'COMPONENT.CLOSE_CASE': 'Close case',
    'COMPONENT.COMMENTS': 'Comments',
    'COMPONENT.COMPANY': 'Company',
    'COMPONENT.COMPLETE_INFO': 'Fill out the information for this case',
    'COMPONENT.CONTENT_NOT_LOADED': 'If the content does not load:',
    'COMPONENT.DELETE_FILE_ERROR': 'Unable to delete the file',
    'COMPONENT.DELETE_FILE_SUCCESS': 'File deleted successfully',
    'COMPONENT.DESCRIPTION': 'Description',
    'COMPONENT.DOWNLOAD_FILE_CONFIRMATION':
      'The file will be downloaded on your device. Do you want to continue?',
    'COMPONENT.DOWNLOAD_FILE_SUCCESS': 'File downloaded successfully',
    'COMPONENT.ELAPSED_TIME': 'Elapsed time',
    'COMPONENT.ESTIMATED_DATE': 'Estimated solution date',
    'COMPONENT.FILE_LIST': 'File list',
    'COMPONENT.FILE_MODAL': 'Download file',
    'COMPONENT.FILE_SIZE_ERROR': 'File exceeds the limit of ',
    'COMPONENT.FILES': 'Files',
    'COMPONENT.FILTER_BY_DESC': 'Filter by description',
    'COMPONENT.FROM': 'From',
    'COMPONENT.GENERAL_FIELDS': 'General fields',
    'COMPONENT.HIDE': 'Hide',
    'COMPONENT.ITEMBLOCKED': 'The case is blocked',
    'COMPONENT.HASVOTINGPROCESS': 'Case has active voting process',
    'COMPONENT.HISTORY': 'History',
    'COMPONENT.INVALID_EMAIL': 'Please enter a valid email',
    'COMPONENT.INVALID_FILE_ERROR': 'This file type is not allowed',
    'COMPONENT.INVALID_LINK': 'Please enter a valid link',
    'COMPONENT.MESSAGE_MODEL': 'The model of the category is inactive',
    'COMPONENT.NUMBERCASE': 'Case number',
    'COMPONENT.NO_APPLY': 'Not apply',
    'COMPONENT.NO_FILES': 'No attached files',
    'COMPONENT.NO_INFO': 'No information',
    'COMPONENT.NO': 'No',
    'COMPONENT.NOTE': 'Note',
    'COMPONENT.NOTES': 'Notes',
    'COMPONENT.PENDING_SURVEY': 'Pending survey',
    'COMPONENT.PENDINGTASKS': 'The case has pending tasks',
    'COMPONENT.PRIVATE': 'Private',
    'COMPONENT.PROJECT': 'Project',
    'COMPONENT.PUBLIC': 'Public',
    'COMPONENT.PROGRESS': 'Progress',
    'COMPONENT.RATE_ARTICLE': 'Rate article',
    'COMPONENT.REASON': 'Reason',
    'COMPONENT.REQUIRED': 'This field is required',
    'COMPONENT.RESPOND': 'Respond',
    'COMPONENT.RESPONSIBLE': 'Responsible',
    'COMPONENT.SAVE': 'Save',
    'COMPONENT.SAVE_FIELDS_ERROR': 'Unable to save the additional fields',
    'COMPONENT.SAVE_FIELDS_SUCCESS': 'Changes saved successfully',
    'COMPONENT.SEE_MORE': 'See more',
    'COMPONENT.SELECT': 'Select',
    'COMPONENT.SEND': 'Send',
    'COMPONENT.SEND_EMAIL': 'Send email',
    'COMPONENT.SERVICE': 'Service',
    'COMPONENT.SOLUTION': 'Solution',
    'COMPONENT.SPECIALIST': 'Specialist',
    'COMPONENT.SPECIALIST_GROUP': 'Specialist Group',
    'COMPONENT.STATUS': 'Status',
    'COMPONENT.SUBJECT': 'Subject',
    'COMPONENT.TO': 'To',
    'COMPONENT.UNDEFINED': 'Undefined',
    'COMPONENT.UNGROUPED_FIELDS': 'Ungrouped additional fields',
    'COMPONENT.YES': 'Yes',
  },
  'pt': {
    'COMPONENT.ACCEPT': 'Aceitar',
    'COMPONENT.ADD_NOTE': 'Adicionar anotação',
    'COMPONENT.ADDITIONAL_FIELDS': 'Campos adicionais',
    'COMPONENT.ADDITIONAL_FIELDS_MANDATORY': 'Campos adicionais obrigatórios',
    'COMPONENT.ADDITIONAL_MANDATORY':
      'Para encerrar o caso, os campos adicionais obrigatórios devem ser preenchidos. Quer continuar?',
    'COMPONENT.ADDITIONAL_DATA': 'Dados adicionais',
    'COMPONENT.ATTACH_FILE': 'Anexar arquivo',
    'COMPONENT.ATTACH_FILE_ERROR': 'Não foi possível anexar o arquivo',
    'COMPONENT.ATTACH_FILE_SUCCESS': 'Arquivo anexado com sucesso!',
    'COMPONENT.ATTACH_FILE_EMAIL': 'Anexar arquivos ao e-mail',
    'COMPONENT.AUTHOR': 'Autor',
    'COMPONENT_BUTTON_DISABLED': 'O botão não tem uma URL definida',
    'COMPONENT.CANCEL': 'Cancelar',
    'COMPONENT.CASE': 'Chamado',
    'COMPONENT.CASE_DETAIL': 'Detalhe do Chamado',
    'COMPONENT.CATEGORY': 'Categoria',
    'COMPONENT.CHANGE_STATUS': 'Alterar status',
    'COMPONENT.CHANGE_STATUS_ERROR': 'Não foi possível alterar o status do chamado',
    'COMPONENT.CHANGE_STATUS_HELP':
      'Após encerrar o chamado, não será possível nenhum tipo de alteração',
    'COMPONENT.CLICK_HERE': 'Clique aqui',
    'COMPONENT.CLIENT': 'Cliente',
    'COMPONENT.CLOSE_CASE': 'Chamado encerrado',
    'COMPONENT.COMMENTS': 'Comentários',
    'COMPONENT.COMPANY': 'Companhia',
    'COMPONENT.COMPLETE_INFO': 'Preencha as informações para este chamado',
    'COMPONENT.CONTENT_NOT_LOADED': 'Conteúdo não carregado:',
    'COMPONENT.DELETE_FILE_ERROR': 'Não foi possível eliminar o arquivo',
    'COMPONENT.DELETE_FILE_SUCCESS': 'Arquivo eliminado com sucesso!',
    'COMPONENT.DESCRIPTION': 'Descrição',
    'COMPONENT.DOWNLOAD_FILE_CONFIRMATION': 'O arquivo será baixado Deseja continuar?',
    'COMPONENT.DOWNLOAD_FILE_SUCCESS': 'Arquivo baixado com sucesso!',
    'COMPONENT.ELAPSED_TIME': 'Tempo percorrido',
    'COMPONENT.ESTIMATED_DATE': 'Data de solução Prevista',
    'COMPONENT.FILE_LIST': 'Lista de Arquivos',
    'COMPONENT.FILE_MODAL': 'Fazer download do arquivo',
    'COMPONENT.FILE_SIZE_ERROR': 'Arquivo excede o limite de ',
    'COMPONENT.FILES': 'Registros',
    'COMPONENT.FILTER_BY_DESC': 'Filtrar por descrição',
    'COMPONENT.FROM': 'De',
    'COMPONENT.GENERAL_FIELDS': 'Campos adicionais Gerais',
    'COMPONENT.HIDE': 'Ocultar',
    'COMPONENT.ITEMBLOCKED': 'O caso está bloqueado',
    'COMPONENT.HASVOTINGPROCESS': 'O caso tem um processo de votação activo',
    'COMPONENT.HISTORY': 'Histórico',
    'COMPONENT.INVALID_EMAIL': 'Por favor, digite um e-mail válido',
    'COMPONENT.INVALID_FILE_ERROR': 'O tipo de arquivo não é permitido',
    'COMPONENT.INVALID_LINK': 'Por favor, digite um link válido',
    'COMPONENT.MESSAGE_MODEL': 'O modelo da categoria está inativo',
    'COMPONENT.NUMBERCASE': 'Número do chamado',
    'COMPONENT.NO_APPLY': 'Não se aplica',
    'COMPONENT.NO_FILES': 'Sem registros',
    'COMPONENT.NO_INFO': 'Sem há informação',
    'COMPONENT.NO': 'Não',
    'COMPONENT.NOTE': 'Anotação',
    'COMPONENT.NOTES': 'Anotações',
    'COMPONENT.PENDING_SURVEY': 'Pesquisa pendente',
    'COMPONENT.PENDINGTASKS': 'O caso possui tarefas pendentes',
    'COMPONENT.PRIVATE': 'Privado',
    'COMPONENT.PROJECT': 'Projeto',
    'COMPONENT.PUBLIC': 'Público',
    'COMPONENT.PROGRESS': 'Progresso',
    'COMPONENT.RATE_ARTICLE': 'Avalie o artigo',
    'COMPONENT.REASON': 'Razão',
    'COMPONENT.REQUIRED': 'Este campo é obrigatório',
    'COMPONENT.RESPOND': 'Responda',
    'COMPONENT.RESPONSIBLE': 'Responsável',
    'COMPONENT.SAVE': 'Salvar',
    'COMPONENT.SAVE_FIELDS_ERROR': 'Não foi possível salvar os campos adicionais',
    'COMPONENT.SAVE_FIELDS_SUCCESS': 'As alterações foram salvas com sucesso!',
    'COMPONENT.SEE_MORE': 'mais',
    'COMPONENT.SELECT': 'Selecionar',
    'COMPONENT.SEND': 'Enviar',
    'COMPONENT.SEND_EMAIL': 'Enviar e-mail',
    'COMPONENT.SERVICE': 'Serviço',
    'COMPONENT.SPECIALIST': 'Analista',
    'COMPONENT.SPECIALIST_GROUP': 'Grupo de Analistas',
    'COMPONENT.SOLUTION': 'Solução',
    'COMPONENT.STATUS': 'Status',
    'COMPONENT.SUBJECT': 'Assunto',
    'COMPONENT.TO': 'Para',
    'COMPONENT.UNDEFINED': 'Indefinido',
    'COMPONENT.UNGROUPED_FIELDS': 'Campos adicionais não agrupados',
    'COMPONENT.YES': 'Sim',
  },
};
