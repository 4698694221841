import { useQuery, UseQueryResult } from 'react-query';
import { Global } from 'global/types/Resolve.interface';
import { DEV } from 'global/utils';

const getAppGlobals = async () => {
  const response = await fetch(
    DEV
      ? `${process.env.REACT_APP_BASE_URL}/WebServices/Settings.asmx/GetDefaultSettings`
      : 'WebServices/Settings.asmx/GetDefaultSettings',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.text();
  const globalData: Global = JSON.parse(data);
  if (data?.indexOf('DOCTYPE') > -1) {
    throw new Error('Error obtaining default settings');
  }

  if (globalData.HideForgotPasswordCustomer === 'True')
    globalData.HideForgotPasswordCustomer = true;

  if (globalData.HideForgotPasswordCustomer === 'False')
    globalData.HideForgotPasswordCustomer = false;

  return globalData;
};

export default function useAppGlobals(): UseQueryResult<Global, Error> {
  return useQuery('resolves', () => getAppGlobals());
}
