import { useMutation, UseMutationResult } from 'react-query';
import { DEV } from 'global/utils';

const postSAMLLogout = async (providerId: string) => {
  const response = await fetch(
    DEV
      ? `${process.env.REACT_APP_BASE_URL}/api/externalauth/${providerId}/session`
      : `api/externalauth/${providerId}/session`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.status !== 200) {
    throw new Error('Error terminating SAML authentication');
  }
  const data = await response.text();
  if (data?.indexOf('DOCTYPE') > -1) {
    throw new Error('Error terminating SAML authentication');
  }
  return JSON.parse(data);
};

export default function useSAMLLogout(): UseMutationResult<string, Error, string, unknown> {
  return useMutation(postSAMLLogout);
}
