import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { Response } from 'global/types/System.interface';
import { CISearch } from 'containers/Wizard/constants/Wizard.interface';

const getCISearch = async (
  projectId: number,
  itemTypeId: number,
  serviceId: number,
  searchText: string,
  withResponsible: boolean,
  userId: number
) => {
  const data = await axios.get<Response<CISearch>>(
    `project/${projectId}/itemtype/${itemTypeId}/ci/search?serviceId=${serviceId}&filter=${searchText}&responsibilityuse=${withResponsible}&userid=${userId}`
  );

  if (data.status !== 200) {
    throw Error('Error obtaining ci list');
  }

  return data?.data?.content;
};

export default function useCISearch(
  projectId: number,
  itemTypeId: number,
  serviceId: number,
  searchText: string,
  withResponsible: boolean,
  userId: number
): UseQueryResult<CISearch[], Error> {
  return useQuery(
    ['cisearch', projectId, itemTypeId, serviceId, searchText, withResponsible, userId],
    () => getCISearch(projectId, itemTypeId, serviceId, searchText, withResponsible, userId),
    { enabled: searchText.length >= 3 }
  );
}
