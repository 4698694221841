import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { Response } from 'global/types/System.interface';
import { State } from 'global/types/Case.interface';

const getStates = async (modelId: number, itemTypeId: number) => {
  const data = await axios.get<Response<State>>(`model/${modelId}/${itemTypeId}/states?stateId=`);
  if (data.status !== 200) {
    throw Error('Error obtaining case states');
  }
  return data?.data?.content;
};

export default function useStates(
  modelId: number,
  itemTypeId: number
): UseQueryResult<State[], Error> {
  return useQuery(['states', modelId, itemTypeId], () => getStates(modelId, itemTypeId), {
    enabled: !!(modelId && itemTypeId),
  });
}
