export default {
  'es': {
    'SURVEY.ALREADY_ANSWERED': 'Esta encuesta ya ha sido respondida',
    'SURVEY.ANSWERED': 'Gracias, la encuesta ha sido enviada',
    'SURVEY.CASE_NUMBER': 'Código del caso:',
    'SURVEY.DESCRIBE_REASON': 'Describe brevemente tu razón...',
    'SURVEY.HEADER_FIRST': 'Encuesta de',
    'SURVEY.HEADER_LAST': 'Satisfación al Cliente',
    'SURVEY.SEND': 'Enviar Encuesta',
    'SURVEY.SUBJECT': 'Asunto:',
    'SURVEY.UNAVAILABLE': 'Lo sentimos, esta encuesta no está disponible',
    'SURVEY.VIEW_DETAIL': 'VER DETALLE',
  },
  'en': {
    'SURVEY.ALREADY_ANSWERED': 'This survey has already been answered',
    'SURVEY.ANSWERED': 'Thank you, the survey has been sent',
    'SURVEY.CASE_NUMBER': 'Case Number:',
    'SURVEY.DESCRIBE_REASON': 'Briefly describe your reason...',
    'SURVEY.HEADER_FIRST': 'Customer',
    'SURVEY.HEADER_LAST': 'Satisfaction Survey',
    'SURVEY.SEND': 'Send Survey',
    'SURVEY.SUBJECT': 'Subject:',
    'SURVEY.UNAVAILABLE': 'We are sorry, this survey is not available',
    'SURVEY.VIEW_DETAIL': 'VIEW DETAILS',
  },
  'pt': {
    'SURVEY.ALREADY_ANSWERED': 'Esta pesquisa já foi respondida por você',
    'SURVEY.ANSWERED': 'Obrigado, a pesquisa foi enviada',
    'SURVEY.CASE_NUMBER': 'Código do chamado:',
    'SURVEY.DESCRIBE_REASON': 'Descreva brevemente seu motivo...',
    'SURVEY.HEADER_FIRST': 'Pesquisa de',
    'SURVEY.HEADER_LAST': 'Satisfação do Cliente',
    'SURVEY.SEND': 'Enviar Pesquisa',
    'SURVEY.SUBJECT': 'Assunto:',
    'SURVEY.UNAVAILABLE': 'Desculpe, esta pesquisa não está disponível',
    'SURVEY.VIEW_DETAIL': 'Exibir Detalhe',
  },
};
