import React, { useEffect } from 'react';
import _ from 'lodash';
import { useFormikContext } from 'formik';
import { useSnackbar, useTranslate } from 'global/hooks';

const FormValidator: React.FC = () => {
  const formik = useFormikContext();
  const translate = useTranslate();
  const { addAlert } = useSnackbar();

  useEffect(() => {
    if (!_.isEmpty(formik.errors) && formik.isSubmitting) {
      addAlert({ msg: translate('WIZARD.MISSING_FIELDS'), severity: 'error' });
    }
  }, [formik.errors, formik.isSubmitting, addAlert, translate]);
  return <></>;
};

FormValidator.propTypes = {};

export default FormValidator;
