import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { Category } from 'containers/Wizard/constants/Wizard.interface';
import { CategoryTree, Mapping } from 'containers/KB/constants/Kb.interface';

const getArticleCategories = async (projectId: number) => {
  const data = await axios.get<Category[]>(`/project/${projectId}/7/category/list`);
  let tree: CategoryTree[] = [];

  if (data.status !== 200) {
    throw Error('Error obtaining article categories');
  }

  if (data?.data?.length > 0) {
    const transformedCategories = data.data.map((category) => ({
      ...category,
      expanded: false,
      items: [],
      selected: false,
    }));
    const idMapping: Mapping = transformedCategories.reduce((acc: Mapping, el, i) => {
      acc[el.id] = i;
      return acc;
    }, {});
    transformedCategories.forEach((tCategory) => {
      if (tCategory.parentId === null) {
        return;
      }
      const parentCat = transformedCategories[idMapping[tCategory.parentId]];
      parentCat.items = [...(parentCat.items || []), tCategory];
    });
    tree = transformedCategories.filter((categoryTree) => categoryTree.parentId === null);
  }
  return tree;
};

export default function useArticleCategories(
  projectId: number
): UseQueryResult<CategoryTree[], Error> {
  return useQuery(['articlecategories', projectId], () => getArticleCategories(projectId), {
    enabled: !!projectId,
  });
}
