import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import _ from 'lodash';
import { Category } from 'containers/Wizard/constants/Wizard.interface';

const getCategories = async (serviceId: number, projectId: number) => {
  const data = await axios.get<Category[]>(`service/${serviceId}/categories/project/${projectId}`);

  if (data.status !== 200) {
    throw Error('Error obtaining categories');
  }

  const categoryFolderCount = _.groupBy(data.data, 'parentId');
  const transformedCategories = data?.data.map((category) => {
    category.folderCount = categoryFolderCount[category.id]
      ? categoryFolderCount[category.id].length
      : 0;
    return category;
  });
  return transformedCategories;
};

export default function useCategories(
  serviceId: number,
  projectId: number
): UseQueryResult<Category[], Error> {
  return useQuery(['categories', serviceId, projectId], () => getCategories(serviceId, projectId), {
    enabled: !!(serviceId && projectId),
  });
}
