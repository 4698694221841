import { Question, Answer } from 'containers/Survey/constants/Survey.interface';
import { AddFieldValue } from 'global/types/AddFields.interface';

export default (
  field: Question,
  value: AddFieldValue,
  justification: string,
  token: string
): Answer => {
  const newAnswer: Answer = {
    QuestionId: field.Id,
    Token: token,
  };
  if (field.IsJustificationRequired) {
    newAnswer.ResponseValue = justification ?? '';
  }
  switch (field.QuestionTypeId) {
    case 1:
      if (typeof value === 'number' && value > 0) {
        newAnswer.ResponseChoiceId = value.toString();
      }
      return newAnswer;
    case 2: // Short Text
      newAnswer.ResponseValue = value as string;
      return newAnswer;
    case 3:
      if (typeof value === 'number') {
        newAnswer.ResponseChoiceId = value as number;
      }
      return newAnswer;
    case 5:
      if (value === true) {
        newAnswer.ResponseChoiceId = field.ResponseChoices[0].Id;
      } else if (value === false) {
        newAnswer.ResponseChoiceId = field.ResponseChoices[1].Id;
      }
      return newAnswer;
    default:
      return null;
  }
};
