import CaseListMsgs from 'containers/CaseList/constants/CaseList.locale';
import ChatBotMsgs from 'containers/ChatBot/constants/ChatBot.locale';
import ComponentMsgs from 'assets/locale/Components.locale';
import FSCMsgs from 'containers/FSC/constants/FSC.locale';
import GridMsgs from 'containers/Grid/constants/Grid.locale';
import HeaderMsgs from 'containers/Header/constants/Header.locale';
import KendoMsgs from 'assets/locale/Kendo.locale';
import KbMsgs from 'containers/KB/constants/Kb.locale';
import LoginMsgs from 'containers/Login/constants/Login.locale';
import PortalMsgs from 'containers/Portal/constants/Portal.locale';
import SidebarsMsgs from 'containers/Sidebars/constants/Sidebars.locale';
import SurveyMsgs from 'containers/Survey/constants/Survey.locale';
import SystemMsgs from 'assets/locale/System.locale';
import WizardMsgs from 'containers/Wizard/constants/Wizard.locale';
import VotingMsgs from 'containers/Case/elements/Voting/constants/Voting.locale';
import VotingPageMsgs from 'containers/VotingPage/constants/VotingPage.locale';
import HistoryMsgs from 'components/History/constants/History.locale';
import CaseMsgs from 'containers/Case/constants/Case.locale';

export default {
  'en': {
    ...CaseListMsgs.en,
    ...ChatBotMsgs.en,
    ...ComponentMsgs.en,
    ...FSCMsgs.en,
    ...GridMsgs.en,
    ...HeaderMsgs.en,
    ...KbMsgs.en,
    ...LoginMsgs.en,
    ...PortalMsgs.en,
    ...SidebarsMsgs.en,
    ...SurveyMsgs.en,
    ...SystemMsgs.en,
    ...WizardMsgs.en,
    ...VotingMsgs.en,
    ...VotingPageMsgs.en,
    ...HistoryMsgs.en,
    ...CaseMsgs.en,
  },
  'es': {
    ...CaseListMsgs.es,
    ...ChatBotMsgs.es,
    ...ComponentMsgs.es,
    ...FSCMsgs.es,
    ...GridMsgs.es,
    ...HeaderMsgs.es,
    ...KendoMsgs.es,
    ...KbMsgs.es,
    ...LoginMsgs.es,
    ...PortalMsgs.es,
    ...SidebarsMsgs.es,
    ...SurveyMsgs.es,
    ...SystemMsgs.es,
    ...WizardMsgs.es,
    ...VotingMsgs.es,
    ...VotingPageMsgs.es,
    ...HistoryMsgs.es,
    ...CaseMsgs.es,
  },
  'pt': {
    ...CaseListMsgs.pt,
    ...ChatBotMsgs.pt,
    ...ComponentMsgs.pt,
    ...FSCMsgs.pt,
    ...GridMsgs.pt,
    ...HeaderMsgs.pt,
    ...KendoMsgs.pt,
    ...KbMsgs.pt,
    ...LoginMsgs.pt,
    ...PortalMsgs.pt,
    ...SidebarsMsgs.pt,
    ...SurveyMsgs.pt,
    ...SystemMsgs.pt,
    ...WizardMsgs.pt,
    ...VotingMsgs.pt,
    ...VotingPageMsgs.pt,
    ...HistoryMsgs.pt,
    ...CaseMsgs.pt,
  },
};
