import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { Response } from 'global/types/System.interface';
import { State } from 'global/types/Case.interface';

const getUserStates = async (modelId: number, itemTypeId: number) => {
  const data = await axios.get<Response<State>>(`model/${modelId}/${itemTypeId}/userStates`);

  if (data.status !== 200) {
    throw Error('Error obtaining user status');
  }

  return data?.data?.content;
};

export default function useUserStates(
  modelId: number,
  itemTypeId: number
): UseQueryResult<State[], Error> {
  return useQuery(['userstates', modelId, itemTypeId], () => getUserStates(modelId, itemTypeId));
}
