import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { LANGUAGECODE, Language } from 'global/types/System.interface';
import { ExtendedCase } from 'containers/CaseList/constants/CaseList.interface';

const getCase = async (caseId: number, localization: Language) => {
  const data = await axios.get<ExtendedCase>(
    `/item/${caseId}?languaje=${LANGUAGECODE[localization] || '0'}&isV8=false`
  );
  if (data.status !== 200) {
    throw Error('Error obtaining case details');
  }
  return data?.data;
};

export default function useCaseDetail(
  caseId: number,
  localization: string
): UseQueryResult<ExtendedCase, Error> {
  return useQuery(['case', caseId, localization], () => getCase(caseId, localization as Language), {
    enabled: !!caseId,
    retry: 2,
  });
}
