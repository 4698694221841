import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@progress/kendo-react-buttons';
import { useAuthentication, useModal, useResolve, useSnackbar, useTranslate } from 'global/hooks';
import { getProjectSetting } from 'global/helpers';
import { Plaque, TextView } from 'components';
import { ExtendedCase } from 'containers/CaseList/constants/CaseList.interface';
import { InterfaceDictionary } from 'containers/Wizard/constants/Wizard.interface';
import { NoData } from 'common';
import ChangeState from './ChangeState/ChangeState';
import CloseCase from './CloseCase/CloseCase';
import { AddField, AddFieldsItemParams, Section } from 'global/types/AddFields.interface';
import styles from './CaseDetail.module.scss';

interface CaseDetailProps {
  bare?: boolean;
  caseDetail?: ExtendedCase;
  disable?: boolean;
  disableAddFields?: boolean;
  handleSaveFields?: (a: AddField[], s?: number, r?: number) => void;
  handleChangeState: (s: number, r?: number) => void;
  itemData?: AddFieldsItemParams;
  interfaceData?: InterfaceDictionary;
  sections?: Section[];
}

const CaseDetail: React.FC<CaseDetailProps> = ({
  bare,
  caseDetail,
  disable,
  disableAddFields,
  handleSaveFields,
  handleChangeState,
  interfaceData,
  itemData,
  sections,
}) => {
  const translate = useTranslate();
  const { CustomerURL } = useResolve();
  const { settings } = useAuthentication();
  const { openModal, closeModal } = useModal();
  const { addAlert } = useSnackbar();

  const handleOpenSurvey = (token: string) => {
    window.open(`${CustomerURL}/#/survey/${token}`, '_blank');
  };

  const alertPendingTask = () => {
    if (!!caseDetail?.hasPendingTask) {
      addAlert({
        msg: translate('COMPONENT.PENDINGTASKS'),
        severity: 'error',
      });
    }
  };

  const getCloseButton = () => {
    if (caseDetail?.canClosed) {
      return (
        <Button
          onClick={() => {
            if (!!caseDetail?.hasPendingTask) {
              return alertPendingTask();
            } else {
              openModal(
                <CloseCase
                  caseDetail={caseDetail}
                  disable={disable}
                  disableAddFields={disableAddFields}
                  handleSaveFields={handleSaveFields}
                  handleChange={handleChangeState}
                  handleClose={closeModal}
                  handleOpen={openModal}
                  itemData={itemData}
                  model={caseDetail?.modelId}
                  sections={sections}
                  state={caseDetail?.stateId}
                  type={caseDetail?.itemTypeId}
                />,
                translate('COMPONENT.CLOSE_CASE'),
                { height: 265, width: 250 }
              );
            }
          }}
          themeColor="primary"
          type="button"
        >
          {translate('COMPONENT.CLOSE_CASE')}
        </Button>
      );
    }
    if (
      getProjectSetting(settings, 'AllowClose', caseDetail.itemTypeId, caseDetail.projectId) ===
      'True'
    ) {
      return (
        <Button
          onClick={() => {
            if (!!caseDetail?.hasPendingTask) {
              return alertPendingTask();
            } else {
              openModal(
                <ChangeState
                  caseDetail={caseDetail}
                  sections={sections}
                  disable={disable}
                  disableAddFields={disableAddFields}
                  handleSaveFields={handleSaveFields}
                  handleChange={handleChangeState}
                  handleClose={closeModal}
                  handleOpen={openModal}
                  itemData={itemData}
                  model={caseDetail?.modelId}
                  type={caseDetail?.itemTypeId}
                />,
                translate('COMPONENT.CHANGE_STATUS'),
                { height: 215, width: 250 }
              );
            }
          }}
          themeColor="primary"
          type="button"
        >
          {translate('COMPONENT.CHANGE_STATUS')}
        </Button>
      );
    }
    return null;
  };
  return (
    <>
      {caseDetail ? (
        <div className={styles.details}>
          <Plaque info={caseDetail} interfaceConfig={interfaceData} />
          <p className={styles.subject}>{caseDetail.subject}</p>
          <TextView
            description={caseDetail.description}
            interfaceConfig={interfaceData}
            showExtend={!bare}
            solution={caseDetail.commentary}
          />
          <div className={styles.buttons}>
            {caseDetail?.hasSurvey && !bare && (
              <Button
                onClick={() => handleOpenSurvey(caseDetail.surveyToken)}
                themeColor="primary"
                type="button"
              >
                {translate('COMPONENT.PENDING_SURVEY')}
              </Button>
            )}
            {!caseDetail?.isClosed && !bare ? getCloseButton() : null}
          </div>
        </div>
      ) : (
        <NoData />
      )}
    </>
  );
};

CaseDetail.defaultProps = {
  bare: false,
  caseDetail: undefined,
  interfaceData: null,
};

CaseDetail.propTypes = {
  bare: PropTypes.bool,
  caseDetail: PropTypes.any,
  handleChangeState: PropTypes.func.isRequired,
  interfaceData: PropTypes.any,
};

export default CaseDetail;
