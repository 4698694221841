import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@progress/kendo-react-buttons';
import { useModal, useTranslate } from 'global/hooks';

import styles from './ButtonWrapper.module.scss';
import AdminPage from 'common/AdminPage/AdminPage';

interface ButtonWrapperProps {
  description: string;
  disabled?: boolean;
  id: string;
  label: string;
  url?: string;
}

const ButtonWrapper: React.FC<ButtonWrapperProps> = ({ description, disabled, id, label, url }) => {
  const { openModal } = useModal();
  const translate = useTranslate();

  const handleExternal = () => {
    window.open(url, '_blank');
  };

  const handleAdminPage = () => {
    openModal(
      <div className={styles.iframe}>
        <div className={styles.external}>
          <p className={styles.externalHint}>{`${translate('COMPONENT.CONTENT_NOT_LOADED')} `}</p>
          <span className={styles.link} onClick={handleExternal} role="presentation">
            {translate('COMPONENT.CLICK_HERE')}
          </span>
        </div>
        <AdminPage url={url} type="Client" />
      </div>,
      label,
      {
        width: 1320,
        height: 900,
      }
    );
  };

  return (
    <div
      className={styles.button}
      title={disabled ? translate('COMPONENT_BUTTON_DISABLED') : description}
    >
      <Button
        className={styles.buttonText}
        type="button"
        id={id}
        onClick={handleAdminPage}
        disabled={disabled}
      >
        {label}
      </Button>
    </div>
  );
};

ButtonWrapper.defaultProps = {
  description: null,
  disabled: false,
  url: null,
};

ButtonWrapper.propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
};

export default ButtonWrapper;
