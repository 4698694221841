import { useMutation, UseMutationResult } from 'react-query';
import axios from 'axios';
import { ResultResponse } from 'global/types/Case.interface';
import { SendEmail } from 'containers/KB/constants/Kb.interface';

const postEmail = async (emailParams: SendEmail) => {
  const formData = new FormData();

  formData.append('Body', emailParams.body);
  formData.append('ProjectId', emailParams?.projectId?.toString());
  formData.append('Subject', emailParams.subject);
  formData.append('Receiver', emailParams.receiver);
  formData.append('Type', emailParams?.type?.toString());
  formData.append('ItemId', emailParams?.itemId?.toString());
  formData.append('IncludeAttachments', String(emailParams.includeAttachments));

  const data = await axios.post<ResultResponse>('/mail/', formData);
  if (data.status !== 200) {
    throw Error('Error sending email');
  }
  return data?.data;
};

export default function useEmail(): UseMutationResult<ResultResponse, Error, SendEmail, Error> {
  return useMutation(postEmail);
}
