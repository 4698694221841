import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import _ from 'lodash';
import { Category } from 'containers/Wizard/constants/Wizard.interface';

const getCategories = async (serviceId: number, projectId: number, typeId: number) => {
  const data = await axios.get<Category[]>(
    `service/${serviceId}/categories/project/${projectId}/type/${typeId}`
  );

  if (data.status !== 200) {
    throw Error('Error obtaining categories of the case type');
  }

  const categoryFolderCount = _.groupBy(data.data, 'parentId');
  const transformedCategories = data?.data.map((category) => {
    category.folderCount = categoryFolderCount[category.id]
      ? categoryFolderCount[category.id].length
      : 0;
    return category;
  });
  return transformedCategories;
};

export default function useCategoryTypes(
  serviceId: number,
  projectId: number,
  typeId: number
): UseQueryResult<Category[], Error> {
  return useQuery(
    ['categories', serviceId, projectId, typeId],
    () => getCategories(serviceId, projectId, typeId),
    {
      enabled: !!(serviceId && projectId && typeId),
    }
  );
}
