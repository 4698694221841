import React, { createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import ErrorPage from 'routers/ErrorPage/ErrorPage';
import { useProjectSettings } from 'global/services';
import { useLocalStorage, useResolve } from 'global/hooks';
import { DefaultProjectSettings } from 'global/types/Resolve.interface';
import { BackDrop } from 'common';
import { useLocation } from 'react-router-dom';

export const BrandingContext = createContext<DefaultProjectSettings>({
  BannerImg: null,
  BgImg: null,
  ContrastColor: null,
  ExternalProviders: [],
  LDAP: null,
  LoginImg: null,
  LogoImg: null,
  PrimaryColor: null,
  Rgb: null,
});

interface BrandingProviderProps {
  children: React.ReactNode;
}

export const BrandingProvider: React.FC<BrandingProviderProps> = ({ children }) => {
  const { AdministratorURL } = useResolve();
  const { pathname } = useLocation();
  const [project] = useLocalStorage<string>(
    'usdk_project',
    pathname.includes('project') ? pathname.split('project')[1].substring(1) : ''
  );
  const { data, isLoading } = useProjectSettings(AdministratorURL, project);

  useEffect(() => {
    if (data?.PrimaryColor && data.Rgb) {
      document.documentElement.style.setProperty('--primary', data.PrimaryColor);
      document.documentElement.style.setProperty('--rgb', data.Rgb);
    }
  }, [data]);

  if (!AdministratorURL) {
    return <ErrorPage />;
  }

  if (isLoading) {
    return <BackDrop show />;
  }

  return <BrandingContext.Provider value={data}>{children}</BrandingContext.Provider>;
};

BrandingProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
