import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { TabsVotes, ItemsTabsVote } from 'global/types/System.interface';
import styles from './TabsVote.module.scss';

interface TabsProps {
  footer?: JSX.Element;
  initialTab?: string;
  selectCallback?: (selectedTab: string) => void;
  showMenu?: boolean;
  tabs: TabsVotes[];
}

const TabsVote: React.FC<TabsProps> = ({ footer, initialTab, selectCallback, showMenu, tabs }) => {
  const [activeTab, setActiveTab] = useState(initialTab || tabs[0]?.id);
  const sliderRef = useRef<HTMLUListElement>(null);

  const handleTabChange = (i: string) => {
    if (selectCallback) {
      selectCallback(i);
    }
    setActiveTab(i);
  };

  const scroll = (scrollOffset: number) => {
    sliderRef.current.scrollLeft += scrollOffset;
  };

  const itemRender = (itemsTabs: ItemsTabsVote) => (
    <div className={styles.itemlist}>
      <span>{itemsTabs.item.icon}</span>
      <p>{itemsTabs.item.title}</p>
    </div>
  );

  const displayedTabs = useMemo(() => {
    const newTabs = tabs.filter((value) => {
      return value.displayTab !== false;
    });
    return newTabs;
  }, [tabs]);

  useEffect(() => {
    const selectedTab = displayedTabs.find((item) => item.id === activeTab);
    if (!selectedTab) {
      setActiveTab(displayedTabs[0]?.id);
    }
  }, [activeTab, displayedTabs]);

  useEffect(() => {
    if (initialTab) {
      setActiveTab(initialTab);
    }
  }, [initialTab, setActiveTab]);

  const contentTab = () =>
    tabs.reduce((tabsArr, tab, index) => {
      if (tab.keepMounted || tab.id === activeTab) {
        tabsArr.push(
          <div
            key={`${tab.title}_${index + 1}`}
            className={`${styles.tabitem} ${
              activeTab === tab.id ? styles.active : styles.unactive
            }`}
          >
            {tab.content}
          </div>
        );
      }
      return tabsArr;
    }, []);

  return (
    <div className={styles.tabstrip}>
      <div className={styles.tabsmenu}>
        {tabs.length > 3 && (
          <Button type="button" icon="arrow-chevron-left" onClick={() => scroll(-200)} />
        )}
        <ul className={styles.tabmenu} ref={sliderRef}>
          {displayedTabs?.map((item, index) => (
            <li
              key={`${item.title}_${index + 1}`}
              className={` ${activeTab === item.id ? styles.active : ''}  
                  ${item.displayTab === false ? styles.hidden : ''} ${
                item.disabled ? styles.disabled : ''
              }`}
            >
              <button id={item.id} onClick={() => handleTabChange(item.id)} type="button">
                {item.icon}
                {item.title}
              </button>
            </li>
          ))}
        </ul>
        {tabs.length > 3 && (
          <Button type="button" icon="arrow-chevron-right" onClick={() => scroll(200)} />
        )}
        {showMenu && (
          <DropDownButton
            className={styles.menuList}
            itemRender={itemRender}
            icon="more-vertical"
            items={displayedTabs}
            onItemClick={(e) => handleTabChange(e.item.id)}
            popupSettings={{ popupAlign: { horizontal: 'center', vertical: 'top' } }}
          />
        )}
      </div>
      <div className={styles.tabcontent}>{contentTab()}</div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  );
};

TabsVote.defaultProps = {
  footer: null,
  initialTab: undefined,
  selectCallback: undefined,
  showMenu: true,
};

TabsVote.propTypes = {
  footer: PropTypes.element,
  initialTab: PropTypes.string,
  selectCallback: PropTypes.func,
  showMenu: PropTypes.bool,
  tabs: PropTypes.array.isRequired,
};

export default TabsVote;
