import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import { RadioGroup } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import {
  Service,
  Category,
  ItemTypes,
  WizardState,
  WizardActions,
} from 'containers/Wizard/constants/Wizard.interface';
import { TYPES } from 'containers/Wizard/constants/Wizard.constants';
import { useToggle, useTranslate } from 'global/hooks';
import { Skeleton } from 'common';
import { CloseSVG, SettingSVG } from 'common/icons';
import { useCategoryTypes } from 'containers/Wizard/services';
import styles from './Options.module.scss';

interface OptionsProps {
  state: WizardState;
  dispatch: React.Dispatch<WizardActions>;
  forwardedRef: React.MutableRefObject<HTMLDivElement>;
  services?: Service[];
  categories?: Category[];
  itemTypes?: ItemTypes[];
  catLoading: boolean;
  typeLoading: boolean;
}

const Options: React.FC<OptionsProps> = ({
  state,
  dispatch,
  forwardedRef,
  services,
  categories,
  itemTypes,
  catLoading,
  typeLoading,
}) => {
  const translate = useTranslate();
  const [open, toggle, close] = useToggle(false);
  const { data } = useCategoryTypes(state?.service?.id, state?.project?.id, state?.itemType);

  const types = itemTypes.map((el) => ({
    label: translate(`WIZARD.${TYPES[el.id]}`),
    value: el.id,
  }));

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggle();
  };

  const handleInsideClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleConfigService = (service: Service) => {
    dispatch({ type: 'CONFIG_SERVICE', payload: { service } });
  };

  const handleConfigCategory = (category: Category) => {
    dispatch({ type: 'CONFIG_CATEGORY', payload: { category } });
  };

  const handleConfigItemType = (itemTypeId: number) => {
    if (itemTypes?.length > 0) {
      const itemType = itemTypes.find((el) => el.id === itemTypeId);
      dispatch({ type: 'SET_ITEMTYPE', payload: { itemType } });
    }
  };

  const filteredCategories = useMemo(() => {
    const newCategory = data?.filter((category) => category.id === state?.category.id)[0];
    if (!newCategory?.modelActive && data) {
      return data[0];
    }
    return newCategory;
  }, [data, state]);

  return (
    <>
      <div className={styles.options} onClick={handleClick} role="presentation">
        <SettingSVG />
        <span>{translate('WIZARD.ADVANCED_CONFIGURATION')}</span>
      </div>
      <Popup
        show={open}
        offset={{ top: 40, left: undefined }}
        style={{ right: 75 }}
        appendTo={forwardedRef.current}
        popupAlign={{ horizontal: 'right', vertical: 'top' }}
      >
        <div className={styles.popup} onClick={handleInsideClick} role="presentation">
          <div className={styles.header}>
            <h5>{translate('WIZARD.CONFIGURE_CASE_OPTIONS')}</h5>
            <CloseSVG onClick={close} />
          </div>
          <div className={styles.optionsGroup}>
            <div className={styles.caseType}>
              <h6>{translate('WIZARD.CASE_TYPE')}</h6>
              {!typeLoading && !catLoading ? (
                <RadioGroup
                  data={types}
                  className={styles.radio}
                  value={state?.itemType}
                  onChange={(e) => handleConfigItemType(e.value)}
                />
              ) : (
                <Skeleton height={25} />
              )}
            </div>
            {services?.length > 0 && (
              <div className={styles.dropdown}>
                <Label editorId="changeService">{translate('WIZARD.SERVICE')}</Label>
                <DropDownList
                  data={services.filter((service) => !service.isFolder)}
                  id="changeService"
                  valid
                  disabled={false}
                  defaultValue={state?.service}
                  textField="name"
                  onChange={(e) => handleConfigService(e.value)}
                />
              </div>
            )}

            {!catLoading ? (
              <>
                {categories?.length > 0 && (
                  <div className={styles.dropdown}>
                    <Label editorId="changeCategory">{translate('WIZARD.CATEGORY')}</Label>
                    <DropDownList
                      data={data?.filter((category) => category.modelActive)}
                      id="changeCategory"
                      valid
                      disabled={false}
                      defaultValue={filteredCategories}
                      textField="name"
                      onChange={(e) => handleConfigCategory(e.value)}
                      value={filteredCategories}
                    />
                  </div>
                )}
              </>
            ) : (
              <Skeleton height={25} />
            )}
          </div>
        </div>
      </Popup>
    </>
  );
};

Options.defaultProps = {
  services: [],
  categories: [],
  itemTypes: [],
};

Options.propTypes = {
  state: PropTypes.any.isRequired,
  dispatch: PropTypes.func.isRequired,
  forwardedRef: PropTypes.any.isRequired,
  services: PropTypes.array,
  categories: PropTypes.array,
  itemTypes: PropTypes.array,
  catLoading: PropTypes.bool.isRequired,
  typeLoading: PropTypes.bool.isRequired,
};

export default Options;
