import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Label, Hint } from '@progress/kendo-react-labels';
import { Button } from '@progress/kendo-react-buttons';
import { State } from 'global/types/Case.interface';
import { useTranslate } from 'global/hooks';
import { useAddFields, useUserStates } from 'global/services';
import { AddField, AddFieldsItemParams, Section } from 'global/types/AddFields.interface';
import { ExtendedCase } from 'containers/CaseList/constants/CaseList.interface';
import AdditionalMandatory from 'components/CaseDetail/AdditionalMandatory/AdditionalMandatory';
import styles from './ChangeState.module.scss';

interface ChangeStateProps {
  caseDetail?: ExtendedCase;
  sections?: Section[];
  itemData?: AddFieldsItemParams;
  disable?: boolean;
  disableAddFields?: boolean;
  handleSaveFields?: (a: AddField[], s?: number, r?: number) => void;
  handleChange: (s: number) => void;
  handleClose: () => void;
  handleOpen: (c: React.ReactNode, t: string, s?: { height: number; width: number }) => void;
  model: number;
  type: number;
}

const ChangeState: React.FC<ChangeStateProps> = ({
  caseDetail,
  sections,
  itemData,
  disable,
  disableAddFields,
  handleSaveFields,
  handleChange,
  handleClose,
  handleOpen,
  model,
  type,
}) => {
  const translate = useTranslate();
  const { data, isLoading, isFetching } = useUserStates(model, type);
  const [status, setStatus] = useState<State>(null);
  const { data: addsData } = useAddFields(
    caseDetail?.categoryId,
    caseDetail?.itemType,
    caseDetail?.modelId,
    status?.id,
    caseDetail?.serviceId,
    caseDetail?.id
  );

  const handleClick = () => {
    if (!addsData?.addFields?.some((f) => f.mandatoryUser === true)) {
      handleClose();
      handleChange(status?.id);
    } else {
      handleClose();
      handleOpen(
        <AdditionalMandatory
          id="change-state-additional-mandatory"
          addFields={addsData?.addFields}
          caseDetail={caseDetail}
          sections={sections}
          itemData={itemData}
          status={status}
          disable={disable}
          disableAddFields={disableAddFields}
          handleSaveFields={handleSaveFields}
          handleClose={handleClose}
          handleOpen={handleOpen}
          handleChange={handleChange}
          translate={translate}
          loading={isLoading || isFetching}
        />,
        translate('COMPONENT.ADDITIONAL_FIELDS'),
        { height: 161, width: 365 }
      );
    }
  };

  return (
    <div className={styles.changeState}>
      <Label editorId="change-state-list" className={styles.label}>
        {translate('COMPONENT.STATUS')}
      </Label>
      <DropDownList
        id="change-state-list"
        value={status}
        data={data}
        textField="name"
        style={{ width: '100%' }}
        onChange={(e) => setStatus(e.value)}
        loading={isLoading || isFetching}
      />
      <Hint>{translate('COMPONENT.CHANGE_STATUS_HELP')}</Hint>
      <Button disabled={!status} onClick={handleClick} themeColor="primary" type="button">
        {translate('COMPONENT.SAVE')}
      </Button>
    </div>
  );
};

ChangeState.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  model: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
};

export default ChangeState;
