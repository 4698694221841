import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@progress/kendo-react-buttons';
import styles from './Keywords.module.scss';

interface KeywordsProps {
  words?: string[];
}

const Keywords: React.FC<KeywordsProps> = ({ words }) => {
  const sliderRef = useRef<HTMLDivElement>(null);

  const scroll = (scrollOffset: number) => {
    sliderRef.current.scrollLeft += scrollOffset;
  };
  return (
    <div className={styles.container}>
      <Button icon="arrow-chevron-left" onClick={() => scroll(-150)} aria-label="left" />
      <div className={styles.slider} ref={sliderRef}>
        {words.map((word, index) => (
          <div key={`${word}-${index + 1}`} className={styles.tag}>
            {word}
          </div>
        ))}
      </div>
      <Button icon="arrow-chevron-right" onClick={() => scroll(150)} aria-label="right" />
    </div>
  );
};

Keywords.defaultProps = {
  words: [],
};

Keywords.propTypes = {
  words: PropTypes.array,
};

export default Keywords;
