import { FilterState } from 'containers/CaseList/constants/CaseList.interface';

export enum ORDERFIELD {
  'OpenedDate',
  'IdbyProject',
}

export enum ORDER {
  'DESC',
  'ASC',
}

export enum REPOSITORY {
  'all',
  'opened',
  'closed',
}

export const initialState: FilterState = {
  exactSearch: false,
  filter: ['CUSTOMER', 'APPLICANT'],
  filterText: '',
  orderField: ORDERFIELD[0],
  order: ORDER[0],
  pageIndex: 0,
  repository: REPOSITORY[1],
  types: null,
};

export const LOCALES: { [key: string]: string } = {
  'all': 'CASELIST.ALL',
  'APPLICANT': 'CASELIST.APPLICANT',
  'CHANGES': 'CASELIST.CHANGE',
  'closed': 'CASELIST.CLOSED',
  'CUSTOMER': 'CASELIST.CUSTOMER',
  'INCIDENTS': 'CASELIST.INCIDENT',
  'opened': 'CASELIST.OPEN',
  'SERVICECALLS': 'CASELIST.SERVICE_REQUIREMENT',
};

export const columnsExport = [
  {
    key: 'IdbyProject',
    member: 'IdByProject',
    name: 'Code',
  },
  {
    key: 'ProjectId',
    member: 'ProjectName',
    name: 'ProjectName',
  },
  {
    key: 'StateId',
    member: 'StateName',
    name: 'State',
  },
  {
    key: 'ItemType',
    member: 'ItemType',
    name: 'Type',
  },
  {
    key: 'OpenedDate',
    member: 'OpenedDate',
    name: 'OpenedDate',
  },
  {
    key: 'Subject',
    member: 'Subject',
    name: 'Subject',
  },
  {
    key: 'ReasonId',
    member: 'ReasonName',
    name: 'Reason',
  },
  {
    key: 'CustomerId',
    member: 'CustomerName',
    name: 'CustomerName',
  },
  {
    key: 'CompanyId',
    member: 'CompanyName',
    name: 'Company',
  },
  {
    key: 'CiId',
    member: 'CiName',
    name: 'Ci',
  },
  {
    key: 'ResponsibleId',
    member: 'ResponsibleName',
    name: 'Responsible',
  },
  {
    key: 'GroupId',
    member: 'GroupName',
    name: 'GroupName',
  },
  {
    key: 'ServiceId',
    member: 'ServiceName',
    name: 'Service',
  },
  {
    key: 'CategoryId',
    member: 'CategoryName',
    name: 'Category',
  },
];
