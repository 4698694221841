import axios from 'axios';
import { UseQueryResult, useQuery } from 'react-query';
import { Response } from 'global/types/System.interface';
import { QuestionsInterface } from 'containers/Case/constants/Case.interface';

const getQuestions = async (riskId: number) => {
  const data = await axios.get<Response<QuestionsInterface>>(`risk/${riskId}/questions`);
  if (data.status !== 200) {
    throw Error('Error obtaining risk questions');
  }
  return data?.data?.content;
};

export default function useQuestions(riskId: number): UseQueryResult<QuestionsInterface[], Error> {
  return useQuery(['riskquestions', riskId], () => getQuestions(riskId), {
    enabled: !!riskId,
  });
}
