import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Label, Error } from '@progress/kendo-react-labels';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { useTranslate } from 'global/hooks';
import { defaultValidator } from 'global/validators';
import { FormValidator } from 'containers/Wizard/elements';
import styles from './CheckboxWrapper.module.scss';

interface CheckboxWrapperProps {
  id: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
}

const CheckboxWrapper: React.FC<CheckboxWrapperProps> = ({ disabled, id, label, required }) => {
  const translate = useTranslate();
  const [, meta, helpers] = useField({
    name: id,
    validate: required ? defaultValidator : undefined,
  });
  const error = meta?.touched && typeof meta?.error === 'string';

  const handleBlur = () => {
    if (meta.value && required) {
      helpers.setError(undefined);
    }
  };

  const handleChange = (e: CheckboxChangeEvent) => {
    helpers.setTouched(true);
    helpers.setValue(e.value);
    if (e.value) {
      helpers.setError(undefined);
    }
  };

  return (
    <div className={`${styles.checkboxWrap} ${disabled ? styles.disabled : ''}`}>
      <div title={label} className={styles.textLabel}>
        <Label editorId={id} editorValid={!error} editorDisabled={disabled}>
          {`${label}${required ? '(*)' : ''}`}
        </Label>
      </div>
      <Checkbox
        className={`${styles.checkbox}  ${!!error ? styles.error : ''}`}
        defaultValue={meta.value ?? false}
        disabled={disabled}
        id={id}
        name={id}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

CheckboxWrapper.defaultProps = {
  disabled: false,
  required: false,
};

CheckboxWrapper.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default CheckboxWrapper;
