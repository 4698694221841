import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import axios from 'axios';
import { SendNote, NoteType } from 'containers/CaseList/constants/CaseList.interface';
import { User } from 'global/types/Resolve.interface';

const postNote = async (sendNote: SendNote) => {
  const data = await axios.post<boolean>(`/item/${sendNote.caseId}/${sendNote.caseType}/note`, {
    description: sendNote.description,
    visible: true,
  });
  if (data.status !== 200) {
    throw Error('Error adding note');
  }
  return data?.data;
};

export default function useAddNote(
  user: User,
  caseId: number
): UseMutationResult<boolean, Error, SendNote, unknown> {
  const queryClient = useQueryClient();
  return useMutation(postNote, {
    onMutate: async (noteData) => {
      if (caseId !== -1) {
        await queryClient.cancelQueries(['casenotes', caseId]);
        const oldNotes: NoteType[] = queryClient.getQueryData(['casenotes', caseId]);
        const unixTime = new Date().getTime();
        const newNote: NoteType = {
          actionId: 9,
          actionName: 'Nota',
          authorId: user.id,
          authorName: user.name,
          created: unixTime,
          description: noteData.description,
          descriptionNoHtml: noteData.description,
          detail: null,
          itemId: -1,
          reviewCode: null,
          visible: true,
        };
        const updatedNotes = [newNote, ...oldNotes];
        queryClient.setQueryData(['casenotes', caseId], updatedNotes);
        return () => queryClient.setQueryData(['casenotes', caseId], oldNotes);
      }
    },
    onError: (error, updateNote, rollback: unknown) => {
      if (typeof rollback === 'function') {
        rollback();
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['casenotes', caseId]);
    },
  });
}
