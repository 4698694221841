import React from 'react';
import { Skeleton } from 'common';
import styles from './CaseFormSkeleton.module.scss';

const CaseFormSkeleton: React.FC = () => (
  <div className={styles.skeletonContainer}>
    <div className={styles.case}>
      <div className={styles.field}>
        <Skeleton />
        <Skeleton />
      </div>
      <div className={styles.field}>
        <Skeleton />
        <Skeleton />
      </div>
      <div className={styles.field}>
        <Skeleton />
        <Skeleton />
      </div>
      <Skeleton />
      <Skeleton />
    </div>
  </div>
);

export default CaseFormSkeleton;
