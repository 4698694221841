import React from 'react';
import PropTypes from 'prop-types';
import { useField, FieldMetaProps } from 'formik';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { Label, Error, Hint } from '@progress/kendo-react-labels';
import { useTranslate } from 'global/hooks';
import { FormValidator } from 'containers/Wizard/elements';
import styles from './ToggleWrapper.module.scss';

interface ToggleWrapperProps {
  id: string;
  label: string;
  meta: FieldMetaProps<unknown>;
  firstChoice: string;
  secondChoice: string;
  disabled?: boolean;
  required?: boolean;
  hint?: string;
}

const ToggleWrapper: React.FC<ToggleWrapperProps> = ({
  id,
  label,
  meta,
  firstChoice,
  secondChoice,
  disabled,
  required,
  hint,
}) => {
  const translate = useTranslate();
  const [, , helper] = useField(id);

  const setValue = (value: boolean) => {
    helper.setValue(value);
  };
  const error = meta?.touched && typeof meta?.error === 'string';
  return (
    <div className={styles.toggle}>
      <Label editorId={id} editorValid={!error} editorDisabled={disabled}>
        {`${label}${required ? '(*)' : ''}`}
      </Label>
      {!!hint && <Hint className={styles.hint}>{hint}</Hint>}
      <div className={styles.buttonGroup}>
        <ButtonGroup>
          <Button
            togglable
            className={styles.left}
            disabled={disabled}
            onClick={() => setValue(true)}
            selected={meta?.value === true}
            type="button"
          >
            {firstChoice}
          </Button>
          <Button
            togglable
            className={styles.right}
            disabled={disabled}
            onClick={() => setValue(false)}
            selected={meta?.value === false}
            type="button"
          >
            {secondChoice}
          </Button>
        </ButtonGroup>
      </div>
      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

ToggleWrapper.defaultProps = {
  disabled: false,
  required: false,
  hint: null,
};

ToggleWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.any.isRequired,
  firstChoice: PropTypes.string.isRequired,
  secondChoice: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  hint: PropTypes.string,
};

export default ToggleWrapper;
