import React from 'react';
import PropTypes from 'prop-types';
import styles from './Instruction.module.scss';

interface InstructionProps {
  number: string;
  text: string;
}

const Instruction: React.FC<InstructionProps> = React.memo(({ number, text }) => (
  <div className={styles.instruction}>
    <div className={styles.numberContainer}>
      <span className={styles.number}>{number}</span>
    </div>
    <span className={styles.text}>{text}</span>
  </div>
));

Instruction.propTypes = {
  number: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Instruction;
