import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useLocalization } from '@progress/kendo-react-intl';
import { Error, Label } from '@progress/kendo-react-labels';
import { Search } from 'containers/Case/constants/Case.interface';
import { useField } from 'formik';
import { useRisk, useRiskList } from 'global/services';
import { useModal, useTranslate } from 'global/hooks';
import { EvaluateSVG } from 'common/icons';
import { Questions } from 'components';
import { dropdownValidator } from 'global/validators';
import styles from './Risk.module.scss';

type RiskProps = {
  disabled?: boolean;
  itemId?: number;
  id: string;
  label: string;
  modelId: number;
  required?: boolean;
  stateId?: number;
  riskCase?: [number, string];
};

const Risk: React.FC<RiskProps> = ({
  disabled,
  itemId,
  label,
  modelId,
  required,
  stateId,
  riskCase,
}) => {
  const translate = useTranslate();
  const { language } = useLocalization();
  const { openModal, closeModal } = useModal();
  const { data: risk, isLoading: riskLoading, isFetching } = useRisk(modelId, stateId);
  const { data, isLoading, refetch } = useRiskList(language);
  const [field, meta, helper] = useField({
    name: 'risk',
    validate: required || risk?.isActive ? dropdownValidator : null,
  });

  const error = !meta?.touched && typeof meta?.error === 'string';

  const handleChange = (value: Search) => {
    if (value.id >= 0) {
      helper.setValue(value);
      helper.setError(null);
    } else if (!value.id) {
      helper.setValue(null);
    }
  };

  const handleQuestions = () => {
    openModal(
      <Questions
        close={closeModal}
        handleChange={handleChange}
        itemId={itemId}
        isLoading={riskLoading}
        modelId={modelId}
        riskId={risk.id}
        stateId={stateId}
      />,
      translate('WIZARD.EVALUATE_RISK_TITLE'),
      {
        width: 500,
        height: 500,
      }
    );
  };

  useEffect(() => {
    if (meta?.value === undefined && riskCase?.[0] !== null && riskCase?.[0] !== undefined) {
      helper.setValue({ id: riskCase?.[0], name: riskCase?.[1] });
    }
  }, [helper, meta?.value, riskCase]);

  return (
    <div className={`${styles.dropdownWrap} ${disabled ? styles.disabled : ''}`}>
      <div className={styles.titlewrapper}>
        <span>{translate('WIZARD.RISK_TITLE')}</span>
      </div>
      <div title={label} className={styles.labelwrapper}>
        <Label id="risk" editorId="risk" editorValid={!error} editorDisabled={disabled}>
          {`${label} ${required ? '(*)' : ''}`}
        </Label>
      </div>
      <div className={styles.inputSection}>
        <DropDownList
          className={`${styles.dropdown} ${styles.question} ${!!error ? styles.error : ''}`}
          data={data ?? []}
          defaultItem={{ name: translate('COMPONENT.SELECT'), id: 0 }}
          disabled={disabled || risk?.isActive || isFetching}
          id="risk"
          valid={!error}
          loading={isLoading}
          onChange={(e) => handleChange(e.target.value)}
          onOpen={() => refetch()}
          popupSettings={{ height: 250 }}
          textField="name"
          {...field}
          value={meta?.value}
        />
        <span
          className={` ${!risk?.isActive || isFetching ? styles.disabled : ''} ${
            styles.question__icon
          }`}
          role="presentation"
          onClick={handleQuestions}
        >
          <EvaluateSVG />
        </span>
      </div>
      {error && <Error>{translate(meta?.error)}</Error>}
    </div>
  );
};

Risk.defaultProps = {
  disabled: false,
  itemId: null,
  modelId: null,
  required: false,
  stateId: null,
};

Risk.propTypes = {
  disabled: PropTypes.bool,
  itemId: PropTypes.number,
  label: PropTypes.string.isRequired,
  modelId: PropTypes.number,
  required: PropTypes.bool,
  stateId: PropTypes.number,
};

export default Risk;
