import React from 'react';
import PropTypes from 'prop-types';
import styles from './Item.module.scss';

interface ItemProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
  selected?: boolean;
}

const Item: React.FC<ItemProps> = ({ left, right, selected }) => (
  <div className={`${styles.item} ${selected && styles.selectedItem}`}>
    <div className={styles.left}>{left}</div>
    <div className={styles.right}>{right}</div>
    {selected && <div className={styles.selected} />}
  </div>
);

Item.defaultProps = {
  left: null,
  right: null,
  selected: false,
};

Item.propTypes = {
  left: PropTypes.element,
  right: PropTypes.element,
  selected: PropTypes.bool,
};

export default Item;
