import React from 'react';
import PropTypes from 'prop-types';
import { FieldMetaProps } from 'formik';
import { Label, Error } from '@progress/kendo-react-labels';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { useTranslate } from 'global/hooks';
import { FormValidator } from 'containers/Wizard/elements';
import styles from './NumericWrapper.module.scss';

interface NumericWrapperProps {
  disabled?: boolean;
  format?: string;
  id: string;
  label: string;
  meta: FieldMetaProps<unknown>;
  min?: number;
  required?: boolean;
}

const NumericWrapper: React.FC<NumericWrapperProps> = ({
  disabled,
  format,
  id,
  label,
  meta,
  min,
  required,
  ...others
}) => {
  const translate = useTranslate();
  const error = meta?.touched && typeof meta?.error === 'string';
  return (
    <div className={`${styles.numericWrap} ${disabled ? styles.disabled : ''}`}>
      <div title={label} className={styles.textLabel}>
        <Label editorId={id} editorValid={!error} editorDisabled={disabled}>
          {`${label}${required ? '(*)' : ''}`}
        </Label>
      </div>
      <NumericTextBox
        className={`${styles.numeric} ${!!error ? styles.error : ''}`}
        disabled={disabled}
        format={format}
        id={id}
        min={min}
        spinners={false}
        {...others}
      />
      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

NumericWrapper.defaultProps = {
  disabled: false,
  format: undefined,
  min: undefined,
  required: false,
};

NumericWrapper.propTypes = {
  disabled: PropTypes.bool,
  format: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.any.isRequired,
  min: PropTypes.number,
  required: PropTypes.bool,
};

export default NumericWrapper;
