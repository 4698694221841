import { StepType, WizardState, ItemTypes } from 'containers/Wizard/constants/Wizard.interface';

export const stepperItems: StepType[] = [
  {
    label: 'WIZARD.PROJECT',
    icon: 'k-i-config',
    disabled: false,
    isValid: false,
    secondary: '',
  },
  {
    label: 'WIZARD.SERVICE',
    icon: 'k-i-cart',
    disabled: true,
    isValid: false,
    secondary: '',
  },
  {
    label: 'WIZARD.CATEGORY',
    icon: 'k-i-categorize',
    disabled: true,
    isValid: false,
    secondary: '',
  },
  {
    label: 'WIZARD.CASE',
    icon: 'k-i-track-changes-enable',
    disabled: true,
    isValid: false,
    secondary: null,
  },
];

export const initialState: WizardState = {
  step: 0,
  stepperItems,
  project: null,
  service: null,
  serviceFolder: null,
  category: null,
  toArticle: false,
  searchText: '',
  showHelper: false,
  itemType: null,
};

export const validateDirect = (token: string[]): boolean => !!(token[1] && token[2]);

export const getSelectedType = (itemTypeId: number, types: ItemTypes[]): ItemTypes => {
  if (itemTypeId && types?.length > 0) {
    const currentTypeData = types.find((type) => type.id === itemTypeId);
    return currentTypeData;
  }
  return null;
};

export enum ICONS {
  'k-i-myspace',
  'k-i-cart',
  'k-i-connector',
  'k-i-track-changes-accept',
}

export enum TYPES {
  'INCIDENT' = 1,
  'PROBLEM' = 2,
  'CHANGE' = 3,
  'SERVICE_REQUIREMENT' = 4,
}
