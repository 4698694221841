import React from 'react';
import PropTypes from 'prop-types';
import { FileType } from 'global/types/Case.interface';
import { useTranslate } from 'global/hooks';
import { FileList } from 'components';
import { NoData } from 'common';
import styles from './FilesView.module.scss';

interface FilesViewProps {
  files: FileType[];
  handleFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteFile: (f: FileType) => void;
  disable?: boolean;
}

const FilesView: React.FC<FilesViewProps> = ({ files, handleFile, handleDeleteFile, disable }) => {
  const translate = useTranslate();
  return (
    <div className={styles.view}>
      {!disable && (
        <div className={styles.actions}>
          <div className={styles.add}>
            <span className="k-i-plus-circle k-icon" />
            <span className={styles.addText}>{translate('COMPONENT.ATTACH_FILE')}</span>
            <input multiple type="file" onChange={handleFile} />
          </div>
        </div>
      )}
      {files?.length > 0 ? (
        <div className={styles.list}>
          <FileList
            files={files}
            handleDeleteFile={handleDeleteFile}
            disable={disable}
            isTempFile={false}
          />
        </div>
      ) : (
        <NoData />
      )}
    </div>
  );
};

FilesView.defaultProps = {
  files: undefined,
  disable: false,
};

FilesView.propTypes = {
  files: PropTypes.array,
  handleFile: PropTypes.func.isRequired,
  handleDeleteFile: PropTypes.func.isRequired,
  disable: PropTypes.bool,
};

export default FilesView;
