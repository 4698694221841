import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { SendFile, DeleteFile, FileType, SaveCase } from 'global/types/Case.interface';
import { AddField } from 'global/types/AddFields.interface';
import { useAuthentication, useSnackbar, useTranslate } from 'global/hooks';
import { useItemFiles, useAddFields, useAttachFile, useDeleteFile } from 'global/services';
import { useAddNote, useCaseNotes, useSaveCase, useCaseDetail } from 'containers/CaseList/services';
import { SendNote } from 'containers/CaseList/constants/CaseList.interface';
import { CaseDetail, NoteList, FilesView, AddFieldsForm } from 'components';
import { CaseSkeleton, ListSkeleton } from 'skeletons';
import { BackDrop, Tabs } from 'common';
import { useInterface } from 'containers/Wizard/services';
import { CaseFormikValues } from 'containers/Wizard/constants/Wizard.interface';
import styles from './Case.module.scss';

interface CaseProps {
  caseId: number;
  loading: boolean;
  bare?: boolean;
  onSaveAction?: () => void;
}

const Case: React.FC<CaseProps> = ({ caseId, loading, bare, onSaveAction }) => {
  const translate = useTranslate();
  const { language } = useLocalization();
  const { fileMax, user } = useAuthentication();
  const { addAlert } = useSnackbar();
  const [selectedTab, setSelectedTab] = useState(0);
  const {
    data: caseData,
    isLoading: caseLoading,
    isFetching: caseFetching,
    refetch: caseFetch,
  } = useCaseDetail(caseId, language);
  const {
    data: notesData,
    isLoading: notesLoading,
    isFetching: notesFetching,
    refetch: notesFetch,
  } = useCaseNotes(caseId);
  const {
    data: filesData,
    isLoading: filesLoading,
    isFetching: filesFetching,
    refetch: filesFetch,
  } = useItemFiles(caseId, caseData?.itemType, 0);
  const {
    data: addsData,
    isLoading: addsLoading,
    isFetching: addsFetching,
    refetch: addsFetch,
  } = useAddFields(
    caseData?.categoryId,
    caseData?.itemType,
    caseData?.modelId,
    caseData?.stateId,
    caseData?.serviceId,
    caseData?.id
  );
  const { mutateAsync: noteMutate } = useAddNote(user, caseData?.id);
  const { mutateAsync: attachMutate, isLoading: attachLoading } = useAttachFile();
  const { mutateAsync: deleteMutate } = useDeleteFile({
    id: caseData?.id,
    itemTypeId: caseData?.itemTypeId,
  });
  const { mutate: saveMutate, isLoading: updateFieldsLoading } = useSaveCase();
  const {
    data: interfaceData,
    isLoading: interLoad,
    refetch: interfaceFetch,
  } = useInterface(
    caseData?.itemTypeId,
    caseData?.stateId,
    caseData?.modelId,
    caseData?.projectId,
    language
  );

  useEffect(() => {
    if (caseData) {
      switch (selectedTab) {
        case 0:
          interfaceFetch();
          break;
        case 1:
          addsFetch();
          break;
        case 2:
          notesFetch();
          break;
        case 3:
          filesFetch();
          break;
        default:
          break;
      }
    }
  }, [selectedTab, addsFetch, notesFetch, filesFetch, caseData, interfaceFetch]);

  const handleNote = async (noteText: string) => {
    const sendNote: SendNote = {
      caseId: caseData.id,
      caseType: caseData.itemType,
      description: noteText,
    };
    await noteMutate(sendNote);
  };

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileSize = Array.from(e.target.files).some((file) => file.size > fileMax);
    if (!fileSize) {
      const sendFile: SendFile = {
        files: e.target.files,
        caseData: {
          id: caseData.id,
          itemTypeId: caseData.itemTypeId,
        },
      };
      await attachMutate(sendFile, {
        onSuccess: () => {
          filesFetch();
        },
        onError: (err) => {
          addAlert({ msg: translate(`CASELIST.${err.message}`), severity: 'error' });
        },
      });
    } else {
      addAlert({
        msg: `${
          e.target.files.length === 1
            ? `${translate('CASELIST.FILE_SIZE_ERROR_SINGULAR')} ${fileMax / 1000000}MB`
            : `${translate('CASELIST.FILE_SIZE_ERROR_PLURAL')} ${fileMax / 1000000}MB`
        }`,
        severity: 'error',
      });
    }
    e.target.value = null;
  };

  const handleDeleteFile = async (file: FileType) => {
    const deleteFile: DeleteFile = {
      file,
      caseData: {
        id: caseData.id,
        itemTypeId: caseData.itemTypeId,
      },
    };
    await deleteMutate(deleteFile);
  };

  const handleSaveFields = (
    addFields: AddField[],
    stateId: number,
    reasonId?: number,
    value?: CaseFormikValues
  ) => {
    let riskId;
    if (typeof value.risk === 'object' && 'id' in value.risk) {
      riskId = value.risk.id;
    }
    const saveCase: SaveCase = {
      additionals: addFields,
      caseId: caseData.id,
      caseType: caseData.itemTypeId,
      dataElements: [
        { field: 'ItemVersion', value: caseData.itemVersion },
        { field: 'CategoryId', value: caseData.categoryId },
        { field: 'ItemTypeId', value: caseData.itemTypeId },
        { field: 'ModelId', value: caseData.modelId },
        { field: 'ServiceId', value: caseData.serviceId },
        { field: 'StateId', value: !!stateId ? stateId : caseData.stateId },
        { field: 'Subject', value: caseData.subject },
        { field: 'Description', value: caseData.description },
        { field: 'RiskId', value: riskId ?? '' },
      ],
    };

    if (reasonId) {
      saveCase.dataElements.push({ field: 'ReasonId', value: reasonId });
    }

    saveMutate(saveCase, {
      onSuccess: () => {
        addAlert({ msg: translate('COMPONENT.SAVE_FIELDS_SUCCESS'), severity: 'success' });
        caseFetch();
      },
      onError: (err) => {
        addAlert({ msg: translate(`COMPONENT.${err.message}`), severity: 'error' });
      },
    });
  };

  const handleChangeState = (stateId: number, reasonId?: number) => {
    const saveCase: SaveCase = {
      additionals: addsData.addFields,
      caseId: caseData.id,
      caseType: caseData.itemTypeId,
      dataElements: [
        { field: 'ItemVersion', value: caseData.itemVersion },
        { field: 'CategoryId', value: caseData.categoryId },
        { field: 'ItemTypeId', value: caseData.itemTypeId },
        { field: 'ModelId', value: caseData.modelId },
        { field: 'StateId', value: stateId },
      ],
    };

    if (reasonId) {
      saveCase.dataElements.push({ field: 'ReasonId', value: reasonId });
    }

    saveMutate(saveCase, {
      onSuccess: () => {
        addAlert({ msg: translate('COMPONENT.SAVE_FIELDS_SUCCESS'), severity: 'success' });
        onSaveAction();
      },
      onError: (err) => {
        addAlert({ msg: translate(`COMPONENT.${err.message}`), severity: 'error' });
      },
    });
  };
  const tabs = [
    {
      disabled: false,
      title: translate('COMPONENT.CASE_DETAIL'),
      content: !(caseLoading || loading || interLoad) ? (
        <CaseDetail
          bare={bare}
          caseDetail={caseData}
          disableAddFields={
            caseFetching || caseLoading || !addsData || addsData?.addFields?.length === 0
          }
          disable={caseData?.isClosed}
          handleSaveFields={handleSaveFields}
          handleChangeState={handleChangeState}
          itemData={{
            customerId: caseData?.customerId,
            id: caseData?.id,
            itemType: caseData?.itemTypeId,
          }}
          interfaceData={interfaceData}
          sections={addsData?.sections}
        />
      ) : (
        <CaseSkeleton />
      ),
    },
    {
      disabled: caseLoading || loading,
      title: translate('COMPONENT.ADDITIONAL_DATA'),
      content: (
        <div className={styles.addFieldsView}>
          <h3>{translate('COMPONENT.COMPLETE_INFO')}</h3>
          <div className={styles.addFieldsBox}>
            <AddFieldsForm
              withSave
              addFields={addsData?.addFields}
              sections={addsData?.sections}
              itemData={{
                customerId: caseData?.customerId,
                id: caseData?.id,
                itemType: caseData?.itemTypeId,
              }}
              disable={caseData?.isClosed}
              handleSaveFields={handleSaveFields}
              loading={addsLoading || addsFetching || loading}
              disabledRisk={!interfaceData?.riskName?.enable}
              itemId={caseData?.id}
              label={
                interfaceData?.riskName?.label
                  ? interfaceData?.riskName?.label
                  : translate('WIZARD.RISK')
              }
              required={interfaceData?.riskName?.mandatory}
              modelId={caseData?.modelId}
              stateId={caseData?.stateId}
              interfaceData={interfaceData}
              riskCase={[caseData?.riskId, caseData?.riskName]}
            >
              {!caseData?.isClosed ? (
                <div className={styles.button}>
                  <Button
                    disabled={
                      caseFetching || caseLoading || !addsData || addsData?.addFields?.length === 0
                    }
                    type="submit"
                    themeColor="primary"
                  >
                    {translate('COMPONENT.SAVE')}
                  </Button>
                </div>
              ) : null}
            </AddFieldsForm>
          </div>
        </div>
      ),
    },
    {
      disabled: caseLoading || loading,
      title: translate('COMPONENT.NOTES'),
      content:
        notesLoading || notesFetching ? (
          <ListSkeleton count={7} height={100} />
        ) : (
          <NoteList noteList={notesData} handleNote={handleNote} disable={caseData?.isClosed} />
        ),
    },
    {
      disabled: caseLoading || loading,
      title: translate('COMPONENT.FILES'),
      content:
        filesLoading || filesFetching || attachLoading ? (
          <ListSkeleton count={7} height={100} />
        ) : (
          <FilesView
            files={filesData}
            handleFile={handleFile}
            handleDeleteFile={handleDeleteFile}
            disable={caseData?.isClosed}
          />
        ),
    },
  ];

  return (
    <>
      <div className={styles.case} data-testid="case">
        <Tabs tabs={tabs} returnTabIndex={(t) => setSelectedTab(t)} />
      </div>
      {updateFieldsLoading && <BackDrop show />}
    </>
  );
};

Case.defaultProps = {
  caseId: null,
  bare: false,
  onSaveAction: null,
};

Case.propTypes = {
  caseId: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  bare: PropTypes.bool,
  onSaveAction: PropTypes.func,
};

export default Case;
