import { useCallback } from 'react';
import { useLocalization } from '@progress/kendo-react-intl';

const useTranslate = (): ((key: string, defaultMessage?: string) => string) => {
  const localizationService = useLocalization();
  const translate = useCallback(
    (key: string, defaultMessage?: string) =>
      localizationService.toLanguageString(key, defaultMessage || 'NO_TRANSLATION'),
    [localizationService]
  );

  return translate;
};

export default useTranslate;
