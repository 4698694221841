import React from 'react';
import { Skeleton } from 'common';
import styles from './CaseSkeleton.module.scss';

const CaseSkeleton: React.FC = () => (
  <div className={styles.skeletonContainer}>
    <div className={styles.case}>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </div>
  </div>
);

export default CaseSkeleton;
