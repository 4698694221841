import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { Service } from 'containers/Wizard/constants/Wizard.interface';
import { Response, ListArticle } from 'global/types/System.interface';

const getArticleList = async (
  articleFolderId: number,
  projectId: number,
  validateArticleDisplay = false
) => {
  const data = await axios.post<Response<ListArticle>>('/article/list', {
    categories: [{ id: articleFolderId }],
    isPublic: true,
    pageIndex: 0,
    pageSize: 100,
    projects: [{ id: projectId }],
    search: '',
    validateArticleDisplay,
  });

  if (data.status !== 200) {
    throw Error('Error obtaining article list');
  }

  let services: Service[] = [];
  if (data?.data?.content) {
    services = data.data.content.map((article) => {
      const transformedArticle: Service = {
        amountCategories: null,
        description: article.description,
        hierarchy: null,
        id: article.id,
        image: null,
        imageId: null,
        impactName: null,
        isConfidential: null,
        isFolder: false,
        name: article.title,
        parentId: articleFolderId,
        serviceCount: null,
        isArticle: true,
        content: article.content,
        modifiedDate: article.modifiedDate,
        responsibleId: article.responsibleId,
        ratingTotal: article.ratingTotal,
        title: article.title,
        keyword: article.keyword,
      };
      return transformedArticle;
    });
  }
  return services;
};

export default function useArticleList(
  articleFolderId: number,
  projectId: number,
  validateArticleDisplay = false
): UseQueryResult<Service[], Error> {
  return useQuery(
    ['articles', articleFolderId, projectId],
    () => getArticleList(articleFolderId, projectId, validateArticleDisplay),
    { enabled: false }
  );
}
