import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocalization } from '@progress/kendo-react-intl';
import DOMPurify from 'dompurify';
import { useAuthentication, useModal, useTranslate } from 'global/hooks';
import { tzDictionary } from 'global/utils';
import { NoteType } from 'containers/CaseList/constants/CaseList.interface';
import { ExtendSVG, NoteSVG } from 'common/icons';
import styles from './Note.module.scss';

interface NoteProps {
  note: NoteType;
  index: number;
}

const MAX_NOTE_HEIGHT = 65;

const Note: React.FC<NoteProps> = ({ note, index }) => {
  const translate = useTranslate();
  const { user } = useAuthentication();
  const { language } = useLocalization();
  const { openModal } = useModal();
  const [height, setHeight] = useState(0);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const element = document.getElementById(`${note.created}-${index}`);
    setHeight(element.offsetHeight);
  }, [note.created, index]);

  const handleExpand = () => {
    setExpanded((prevState) => !prevState);
  };

  const sanitizedText = DOMPurify.sanitize(note.description, { ADD_ATTR: ['target'] });

  return (
    <div className={styles.note}>
      <div className={styles.noteIcon}>
        <NoteSVG />
      </div>
      <div className={styles.main}>
        <div className={styles.details}>
          <span className={styles.author}>{note.authorName}</span>
          <span className={styles.date}>
            {new Date(note.created).toLocaleString(language, {
              timeZone: tzDictionary[user.timeZone],
            })}
          </span>
        </div>
        <div
          className={`${styles.body} ${expanded && styles.expanded}`}
          dangerouslySetInnerHTML={{
            __html: sanitizedText,
          }}
          id={`${note.created}-${index}`}
        />
        {height >= MAX_NOTE_HEIGHT ? (
          <div className={styles.viewMore} onClick={handleExpand} role="presentation">
            <span>{expanded ? translate('COMPONENT.HIDE') : translate('COMPONENT.SEE_MORE')}</span>
          </div>
        ) : null}
      </div>
      <div
        className={styles.expand}
        onClick={() =>
          openModal(
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(note.description),
              }}
            />,
            translate('COMPONENT.NOTE')
          )
        }
        role="presentation"
      >
        <ExtendSVG />
      </div>
    </div>
  );
};

Note.propTypes = {
  note: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
};

export default Note;
