import React from 'react';
import PropTypes from 'prop-types';
import { FieldMetaProps } from 'formik';
import { Label, Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { useTranslate } from 'global/hooks';
import { FormValidator } from 'containers/Wizard/elements';
import styles from './LinkWrapper.module.scss';

interface LinkWrapperProps {
  id: string;
  label: string;
  disabled?: boolean;
  meta: FieldMetaProps<string>;
  required?: boolean;
  value?: unknown;
}

const LinkWrapper: React.FC<LinkWrapperProps> = ({
  id,
  label,
  disabled,
  meta,
  required,
  value,
  ...others
}) => {
  const translate = useTranslate();
  const error = meta?.touched && typeof meta?.error === 'string';
  const handleExternal = () => {
    if (meta?.value) {
      if (meta.value.includes('http://') || meta.value.includes('https://')) {
        window.open(meta.value, '_blank');
      } else {
        window.open(`http://${meta.value}`, '_blank');
      }
    }
  };

  return (
    <div className={`${styles.linkWrap} ${disabled ? styles.disabled : ''}`}>
      <div title={label} className={styles.textLabel}>
        <Label editorId={id} editorValid={!error} editorDisabled={disabled}>
          {`${label}${required ? '(*)' : ''}`}
        </Label>
      </div>
      <div className={styles.link}>
        <Input
          id={id}
          valid={!error}
          disabled={disabled}
          className={styles.linkInput}
          value={(value as string) ?? ''}
          title={(value as string) ?? ''}
          {...others}
        />
        <div className={styles.linkIcon} onClick={handleExternal} role="presentation">
          <span className="k-icon k-i-hyperlink-globe" />
        </div>
      </div>
      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

LinkWrapper.defaultProps = {
  disabled: false,
  required: false,
  value: null,
};

LinkWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  meta: PropTypes.any.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
};

export default LinkWrapper;
