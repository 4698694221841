import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import axios from 'axios';
import { KbArticle, SendRating } from 'containers/KB/constants/Kb.interface';

const postRating = async (sendRating: SendRating) => {
  const data = await axios.post<boolean>('/article/rate', {
    id: sendRating.article.id,
    comment: sendRating.comment,
    rate: sendRating.rate,
  });
  if (data.status !== 200) {
    throw Error('Error rating article');
  }
  return data?.data;
};

export default function useRating(
  articleId: number
): UseMutationResult<boolean, SendRating, SendRating, unknown> {
  const queryCache = useQueryClient();
  return useMutation(postRating, {
    onMutate: async (RatingParams) => {
      await queryCache.cancelQueries(['article', RatingParams.article.id]);
      const oldArticle: KbArticle = queryCache.getQueryData(['article', RatingParams.article.id]);
      const updatedArticle = { ...oldArticle, isRatedByUser: true };
      queryCache.setQueryData(['article', RatingParams.article.id], updatedArticle);
      return () => queryCache.setQueryData(['article', RatingParams.article.id], oldArticle);
    },
    onError: (error, updateArticle, rollback: unknown) => {
      if (typeof rollback === 'function') {
        rollback();
      }
    },
    onSettled: () => {
      queryCache.invalidateQueries(['article', articleId]);
    },
  });
}
