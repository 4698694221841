import React from 'react';
import PropTypes from 'prop-types';
import { Item, WizardImage, Category } from 'containers/Wizard/constants/Wizard.interface';
import { LinkPopup } from 'containers/Wizard/elements';
import { Image } from 'common';
import { useTranslate } from 'global/hooks';
import styles from './Card.module.scss';

interface CardProps {
  item: Item;
  handleClick: (card: Item) => void;
}

const Card: React.FC<CardProps> = React.memo(({ item, handleClick }) => {
  const translate = useTranslate();
  let type: WizardImage = 'category';
  let modelActive = true;
  if ('isArticle' in item) {
    type = item.isArticle ? 'article' : 'service';
  }
  if ('modelActive' in item) {
    modelActive = item.modelActive;
  }

  return (
    <div
      className={`${styles.card} ${!modelActive ? styles.disabled : ''}`}
      onClick={() => modelActive && handleClick(item)}
      role="presentation"
      title={!modelActive ? translate('COMPONENT.MESSAGE_MODEL') : ''}
    >
      <div className={styles.upper} data-testid="wizard-cardupper">
        <div className={styles.image}>
          <Image id={item.imageId} type={type} />
        </div>
        <h6 title={item.name}>{item.name}</h6>
      </div>
      <p title={item.description}>{item.description}</p>
      {type === 'category' ? (
        <div className={styles.link}>
          <LinkPopup category={item as Category} />
        </div>
      ) : null}
    </div>
  );
});

Card.propTypes = {
  item: PropTypes.any.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default Card;
