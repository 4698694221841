import React from 'react';
import PropTypes from 'prop-types';
import { useToggle } from 'global/hooks';
import { ArrowSVG, ArrowBottomSVG } from 'common/icons';
import styles from './Section.module.scss';

interface SectionProps {
  children: React.ReactNode;
  description?: string;
  title: string;
}
const Section: React.FC<SectionProps> = ({ children, description, title }) => {
  const [open, toggle] = useToggle(true);

  return (
    <div className={styles.section}>
      <div className={styles.header} onClick={toggle} role="presentation">
        <h2>{title}</h2>
        {open ? <ArrowSVG /> : <ArrowBottomSVG />}
      </div>
      <div style={{ display: open ? 'block' : 'none' }}>
        {!!description && <p className={styles.desc}>{description}</p>}
        {children}
      </div>
    </div>
  );
};

Section.defaultProps = {
  description: null,
};

Section.propTypes = {
  children: PropTypes.element.isRequired,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Section;
