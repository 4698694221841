import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { saveAs } from '@progress/kendo-file-saver';
import { Label, Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { SendFile, DeleteAddFieldFile } from 'global/types/Case.interface';
import { useAttachFile, useCreateToken, useDeleteAddFile, useDownloadFile } from 'global/services';
import { useAuthentication, useModal, useResolve, useSnackbar, useTranslate } from 'global/hooks';
import { defaultValidator } from 'global/validators';
import { TrashSVG } from 'common/icons';
import { FileModalTeams } from 'components';
import { FormValidator } from 'containers/Wizard/elements';
import styles from './FileWrapper.module.scss';

interface FileWrapperProps {
  disabled?: boolean;
  id: string;
  itemId?: number;
  itemType?: number;
  label: string;
  required?: boolean;
  valueIntField: number;
}

const FileWrapper: React.FC<FileWrapperProps> = ({
  disabled,
  id,
  itemId,
  itemType,
  label,
  required,
  valueIntField,
}) => {
  const translate = useTranslate();
  const { fileMax } = useAuthentication();
  const { openModal, closeModal } = useModal();
  const { addAlert } = useSnackbar();
  const [temporary, setTemporary] = useState(false);
  const [, meta, helper] = useField<string>({
    name: id,
    validate: required ? defaultValidator : null,
  });
  const { mutateAsync } = useAttachFile();
  const { mutateAsync: deleteMutate } = useDeleteAddFile();
  const { mutate } = useDownloadFile();
  const error = meta?.touched && typeof meta?.error === 'string';
  const { data: dataToken } = useCreateToken();
  const { CustomerURL } = useResolve();
  const teams = window.location.href.includes('teams');

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      if (e.target.files[0].size < fileMax) {
        const sendFile: SendFile = {
          files: e.target.files[0],
          addData: {
            AdditionalFieldId: id,
            FileItemId: itemId,
            FileItemType: itemType,
          },
        };
        await mutateAsync(sendFile, {
          onSuccess: (fileResponse) => {
            addAlert({ msg: translate('COMPONENT.ATTACH_FILE_SUCCESS'), severity: 'success' });
            helper.setValue(fileResponse[0].fileName);
            setTemporary(true);
          },
          onError: (err) => {
            addAlert({ msg: translate(`COMPONENT.${err.message}`), severity: 'error' });
          },
        });
      } else {
        addAlert({
          msg: `${translate('COMPONENT.FILE_SIZE_ERROR')} ${fileMax / 1000000}MB`,
          severity: 'error',
        });
      }
    }
  };

  const handleDelete = async () => {
    const deleteFile: DeleteAddFieldFile = {
      caseData: {
        id: itemId,
        itemTypeId: itemType,
      },
      addFieldId: Number(id),
    };

    await deleteMutate(deleteFile, {
      onSuccess: () => {
        setTemporary(false);
        addAlert({ msg: translate('COMPONENT.DELETE_FILE_SUCCESS'), severity: 'success' });
        helper.setValue('');
      },
      onError: () => {
        addAlert({ msg: translate('COMPONENT.DELETE_FILE_ERROR'), severity: 'error' });
      },
    });
  };

  const downloadFile = () => {
    if (teams && window.matchMedia('(max-width: 1024px)').matches) {
      window.open(`${CustomerURL}/api/v9/file/${id}?id_token=${dataToken}`, '_blank');
    } else if (!temporary && meta.value && valueIntField) {
      mutate(valueIntField, {
        onSuccess: (blob) => {
          saveAs(blob, meta.value);
          addAlert({ msg: translate('COMPONENT.DOWNLOAD_FILE_SUCCESS'), severity: 'success' });
        },
        onError: () => {
          addAlert({ msg: translate('SYSTEM.GENERAL'), severity: 'error' });
        },
      });
    }
  };

  const handleDownloadFile = () => {
    if (teams) {
      openModal(
        <FileModalTeams close={closeModal} download={() => downloadFile()} />,
        translate('COMPONENT.FILE_MODAL'),
        {
          width: 800,
          height: 190,
        }
      );
    } else {
      downloadFile();
    }
  };

  return (
    <div className={`${styles.fileWrap} ${disabled ? styles.disabled : ''}`}>
      <div title={label} className={styles.textLabel}>
        <Label editorId={id} editorValid={!error} editorDisabled={disabled}>
          {`${label}${required ? '(*)' : ''}`}
        </Label>
      </div>
      <div className={styles.file}>
        <div className={styles.fileButton}>
          <span className="k-icon k-i-clip-45">
            {!disabled && <input className={styles.fileInput} type="file" onChange={handleFile} />}
          </span>
        </div>
        <Input
          className={styles.fileText}
          disabled={disabled}
          id={id}
          name={id}
          onClick={handleDownloadFile}
          readOnly
          valid={!error}
          value={meta.value ?? ''}
          title={meta.value ?? ''}
        />
        {!disabled && meta?.value && <TrashSVG className={styles.delete} onClick={handleDelete} />}
      </div>
      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

FileWrapper.defaultProps = {
  disabled: false,
  itemId: null,
  itemType: null,
  required: false,
  valueIntField: null,
};

FileWrapper.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  itemId: PropTypes.number,
  itemType: PropTypes.number,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  valueIntField: PropTypes.number,
};

export default FileWrapper;
