import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Editor, EditorTools } from '@progress/kendo-react-editor';
import { loadMessages } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { useTranslate } from 'global/hooks';
import styles from './AddNote.module.scss';

interface AddNoteProps {
  handleSave: (text: string) => void;
}

const {
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignCenter,
  AlignRight,
  OrderedList,
  UnorderedList,
  ForeColor,
  BackColor,
  Link,
  Unlink,
} = EditorTools;

const esMessages = {
  editor: {
    'hyperlink-dialog-content-address': 'Dirección web',
    'hyperlink-dialog-title': 'Insertar hipervínculo',
    'hyperlink-dialog-content-title': 'Título',
    'hyperlink-dialog-content-newwindow': 'Abrir enlace en una nueva ventana',
    'hyperlink-dialog-cancel': 'Cancelar',
    'hyperlink-dialog-insert': 'Insertar',
  },
};

const ptMessages = {
  editor: {
    'hyperlink-dialog-content-address': 'Endereço da web',
    'hyperlink-dialog-title': 'Insira o hyperlink',
    'hyperlink-dialog-content-title': 'Título',
    'hyperlink-dialog-content-newwindow': 'Abrir link em nova janela',
    'hyperlink-dialog-cancel': 'Cancelar',
    'hyperlink-dialog-insert': 'Inserir',
  },
};

loadMessages(esMessages, 'es');
loadMessages(ptMessages, 'pt');

const AddNote: React.FC<AddNoteProps> = ({ handleSave }) => {
  const translate = useTranslate();
  const [text, setText] = useState<unknown>('');

  const handleNote = () => {
    handleSave(text as string);
  };

  return (
    <div className={styles.adder}>
      <Editor
        tools={[
          [ForeColor],
          [BackColor],
          [Link, Unlink],
          [Bold, Italic, Underline],
          [AlignLeft, AlignCenter, AlignRight],
          [OrderedList, UnorderedList],
        ]}
        onChange={(e) => setText(e.html)}
        className={styles.editor}
      />
      <Button
        disabled={text === '' || text === '<p></p>'}
        id="add-note"
        onClick={handleNote}
        themeColor="primary"
        type="button"
      >
        {translate('COMPONENT.SAVE')}
      </Button>
    </div>
  );
};

AddNote.propTypes = {
  handleSave: PropTypes.func.isRequired,
};

export default AddNote;
