import React from 'react';
import PropTypes from 'prop-types';
import styles from './Title.module.scss';

interface TitleProps {
  svg?: React.ReactNode;
  text: string;
}

const Title: React.FC<TitleProps> = ({ svg, text }) => (
  <div className={styles.title}>
    <div className={styles.icon}>{svg}</div>
    <h2>{text}</h2>
  </div>
);

Title.defaultProps = {
  svg: null,
};

Title.propTypes = {
  svg: PropTypes.element,
  text: PropTypes.string.isRequired,
};

export default Title;
