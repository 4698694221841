import React from 'react';
import PropTypes from 'prop-types';
import styles from './Counter.module.scss';

interface CounterProps {
  number: number;
  text: string;
}

const Counter: React.FC<CounterProps> = React.memo(({ number, text }) => (
  <div className={styles.counter}>
    <span>{`${number} ${text}${number > 1 ? 's' : ''}`}</span>
  </div>
));

Counter.propTypes = {
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

export default Counter;
