export default (code: string): string => {
  if (code?.indexOf('es') > -1) {
    return 'es';
  }
  if (code?.indexOf('en') > -1) {
    return 'en';
  }
  if (code?.indexOf('pt') > -1) {
    return 'pt';
  }
};
