import axios from 'axios';
import { UseQueryResult, useQuery } from 'react-query';
import { Risk } from 'containers/Case/constants/Case.interface';

const getRisk = async (modelId: number, statusId: number) => {
  const data = await axios.get<Risk>(`model/${modelId}/status/${statusId}/risk`);

  if (data.status !== 200) {
    throw Error('Error obtaining Risk');
  }
  return data?.data;
};

export default function useRisk(modelId: number, statusId: number): UseQueryResult<Risk, Error> {
  return useQuery(['risk', modelId, statusId], () => getRisk(modelId, statusId), {
    enabled: !!(modelId && statusId),
  });
}
