import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Label, Error } from '@progress/kendo-react-labels';
import { TimePicker, TimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { useTranslate } from 'global/hooks';
import { defaultValidator } from 'global/validators';
import { FormValidator } from 'containers/Wizard/elements';
import styles from './TimeWrapper.module.scss';

const DEFAULT_TIME_VALUE = -18000000;
interface TimeWrapperProps {
  disabled?: boolean;
  id: string;
  label: string;
  required?: boolean;
}

const TimeWrapper: React.FC<TimeWrapperProps> = ({ disabled, id, label, required }) => {
  const translate = useTranslate();
  const [field, meta, helpers] = useField({
    name: id,
    validate: required ? defaultValidator : null,
  });
  const error = meta?.touched && typeof meta?.error === 'string';

  const handleBlur = () => {
    if (meta.value && required) {
      helpers.setError(undefined);
    }
  };

  const handleUTC = (value: number) => {
    if (value.valueOf() !== DEFAULT_TIME_VALUE) {
      const offset = new Date(value).getTimezoneOffset();
      const date = offset * 60 * 1000;
      return new Date(value + date);
    }
  };

  const handleChange = (e: TimePickerChangeEvent) => {
    const offset = new Date(e.value).getTimezoneOffset();
    const date = offset * 60 * 1000;
    helpers.setValue(new Date((e.value as unknown as number) - date).getTime());
  };

  return (
    <div className={`${styles.timeWrap} ${disabled ? styles.disabled : ''}`}>
      <div title={label} className={styles.textLabel}>
        <Label editorId={id} editorValid={!error} editorDisabled={disabled}>
          {`${label}${required ? '(*)' : ''}`}
        </Label>
      </div>
      <TimePicker
        className={`${styles.time} ${!meta.value ? styles.placeholder : ''} ${
          !!error ? styles.error : ''
        }`}
        disabled={disabled}
        formatPlaceholder="short"
        id={id}
        name={field.name}
        onBlur={handleBlur}
        onChange={handleChange}
        value={meta.value ? handleUTC(meta.value as number) : null}
      />
      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

TimeWrapper.defaultProps = {
  disabled: false,
  required: false,
};

TimeWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default TimeWrapper;
