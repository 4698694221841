import { WizardState, WizardActions, StepType } from 'containers/Wizard/constants/Wizard.interface';
import { initialState, stepperItems } from 'containers/Wizard/constants/Wizard.constants';

const checkItem = (item: StepType, label: string): StepType => ({
  ...item,
  secondary: label,
  disabled: false,
  isValid: true,
  icon: 'k-i-check',
});

const wizardReducer = (state: WizardState, action: WizardActions): WizardState => {
  const prevSteps = [...state.stepperItems];
  switch (action.type) {
    case '1':
      prevSteps[0] = checkItem(prevSteps[0], action.payload.project.name);
      return { ...state, ...action.payload, stepperItems: prevSteps, step: 1 };
    case '2':
      prevSteps[1] = checkItem(prevSteps[1], action.payload.service.name);
      return { ...state, ...action.payload, searchText: '', stepperItems: prevSteps, step: 2 };
    case 'SERVICE_FOLDER':
      return { ...state, ...action.payload };
    case '3':
      prevSteps[2] = checkItem(prevSteps[2], action.payload.category.name);
      return { ...state, ...action.payload, step: 3, stepperItems: prevSteps };
    case 'ARTICLE_DETAIL':
      prevSteps[1] = checkItem(prevSteps[1], action.payload.service.name);
      prevSteps[2] = { ...prevSteps[2], disabled: true };
      return { ...state, ...action.payload, toArticle: true, stepperItems: prevSteps, step: 3 };
    case 'HELPER':
    case 'SEARCH':
      return { ...state, ...action.payload };
    case 'SET_ITEMTYPE':
      return {
        ...state,
        itemType: action.payload.itemType.id,
        showHelper: !!action.payload.itemType.help,
      };
    case 'CONFIG_SERVICE':
      prevSteps[1] = checkItem(prevSteps[1], action.payload.service.name);
      prevSteps[2] = {
        ...stepperItems[2],
      };
      return {
        ...state,
        ...action.payload,
        category: null,
        showHelper: false,
        itemType: null,
        stepperItems: prevSteps,
      };
    case 'CONFIG_CATEGORY':
      prevSteps[2] = checkItem(prevSteps[2], action.payload.category.name);
      return { ...state, ...action.payload, stepperItems: prevSteps };
    case 'STEPPER':
      switch (action.payload.step) {
        case 0:
          return initialState;
        case 1:
          prevSteps[1] = { ...initialState.stepperItems[1] };
          prevSteps[2] = { ...initialState.stepperItems[2] };
          return { ...initialState, stepperItems: prevSteps, project: state.project, step: 1 };
        case 2:
          prevSteps[2] = { ...initialState.stepperItems[2] };
          return {
            ...initialState,
            stepperItems: prevSteps,
            project: state.project,
            service: state.service,
            step: 2,
          };
        default:
          return state;
      }
    case 'DIRECT_ACCESS_1':
      return { ...initialState, step: 3, project: action.payload.project };
    case 'DIRECT_ACCESS_2':
      return { ...state, service: action.payload.service };
    case 'DIRECT_ACCESS_3':
      prevSteps[0] = checkItem(prevSteps[0], state.project.name);
      prevSteps[1] = checkItem(prevSteps[1], state.service.name);
      prevSteps[2] = checkItem(prevSteps[2], action.payload.category.name);
      return {
        ...state,
        category: action.payload.category,
        stepperItems: prevSteps,
      };
    default:
      return state;
  }
};

export default wizardReducer;
