import React, { cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import { FieldMetaProps, useField } from 'formik';
import { AutoComplete, ListItemProps } from '@progress/kendo-react-dropdowns';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Label, Error } from '@progress/kendo-react-labels';
import { CISearch as CISearchType } from 'containers/Wizard/constants/Wizard.interface';
import { useAuthentication, useDebounce, useTranslate } from 'global/hooks';
import { useCISearch } from 'containers/Wizard/services';
import { FormValidator } from 'containers/Wizard/elements';
import styles from './CISearch.module.scss';

interface CISearchProps {
  id: string;
  label: string;
  meta: FieldMetaProps<unknown>;
  disabled: boolean;
  project?: number;
  service?: number;
  itemType?: number;
}

const CISearch: React.FC<CISearchProps> = ({
  id,
  label,
  meta,
  disabled,
  itemType,
  project,
  service,
  ...others
}) => {
  const translate = useTranslate();
  const { user } = useAuthentication();
  const [, , helpers] = useField(id);
  const [text, setText] = useState('');
  const [withResp, setWithResp] = useState(false);
  const debouncedValue = useDebounce<string>(text, 500);
  const { data, isLoading, isFetching } = useCISearch(
    project,
    itemType,
    service,
    debouncedValue,
    withResp,
    user.id
  );

  const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = (
      <div className={styles.searchResult}>
        <span className={styles.title}>{itemProps.dataItem.name}</span>
        <span className={styles.detail}>{`Serial: ${
          itemProps.dataItem.serial ?? translate('WIZARD.NO_INFO')
        }`}</span>
        <span className={styles.detail}>{`${translate('WIZARD.MODEL')}: ${
          itemProps.dataItem.modelName ?? translate('WIZARD.NO_INFO')
        }`}</span>
        <span className={styles.detail}>{`${translate('WIZARD.BRAND')}: ${
          itemProps.dataItem.brandName ?? translate('WIZARD.NO_INFO')
        }`}</span>
        <span className={styles.detail}>{`${translate('WIZARD.TAG')}: ${
          itemProps.dataItem.assetTag ?? translate('WIZARD.NO_INFO')
        }`}</span>
      </div>
    );

    return cloneElement(li, li.props, itemChildren);
  };

  const handleSearch = (value: string | CISearchType) => {
    if (typeof value === 'string') {
      setText(value);
      if (value === '') {
        helpers.setValue(null);
      }
    } else if (typeof value === 'object') {
      setText(value.name);
      helpers.setValue(value);
    }
  };
  const error = (meta?.touched || !meta?.touched) && typeof meta?.error === 'string';
  return (
    <div className={`${styles.searchWrap} ${disabled ? styles.disabled : ''}`}>
      <div className={styles.labelContainer}>
        <div title={label} className={styles.textLabel}>
          <Label editorId={id} editorValid={!error} editorDisabled={disabled}>
            {label}
          </Label>
        </div>
        <Checkbox
          label={translate('WIZARD.RESPONSIBLE_CI')}
          value={withResp}
          onChange={(e) => setWithResp(e.value)}
          disabled={disabled}
        />
        <span className="k-icon k-i-question" title={translate('WIZARD.RESPONSIBLE_CI_HELP')} />
      </div>
      <AutoComplete
        id={id}
        data={data}
        itemRender={itemRender}
        valid={!error}
        {...others}
        value={text}
        disabled={disabled}
        className={styles.autoComplete}
        onChange={(e) => handleSearch(e.target.value)}
        loading={isLoading || isFetching}
        placeholder={translate('WIZARD.MINIMUM_THREE')}
      />
      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

CISearch.defaultProps = {
  project: null,
  service: null,
  itemType: null,
};

CISearch.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.any.isRequired,
  disabled: PropTypes.bool.isRequired,
  project: PropTypes.number,
  service: PropTypes.number,
  itemType: PropTypes.number,
};

export default CISearch;
