import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepperChangeEvent, StepProps } from '@progress/kendo-react-layout';
import { useTranslate, useWindowSize } from 'global/hooks';
import { StepType } from 'containers/Wizard/constants/Wizard.interface';
import styles from './Steps.module.scss';

interface StepsProps {
  step: number;
  items: StepType[];
  handleChange: (e: StepperChangeEvent) => void;
  content: React.ReactNode;
}

const CustomStep = ({ icon, label, secondary, ...props }: StepProps) => (
  <Step {...props}>
    <span className="k-step-indicator">
      <span className={`k-step-indicator-icon k-icon ${icon}`} />
    </span>
    <span className={`k-step-label ${styles.labelOverride} ${secondary ? styles.center : ''}`}>
      <span className="k-step-text">{label}</span>
      <span className={styles.secondary} title={secondary}>
        {secondary}
      </span>
    </span>
  </Step>
);

const Steps: React.FC<StepsProps> = ({ step, items, handleChange, content }) => {
  const translate = useTranslate();
  const [parentLineHeight, setParentLineHeight] = useState(0);
  const [stepperTopPosition, setStepperTopPosition] = useState(0);
  const [height, width] = useWindowSize();

  useEffect(() => {
    const parentHeight = document.getElementById('stepperContainer').clientHeight;
    const lineHeightRatio = 49;
    const lineHeightStepper = parentHeight / lineHeightRatio;
    const topRatio = 14;
    const topStepper = parentHeight / topRatio;
    setParentLineHeight(lineHeightStepper);
    setStepperTopPosition(topStepper * -1);
  }, [parentLineHeight, height, width]);

  const applyTranslations = () =>
    items.map((el) => ({
      ...el,
      label: translate(el.label),
    }));

  return (
    <div className={styles.stepperContainer} id="stepperContainer">
      <div className={styles.stepper} id="stepper" style={{ top: stepperTopPosition }}>
        <Stepper
          value={step}
          item={CustomStep}
          items={applyTranslations()}
          orientation="vertical"
          onChange={handleChange}
          className={styles.stepperOverride}
          style={{ lineHeight: parentLineHeight }}
        />
      </div>
      <div className={styles.step}>{content}</div>
    </div>
  );
};

Steps.propTypes = {
  step: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  content: PropTypes.element.isRequired,
};

export default Steps;
