import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';

const getFileMax = async () => {
  const data = await axios.post<number>(`/setting/value`, {
    applicationCode: 13,
    key: 'FileMaxSize',
    valueType: 'INT',
  });
  if (data.status !== 200) {
    throw Error('Error obtaining max file size');
  }
  let fileMax = 0;
  if (data.data) {
    fileMax = Number(data.data) * 1000000;
  }
  return fileMax;
};

export default function useFileMax(isLogged: boolean): UseQueryResult<number, Error> {
  return useQuery(['fileMax'], () => getFileMax(), { enabled: isLogged });
}
