import { useContext } from 'react';
import { ModalContext } from 'context/withModal';
import { Size } from 'global/types/System.interface';

interface ModalContextProps {
  openModal: (el: React.ReactNode, title: string, size?: Size) => void;
  optionalOpenModal: (el: React.ReactNode, title: string, size?: Size) => void;
  closeModal: () => void;
  optionalcloseModal: () => void;
  openParentModal: (el: React.ReactNode, title: string, size?: Size) => void;
  closeParentModal: () => void;
}

const useModal = (): ModalContextProps => useContext(ModalContext);

export default useModal;
