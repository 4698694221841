const formatJSONDate = (date: string): number => {
  try {
    const formattedJson = new Date(parseInt(date.substr(6), 10));
    return formattedJson.getTime();
  } catch (e) {
    return null;
  }
};

export default formatJSONDate;
