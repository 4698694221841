import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { NoteType } from 'containers/CaseList/constants/CaseList.interface';

const getCaseNotes = async (caseId: number) => {
  const data = await axios.get<NoteType[]>(`/item/${caseId}/history/list?languaje=0&isV8=false`);
  if (data.status !== 200) {
    throw Error('Error obtaining case notes');
  }
  return data?.data;
};

export default function useCaseNotes(caseId: number): UseQueryResult<NoteType[], Error> {
  return useQuery(['casenotes', caseId], () => getCaseNotes(caseId), {
    enabled: false,
    retry: 2,
  });
}
