import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Editor as KEditor, EditorChangeEvent, EditorTools } from '@progress/kendo-react-editor';
import { Label, Error } from '@progress/kendo-react-labels';
import { Button } from '@progress/kendo-react-buttons';
import { useModal, useTranslate } from 'global/hooks';
import { defaultValidator } from 'global/validators';
import { FormValidator } from 'containers/Wizard/elements';
import styles from './Editor.module.scss';
import { ReadWriteEditor } from 'global/hooks/ReadWriteEditor';

interface EditorProps {
  id?: string;
  label?: string;
  disabled: boolean;
  required: boolean;
}

const {
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignCenter,
  AlignRight,
  OrderedList,
  UnorderedList,
  ForeColor,
  BackColor,
} = EditorTools;

const modalTools = [
  [ForeColor],
  [BackColor],
  [Bold, Italic, Underline],
  [AlignLeft, AlignCenter, AlignRight],
  [OrderedList, UnorderedList],
];

const Editor: React.FC<EditorProps> = ({ id, label, disabled, required }) => {
  const translate = useTranslate();
  const { openModal } = useModal();
  const [, meta, helpers] = useField({
    name: id,
    validate: required ? defaultValidator : undefined,
  });
  const [text, setText] = useState('');

  useLayoutEffect(() => {
    if ((meta.value === null || meta.value === '') && text.length > 0) {
      helpers.setValue(text);
    }
  }, [helpers, meta.value, text]);

  useEffect(() => {
    setText(meta?.initialValue ? meta.initialValue : '');
  }, [meta?.initialValue]);

  const handleBlur = () => {
    helpers.setValue(text);
    helpers.setTouched(true);
  };

  const handleChange = (e: EditorChangeEvent) => {
    if (!disabled) {
      if (e.html === '<p></p>') {
        setText('');
      } else {
        setText(e.html);
      }
    }
  };

  const onModalChange = (e: EditorChangeEvent) => {
    helpers.setValue(e.html);
    helpers.setTouched(true);
    setText(e.html);
  };

  const expandTool = () => (
    <Button
      icon="windows"
      onClick={() =>
        openModal(
          <KEditor
            className={styles.modalEditor}
            defaultContent={text || meta?.initialValue}
            tools={modalTools}
            onBlur={handleBlur}
            onChange={onModalChange}
          />,
          label,
          { width: 800, height: 600 }
        )
      }
      type="button"
    />
  );

  const fullTools = [
    [ForeColor],
    [BackColor],
    [Bold, Italic, Underline],
    [AlignLeft, AlignCenter, AlignRight],
    [OrderedList, UnorderedList],
    [expandTool],
  ];

  const error = required && typeof meta?.error === 'string';
  return (
    <div className={styles.editorContainer}>
      <div title={label} className={styles.textLabel}>
        <Label editorDisabled={disabled} editorValid={!error}>
          {`${label}${required ? '(*)' : ''}`}
        </Label>
      </div>
      <ReadWriteEditor
        className={`${styles.editor} idEditor ${error ? styles.error : ''}`}
        defaultContent={meta?.initialValue}
        onBlur={handleBlur}
        onChange={handleChange}
        tools={disabled ? [] : fullTools}
        value={text}
      />
      {error && (
        <>
          <Error>{translate(meta?.error)}</Error> <FormValidator />
        </>
      )}
    </div>
  );
};

Editor.defaultProps = {
  label: null,
};

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
};

export default Editor;
