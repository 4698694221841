import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'global/hooks';
import styles from './StatusLabel.module.scss';

interface StatusLabelProps {
  isClosed: boolean;
}

const StatusLabel: React.FC<StatusLabelProps> = ({ isClosed }) => {
  const translate = useTranslate();
  return (
    <div className={`${styles.statusLabel} ${isClosed ? styles.closedBG : ''}`}>
      <span className="k-i-clock k-icon" />
      {isClosed ? translate('CASELIST.CLOSED') : translate('CASELIST.OPEN')}
    </div>
  );
};

StatusLabel.propTypes = {
  isClosed: PropTypes.bool.isRequired,
};

export default StatusLabel;
