import React from 'react';
import PropTypes from 'prop-types';
import styles from './SubHeader.module.scss';

interface SubHeaderProps {
  children?: React.ReactNode[] | React.ReactNode;
}

const SubHeader: React.FC<SubHeaderProps> = ({ children }) => (
  <div className={styles.subheader}>{children}</div>
);

SubHeader.defaultProps = {
  children: null,
};

SubHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

export default SubHeader;
